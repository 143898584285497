import { Organization } from "src/types";
import { ORGANIZATION_TYPES } from "./types";

function get() {
    return {
        type: ORGANIZATION_TYPES.GET
    }
}

function set(organization: Organization) {
    return {
        type: ORGANIZATION_TYPES.SET,
        payload: { organization }
    }
}

export const organizationActions = {
    get,
    set,
}