import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        backgroundColor: 'white',
        borderRadius: 8,
        border: '1px solid #CFD5E2',
        height: '100%',
        padding: '16px 20px 20px',
        position: 'relative',
    },
    container: {
    },
    menuButton: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
    statusChip: {
        borderRadius: 8,
        fontSize: 12,
        margin: '0 auto',
        marginTop: 8,
        minWidth: 78,
        lineHeight: '16px',
        letterSpacing: '0.4px',
        padding: '0 14px',
        width: 'fit-content',
    },
    iconContainer: {
        '& svg': {
        }
    },
    title: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
    },
    description: {
        fontSize: '12px',
        fontWeight: 450,
        lineHeight: '16px',
    },
    actionButton: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '18px'
    }
}))