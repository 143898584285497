import { TableCellProps } from "@mui/material";
import { SelectValueType } from "src/types";

export interface TableColumnType<T = any> extends TableCellProps {
    label: string;
    key: keyof T;
    render?: (value: any, row?: T) => any | string | Element;
    hidden?: boolean;
    filterOptions?: SelectValueType[];
    onFilterSelect?: (value: SelectValueType) => void;
    disableSortBy?: boolean;
    /** TableCellProps for especific column, replace tableCellProps from TableProps */
    tableCellProps?: TableCellProps;
    /** Hidde in mobile */
    hiddenSm?: boolean;
}

export interface TableActionsType {
    label: string;
    onClick: (row: any) => void;
    disabled?: (row: any) => boolean;
    hidden?: (row: any) => boolean;
    isLink?: boolean;
    isExternalLink?: boolean;
    getHref?: (row: any) => string;
    hideSm?: boolean
}

export interface FilterMenuType {
    anchorEl: null | HTMLElement,
    options: SelectValueType[],
    onClick: (value: SelectValueType) => void
}

export function getTooltipTitle(label: string, sortBy: { key: string, order: string }) {
    if (!sortBy || !sortBy.order) {
        return "Sin orden aplicado";
    }
    let _label: string = sortBy.order === "asc" ? "Orden A-Z" : "Orden Z-A";
    const lowerLabel = label.toLocaleLowerCase();
    if (lowerLabel.includes("fecha")) {
        _label = sortBy.order === "asc" ? "Orden Antiguo-Reciente" : "Orden Reciente-Antiguo";
    } else if (["teléfono", "telefono", "monto"].filter((s) => s.includes(lowerLabel)).length > 0) {
        _label = sortBy.order === "asc" ? "Orden Menor-Mayor" : "Orden Mayor-Menor";
    }
    return _label;
}