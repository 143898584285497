import { ActionableType, CustomSwitchableEvaluator } from "src/types"
import { APPLICATION_EVALUATOR_FIELDS } from "./types"

function executeEvaluators(processType: 'create' | 'update' | 'delete', applicationId: string, actionableType: ActionableType, actionable: any) {
    return {
        type: APPLICATION_EVALUATOR_FIELDS.EXECUTE_EVALUATORS,
        payload: { applicationId, actionable, actionableType, processType }
    }
}

function getActionable(id: string, type: ActionableType) {
    return {
        type: APPLICATION_EVALUATOR_FIELDS.GET_ACTIONABLE,
        payload: { id, type }
    }
}

function setActionable({
    actionable,
    actionableId,
    actionableType
}: {
    actionable?: any,
    actionableId?: string,
    actionableType?: ActionableType
}) {
    return {
        type: APPLICATION_EVALUATOR_FIELDS.SET_ACTIONABLE,
        payload: { actionable, actionableId, actionableType }
    }
}

function getSwitchableEvaluators(applicationId: string) {
    return {
        type: APPLICATION_EVALUATOR_FIELDS.GET_SWITCHABLE_EVALUATORS,
        payload: { applicationId }
    }
}

function setSwitchableEvaluators(switcheableEvaluators: CustomSwitchableEvaluator[] = []) {
    return {
        type: APPLICATION_EVALUATOR_FIELDS.SET_SWITCHABLE_EVALUATORS,
        payload: { switcheableEvaluators }
    }
}

export const applicationEvaluatorFieldsActions = {
    executeEvaluators,
    getActionable,
    setActionable,
    getSwitchableEvaluators,
    setSwitchableEvaluators
}