import { CustomerService, Service } from "src/services"
import { Customer, Employment, Response } from "src/types"
import _ from "lodash"

/**
 * Use to get predetermined customer employment
 * @returns Customer employment
 */
export async function getDefaultCustomerEmployment(): Promise<Employment> {
    try {
        const response: Response = await new Service('employments').all({ search: { s: ['predetermined desc', 'created_at desc'] } })

        if (_.isArray(response?.data) && response.data.length > 0) {
            return response?.data[0]
        }
        return {}
    } catch (ex) {
        console.log(`getDefaultCustomerEmployment Application helper error: ${ex}`)
        return {}
    }
}

/**
 * Use to get current auth customer
 * @returns Customer
 */
export async function getCustomer(): Promise<Customer> {
    try {
        const response: Response = await CustomerService.findOne('')

        if (Boolean(response?.data)) {
            return response.data
        }
        return {}
    } catch (ex) {
        console.log(`getCustomer Application helper error: ${ex}`)
        return {}
    }
}