import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ChipProps, Grid, GridProps } from "@mui/material"
import { Loading } from "src/components/Loading"
import { ApplicationStatus as ApplicationStatusLegacy } from "./_Status"
import { applicationActions, applicationRejectedDocuments } from "src/store/application/actions"

import { useStyles } from "./styles"
import _ from "lodash"

export interface ApplicationStatusCardProps extends GridProps {
    chipProps?: ChipProps
}

export function ApplicationStatusCard({ chipProps, ...rest }: ApplicationStatusCardProps) {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    const { className: gridClassName, ...restGridProps } = rest || {}
    const isLoading = useSelector(state => state.applications.isLoading)
    const lastOneApplication = useSelector(state => state.applications.lastApplication)

    const currentApplicationWorkflowStage = lastOneApplication?.applicationWorkflowStages?.find(stage => stage.stage_id === lastOneApplication?.current_stage?.id)

    useEffect(() => {
        dispatch(applicationActions.getLastOneApplication())
    }, [])

    useEffect(() => {
        if (lastOneApplication?.id) {
            dispatch(applicationRejectedDocuments.hasAny(lastOneApplication.id))
        }
    }, [lastOneApplication?.id])

    return (
        <Loading
            isLoading={isLoading}
            variant="skeleton"
            skeletonsNumber={1}
        >
            <Grid
                container
                item
                className={cx(classes.root, gridClassName)}
                {...restGridProps}
            >
                <Grid item xs={12}>
                    <ApplicationStatusLegacy
                        currentApplicationWorkflowStage={currentApplicationWorkflowStage}
                        application={lastOneApplication}
                    />
                </Grid>
            </Grid>
        </Loading>
    )
}