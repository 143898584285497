import { useState, MouseEvent } from "react";
import {
	Button,
	Grid,
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	MenuItem,
	ListItemText,
	Hidden,
	IconButton,
} from "@mui/material";
import {
	MoreVert as MoreIcon,
	ArrowUpward as UpIcon,
	ArrowDownward as DownIcon,
	Add as AddIcon,
	HelpOutline as HelpIcon
} from "@mui/icons-material";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { EmptyState, ConfirmModal, Loading, Menu, Link, Tooltip } from "src/components";
import { currencyString, segment } from "src/helpers";
import { useStyles } from "./styles";

import { productApplicationActions } from "src/store/productApplication/actions";
import { useEffect } from "react";
import { ProductApplication } from "src/types/productApplication";
import { ProductApplicationDrawer } from "./ProductApplicationDrawer";
import { columns, tooltipTitle } from "./helpers";

export function ProductApplications() {
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const { productApplications, isLoading } = useSelector(state => state.productApplication);
	const isSegmentLoaded = useSelector(state => state.auth.isSegmentLoaded);

	const getProductApplications = () => dispatch(productApplicationActions.getAll());
	const cleanProductApplications = () => dispatch(productApplicationActions.setAll([]));
	const destroy = (id: number) => dispatch(productApplicationActions.destroy(id));

	const [dialogOpen, setDialogOpen] = useState({ open: false, quotation: {} });
	const [confirmation, setConfirmation] = useState({ open: false, productApplicationId: 0 });
	const [menu, setMenu] = useState<{ anchorEl: null | HTMLElement, productApplicationId: '', productApplication: any }>({ anchorEl: null, productApplicationId: '', productApplication: {} });
	const [orderAnchorEl, setOrderAnchorEl] = useState<null | HTMLElement>(null);
	const [productApplicationId, setProductApplicationId] = useState(0);
	const [sortBy, setSortBy] = useState<{ type: string, order: 'asc' | 'desc' }>({ type: '', order: 'asc' });

	function closeMenu() {
		setMenu({ anchorEl: null, productApplicationId: '', productApplication: {} });
	}

	function closeOrderMenu() {
		setOrderAnchorEl(null);
	}

	function handleSortBy(property: string) {
		if (property == sortBy.type) {
			setSortBy({ type: property, order: sortBy.order == 'asc' ? 'desc' : 'asc' });
		} else {
			setSortBy({ type: property, order: 'asc' });
		}
		closeOrderMenu();
	}

	useEffect(() => {
		getProductApplications();
		return () => {
			cleanProductApplications();
		}
	}, [])

	useEffect(() => {
		if (isSegmentLoaded) {
			segment.page('Quotations');
		}
	}, [window.analytics])

	return (
		<>

			<ProductApplicationDrawer
				open={dialogOpen.open}
				quotation={dialogOpen.quotation}
				onClose={() => setDialogOpen({ open: false, quotation: {} })}
			/>

			<ConfirmModal
				open={confirmation.open}
				title="Eliminar cotización"
				description="¿Estás seguro de que deseas eliminar esta cotización?"
				onConfirm={() => {
					if (confirmation.productApplicationId) {
						destroy(confirmation.productApplicationId);
					}
					setConfirmation({ open: false, productApplicationId: 0 });
					closeMenu();
				}}
				onCancel={() => {
					setConfirmation({ open: false, productApplicationId: 0 });
					closeMenu();
				}}
			/>

			<div className={classes.root}>
				<Grid container className={classes.container}>
					<Grid item xs={12} className={classes.titleContainer}>
						<Typography variant="h3"> Cotizaciones </Typography>
						<div>
							{productApplications.length > 0 && (
								<>
									<Hidden smDown>
										{/* <Button
											variant="outlined"
											color="primary"
											className={classes.titleButton}
											style={{ marginRight: 14 }}
											onClick={(event: MouseEvent<HTMLElement>) => setOrderAnchorEl(event.currentTarget)}
										>
											Ordernar por
										</Button> */}
										<Menu
											anchorEl={orderAnchorEl}
											open={Boolean(orderAnchorEl)}
											onClose={closeOrderMenu}
											elevation={0}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
											PaperProps={{
												style: {
													borderRadius: 8,
													boxShadow: '0px 25px 50px rgba(208, 218, 232, 0.25)',
													border: '1px solid rgba(117, 139, 183, 0.3)'
												}
											}}
										>
											{columns.map((column: any, index: number) => {
												if (column.name !== 'Seguros y comisiones')
													return (
														<MenuItem key={index} onClick={() => handleSortBy(column.key)}>
															<ListItemText primary={column.name} />
															{column.key == sortBy.type && (
																sortBy.order == 'asc' ? <DownIcon /> : <UpIcon />
															)}
														</MenuItem>
													)
											})}
										</Menu>
									</Hidden>
									<Button
										variant="contained"
										color="primary"
										className={classes.titleButton}
										onClick={() => setDialogOpen({ open: true, quotation: {} })}
										startIcon={<AddIcon />}
									>
										Nueva
									</Button>
								</>
							)}
						</div>
					</Grid>
					<Loading isLoading={isLoading} variant="skeleton" skeletonContainerProps={{ className: classes.skeletonsContainer }} >
						{productApplications.length === 0 ? (
							<EmptyState text="No tienes ninguna cotización" className={classes.emptyState}>
								<div className={classes.buttonContainer}>
									<Button
										variant="contained"
										color="primary"
										size="large"
										onClick={() => {
											setDialogOpen({ open: true, quotation: {} })
											segment.track('Quotation', 'Clicked', 'New Quotation')
										}}
									>
										Nueva cotización
									</Button>
								</div>
							</EmptyState>
						) : (
							<>
								<Hidden smDown>
									<Table className={classes.table}>
										<TableHead>
											<TableRow className={classes.headerRow}>
												<TableCell align="left"> Valor de la vivienda </TableCell>
												<TableCell align="left"> Enganche </TableCell>
												<TableCell align="left"> Crédito </TableCell>
												<TableCell align="left">
													Plazo
													<Tooltip title={tooltipTitle['plazo']}>
														<HelpIcon />
													</Tooltip>
												</TableCell>
												<TableCell align="left">
													Interés
													<Tooltip title={tooltipTitle['interes']}>
														<HelpIcon />
													</Tooltip>
												</TableCell>
												<TableCell align="left"> Mensualidad </TableCell>
												<TableCell></TableCell>
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{_.orderBy(productApplications, sortBy.type, sortBy.order).map((productApplication: ProductApplication) => (
												<TableRow key={productApplication.id} className={classes.row} hover>
													<TableCell align="left"> {currencyString(productApplication.answers?.valor_del_inmueble)} </TableCell>
													<TableCell align="left"> {currencyString(productApplication.hitch)} </TableCell>
													<TableCell align="left"> {currencyString(productApplication.answers?.monto_del_credito_total)} </TableCell>
													<TableCell align="left"> {productApplication.answers?.plazo_en_anos} años</TableCell>
													<TableCell align="left"> {(parseFloat(productApplication.answers?.tasa_de_interes || '0') * 100).toFixed(2).replace('.0', '')} % </TableCell>
													<TableCell align="left"> {currencyString(productApplication.monthly_payment)} </TableCell>
													<TableCell align="right">
														<Link to={`/home/product_applications/details/${productApplication.id}`}>
															<Button
																color="primary"
																onClick={() => {
																	segment.track('Quotation', 'Clicked', { quotationId: productApplication.id, action: 'View Details' })
																}}
															>
																Ver detalles
															</Button>
														</Link>
													</TableCell>
													<TableCell align="left" style={{ width: 150 }}>
														<div className={classes.monthlyContainer}>
															<IconButton
																data-cy="product_application_actions"
																className={cx(classes.more, { [classes.moreActive]: Boolean(menu.anchorEl) && menu.productApplicationId === productApplication.id })}
																onClick={(e: MouseEvent<HTMLButtonElement>) => {
																	setProductApplicationId(productApplication.id!)
																	setMenu({ anchorEl: e.currentTarget, productApplicationId: productApplication.id, productApplication })
																}}
															>
																<MoreIcon />
															</IconButton>
														</div>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</Hidden>

								<Hidden mdUp>
									{_.orderBy(productApplications, sortBy.type, sortBy.order).map((productApplication: ProductApplication, index: number) => (
										<Grid key={productApplication.id} container className={cx(classes.item, { [classes.firstElement]: index === 0 })}>
											<Grid container>
												<Grid item xs={12} className={classes.headerMobile}>
													<div className={classes.imgContainer}>
													</div>
													<div
														className={cx(classes.more, { [classes.moreActive]: Boolean(menu.anchorEl) && menu.productApplicationId === productApplication.id })}
														onClick={(e: MouseEvent<HTMLDivElement>) => {
															setProductApplicationId(productApplication.id!)
															setMenu({ anchorEl: e.currentTarget, productApplicationId: productApplication.id, productApplication })
														}}
													>
														<MoreIcon />
													</div>
												</Grid>
											</Grid>
											<Grid container className={classes.rowsMobile}>
												<Grid container className={classes.rowMobile}>
													<Grid item xs={6}>
														<Typography> Valor de la vivienda </Typography>
													</Grid>
													<Grid item xs={6}>
														<span> {currencyString(productApplication.answers?.valor_del_inmueble)} </span>
													</Grid>
												</Grid>
												<Grid container className={classes.rowMobile}>
													<Grid item xs={6}>
														<Typography> Enganche </Typography>
													</Grid>
													<Grid item xs={6}>
														<span> {currencyString(productApplication.hitch)} </span>
													</Grid>
												</Grid>
												<Grid container className={classes.rowMobile}>
													<Grid item xs={6}>
														<Typography> Crédito </Typography>
													</Grid>
													<Grid item xs={6}>
														<span> {currencyString(productApplication.answers?.monto_del_credito_total)} </span>
													</Grid>
												</Grid>
												<Grid container className={classes.rowMobile}>
													<Grid item xs={6}>
														<Typography> Plazo </Typography>
													</Grid>
													<Grid item xs={6}>
														<span> {productApplication.answers?.plazo_en_anos} años </span>
													</Grid>
												</Grid>
												<Grid container className={classes.rowMobile}>
													<Grid item xs={6}>
														<Typography> Interés </Typography>
													</Grid>
													<Grid item xs={6}>
														<span> {(parseFloat(productApplication.answers?.tasa_de_interes || '0') * 100).toFixed(2).replace('.0', '')}%</span>
													</Grid>
												</Grid>
												<Grid container className={classes.rowMobile}>
													<Grid item xs={6}>
														<Typography> Mensualidad </Typography>
													</Grid>
													<Grid item xs={6}>
														<span>  {currencyString(productApplication.monthly_payment)} </span>
													</Grid>
												</Grid>
												<Grid container className={classes.rowMobile}>
													<Grid item xs={12}>
														<Link to={`/home/product_applications/details/${productApplication.id}`}>
															<Button
																color="primary"
																onClick={() => {
																	segment.track('Quotation', 'Clicked', { quotationId: productApplication.id, action: 'View Details' })
																}}
															>
																Ver detalles
															</Button>
														</Link>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									))}
								</Hidden>

								<Menu
									anchorEl={menu.anchorEl}
									open={Boolean(menu.anchorEl)}
									onClose={closeMenu}
									PaperProps={{
										className: classes.menuPaper
									}}
								>
									<MenuItem
										className={classes.menuItem}
										onClick={() => {
											setDialogOpen({ open: true, quotation: menu.productApplication })
											segment.track('Quotation', 'Clicked', { quotationId: productApplicationId, action: 'Edit Quotation' })
											closeMenu();
										}}
									>
										Editar
									</MenuItem>
									<MenuItem
										className={classes.menuItem}
										onClick={() => {
											setConfirmation({ open: true, productApplicationId });
											segment.track('Quotation', 'Clicked', { quotationId: productApplicationId, action: 'Delete Quotation' })
											closeMenu();
										}}
									>
										Eliminar
									</MenuItem>
								</Menu>

							</>
						)}
					</Loading>

				</Grid>
			</div>

		</>
	)
}