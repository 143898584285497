import { Fragment, useEffect, useState, MouseEvent } from "react"
import { Grid, Typography, Button, Zoom, IconButton, Chip, MenuItem } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import MoreIcon from "@mui/icons-material/MoreVert"
import { useStyles } from "./styles"

import AutopayImg from "src/assets/img/pago_domiciliado.svg"
import CitibanamexLogo from "src/assets/img/citibanamex.svg"
import BanorteLogo from "src/assets/img/banorte.svg"

import { AutomaticPaymentDrawer } from "./Drawer"
import { useParams } from "react-router-dom"
import { isValidId } from "src/helpers"
import { useDispatch, useSelector } from "react-redux"
import { directsDebitsActions } from "src/store/credits/actions"
import { DirectDebitType } from "src/types"
import { ConfirmModal, Menu } from "src/components"

export function AutomaticPayment() {
    const { creditId }: any = useParams()
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    const [drawer, setDrawer] = useState<{ open: boolean, directDebit?: DirectDebitType }>({ open: false })
    const [menu, setMenu] = useState<{ anchorEl?: HTMLButtonElement, directDebit?: DirectDebitType }>({})
    const [confirmation, setConfirmation] = useState<{ open: boolean, id?: string }>({ open: false })

    const directDebits = useSelector(state => state.credits.directsDebits.data) || []

    useEffect(() => {
        if (isValidId(creditId)) {
            dispatch(directsDebitsActions.getAll(creditId, { perPage: 100 }))
        }
    }, [creditId])

    function closeItemMenu() {
        setMenu({})
    }

    return (
        <>
            <ConfirmModal
                open={confirmation.open}
                title="Eliminar cuenta de domiciliación"
                description="¿Deseas eliminar esta cuenta?"
                confirmText="Sí, eliminar"
                cancelText="Volver"
                onConfirm={() => {
                    dispatch(directsDebitsActions.destroy(creditId, confirmation.id!))
                    setConfirmation({ open: false })
                }}
                onCancel={() => setConfirmation({ open: false })}
            />

            <AutomaticPaymentDrawer
                open={drawer.open}
                onClose={() => setDrawer({ open: false })}
                directDebit={drawer.directDebit}
                defaultDirectDebit={directDebits.find(d => d.default)}
                creditId={creditId}
            />

            <Menu
                anchorEl={menu.anchorEl}
                open={Boolean(menu.anchorEl)}
                onClose={() => setMenu({})}
            >
                <MenuItem
                    onClick={() => {
                        dispatch(directsDebitsActions.update(creditId, menu.directDebit?.id!, {
                            ...menu.directDebit,
                            default: true
                        }))
                        closeItemMenu()
                    }}
                >
                    Marcar como predeterminada
                </MenuItem>
                {/* <MenuItem
                    onClick={() => {
                        setDrawer({ open: true, directDebit: menu.directDebit })
                        closeItemMenu()
                    }}
                >
                    Editar
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setConfirmation({ open: true, id: menu.directDebit?.id })
                        closeItemMenu()
                    }}
                >
                    Eliminar
                </MenuItem> */}
            </Menu>

            <Grid container className={cx(classes.card, classes.item)}>
                {directDebits.length === 0 ? (
                    <Grid container justifyContent="space-between">
                        <Grid item className={classes.flexColumn}>
                            <Typography variant="h3"> Realizar mi pago de forma automática </Typography>
                            <Typography> Activa tu servicio de pago domiciliado automático y asegura tu pago mensual. </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => setDrawer({ open: true })}
                            >
                                Me interesa
                            </Button>
                        </Grid>
                        <Grid item className={classes.imgContainer}>
                            <AutopayImg />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container className={classes.accountContainer}>
                        <Grid item xs={12} className={classes.spaceBetween}>
                            <div className={classes.flexColumn}>
                                <Typography variant="h3"> Realizar mi pago de forma automática </Typography>
                                <Typography> Activa tu servicio de pago domiciliado automático y asegura tu pago mensual. </Typography>
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                className={cx(classes.button, classes.bottomButton)}
                                onClick={() => setDrawer({ open: true })}
                                startIcon={<AddIcon />}
                                style={{ marginTop: 0 }}
                            >
                                Agregar cuenta
                            </Button>
                        </Grid>

                        <Grid container spacing={2} className={classes.account}>
                            {directDebits.map(directDebit => (
                                <Zoom key={directDebit.id} in>
                                    <Grid item xs={12} md={6} style={{ opacity: directDebit.default ? 1 : .5 }}>
                                        <Grid container className={cx(classes.card, classes.accountCard)}>
                                            <Grid item xs={12} className={classes.spaceBetween}>
                                                <Typography className={classes.accountTitle}> Cuenta </Typography>
                                                <div>
                                                    <Chip
                                                        className={cx({
                                                            [classes.inactiveChip]: !directDebit.default,
                                                            [classes.activeChip]: directDebit.default
                                                        })}
                                                        label={directDebit.default ? 'Predeterminado' : 'Inactiva'}
                                                    />
                                                    <IconButton
                                                        className={classes.moreIcon}
                                                        size="small"
                                                        onClick={(e: MouseEvent<HTMLButtonElement>) => setMenu({ anchorEl: e.currentTarget, directDebit })}
                                                    >
                                                        <MoreIcon />
                                                    </IconButton>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.flexColumn}>
                                                <Typography className={classes.accountBankName}> Banco </Typography>
                                                <Typography> {directDebit.bank?.alias} </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.flexColumn}>
                                                <Typography className={classes.accountBankName}> CLABE </Typography>
                                                <Typography> {directDebit.clabe} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Zoom>
                            ))}
                        </Grid>


                    </Grid>
                )}
            </Grid>
        </>
    )
}