import { Grid, GridProps, Typography } from "@mui/material"
import { ReactChild, ReactNode } from "react"
import { useStyles } from "./Card.styles"

interface CardProps extends GridProps {
    title?: string
    description?: string
    disableDivider?: boolean
    titleAction?: ReactNode
    children: ReactChild | ReactChild[]
    hideHeader?: boolean
    titleContainerProps?: GridProps
    contentGridProps?: GridProps
}

export function Card({
    title,
    description,
    titleAction,
    children,
    disableDivider,
    className,
    hideHeader,
    titleContainerProps,
    contentGridProps,
    ...rest
}: CardProps) {
    const { classes, cx } = useStyles()
    const { className: titleContainerClassName, ...restTitleContainerProps } = titleContainerProps || {}

    return (
        <Grid container className={cx(classes.card, className)} {...rest}>
            {!hideHeader && (
                <Grid
                    item
                    xs={12}
                    className={cx(classes.titleContainer, {
                        [classes.spaceBetween]: Boolean(titleAction),
                        [classes.borderBottom]: !disableDivider
                    }, titleContainerClassName)}
                    {...restTitleContainerProps}
                >
                    <div className={classes.flex}>
                        {title && <Typography variant="h3" className={classes.title}> {title} </Typography>}
                        {description && <Typography> {description} </Typography>}
                    </div>
                    {titleAction}
                </Grid>
            )}
            <Grid
                item
                xs={12}
                {...contentGridProps}
                className={cx(classes.contentContainer, contentGridProps?.className)}
            >
                {children}
            </Grid>
        </Grid >
    )
}