import { call, put, takeLatest } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { Action, Response } from "src/types";

import { participantsActions } from "./actions";
import { PARTICIPANTS_TYPES } from "./types";

const creditService = new Service('credits', { api: 'kronos', namespace: 'customer' })

function* getAll({ payload }: Action) {
    const response: Response = yield call(creditService.allPath, `${payload.creditId}/participants`, {})
    yield put(participantsActions.setAll(response.data))
}

export function* participantsSagas() {
    yield takeLatest(PARTICIPANTS_TYPES.GET_ALL, sagaWrapper(getAll, participantsActions.setAll()))
}