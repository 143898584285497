import { ActionableType, CustomSwitchableEvaluatorQualifierField, RejectedDocumentType, RejectedStatusType, ScoringFormulaCriteriaType } from "src/types"

export interface CustomCriteria extends ScoringFormulaCriteriaType {
    value: string;
    valueToShow: string;
}

/** Rejected status array to filter documents allowed to show/render and upload */
export const allowedStatusesToUpload = ['pending', 'failed'] // RejectedStatusType

/** Use to filter approvals by an actionable type and/or actionable id
 * @param approvals Approvals array
 * @param actionableType ApplicationWorkflow, Customer, ...
 * @param actionableId Example: of is actionableType is a Customer and we need filter their approvals, we set actionableId with the customer id/identifier
 * @returns Filtered approvals array
 */
export function filterApprovals(approvals: CustomSwitchableEvaluatorQualifierField[], actionableType?: ActionableType[], actionableId?: string): CustomSwitchableEvaluatorQualifierField[] {
    if (!Boolean(actionableType)) {
        return []
    }
    if (Boolean(actionableId)) {
        return approvals.filter(a =>
            Boolean(a?.actionable_type)
            && actionableType?.includes(a.actionable_type! as ActionableType)
            && a?.actionable_id === actionableId
        ) || []
    } else {
        return approvals.filter(a =>
            Boolean(a?.actionable_type)
            && actionableType?.includes(a.actionable_type! as ActionableType)
        ) || []
    }
}

export function getRejectComments(
    resource_criteria?: {
        rejected: ScoringFormulaCriteriaType[]
    },
    verification_reason?: {
        rejected?: { [key: string]: any }
    },
    comments?: string
): CustomCriteria[] {
    const filledCriteria: CustomCriteria[] = [];

    resource_criteria?.rejected?.forEach(({ key, label, type }) => {
        if (verification_reason && verification_reason?.rejected) {
            if (verification_reason?.rejected[key!])
                filledCriteria.push({
                    key,
                    label,
                    type,
                    value: verification_reason?.rejected[key!],
                    valueToShow: type === 'boolean' ? `${label}` : (`${verification_reason?.rejected[key!]?.includes('-') ? '' : '-'} ${verification_reason?.rejected[key!]}`),
                })
        }
    })

    if (comments) {
        //@ts-ignore
        filledCriteria.push({ valueToShow: `${comments}` })
    }

    return filledCriteria
}