import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    card: {
        border: '1px solid #CFD5E2',
        borderRadius: 8,
        padding: 24,
        background: '#fff',
    },
    item: {
        marginBottom: 24
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        '& > h3': {
            fontSize: 24,
            fontWeight: 'bold',
            color: theme.palette.primary.dark,
            marginBottom: 8
        },
    },
    button: {
        maxWidth: '100%',
        width: 166,
        marginTop: 24,
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    table: {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            padding: 16
        }
    }
}))