import { Button } from "@mui/material";
import SessionExpiredIcon from "src/assets/img/session_expired.svg";
import { TextField, MessagePage, Loading } from "src/components";
import { useStyles } from "./styles";
import { useFormik } from "formik";
import { useIsMobile } from "src/helpers";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { authActions } from "src/store/auth/actions";

export function SessionExpired() {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const isLoading = useSelector(state => state.customer.isLoading);

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: { email: '' },
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(authActions.sendMagicLink(values.email))
        },
        validationSchema: yup.object().shape({
            email: yup.string().email('Debe ser un email válido').required('Ingresa un email')
        })
    });

    return (
        <Loading isLoading={isLoading} className={classes.loadingContainer}>
            <MessagePage
                icon={SessionExpiredIcon}
                title="Tu sesión ha expirado"
                message="No te preocupes, solo inicia sesión nuevamente y te enviaremos un link mágico con el que podrás ingresar de nuevo."
                messageContainerProps={{
                    className: classes.message
                }}
            >
                <form onSubmit={handleSubmit} className={classes.form}>
                    <TextField
                        label="Correo"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.email && touched.email)}
                        helperText={Boolean(errors.email && touched.email) && errors.email}
                    />
                    <div className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            className={classes.button}
                            onClick={() => handleSubmit()}
                            fullWidth={isMobile}
                        >
                            Enviar link mágico
                        </Button>
                    </div>
                </form>
            </MessagePage>
        </Loading>
    )
}