import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    stageTitle: {
        color: theme.typography.body1.color,
    },
    header: {
        paddingBottom: 30
    },
    title: {
        fontWeight: 'bold',
        fontSize: 24,
    },
}))