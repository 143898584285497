import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    statusChip: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        padding: '4px 12px',
        maxWidth: 110,
        minWidth: 100
    },
    cardItem: {
        width: "100%",
        marginBottom: 32,
    },
    card: {
        boxShadow: '0px 5px 22px rgba(57, 74, 97, 0.12)',
        borderRadius: 8,
        border: '1px solid #CFD5E2',
        padding: 24,
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            padding: '24px 16px 16px'
        }
    },
    header: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    workflowTitle: {
        fontSize: 24,
        color: theme.palette.primary.dark,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        }
    },
    details: {
        alignItems: 'center',
        marginTop: 16,
        marginBottom: 16,
        flexWrap: 'nowrap',
        "& p": {
            fontSize: '14px',
            padding: "0 16px",
            borderRight: "1px solid #CFD5E2",
        },
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            flexDirection: 'column',
            marginTop: 10,
            "& p": {
                fontSize: '14px',
                padding: "0",
                marginBottom: 10,
                borderRight: "unset",
            },
        }

    },
    firstDetail: {
        paddingLeft: "0 !important"
    },
    lastDetail: {
        border: 'none !important',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    status: {
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        paddingLeft: 16,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        }
    },
    iconButton: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: 6,
            right: 6
        }
    },
    buttonsContainer: {
        gap: 24
    },
    rejectedDocumentsChip: {
        cursor: 'pointer',
        color: '#F44336',
        background: 'rgba(244, 67, 54, 0.1)',
        '&:hover': {
            background: 'rgba(244, 67, 54, 0.2)',
        },
        '& svg': {
            color: '#FF782D',
            height: 16,
            '&:hover': {
                color: '#FF782D',
            }
        }
    },
    chip: {
        marginLeft: 16,
        height: 24,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
}));