import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        height: 'calc(100vh - 70px)',
        display: 'flex',
        justifyContent: 'center',
        background: 'rgba(117, 139, 183, 0.04)',
        overflow: 'hidden',
    },
    mainContainer: {
        width: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        width: '100%',
        maxWidth: 1440,
        height: 'fit-content',
        padding: '47px 60px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            paddingTop: 40,
            paddingLeft: 16,
            paddingRight: 16,
        }
    },
    hero: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 10px',
        '& > div': {
            '& > h3': {
                color: theme.palette.primary.main,
                fontSize: 60,
                fontWeight: 'bold',
                maxWidth: 743
            },
            '& > p': {
                fontSize: 34,
                marginTop: 32
            }
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            '& > div': {
                '& > h3': {
                    fontSize: '40px !important',
                    maxWidth: '100% !important',
                    lineHeight: '45px'
                },
                '& > p': {
                    fontSize: '18px !important',
                    marginTop: '24px !important'
                }
            },
        }
    },
    heroImage: {
        textAlign: 'right',
        '& svg': {
            maxWidth: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginTop: 24,
        }
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 40
    },
    messageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > h3': {
            fontSize: 24,
            fontWeight: 'bold',
            marginBottom: 8
        },
        '& > p': {
            fontSize: 20,
            marginBottom: 32
        }
    },
    consultantCard: {
        textAlign: 'left'
    }
}));