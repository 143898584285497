import { Action, PaymentType } from "src/types";
import { PAYMENT_TYPES } from "./types";

interface PaymentsStateType {
    payment?: PaymentType,
    isLoading: boolean
}

const INITIAL_STATE: PaymentsStateType = {
    isLoading: false
}

export function paymentsReducer(state: PaymentsStateType = INITIAL_STATE, action: Action): PaymentsStateType {
    const { type, payload } = action
    switch (type) {

        case PAYMENT_TYPES.GET_ONE: {
            return {
                ...state,
                isLoading: true
            }
        }

        case PAYMENT_TYPES.SET_ONE: {
            return {
                ...state,
                isLoading: false,
                payment: payload.payment
            }
        }

        default:
            return state
    }
}