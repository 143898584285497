export type CreditProposalStatusType = 'accepted' | 'rejected'

export const translateCreditProposalStatus: { [key in CreditProposalStatusType]: string } = {
    'accepted': 'Aceptada',
    'rejected': 'Rechazada'
}

export const confirmationModalTitle: { [key in CreditProposalStatusType | '']: string } = {
    'accepted': 'Aceptar propuesta de crédito',
    'rejected': 'Rechazar propuesta de crédito',
    '': '-'
}

export const confirmationModalDescription: { [key in CreditProposalStatusType | '']: string } = {
    'accepted': '¿Deseas aceptar la propuesta de crédito?',
    'rejected': '¿Deseas rechazar la propuesta de crédito?',
    '': '-'
}

export const confirmationModalAction: { [key in CreditProposalStatusType | '']: string } = {
    'accepted': 'Sí, aceptar propuesta',
    'rejected': 'Sí, rechazar propuesta',
    '': '-'
}