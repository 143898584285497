import { useEffect, useState } from "react"
import { Button, Grid } from "@mui/material"
import { Link, StagePage } from 'src/components'
import { ApplicationStatusCard, MainConsultantCard, MainQuoteCard } from "./components"
import { ApplicationCardsMenu } from "./CardsMenu"

import { useDispatch, useSelector } from "react-redux"
import { applicationCategoriesActions } from "src/store/application/actions"

import { hasInstalledApp, segment } from "src/helpers"

import { useStyles } from "./styles"
import _ from "lodash"

export function HomeMainLegacy() {
	const { classes, cx } = useStyles()
	const dispatch = useDispatch()

	const customer = useSelector(state => state.auth.customer)
	const user = useSelector(state => state.auth.customer.user)
	const lastOneApplication = useSelector(state => state.applications.lastApplication)
	const organization = useSelector(state => state.organization)
	const isSegmentLoaded = useSelector(state => state.auth.isSegmentLoaded)
	const canceledCategories = useSelector(state => state.applications.categories?.data || [])

	const hasAbacusInstalled = hasInstalledApp(organization?.installed_apps, 'abacus')

	useEffect(() => {
		if (isSegmentLoaded) {
			segment.page('Home')
		}

		if (canceledCategories.length <= 0) {
			dispatch(applicationCategoriesActions.getAll({
				search: {
					status_eq: 'cancelled'
				},
				perPage: 200
			}))
		}
	}, [window.analytics])

	return (
		<StagePage
			title={`¡Hola ${customer?.first_name || ''}!`}
			titleProps={{
				className: classes.stagePageTitle
			}}
		>
			<Grid
				container
				spacing={3}
			>
				<Grid item xs={12}>
					<ApplicationStatusCard />
				</Grid>

				{(!(["rejected"].includes(lastOneApplication?.status || '')) && user) && (
					<Grid item xs={12} md={6}>
						<MainConsultantCard
							text={"Asesoría"}
							className={classes.consultantCard}
						/>
					</Grid>
				)}

				{hasAbacusInstalled && (
					<Grid item xs={12} md={6}>
						<MainQuoteCard
							text={"Cotizaciones"}
							className={classes.consultantCard}
							actionElement={(
								<Link to="/home/product_applications">
									<Button
										variant="text"
										color="primary"
									>
										Cotizar crédito
									</Button>
								</Link>
							)}
						/>
					</Grid>
				)}

				<Grid item xs={12}>
					<ApplicationCardsMenu />
				</Grid>
			</Grid>
		</StagePage>
	)
}