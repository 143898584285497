export const AUTH_TYPES = {
    SET: "AUTH/SET",
    GET_TOKEN: "AUTH/GET_TOKEN",
    SUCCESS_LOGIN: "AUTH/SUCCESS_LOGIN",
    SEND_MAGIC_LINK: 'AUTH/SEND_MAGIC_LINK',
    GET_CUSTOMER: "AUTH/GET_CUSTOMER",
    SET_CUSTOMER: "AUTH/SET_CUSTOMER",
    LOGOUT: "AUTH/LOGOUT",
    CREATE_ACCOUNT: "AUTH/CREATE_ACCOUNT",
    SET_EMPLOYMENTS: "AUTH/SET_EMPLOYMENTS",
    REDIRECT_LOGIN: "AUTH/REDIRECT_LOGIN",
    SET_LOGROCKET_SESSION: "AUTH/SET_LOGROCKET_SESSION",
    IS_SEGMENT_LOADED: "AUTH/LOADED_SEGMENT",
    ME: "AUTH/ME",
    SET_SCORING_DATA: "AUTH/SET_SCORING_STATUS",
    LOGIN: "AUTH/LOGIN",
    ADD_FAILED_QUEUE: 'AUTH/ADD_FAILED_QUEUE',
    CLEAR_FAILED_QUEUE: 'AUTH/CLEAR_FAILED_QUEUE',
}