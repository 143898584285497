import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    card: {
        border: "1px solid #CFD5E2;",
        boxShadow: "0px 2px 2px rgba(117, 139, 183, 0.1)",
        borderRadius: 8,
        marginBottom: 24,
    },
    cardIconButton: {
        color: '#242C37',
        padding: 0,
        height: 24,
        width: 24,
        '& svg': {
            height: 20
        }
    },
    titleContainer: {
        padding: 8,
        borderBottom: "1px solid #CFD5E2",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        letterSpacing: "0.15px;",
    },
    infoContainer: {
        display: 'grid',
        gridTemplateColumns: "50% 50%",
        padding: 8,
        rowGap: 16,
    },
    inforContainerStriped: {
        padding: 0,
        rowGap: 0,
    },
    label: {
        fontSize: 14,
        letterSpacing: "0.15px;",
        fontWeight: 'bold',
        gridColumn: 1,

    },
    labelStripedGray: {
        background: '#F4F6F9',
        padding: '7px 0',
        paddingLeft: '16px'
    },
    labelStripedWhite: {
        padding: '7px 0',
        paddingLeft: '16px'
    },
    value: {
        fontSize: 14,
        letterSpacing: "0.15px;",
        color: '#8892A0',
        gridColumn: 2,
        wordBreak: 'break-all',
    },
    ul: {
        justifyContent: 'space-between'
    }
}));