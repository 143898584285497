import { Action, AccountStatementType, GetOptionsType } from "src/types";
import { CreditAccountStatementStateType } from "./reducer";
import { CREDIT_ACCOUNT_STATEMENTS_TYPES } from "./types";

function set(key: keyof (CreditAccountStatementStateType), value: any) {
    return {
        type: CREDIT_ACCOUNT_STATEMENTS_TYPES.SET,
        payload: { key, value }
    }
}

function getAll(creditId: string, options?: GetOptionsType) {
    return {
        type: CREDIT_ACCOUNT_STATEMENTS_TYPES.GET_ALL,
        payload: { creditId, options }
    }
}

function setAll(accountStatements?: AccountStatementType[], meta?: any) {
    return {
        type: CREDIT_ACCOUNT_STATEMENTS_TYPES.SET_ALL,
        payload: { accountStatements, meta }
    }
}

function getOne(id: string) {
    return {
        type: CREDIT_ACCOUNT_STATEMENTS_TYPES.GET_ONE,
        payload: { id }
    }
}

function setOne(accountStatement?: AccountStatementType) {
    return {
        type: CREDIT_ACCOUNT_STATEMENTS_TYPES.SET_ONE,
        payload: { accountStatement }
    }
}

function getLastOne(creditId: string) {
    return {
        type: CREDIT_ACCOUNT_STATEMENTS_TYPES.GET_LAST_ONE,
        payload: { creditId }
    }
}

function downloadByUrl(fileUrl: string, fileName: string = 'Estado de cuenta') {
    return {
        type: CREDIT_ACCOUNT_STATEMENTS_TYPES.DOWNLOAD_URL,
        payload: { fileUrl, fileName }
    }
}

export const creditAccountStatementsActions = {
    getAll,
    setAll,
    getOne,
    setOne,
    getLastOne,
    downloadByUrl,
    set,
}