import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    label: {
        fontWeight: 'bold'
    },
    value: {
        fontWeight: 500,
        color: '#656565'
    }
}))