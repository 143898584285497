import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    headerButton: {
        '& svg': {
            height: 24,
            fill: '#323232',
            width: 24,
        }
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '32px',
        textAlign: 'center',
    },
}))