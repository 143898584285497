import { genderOptions, maritalStatusOptions } from "src/helpers";
import { Field, Address, Employment } from "src/types";

// const salarySchemes = [
// 	{ value: "assalaried", caption: "Asalariado" },
// 	{ value: "assimilated", caption: "Asimiliado" },
// 	{ value: "commissioned", caption: "Comisionado" },
// 	{ value: "cash", caption: "En efectivo (Ingresos no comprobables)" },
// 	{ value: "freelance", caption: "Profesionista Independiente" },
// 	{ value: "businessman", caption: "Empresario" },
// ]

const salarySchemes = [
	{ value: "assalaried", caption: "Asalariado" },
	{ value: "freelance", caption: "Profesionista Independiente o Empresario" },
	{ value: "businessman", caption: "Recibo dividendos de mi empresa (utilidades)" },
	{ value: "cash", caption: "Recibo dinero en Efectivo (No tengo depósitos bancarios)" },
]

export const fields: Field[] = [
	// {
	// 	key: 'starts_on',
	// 	name: 'starts_on',
	// 	label: 'Inicio de tu empleo actual',
	// 	required: false,
	// 	field_type: 'date',
	// 	sort_order: 1,
	// 	placeholder: '',
	// 	row: 1,
	// 	width: 6,
	// 	hidden: false,
	// },
	// {
	// 	key: 'company_name',
	// 	name: 'company_name',
	// 	label: 'Empresa',
	// 	required: true,
	// 	field_type: 'text_field',
	// 	sort_order: 1,
	// 	placeholder: 'Ingresa la empresa',
	// 	row: 2,
	// 	width: 12,
	// 	hidden: false,
	// },
	// {
	// 	key: 'position',
	// 	name: 'position',
	// 	label: 'Puesto',
	// 	required: true,
	// 	field_type: 'text_field',
	// 	sort_order: 1,
	// 	placeholder: 'Ingresa tu puesto',
	// 	row: 1,
	// 	width: 12,
	// 	hidden: false,
	// },
	{
		key: 'salary_scheme',
		name: 'salary_scheme',
		label: '¿Cómo compruebas tus ingresos?',
		required: true,
		field_type: 'radio_buttons',
		config: {
			variant: "card",
		},
		sort_order: 2,
		placeholder: '',
		row: 2,
		width: 12,
		hidden: false,
		options: salarySchemes.map(salary_scheme => ({
			value: salary_scheme.value,
			caption: salary_scheme.caption
		})),
		dataCy: 'salary_scheme'
	},
	// {
	// 	key: 'before_taxes_salary',
	// 	name: 'before_taxes_salary',
	// 	label: '¿Cuál es tu ingreso bruto (antes de impuestos) mensual?',
	// 	required: true,
	// 	field_type: 'currency_mxn',
	// 	sort_order: 6,
	// 	placeholder: '',
	// 	row: 3,
	// 	width: 12,
	// 	hidden: false
	// },
	{
		key: 'net_salary',
		name: 'net_salary',
		label: '¿Cuál es tu ingreso neto promedio mensual (después de impuestos) incluyendo ingresos extra?',
		required: true,
		field_type: 'currency',
		sort_order: 2,
		placeholder: '',
		row: 4,
		width: 12,
		hidden: false
	},
	// {
	//     key: 'has_extra_income',
	//     name: 'has_extra_income',
	//     label: '¿Tienes algún ingreso extra?',
	//     required: false,
	//     field_type: 'currency_mxn',
	//     sort_order: 3,
	//     placeholder: '',
	//     row: 5,
	//     width: 12,
	//     hidden: false
	// },

	// {
	//     key: 'extra_income',
	//     name: 'extra_income',
	//     label: '¿Cuál es la fuente de tu ingreso extra?',
	//     required: false,
	//     field_type: 'text_field',
	//     sort_order: 6,
	//     placeholder: '',
	//     row: 6,
	//     width: 12,
	//     hidden: false
	// },
]

export let initialValues: Employment = {
	company_name: '',
	position: '',
	//salary_scheme: '',
	starts_on: new Date(),
	currency: '',
	before_taxes_salary: '',
	net_salary: '',
};

