import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    snackbar: {
        minWidth: 344,
        minHeight: 48,
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 5px 22px rgba(57, 74, 97, 0.2), 0px 7px 8px rgba(57, 74, 97, 0.1)',
        borderRadius: 4
    },
    alert: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 5px 22px rgba(57, 74, 97, 0.2), 0px 7px 8px rgba(57, 74, 97, 0.1)',
        borderRadius: 4,
        minWidth: 344,
        minHeight: 48,
        '& .MuiAlert-icon': {
            marginRight: 0
        }
    },
    success: {
        width: '100%',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), #EFFFDF',
        border: '1px solid #43A547',
        '& svg': {
            fill: '#43A547',
            color: '#43A547'
        }
    },
    warning: {
        width: '100%',
        background: '#FFEFDC',
        border: '1px solid #FF8D02',
        '& svg': {
            fill: '#FF8D02',
            color: '#FF8D02'
        }
    },
    error: {
        width: '100%',
        background: '#FFF1F1',
        border: '1px solid #F23A30',
        '& svg': {
            fill: '#F23A30',
            color: '#F23A30'
        }
    },
    info: {
        border: '1px solid #CFD5E2',
        background: '#FFF'
    },
    typography: {
        fontWeight: 400,
        fontSize: 14,
        color: '#242C37'
    },
}));