import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { rootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";

export type RootState = ReturnType<typeof rootReducer>;
const storeName = `onboarding - ${import.meta.env.DEV ? 'localhost' : import.meta.env.REACT_APP_ENV}`;

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: storeName,
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['auth']
};

const persistedReducer = persistReducer<any>(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    composeWithDevTools(
        applyMiddleware(
            sagaMiddleware
        )
    )
);

export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);