import { useEffect, useState } from "react";
import { TextInput } from "@kredimx/form-builder";
import { Button, Typography, Grid } from "@mui/material";
import { DrawerRight } from "src/components";
import { useStyles } from "./styles"
import { DirectDebitType } from "src/types";

import CitibanamexLogo from "src/assets/img/citibanamex.svg"
import { useDispatch } from "react-redux";
import { directsDebitsActions } from "src/store/credits/actions";


interface AutomaticPaymnetDrawerProps {
    open: boolean
    onClose: () => void
    directDebit?: DirectDebitType
    defaultDirectDebit?: DirectDebitType
    creditId: string
}

export function AutomaticPaymentDrawer({ open, onClose, directDebit, defaultDirectDebit, creditId }: AutomaticPaymnetDrawerProps) {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()
    const [clabe, setClabe] = useState('')

    const submit = () => {
        const _directDebit: DirectDebitType = {
            ...directDebit,
            clabe,
        }
        if (directDebit?.id) {
            dispatch(directsDebitsActions.update(creditId, directDebit.id, _directDebit))
        } else {
            dispatch(directsDebitsActions.create(creditId, _directDebit))
        }
        setClabe('')
        onClose()
    }

    useEffect(() => {
        if (directDebit?.id) {
            setClabe(directDebit?.clabe || '')
        } else {
            setClabe('')
        }
    }, [directDebit?.id])

    return (
        <DrawerRight
            title={directDebit?.id ? 'Editar cuenta' : 'Agregar cuenta'}
            open={open}
            onClose={onClose}
            titleContainerProps={{
                style: {
                    padding: 16
                }
            }}
        >
            {!directDebit?.id && (
                <Typography className={classes.text}>
                    Agrega una cuenta para domiciliar tu pago. Verifica que tu banco se encuentre en la
                    <span className={classes.primaryColor}> lista de bancos disponibles. </span>
                </Typography>
            )}

            <TextInput
                label="Cuenta CLABE"
                className={classes.text}
                value={clabe}
                onChange={(e) => setClabe(e.currentTarget.value)}
                inputProps={{
                    maxLength: 18
                }}
            />

            {defaultDirectDebit?.id && (
                <Grid container className={classes.card}>
                    <Typography color="primary" style={{ fontSize: 12 }}> ASOCIADA </Typography>
                    <Grid item xs={12} className={classes.spaceBetween}>
                        <Typography> Tarjeta </Typography>
                        {defaultDirectDebit.bank?.alias}
                    </Grid>
                </Grid>
            )}

            <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={clabe.length != 18}
                onClick={submit}
            >
                {directDebit?.id ? 'Guardar cambios' : 'Agregar'}
            </Button>
        </DrawerRight>
    )
}