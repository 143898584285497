import { Action, ContractType } from "src/types";
import { CONTRACTS_TYPES } from "./types";

interface CreditStateType {
    contract?: ContractType,
    isLoading: boolean
}

const INITIAL_STATE: CreditStateType = {
    isLoading: false
}

export function contractsReducer(state: CreditStateType = INITIAL_STATE, action: Action): CreditStateType {
    const { type, payload } = action
    switch (type) {

        case CONTRACTS_TYPES.GET_ONE: {
            return {
                ...state,
                isLoading: true
            }
        }

        case CONTRACTS_TYPES.SET_ONE: {
            return {
                ...state,
                isLoading: false,
                contract: payload.contract
            }
        }

        default:
            return state
    }
}