import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        height: 'calc(100vh - 70px)',
        display: 'flex',
        justifyContent: 'center',
        background: 'rgba(117, 139, 183, 0.04)',
        overflow: 'hidden',
    },
    mainContainer: {
        width: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        width: '100%',
        maxWidth: 1440,
        height: 'fit-content',
        padding: '47px 60px',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 16px',
        }
    },
}));