import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	root: {
		padding: '64px 40px',
		[theme.breakpoints.down('sm')]: {
			padding: '64px 16px',
			paddingTop: '80px'

		}
	},
	title: {
		color: theme.palette.primary.dark,
		fontSize: 34,
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			fontSize: 24,
		}
	},
	resultContainer: {
		marginTop: 32
	},
	button: {
	},
	cardsContianer: {
		gap: 27,
	}
}))