import { InputFields } from "@kredimx/abacus-components";
import { InputAdornment } from "@mui/material";
import { useStyles } from "./styles";
import { DrawerRight } from "src/components";
import { CurrencyInput, PRODUCT_TYPES, navigate, segment } from "src/helpers";
import { useSelector } from "react-redux";

interface ProductApplicationDrawerProps {
	open: boolean,
	onClose: () => void
	quotation?: any
}

export function ProductApplicationDrawer({ open, onClose, quotation }: ProductApplicationDrawerProps) {
	const { classes, cx } = useStyles()
	const customer = useSelector((state) => state.auth.customer)
	return (
		<DrawerRight
			title={quotation?.id ? 'Editar cotización' : 'Nueva cotización'}
			open={open}
			onClose={onClose}
		>
			<InputFields
				dryRun
				buttonText={quotation?.id ? "Guardar" : "Calcular"}
				customerId={customer.id}
				productApplicationId={quotation?.id}
				// history={history}
				textFieldProps={{
					size: "medium"
				}}
				currencyInputProps={{
					startAdornment: (
						<InputAdornment className={classes.inputAdornment} position="start"> $ </InputAdornment>
					),
					inputComponent: function Component(props: any) {
						return <CurrencyInput prefix="" {...props} />
					}
				}}
				percentageInputProps={{
					startAdornment: (
						<InputAdornment className={classes.inputAdornment} position="start"> % </InputAdornment>
					),
					endAdornment: null,
				}}
				buttonContainerProps={{
					className: classes.buttonContainer,
				}}
				buttonProps={{
					fullWidth: true,
					className: classes.button,
					onClick: () => {
						navigate.to('/home/product_applications/details');
						segment.track('Quotation', 'Created')
						onClose();
					}
				}}
				productsSearchParams={{
					search: {
						'product_type_not_eq': PRODUCT_TYPES.legacy
					}
				}}
			/>
		</DrawerRight>
	)
}