import { Grid } from "@mui/material";
import { Routes, Sidebar } from "src/components";
import { useStyles } from "./styles";
import { sidebarItems } from "./sidebarItems";
import { NotFound } from "../../NotFound";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "src/store/auth/actions";
import { segment } from "src/helpers";
import { RootState } from "src/store";

export function Profile() {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const isSegmentLoaded = useSelector((state: RootState) => state.auth.isSegmentLoaded);

    useEffect(() => {
        dispatch(authActions.getCustomer({ disable: true }));
    }, [])

    useEffect(() => {
        if (isSegmentLoaded) {
            segment.page('Profile');
        }
    }, [window.analytics])

    return (
        <Grid container className={classes.container}>
            <Sidebar
                items={sidebarItems}
                hover
                sidebarProps={{
                    className: classes.sidebar
                }}
            />
            <div className={classes.content}>
                <Routes
                    routes={sidebarItems as any}
                    notFoundPage={() => <NotFound backgroundColor="#fff" />}
                />
            </div>
        </Grid>
    )
}