import { Fragment } from "react";
import { TextField as MuiTextField, FormLabel, FormControl, TextFieldProps } from "@mui/material";
import { WarningIcon } from "src/assets/icons";
import { useStyles } from "./TextField.styles";
import { SelectInput } from "@kredimx/form-builder";

interface ITextField {
    /** If is select2 the controls becomes a select input (by default is a text input) */
    field_type?: string
}

/** Use to show a input control */
export function TextField({ label, name, value, onChange, error, ...rest }: TextFieldProps & ITextField) {
    const { classes, cx } = useStyles();

    if (rest.field_type === 'select2' || rest.field_type === 'select') {
        return <SelectInput
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            error={error}
            {...rest}
        />
    }

    return (
        <FormControl fullWidth>
            {label && <FormLabel htmlFor={name} className={classes.label}> {label} </FormLabel>}
            <MuiTextField
                {...rest}
                id={name}
                variant="outlined"
                value={value}
                className={cx(classes.textField, rest.className, {
                    [classes.errorColor]: error,
                    [classes.primaryColor]: !error
                })}
                name={name}
                onChange={onChange}
                aria-describedby={`helper-text-${name}`}
                autoComplete="off"
                error={error}
                InputProps={{
                    className: cx(classes.input, rest.InputProps?.className),
                    endAdornment: error ? <WarningIcon /> : <Fragment />,
                    ...rest.InputProps
                }}
                inputProps={{ ...rest.inputProps }}
            />
        </FormControl>
    )
}