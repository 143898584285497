import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        padding: '45px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px 60px',
        }
    },
    stagePageTitle: {
        marginBottom: 32,
        fontSize: 34,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            marginTop: 24,
            marginBottom: 16,
        }
    },
    menuItem: {
        background: "#FFFFFF",
        border: "1px solid #CFD5E2",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: '24px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
            maxWidth: '100%'
        },
    },
    imageAndText: {
        display: 'flex',
        gap: '16px'
    },
    consultantCard: {
        textAlign: 'left'
    },
}));
