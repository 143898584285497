export const CUSTOMER_TYPES = {
    SET: "CUSTOMER/SET",
    UPDATE: "CUSTOMER/UPDATE",

    GET_EMPLOYMENTS: "CUSTOMER/GET_EMPLOYMENTS",
    SET_EMPLOYMENTS: "CUSTOMER/SET_EMPLOYMENTS",
    GET_EMPLOYMENT: "CUSTOMER/GET_EMPLOYMENT",
    SET_EMPLOYMENT: "CUSTOMER/SET_EMPLOYMENT",
    CREATE_EMPLOYMENT: "CUSTOMER/CREATE_EMPLOYMENT",
    UPDATE_EMPLOYMENT: "CUSTOMER/UPDATE_EMPLOYMENT",
    DESTROY_EMPLOYMENT: "CUSTOMER/DESTROY_EMPLOYMENT",
    EXECUTE_EMPLOYMENT_EVALUATORS: "CUSTOMER/EXECUTE_EMPLOYMENT_EVALUATORS",

    GET_ADDRESSES: "CUSTOMER/GET_ADDRESSES",
    SET_ADDRESSES: "CUSTOMER/SET_ADDRESSES",
    GET_ADDRESS: "CUSTOMER/GET_ADDRESS",
    SET_ADDRESS: "CUSTOMER/SET_ADDRESS",
    CREATE_ADDRESS: "CUSTOMER/CREATE_ADDRESS",
    UPDATE_ADDRESS: "CUSTOMER/UPDATE_ADDRESS",
    DESTROY_ADDRESS: "CUSTOMER/DESTROY_ADDRESS",

    HAS_PENDING_APPLICATION: "CUSTOMER/HAS_PENDING_APPLICATION",
    SET_HAS_PENDING_APPLICATION: "CUSTOMER/SET_HAS_PENDING_APPLICATION",
}