import { tss } from "tss-react/mui";

export const usetStyles = tss.create(({ theme }) => ({
    termsConditionsContainer: {
        marginBottom: 8,
    },
    termCondition: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'flex-start',
            '& > span': {
                paddingTop: 11
            }
        }
    },
}));