import { Grid, Typography } from "@mui/material";
import { useStyles } from "./styles"

import CnbvImg from "src/assets/img/authorities/cnbv.png"
import CondusefImg from "src/assets/img/authorities/condusef.png"
import BuroImg from "src/assets/img/authorities/buro.png"

export function AuthoritiesCard() {
    const { classes, cx } = useStyles()

    return (
        <Grid container className={classes.item}>
            <Typography className={classes.title}> Nuestras autoridades </Typography>
            <Grid container>
                <Grid item xs={4} className={classes.imgContainer}>
                    <img src={CnbvImg} />
                </Grid>
                <Grid item xs={4} className={classes.imgContainer}>
                    <img src={CondusefImg} />
                </Grid>
                <Grid item xs={4} className={classes.imgContainer}>
                    <img src={BuroImg} />
                </Grid>
            </Grid>
        </Grid>
    )
}