import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Grid } from "@mui/material"
import { Loading, StagePage } from "src/components"
import { isValidId } from "src/helpers"
import { ActionableType } from "src/types"

import { useDispatch, useSelector } from "react-redux"
import { applicationActions, applicationEvaluatorFieldsActions, applicationRejectedDocuments } from "src/store/application/actions"

import { DocumentsFlowDialog } from "./FlowDialog"
import { PersonalDocumentsSection, PropertyDocumentsSection } from "./Sections"
import { useStyles } from "./styles"

export function DocumentsPage() {
    const { applicationId }: any = useParams()

    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    const [documentsDialog, setDocumentsDialog] = useState<{
        types?: ActionableType[],
        open?: boolean,
        showOnlyDocuments?: boolean
    }>()

    const isLoading = useSelector(state => state.applications.switchableEvaluators.isLoading)

    useEffect(() => {
        if (isValidId(applicationId)) {
            dispatch(applicationActions.getApplication(applicationId))
            dispatch(applicationEvaluatorFieldsActions.getSwitchableEvaluators(applicationId))
        }
    }, [applicationId])

    const openDocumentsDialog = (props: {
        actionableTypes?: ActionableType[],
        actionableType?: ActionableType,
        actionableId?: string,
        showOnlyDocuments?: boolean
    }) => {
        const { actionableId, actionableType, actionableTypes, showOnlyDocuments } = props || {}

        dispatch(applicationEvaluatorFieldsActions.setActionable({
            actionableId,
            actionableType
        }))

        setDocumentsDialog({
            open: true,
            showOnlyDocuments: showOnlyDocuments,
            types: actionableTypes
        })
    }

    return (
        <>
            <DocumentsFlowDialog
                showOnlyDocuments={documentsDialog?.showOnlyDocuments}
                actionableTypes={documentsDialog?.types}
                dialogProps={{
                    open: Boolean(documentsDialog?.open),
                    onClose() {
                        setDocumentsDialog({ open: false })
                    },
                }}
            />

            <StagePage
                title="Documentos Solicitud"
                titleContainerProps={{
                    style: { marginBottom: 32 }
                }}
                titleProps={{
                    className: classes.stageTitle
                }}
                breadcrumbs={[
                    { name: 'Inicio', to: '/home/main' },
                    { name: 'Documentos Solicitud' },
                ]}
            >
                <Loading
                    variant={'skeleton'}
                    isLoading={isLoading}
                >
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <PersonalDocumentsSection
                                applicationId={applicationId}
                                openDocumentsDialog={openDocumentsDialog}
                                setDocumentsDialog={setDocumentsDialog}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <PropertyDocumentsSection
                                applicationId={applicationId}
                                openDocumentsDialog={openDocumentsDialog}
                                setDocumentsDialog={setDocumentsDialog}
                            />
                        </Grid>

                    </Grid>
                </Loading>
            </StagePage>
        </>
    )
}