import { Button, Grid, Typography, MenuItem } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { EmptyState, Link, Loading, Menu } from "src/components";
import { Application } from "src/types";
import { useStyles } from "./styles";
import { segment, isCancelledOrRejected } from "src/helpers";
import AddIcon from "@mui/icons-material/Add";

import { RootState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { applicationActions } from "src/store/application/actions";
import { customerActions } from "src/store/customer/actions";
import { customActions } from "src/store/custom/actions";
import { applicationCategoriesActions } from "src/store/application/categories/actions";

import { CancelApplicationDrawer } from "./CancelApplicationDrawer";
import { ApplicationItem } from "./Item";

export function Applications() {
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();

	const isSegmentLoaded = useSelector((state: RootState) => state.auth.isSegmentLoaded);
	const hasPendingApplication = useSelector(state => state.customer.hasPendingApplication);

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [application, setApplication] = useState<Application>();
	const [open, setOpen] = useState(false);

	const { data: applications, isLoading } = useSelector((state: RootState) => state.applications);

	useEffect(() => {
		dispatch(applicationActions.getApplications())
		dispatch(customerActions.hasPendingApplication())
		dispatch(applicationCategoriesActions.getAll({
			search: {
				status_eq: 'cancelled'
			},
			perPage: 200
		}))
	}, [])

	useEffect(() => {
		if (isSegmentLoaded) {
			segment.page('Applications');
		}
	}, [window.analytics])

	function closeMenu() {
		setAnchorEl(null);
	}

	function handleCreateApplication() {
		if (hasPendingApplication && import.meta.env.REACT_APP_ENV != 'staging') {
			dispatch(customActions.customerHasApplicationInProgress())
		}
	}

	return (
		<>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={closeMenu}
				PaperProps={{
					style: {
						width: 164,
						maxWidth: "100%",
					},
				}}
			>
				<MenuItem
					className={classes.menuItem}
					disabled={isCancelledOrRejected(application)}
					onClick={() => {
						setOpen(true)
						setAnchorEl(null);
					}}
				>
					Cancelar solicitud
				</MenuItem>
			</Menu>

			<CancelApplicationDrawer
				application={application}
				open={open}
				onClose={() => setOpen(false)}
			/>

			<Grid container className={classes.container}>
				<Loading isLoading={isLoading} variant="skeleton">
					{applications?.length === 0 ? (
						<EmptyState text="No tienes ninguna solicitud pendiente" className={classes.emptyState} />
					) : (
						<>
							<Grid container justifyContent="space-between">
								<Grid item >
									<Typography variant="h3" className={classes.pageTitle}>Solicitudes</Typography>
								</Grid>
								<Grid>
									<Link to="/application" disabled={hasPendingApplication && import.meta.env.REACT_APP_ENV != 'staging'}>
										<Button
											color="primary"
											variant="contained"
											startIcon={<AddIcon />}
											onClick={handleCreateApplication}
										>
											Nueva solicitud
										</Button>
									</Link>
								</Grid>
							</Grid>
							<Grid container className={classes.applicationsList}>
								{applications?.map((application: Application) => (
									<ApplicationItem
										key={application.id}
										onMoreClick={(e: MouseEvent<HTMLButtonElement>) => {
											setAnchorEl(e.currentTarget);
											setApplication(application || {})
										}}
										{...application}
									/>
								))}

							</Grid>
						</>
					)}
				</Loading>
			</Grid>
		</>
	)
}