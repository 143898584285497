import { Menu as MuiMenu, MenuProps } from "@mui/material";
import { useStyles } from "./Menu.styles";

export function Menu({ anchorEl, onClose, open, children, PaperProps, ...rest }: MenuProps) {
    const { classes, cx } = useStyles();
    //@ts-ignore
    const { style, ...restPaperProps } = PaperProps || {};

    return (
        <MuiMenu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            elevation={0}
            // getContentAnchorEl={null}
            disablePortal
            className={classes.menu}
            disableAutoFocusItem
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                elevation: 0,
                style: {
                    boxShadow: '0px 2px 2px rgba(57, 74, 97, 0.14), 0px 3px 1px rgba(57, 74, 97, 0.12), 0px 1px 5px rgba(57, 74, 97, 0.2)',
                    borderRadius: 4,
                    ...style
                },
                ...restPaperProps
            }}
            {...rest}
        >
            {children}
        </MuiMenu>
    )
}