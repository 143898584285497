import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        padding: '45px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '32px 16px 60px',
        }
    },
    downloadIcon: {
        padding: 0,
        '& + &': {
            marginLeft: 42,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 10,
            }
        }
    },
    skeletonsContainer: {
        padding: '24px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 30px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '24px 16px'
        }
    },
    emptyState: {
        paddingTop: 48
    },
}));