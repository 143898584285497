import { Route } from "src/types";
import {
    Profile,
    Home,
    Summary,
    StartApplication,
    ApplicationStatus,
    ApplicationRunningScoring,
    ApplicationRunningPublishing,
    ConstultantPage,
    AssignCustomerPage,
    SuccessfulPaymentPage,
} from "src/pages";

import { ApplicationStatusResponsePage } from "src/pages/Application/Status/Response"
import { ApplicationReceivedPage } from "src/pages/Application/Received";
import { ApplicationPaymentsPage } from "src/pages/Application/Payments";

export const privateRoutes: Route[] = [
    {
        path: 'application',
        component: StartApplication,
        isPrivate: true
    },
    {
        path: 'profile',
        component: Profile,
        isParent: true,
        isPrivate: true
    },
    {
        path: 'home/*',
        component: Home,
        isParent: true,
        isPrivate: true,
    },
    {
        path: 'successful-payment',
        component: SuccessfulPaymentPage,
        isParent: false,
        isPrivate: true,
    },
    // {
    //     path: 'summary/:applicationId',
    //     component: Summary,
    //     isParent: true,
    //     isPrivate: true
    // },
    // {
    //     path: 'application/:applicationId/status',
    //     component: ApplicationStatus,
    //     isPrivate: true
    // },
    {
        path: 'application/:applicationId/status/response/detail',
        component: ApplicationStatusResponsePage,
        isPrivate: true
    },
    {
        path: 'application/:applicationId/status/response/page/:status',
        component: ApplicationStatusResponsePage,
        isPrivate: true
    },
    {
        path: 'application/:applicationId/status/response/detail/consultant',
        component: ConstultantPage,
        isPrivate: true
    },
    {
        path: 'application/:applicationId/running_scoring',
        component: ApplicationRunningScoring,
        isPrivate: true
    },
    {
        path: 'application/:applicationId/assign',
        component: AssignCustomerPage,
        isPrivate: true
    },
    {
        path: 'application/:applicationId/received',
        component: ApplicationReceivedPage,
        isPrivate: true
    },
    {
        path: 'application/:applicationId/running_publishing',
        component: ApplicationRunningPublishing,
        isPrivate: true
    },
]