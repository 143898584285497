import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        padding: '45px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '32px 16px 60px',
        }
    },
    emptyState: {
        '& svg': {
            height: 60,
            width: 60,
        },
        '& p': {
            fontSize: 12,
            fontWeight: 700,
            lineHeight: '24px',
            marginTop: 0
        }
    },
    card: {
    },
    label: {
        fontWeight: 900,
        lineHeight: '24px',
        maxWidth: '100%',
        wordBreak: 'break-word',
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
        }
    },
    value: {
        fontWeight: 300,
        lineHeight: '24px',
        maxWidth: '100%',
        wordBreak: 'break-word',
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
        }
    },
}));