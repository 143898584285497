import { Grid, Typography } from "@mui/material"
import { StageStepper } from "src/pages/Summary/Main/StageStepper"
import { Application, ApplicationWorkflowStage } from "src/types"
import { useStyles } from "./styles"
import _ from "lodash"

interface StepperProps {
    currentApplicationWorkflowStage?: ApplicationWorkflowStage
    application?: Application
}

export function Stepper({ currentApplicationWorkflowStage, application }: StepperProps) {
    const { classes, cx } = useStyles()

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Typography variant="h3" className={classes.title}>
                    Proceso de solicitud
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.stepperWrapper}>
                <StageStepper
                    activeStep={currentApplicationWorkflowStage?.sort_order || 0}
                    stages={_.sortBy(application?.applicationWorkflowStages || [], 'sort_order')
                        .filter((stage) => stage.visibility != 'user')}
                    currentStage={currentApplicationWorkflowStage}
                    stepperProps={{
                        orientation: 'horizontal',
                        style: { padding: '32px 0px', },
                        className: classes.stepper,
                        alternativeLabel: true
                    }}
                    stepConnectorProps={{
                        className: classes.stepperConnector
                    }}
                    containerProps={{
                        container: false,
                    }}
                />
            </Grid>
        </Grid>
    )
}