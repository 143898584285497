import { ButtonProps } from "@mui/material";
import { MESSAGE_TYPES } from "./types";


function show(config: { icon?: any, title: string, description?: string, buttonProps?: ButtonProps }) {
    return {
        type: MESSAGE_TYPES.SHOW,
        payload: { ...config }
    }
}

function clean() {
    return {
        type: MESSAGE_TYPES.CLEAN
    }
}

export const messageActions = {
    show,
    clean
}