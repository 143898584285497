import { call, delay, put, takeLatest } from "redux-saga/effects";
import { navigate, sagaWrapper } from "src/helpers";
import { ApplicationService, CustomerService, Service } from "src/services";
import { Action, Response } from "src/types";
import { customerActions } from "./actions";
import { authActions } from "../auth/actions";
import { applicationActions, applicationRejectedDocuments } from "../application/actions";
import { snackbarActions } from "../snackbar/actions";
import { employmentsActions } from "../employments/actions";
import { CUSTOMER_TYPES } from "./types";

const EmploymentService = new Service('employments');
const AddressService = new Service('addresses');
const ScoringScoresService = new Service('scores', { api: 'scoring', namespace: 'customer' });

// CUSTOMER

function* update({ payload }: Action) {
    const response: Response = yield call(CustomerService.update, '', {
        customer: payload.customer
    });
    yield put(snackbarActions.add('success', '¡Hemos guardado tu información!'))
    yield put(authActions.set('customer', response.data));
}

// EMPLOYMENTS

function* getEmployments({ payload }: Action) {
    const response: Response = yield call(EmploymentService.all, { ...payload || {} });
    yield put(customerActions.setEmployments(response.data));
}

function* getEmployment({ payload }: Action) {
    const { id } = payload
    const response: Response = yield call(EmploymentService.findOne, id);
    yield put(customerActions.setEmployment(response.data));
}

function* createEmployment({ payload }: Action) {
    const { employment, notRedirect } = payload;
    yield call(EmploymentService.create, { employment });
    yield put(snackbarActions.add('success', '¡Hemos guardado tu empleo!'));

    if (!notRedirect) {
        yield navigate.to('/profile/employments');
    }
}

function* updateEmployment({ payload }: Action) {
    const { employment, notRedirect } = payload;
    yield call(EmploymentService.update, employment.id, { employment });
    yield put(snackbarActions.add('success', '¡Hemos actualizado tu empleo!'));

    if (!notRedirect) {
        yield navigate.to('/profile/employments');
    }
}

function* executeEmploymentEvaluators({ payload }: Action) {
    const { employment, applicationId, isNew, isDelete } = payload
    let employmentResponse: Response

    if (isDelete) {
        yield call(EmploymentService.destroy, employment?.id)
    } else {
        if (isNew) {
            employmentResponse = yield call(EmploymentService.create, {
                employment: {
                    ...employment,
                    application_workflow_customer_resources_attributes: [
                        { application_workflow_id: applicationId }
                    ]
                }
            })
        } else {
            employmentResponse = yield call(EmploymentService.update, employment.id, { employment })
        }

        yield put(customerActions.setEmployment(employmentResponse?.data))
    }

    yield call(ApplicationService.patchPath, `${applicationId}/resync`, {})
    yield call(ScoringScoresService.allPath, `${applicationId}/retry_all_evaluators`, {})

    yield delay(5000) // there is a delay job in back-end

    yield put(applicationRejectedDocuments.getAll(applicationId))
    yield put(customerActions.set('isLoadingEvaluators', false))

    if (isDelete) {
        yield put(snackbarActions.add('success', 'Empleo borrado con éxito'))
    } else {
        yield put(snackbarActions.add('success', 'Empleo guardado con éxito'))
    }
}

function* destroyEmployment({ payload }: Action) {
    const { id, notRedirect } = payload;
    yield call(EmploymentService.destroy, id);
    yield put(snackbarActions.add('success', '¡Hemos eliminado tu empleo!'));

    if (!notRedirect) {
        yield put(customerActions.getEmployments())
        yield navigate.to('/profile/employments');
    }
}

// ADDRESSES

function* getAddresses() {
    const response: Response = yield call(AddressService.all, {});
    yield put(customerActions.setAddresses(response.data));
}

function* getAddress({ payload }: Action) {
    const { id } = payload;
    const response: Response = yield call(AddressService.findOne, id);
    yield put(customerActions.setAddress(response.data));
}

function* createAddress({ payload }: Action) {
    const { address } = payload;
    yield call(AddressService.create, { address });
    yield put(snackbarActions.add('success', '¡Hemos guardado tu dirección!'));
    yield navigate.to('/profile/addresses');
}

function* updateAddress({ payload }: Action) {
    const { address } = payload;
    yield call(AddressService.update, address.id, { address });
    yield put(snackbarActions.add('success', '¡Hemos actualizado tu dirección!'));
    yield navigate.to('/profile/addresses');
}

function* destroyAddress({ payload }: Action) {
    const { id } = payload;
    yield call(AddressService.destroy, id);
    yield put(snackbarActions.add('success', '¡Hemos eliminado tu dirección!'));
    yield put(customerActions.getAddresses())
    yield navigate.to('/profile/addresses');

}

function* hasPendingApplication() {
    const response: { in_process_or_draft: boolean } = yield call(CustomerService.getPath, `with_application_workflows`, false);
    yield put(customerActions.setHasPendingApplication(Boolean(response?.in_process_or_draft)));
}

export function* customerSagas() {
    yield takeLatest(CUSTOMER_TYPES.UPDATE, sagaWrapper(update));
    yield takeLatest(CUSTOMER_TYPES.CREATE_EMPLOYMENT, sagaWrapper(createEmployment));
    yield takeLatest(CUSTOMER_TYPES.GET_EMPLOYMENTS, sagaWrapper(getEmployments, customerActions.setEmployments([])));
    yield takeLatest(CUSTOMER_TYPES.GET_EMPLOYMENT, sagaWrapper(getEmployment, customerActions.setEmployment({})));
    yield takeLatest(CUSTOMER_TYPES.UPDATE_EMPLOYMENT, sagaWrapper(updateEmployment));
    yield takeLatest(CUSTOMER_TYPES.DESTROY_EMPLOYMENT, sagaWrapper(destroyEmployment));
    yield takeLatest(CUSTOMER_TYPES.GET_ADDRESSES, sagaWrapper(getAddresses, customerActions.setAddresses([])));
    yield takeLatest(CUSTOMER_TYPES.GET_ADDRESS, sagaWrapper(getAddress, customerActions.setAddress({})));
    yield takeLatest(CUSTOMER_TYPES.CREATE_ADDRESS, sagaWrapper(createAddress));
    yield takeLatest(CUSTOMER_TYPES.UPDATE_ADDRESS, sagaWrapper(updateAddress))
    yield takeLatest(CUSTOMER_TYPES.DESTROY_ADDRESS, sagaWrapper(destroyAddress))
    yield takeLatest(CUSTOMER_TYPES.HAS_PENDING_APPLICATION, sagaWrapper(hasPendingApplication))
    yield takeLatest(CUSTOMER_TYPES.EXECUTE_EMPLOYMENT_EVALUATORS, sagaWrapper(executeEmploymentEvaluators))
}