import { call, put, takeLatest } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { Action, Response } from "src/types";

import { amortizationTablesActions } from "./actions";
import { AMORTIZATION_TABLES_TYPES } from "./types";

const amortizationTablesService = new Service('amortization_tables', { api: 'kronos', namespace: 'customer' })
const creditService = new Service('credits', { api: 'kronos', namespace: 'customer' })

function* getCurrent({ payload }: Action) {
    const response: Response = yield call(creditService.allPath, `${payload.creditId}/amortization_table`, {})
    yield put(amortizationTablesActions.setOne(response.data))
}

function* getOne({ payload }: Action) {
    const response: Response = yield call(amortizationTablesService.findOne, payload.id)
    yield put(amortizationTablesActions.setOne(response.data))
}

export function* amortizationTablesSagas() {
    yield takeLatest(AMORTIZATION_TABLES_TYPES.GET_ONE, sagaWrapper(getOne, amortizationTablesActions.setOne()))
    yield takeLatest(AMORTIZATION_TABLES_TYPES.GET_CURRENT, sagaWrapper(getCurrent, amortizationTablesActions.setOne()))
}