import { Divider, Drawer, Grid, IconButton, Typography } from "@mui/material";
import { ArrowBackIos, KeyboardArrowLeft } from "@mui/icons-material";
import { ComponentType, Fragment } from "react";
import { DrawerRight, Link } from "src/components";
import { useStyles } from "./styles";
import { getApplicationStatusIcon } from "src/helpers";
import { Application } from "src/types";

interface SectionItem {
	id?: string;
	icon?: ComponentType;
	name?: string;
	path?: string; // Use this if you need to declare params
	to: string; // Use this to redirect replacing the params
	isMenuItem?: boolean;
	component?: ComponentType
	status?: string;
	isParent?: boolean;
	sortOrder?: number;
	disabled?: boolean;
	/** Use for validate if a app is installed */
	app?: 'abacus';
}

interface SectionsDrawerProps {
	open: boolean;
	onClose: () => void;
	stageName?: string;
	items: SectionItem[];
	onItemClick?: (item: any) => void;
	hover?: boolean;
	exactRoutes?: boolean;
	showStatusIcon?: boolean
	sectionsIndex?: 'progress_line' | 'sections'
}

export function SectionsDrawer({
	open,
	onClose,
	stageName,
	items = [],
	onItemClick,
	hover,
	exactRoutes,
	showStatusIcon,
	sectionsIndex
}: SectionsDrawerProps) {
	const { classes, cx } = useStyles()

	function onClick(item: SectionItem, index: number) {
		if (typeof (onItemClick) === 'function') {
			onItemClick(item);
		}
		onClose()
	}

	return (
		<Drawer
			open={open}
			onClose={onClose}
			anchor="right"
			variant="temporary"
			PaperProps={{
				className: classes.paper,
			}}
			BackdropProps={{
				invisible: true
			}}
		>
			<Grid container direction="column">
				<Grid
					container
					direction="row"
					alignItems="center"
					className={classes.header}
				>
					<IconButton onClick={onClose}>
						<KeyboardArrowLeft />
					</IconButton>
					<Typography className={classes.title}>{stageName}</Typography>
				</Grid>

				<div className={classes.divider} />

				<Grid container direction="column" className={classes.sectionsContainer}>
					{items
						.filter(section => sectionsIndex == 'progress_line' ? !section.disabled : true)
						.map((item: SectionItem, index: number) => {
							const Icon = item.icon || Fragment;
							const Component = (item.isParent || item.disabled) ? Fragment : Link;
							const props = (item.isParent || item.disabled) ? {} : { to: item.to };

							if (item.isMenuItem)
								return (
									<Fragment key={index}>
										{/*@ts-ignore*/}
										<Component {...props}>
											<div
												onClick={() => item.disabled ? null : onClick(item, index)}
												className={cx(classes.item, {
													[classes.hover]: hover && !item.disabled,
													[classes.selectedItem]: (exactRoutes && !!!item.isParent)
														? window.location.pathname === item.to.replace('?from=summary', '').replace('?from=stage_summary', '')
														: window.location.pathname.includes(item.to?.toLowerCase())
												})}
											>
												<div className={classes.itemIconContainer}>
													<Icon />
													<Typography className={cx({ [classes.disabledText]: item.disabled })} > {item.name} </Typography>
												</div>
												<div className={classes.statusIconContainer}>
													{showStatusIcon && getApplicationStatusIcon(item.status || '')}
												</div>
											</div>
										</Component>
									</Fragment>
								)
						})}
				</Grid>

			</Grid>
		</Drawer>
	)
}