import { Action, MetaDataType, Workflow } from "src/types"
import { DEFAULT_WORKFLOWS_TYPES } from "./types"

interface DefaultWorkflowsState {
    data?: Workflow[]
    isLoading: boolean
    meta: MetaDataType
}

const INITIAL_STATE: DefaultWorkflowsState = {
    isLoading: false,
    meta: {
        page: 1,
        perPage: 20,
        total: 0,
        totalPages: 0
    },
}

export function defaultWorkflowsReducer(state: DefaultWorkflowsState = INITIAL_STATE, action: Action): DefaultWorkflowsState {
    const { payload, type } = action
    switch (type) {

        case DEFAULT_WORKFLOWS_TYPES.GET_ALL: {
            return {
                ...state,
                isLoading: true
            }
        }

        case DEFAULT_WORKFLOWS_TYPES.SET_ALL: {
            return {
                ...state,
                isLoading: false,
                data: payload.workflows,
                meta: {
                    page: payload.meta?.current_page || state.meta.page,
                    perPage: payload.meta?.per_page || state.meta.perPage,
                    total: payload.meta?.total || state.meta.total,
                    totalPages: payload.meta?.total_pages || state.meta.totalPages
                }
            }
        }

        default:
            return state
    }
}