// import { Worker } from '@phuocng/react-pdf-viewer';
// import Viewer from '@phuocng/react-pdf-viewer';
// import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { useStyles } from "./PdfViewer.styles"
import { Grid, GridProps } from '@mui/material';

export interface PdfViewerProps {
    /** Pdf link (by default is https.../Placeholder-PDF.pdf) */
    url: string;
    containerProps?: GridProps;
}

/** Use to show a pdf by its url */
export function PdfViewer({ url, containerProps }: PdfViewerProps) {
    const { classes, cx } = useStyles();
    const { className, ...rest } = containerProps || {};

    return null

    // return (
    //     <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
    //         <Grid className={cx(classes.viewer, className)} {...rest}>
    //             <Viewer
    //                 fileUrl={url || 'https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf'}
    //                 defaultScale={1.25}
    //             />
    //         </Grid>
    //     </Worker>
    // )
}
//pdfjs-dist@2.3.200 2.4.456
