export const PRODUCT_APPLICATION_TYPES = {
    GET_ALL: 'PRODUCT_APPLICATION/GET_ALL',
    SET_ALL: 'PRODUCT_APPLICATION/SET_ALL',
    DESTROY: 'PRODUCT_APPLICATION/DESTROY',
    SET: 'PRODUCT_APPLICATION/SET',
    SEND_RESULT: 'PRODUCT_APPLICATION/SEND_RESULT',
    GET_ONE: 'PRODUCT_APPLICATION/GET_ONE',
    SET_ONE: 'PRODUCT_APPLICATION/SET_ONE',

    GET_DATA: "QUOTATION/GET_DATA",
    SET_DATA: "QUOTATION/SET_DATA",
    CLEAN_QUOTATION: "QUOTATION/CLEAN_QUOTATION",
}