import { AppType } from "src/types";

/**
 * Verify if installedApps param contains specific app
 * @param installedApps Installed applications object
 * @param app Application type
 * @returns boolean
 */
export function hasInstalledApp(installedApps: any, app: AppType) {
	return Object(installedApps).hasOwnProperty(app)
}