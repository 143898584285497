export enum UserStatus {
    inactive = 0,
    active = 1,
    banned = -1
}

export enum Gender {
    male = 0,
    female = 1
}

export const genderOptions = [
    { caption: "Hombre", value: Gender.male },
    { caption: "Mujer", value: Gender.female }
]

export enum MaritalStatus {
    // single = 0, 
    // married = 1, 
    // divorced = 2,
    // separated = 3, 
    // living_together = 4, 
    // widowed = 5,
    single = "single",
    married = "married",
    divorced = "divorced",
    separated = "separated",
    living_together = "living_together",
    widowed = "widowed",
}

export const maritalStatusOptions = [
    { caption: "Soltero", value: MaritalStatus.single },
    { caption: "Casado", value: MaritalStatus.married },
    { caption: "Divorciado", value: MaritalStatus.divorced },
    { caption: "Separado", value: MaritalStatus.separated },
    { caption: "Unión libre", value: MaritalStatus.living_together },
    { caption: "Viudo", value: MaritalStatus.widowed }
]

export function getEmploymentType(type?: 'salaried' | string): string {
    switch (type) {
        case 'assalaried': return 'Asalariado';
        case 'assimilated': return 'Asimiliado';
        case 'commissioned': return 'Comisionado';
        case 'cash': return 'En efectivo (Ingresos no comprobables)';
        case 'freelance': return 'Profesionista Independiente';
        case 'businessman': return 'Empresario';
        default: return '';
    }
}