import { QUESTION_TYPES } from "./types";

function getQuestions(applicationId: string) {
	return {
		type: QUESTION_TYPES.GET_QUESTIONS,
		payload: { applicationId }
	}
}

function setQuestions(questions: any[]) {
	return {
		type: QUESTION_TYPES.SET_QUESTIONS,
		payload: { questions }
	}
}

function getConditionedQuestions(fieldId: string, values: any) {
	return {
		type: QUESTION_TYPES.GET_CONDITIONED_QUESTIONS,
		payload: { fieldId, values }
	}
}

/**
 * Save answers and change to next section
 * @param answers 
 * @param isLast 
 * @returns 
 */
function saveAnswers(answers: any, options: { isLastSection?: boolean, handleSummary?: boolean }) {
	return {
		type: QUESTION_TYPES.SAVE_ANSWERS,
		payload: { answers, options }
	}
}

function getAddressByZipCode(zipCode: string, keys: any[], fieldKey: string, currentValues: any, isValid: boolean) {
	return {
		type: QUESTION_TYPES.GET_ADDRESS_BY_ZIP_CODE,
		payload: { zipCode, keys, fieldKey, currentValues, isValid }
	}
}


function handleZipCodeError() {
	return {
		type: QUESTION_TYPES.HANDLE_ZIP_CODE_ERROR
	}
}

function downloadFile(fileName: string, fileUrl: string) {
	return {
		type: QUESTION_TYPES.DOWNLOAD_FILE,
		payload: { fileName, fileUrl }
	}
}

function answersBackup(answers: any) {
	return {
		type: QUESTION_TYPES.ANSWERS_BACKUP,
		payload: { answers }
	}
}

/**
 * Only save answers, without change section
 * @param answers 
 * @returns 
 */
function onlySaveAnswers(answers: any) {
	return {
		type: QUESTION_TYPES.ONLY_SAVE_ANSWERS,
		payload: { answers }
	}
}

function cleanCountryInputs({
	key,
	value,
	dependencies,
	currentValues,
	removeRequiredOnly
}: {
	key: string,
	value: string,
	dependencies: any,
	currentValues: any,
	removeRequiredOnly?: boolean
}) {
	return {
		type: QUESTION_TYPES.CLEAN_COUNTRY_INPUTS,
		payload: { key, value, dependencies, currentValues, removeRequiredOnly }
	}
}

export const questionActions = {
	getQuestions,
	setQuestions,
	getConditionedQuestions,
	saveAnswers,
	getAddressByZipCode,
	handleZipCodeError,
	downloadFile,
	answersBackup,
	onlySaveAnswers,
	cleanCountryInputs
}