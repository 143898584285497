import { Button, Grid, Hidden, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import ReceviedImg from "src/assets/img/received.svg"
import { Link } from "src/components";
import { useStyles } from "./styles";

export function ApplicationReceivedPage() {
    const { classes, cx } = useStyles();
    const { applicationId }: any = useParams();

    return (
        <Grid container className={classes.root}>
            <Grid container className={classes.mainContainer}>
                <Grid container className={classes.container}>
                    <Grid item xs={12} className={classes.hero}>
                        <div className={classes.heroTexts}>
                            <Typography variant="h3"> Hemos recibido tu información </Typography>
                            <Typography> ¡Gracias! Nuestro equipo se encuentra trabajando en procesar tu información. Te informaremos sobre actualizaciones muy pronto. </Typography>
                            <Hidden mdUp>
                                <div className={classes.image}>
                                    <ReceviedImg />
                                </div>
                            </Hidden>
                            <Link to={`/`}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                >
                                    Ir a Inicio
                                </Button>
                            </Link>
                        </div>
                        <Hidden smDown>
                            <div className={classes.image}>
                                <ReceviedImg />
                            </div>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}