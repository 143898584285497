import { ReactChild, ReactNode, useEffect, useState } from "react";
import { Grid, GridProps, Typography, TypographyProps, Accordion, AccordionProps, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useStyles } from "./TitleCard.styles"
import _ from "lodash"

export type TitleCardProps = Omit<GridProps, 'title'> & Omit<AccordionProps, 'title'> & {
    title: string | ReactNode;
    children: ReactChild | ReactChild[] | Element | Element[] | undefined
    titleContainerProps?: GridProps
    titleProps?: TypographyProps
    containerProps?: GridProps
    titleAction?: ReactNode
    /**
     * Renders the card as an accordion
     */
    collapsable?: boolean
    /**
     * Sets the default expanded state of the accordion
     */
    defaultExpanded?: boolean
    /**
     * Callback for when the accordion is expanded or collapsed
     * @param expanded 
     * @returns 
     */
    onExpandedChange?: (expanded: boolean) => void
}

export function TitleCard({
    title,
    children,
    titleContainerProps,
    containerProps,
    titleAction,
    className,
    titleProps,
    collapsable,
    defaultExpanded,
    onExpandedChange,
    ...rest
}: TitleCardProps) {
    const { classes, cx } = useStyles()
    const [expanded, setExpanded] = useState(defaultExpanded ?? true)
    const { className: titleContainerClassName, ...restTitleContainerProps } = titleContainerProps || {}
    const { className: containerClassName, ...restContainerProps }: any = containerProps || {}
    const { className: titleClassName, ...restTitleProps } = titleProps || {}

    const ContainerComponent = collapsable ? Accordion : Grid
    const TitleComponent = collapsable ? AccordionSummary : Grid
    const ContentComponent = collapsable ? AccordionDetails : Grid

    const containerPropsComponent = collapsable ? {
        expanded: expanded,
        className: cx(classes.container, classes.accordion, { ['active']: expanded }),
        onChange: () => {
            setExpanded(!expanded)
            if (onExpandedChange) onExpandedChange(!expanded)
        },
        ...rest
    } : {
        className: cx(classes.container, className),
        ...rest
    }

    const titleComponentProps = collapsable ? {
        expandIcon: <ExpandMoreIcon />,
        className: cx(classes.sectionNameContainer, classes.accordionSummary, titleContainerClassName, {
            [classes.spaceBetween]: Boolean(titleAction)
        }),
        ...restTitleContainerProps
    } : {
        className: cx(classes.sectionNameContainer, titleContainerClassName, {
            [classes.spaceBetween]: Boolean(titleAction)
        }),
        ...restTitleContainerProps
    }

    useEffect(() => {
        setExpanded(defaultExpanded ?? true)
    }, [collapsable])

    return (
        // @ts-ignore
        <ContainerComponent
            container
            {...containerPropsComponent}
        >
            {/* @ts-ignore */}
            <TitleComponent
                item
                xs={12}
                {...titleComponentProps}
            >
                {_.isString(title) ? (
                    <Typography className={cx(classes.title, titleClassName)} {...restTitleProps}>
                        {title}
                    </Typography>
                ) : (
                    title
                )}
                {titleAction}
            </TitleComponent>
            <ContentComponent
                item
                xs={12}
                className={cx(classes.summaryData, containerClassName)}
                {...restContainerProps}
            >
                {children}
            </ContentComponent>
        </ContainerComponent>
    )
}