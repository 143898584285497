import { useEffect, useState } from "react";
import queryString from 'query-string';

/** Use to get query params from url */
export function useQueryParams() {
    const [queryParams, setQueryParams] = useState<any>({});

    useEffect(() => {
        setQueryParams(queryString.parse(window.location.search));
    }, [window.location.search])

    return queryParams;
}