import { call, put, takeLatest, select, debounce, all, takeEvery } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { Action, Response, Organization } from "src/types";
import { RootState } from "..";
import { PROVIDERS_TYPES } from "./types";
import _ from "lodash";
import { BelvoCredentialType, associationToSingular } from "@kredimx/form-builder";
import { providersActions } from "./actions";

const addressService = new Service('addresses', { isOptionalToken: true });
const applicationService = new Service('application_workflows');
const organizationProviderService = new Service('organization_provider');
const belvoService = new Service('belvo');
const documentTypeService = new Service('document_types')
// const bankService = new Service('banks', { api: 'kronos', namespace: 'customer' })
const bankService = new Service('banks', { api: 'kronos', disableNamespace: true })
const incodeService = new Service('incode')
const konciergeService = new Service('services')
const incodeDocumentsService = new Service('documents', { api: 'koncierge', namespace: 'incode' })
const krediDocumentsService = new Service('documents', { api: 'koncierge', namespace: 'kredi' })
const incodeVerificationFlowsService = new Service('verification_flows', { api: 'koncierge', namespace: 'incode' })

//#region CreditReports
function* getCreditReports({ payload }: Action) {
    const response: Response = yield call(applicationService.allPath, `${payload.applicationId}/application_workflow_credit_reports`, {});
    yield put(providersActions.setCreditReports(response.data));
}

function* getContractDetails({ payload }: Action) {
    const response: Response = yield call(organizationProviderService.allPath,
        `customer/config/collection/details?provider=weesign&detail_name=signature&credit_report_id=${payload.creditReportId}`, {}, false
    );
    yield put(providersActions.setContractDetails(response.data));
}
//#endregion

//#region MATI
function* getMatiData({ payload }: Action) {
    const response: Response = yield call(organizationProviderService.allPath,
        `customer/config/collection/details?provider=mati&detail_name=client_id&verification_flow_id=${payload.flow_id}`, {}, false
    );
    yield put(providersActions.setMatiData({
        customFlowId: payload.flow_id,
        clientId: response.data?.client_id,
        flowId: response.data?.flow_id,
    }))
}
//#endregion

//#region BELVO
function* getBelvoToken({ payload }: Action) {
    const { credentialId } = payload;
    const organization: Organization = yield select((state: RootState) => state.organization);
    const response: { access: string, refresh: string } = yield call(organizationProviderService.allPath,
        `customer/config/collection/details?provider=belvo&
		detail_name=access_token&
		branding[company_name]=${organization.name}
		${credentialId ? `&credential_id=${credentialId}` : ''}`, {}, false
    );
    yield put(providersActions.setBelvoData(credentialId ? 'updateAccessToken' : 'accessToken', response.access))
}

function* sendBelvoData({ payload }: Action) {
    const { credential } = payload;
    const response: Response = yield call(belvoService.createPath, `customer/credentials/collection/upsert `, { credential });
    yield put(providersActions.setBelvoCredentials([response.data]));

    if (response.data?.id) {
        yield put(providersActions.getBelvoAccounts(response.data?.id));
    }
}

function* getBelvoCredentials({ payload }: Action) {
    const response: Response = yield call(belvoService.allPath, `customer/credentials?q[external_id_eq]=${payload.linkId}`, {});
    yield put(providersActions.setBelvoCredentials(response.data));
    yield all(response.data?.map((credential: BelvoCredentialType) => put(providersActions.getBelvoAccounts(credential.id))))
}

function* getBelvoAccounts({ payload }: Action) {
    const { credentialId } = payload;
    const response: Response = yield call(belvoService.allPath, `customer/accounts?q[credential_id_eq]=${credentialId}`, {});
    yield put(providersActions.setBelvoAccounts(credentialId, response.data));
}

function* destroyBelvoCredential({ payload }: Action) {
    const { credentialId, linkId } = payload;
    yield call(belvoService.destroyPath, `customer/credentials`, credentialId);
    if (linkId) {
        yield put(providersActions.setBelvoCredentials());
    } else {
        yield put(providersActions.getBelvoCredentials(linkId));
    }
}
//#endregion

//#region ADDRESS
function* getCountries() {
    const response: Response = yield call(addressService.allPath, 'countries', {});
    yield put(providersActions.setCountries(response.data));
}

function* getStates({ payload }: Action) {
    if (payload.countryCode) {
        const response: Response = yield call(addressService.allPath, `states?state[country_code]=${payload.countryCode}`, {});
        yield put(providersActions.setStates(response.data));
    } else {
        yield put(providersActions.setStates());
    }
}

function* getMunicipalities({ payload }: Action) {
    if (payload.state) {
        const response: Response = yield call(addressService.allPath, `municipalities?municipality[state]=${payload.state}`, {});
        yield put(providersActions.setMunicipalities(response.data));
    } else {
        yield put(providersActions.setMunicipalities());
    }
}
//#endregion

//#region ASSOCIATIONS
function* getAssociations({ payload }: Action) {
    const response: Response = yield call(applicationService.allPath, `${payload.applicationId}/${payload.type}`, {});
    yield put(providersActions.setAssociations(payload.type, response.data));
}


function* createAssociation({ payload }: Action) {
    yield call(applicationService.createPath, `${payload.applicationId}/${payload.type}`, {
        [associationToSingular[payload.type]]: payload.association
    });
    yield put(providersActions.getAssociations(payload.type, payload.applicationId));
}

function* updateAssociation({ payload }: Action) {
    yield call(applicationService.updateCustomPath, `${payload.applicationId}/${payload.type}/${payload.id}`, {
        [associationToSingular[payload.type]]: payload.association
    });
    yield put(providersActions.getAssociations(payload.type, payload.applicationId));
}

function* destroyAssociation({ payload }: Action) {
    yield call(applicationService.destroyPath, `${payload.applicationId}/${payload.type}`, payload.id);
}

function* getBanks() {
    const response: Response = yield call(bankService.all, {})
    yield put(providersActions.setBanks(response.data))
}

function* getDocumentTypes() {
    const response: Response = yield call(documentTypeService.all, {})
    yield put(providersActions.setDocumentTypes(response.data))
}
//#endregion

//#region INCODE
function* getIncodeVerificationFlows() {
    const response: Response = yield call(incodeVerificationFlowsService.allPath, `all`, {}, false)
    yield put(providersActions.setIncodeVerificationFlows(response.data))
}

function* getIncodeVerificationFlow({ payload }: Action) {
    const response: Response = yield call(incodeVerificationFlowsService.getPath, `all?flow_id=${payload.id}`, false)
    yield put(providersActions.setIncodeVerificationFlow(response.data))
}

function* getIncodeData() {
    const response: Response = yield call(organizationProviderService.allPath,
        `customer/config/collection/details?provider=incode&detail_name=api_key`, {}, false
    );
    yield put(providersActions.setIncodeData(response.data))
}

function* getIncodeSession({ payload }: Action) {
    const response: Response = yield call(incodeDocumentsService.all, {
        search: {
            application_workflow_id_eq: payload.applicationWorkflowId,
            application_id_eq: payload?.applicationId,
            m: 'or'
        }
    })

    if (_.isArray(response?.data) && !_.isEmpty(response?.data)) {
        yield put(providersActions.setIncodeSessions(response.data))
    } else {
        yield put(providersActions.setIncodeSessions([{ interview_id: 'null' }]))
    }
}

function* incodeUpsert({ payload }: Action) {
    yield call(incodeDocumentsService.createPath, `upsert`, payload.data)
}

function* krediUpsert({ payload }: Action) {
    yield call(krediDocumentsService.createPath, `upsert`, payload.data)
}

//#endregion

export function* providersSagas() {
    yield takeLatest(PROVIDERS_TYPES.GET_APPLICATION_CREDIT_REPORTS, sagaWrapper(getCreditReports));
    yield takeLatest(PROVIDERS_TYPES.GET_CONTRACT_DETAILS, sagaWrapper(getContractDetails));
    yield takeEvery(PROVIDERS_TYPES.GET_MATI_DATA, sagaWrapper(getMatiData));
    yield takeLatest(PROVIDERS_TYPES.GET_BELVO_TOKEN, sagaWrapper(getBelvoToken));
    yield takeLatest(PROVIDERS_TYPES.SEND_BELVO_DATA, sagaWrapper(sendBelvoData));
    yield debounce(500, PROVIDERS_TYPES.GET_BELVO_CREDENTIALS, sagaWrapper(getBelvoCredentials));
    yield takeLatest(PROVIDERS_TYPES.GET_BELVO_ACCOUNTS, sagaWrapper(getBelvoAccounts));
    yield takeLatest(PROVIDERS_TYPES.GET_COUNTRIES, sagaWrapper(getCountries, providersActions.setCountries()));
    yield takeLatest(PROVIDERS_TYPES.GET_STATES, sagaWrapper(getStates, providersActions.setStates()));
    yield takeLatest(PROVIDERS_TYPES.GET_MUNICIPALITIES, sagaWrapper(getMunicipalities, providersActions.setMunicipalities()));
    yield takeLatest(PROVIDERS_TYPES.DESTROY_BELVO_CREDENTIAL, sagaWrapper(destroyBelvoCredential))
    yield takeLatest(PROVIDERS_TYPES.GET_ASSOCIATIONS, sagaWrapper(getAssociations));
    yield takeLatest(PROVIDERS_TYPES.CREATE_ASSOCIATION, sagaWrapper(createAssociation));
    yield takeLatest(PROVIDERS_TYPES.UPDATE_ASSOCIATION, sagaWrapper(updateAssociation));
    yield takeLatest(PROVIDERS_TYPES.DESTROY_ASSOCIATION, sagaWrapper(destroyAssociation));
    yield debounce(500, PROVIDERS_TYPES.GET_BANKS, sagaWrapper(getBanks, providersActions.setBanks()))
    yield debounce(500, PROVIDERS_TYPES.GET_DOCUMENT_TYPES, sagaWrapper(getDocumentTypes, providersActions.setDocumentTypes()))
    yield takeLatest(PROVIDERS_TYPES.GET_INCODE_VERIFICATION_FLOWS, sagaWrapper(getIncodeVerificationFlows, providersActions.setIncodeVerificationFlows()))
    yield debounce(500, PROVIDERS_TYPES.GET_INCODE_VERIFICATION_FLOW, sagaWrapper(getIncodeVerificationFlow, providersActions.setIncodeVerificationFlow()))
    yield debounce(500, PROVIDERS_TYPES.GET_INCODE_DATA, sagaWrapper(getIncodeData))
    yield debounce(500, PROVIDERS_TYPES.GET_INCODE_SESSION, sagaWrapper(getIncodeSession))
    yield takeLatest(PROVIDERS_TYPES.INCODE_UPSERT, sagaWrapper(incodeUpsert))
    yield takeLatest(PROVIDERS_TYPES.KREDI_UPSERT, sagaWrapper(krediUpsert))
}