import { GridSize } from "@mui/material"
import { CreditProposalType } from "src/types"
import _ from "lodash"
import { creditProposalSchemas, currencyString, formatDate, percentage } from "src/helpers"

export interface CreditProposalFieldType<T = CreditProposalType> {
    label: string,
    key: keyof T,
    width: GridSize,
    render?: (value: T[keyof T], row: T) => string
}

export function getCreditDataFields(canSeeRealExpiresAt: boolean, productTypeName?: string): CreditProposalFieldType[] {
    const fields = [...creditDataFields]

    if (productTypeName) {
        fields.push({
            sortOrder: 1,
            label: 'Políticas de crédito',
            key: 'application_workflow_internal_id',
            width: 4,
            render: value => productTypeName
        })
    }

    if (!canSeeRealExpiresAt) {
        fields.pop()
    }

    return _.sortBy(fields, 'sortOrder')
}

export const creditDataFields: (CreditProposalFieldType & { sortOrder: number })[] = [
    {
        sortOrder: 0,
        label: 'Número interno',
        key: 'application_workflow_internal_id',
        width: 4
    },
    {
        sortOrder: 2,
        label: 'Estado',
        key: 'property_state',
        width: 4,
    },
    {
        sortOrder: 3,
        label: 'Municipio',
        key: 'property_municipality',
        width: 4,
    },
    {
        sortOrder: 4,
        label: 'Fecha de autorización',
        key: 'authorized_at',
        width: 4,
        render: value => value ? formatDate(value, 'DD [de] MMMM YYYY') : ''
    },
    {
        sortOrder: 5,
        label: 'Vigencia',
        key: 'soft_expires_at',
        width: 4,
        render: value => value ? formatDate(value, 'DD [de] MMMM YYYY') : ''
    },
    {
        sortOrder: 5,
        label: 'Vigencia definitiva',
        key: 'expires_at',
        width: 4,
        render: value => value ? formatDate(value, 'DD [de] MMMM YYYY') : ''
    },
]

export const financialConditionsFields: CreditProposalFieldType[] = [
    {
        label: 'Producto',
        key: 'workflow_title',
        width: 4
    },
    {
        label: 'Esquema',
        key: 'schema',
        width: 4,
        render: value => creditProposalSchemas.find(s => s.value == value)?.caption || ''

    },
    {
        label: 'Tasa de interés (anual)',
        key: 'annual_interest',
        width: 4,
        render: value => percentage(value)
    },
    {
        label: 'Valor del inmueble máximo',
        key: 'max_property_value',
        width: 4,
        render: value => currencyString(value)
    },
    {
        label: 'Crédito máximo autorizado',
        key: 'max_approved_amount',
        width: 4,
        render: value => currencyString(value)
    },
    {
        label: 'Monto solicitado',
        key: 'requested_amount',
        width: 4,
        render: value => currencyString(value)
    },
    {
        label: 'Plazo',
        key: 'years',
        width: 4,
        render: value => value ? `${value} años` : ''
    },
    {
        label: 'Comisión por apertura',
        key: 'commission_to_open',
        width: 4,
        render: value => percentage(value)
    },
    {
        label: 'Financiar CxA',
        key: 'finance_commission_to_open',
        width: 4,
        render: value => value ? 'Sí' : 'No'
    },
    {
        label: 'Porcentaje (%) máximo autorizado a financiar',
        key: 'max_loan_capacity_percentage',
        width: 4,
        render: value => percentage(value)
    },
    {
        label: 'Observaciones',
        key: 'notes',
        width: 12,
        render: (value, row: CreditProposalType) => (
            row.notes?.length > 0
                ? row.notes[0].description || '-'
                : '-'
        )
    },
]

export function getProposalTermsAndConditions(legalName: string, expires_at: any) {
    return `Propuesta de crédito condicionada a la vigencia establecida anteriormente. Esta carta de autorización se encuentra sujeta a las siguientes condiciones: </br>
    1. Veracidad y autenticidad de la Información entregada en la solicitud de crédito.</br>
    2. Mantener adecuado comportamiento crediticio y de endeudamiento reportado en el Buro de crédito del Solicitante y en su caso, del Participante del crédito a la fecha de autorización.</br>
    3. Entrega de la documentación requerida, completa y correcta de acuerdo al destino, los participantes del crédito y que la información de la documentación coincida con la que se obtuvo en la solicitud de crédito.</br>
    4. El monto del crédito efectivamente otorgado será el menor entre el monto de crédito máximo autorizado y el monto que represente el aforo máximo autorizado sobre el mínimo entre el valor de compra venta o el valor avalúo.</br>
    5. El valor mínimo del inmueble es de $2,500,00 MXN para Ciudad de México y de $1,500,00 MXN para cualquier otro estado del país.</br>
    6. Se agrega algún participante en el crédito.</br></br>

    La presente constancia no constituye una Oferta Vinculante ni garantiza una tasa financiera, pues se emite únicamente con el propósito de informarle que <b> ${legalName} </b> ha recibido información suficiente para iniciar el trámite de formalización de un crédito hipotecario, por lo que dicha constancia únicamente tiene como objetivo compartirle una idea preliminar de las condiciones que pudieran ser aplicables al mismo, sujeta a los cambios y condiciones ya mencionados; no obstante lo anterior, usted tiene el derecho de solicitar una Oferta Vinculante en cualquier momento.`
}