import { BankStatementInput as KrediTaxIdStatusInput } from "@kredimx/form-builder"
import { ApplicationService } from "src/services"
import { UploadComponentProps } from ".."

import { providersActions } from "src/store/providers/actions"
import { snackbarActions } from "src/store/snackbar/actions"

import _ from "lodash"

export function TaxIdStatusInput({
    onUpload,
    fieldKey,
    approval,
    actionable,
    approvalId,
    application,
    customer,
    dispatch
}: UploadComponentProps) {
    return (
        <KrediTaxIdStatusInput
            {...approval?.field}
            config={{
                ...approval?.field?.config,
                comments: approval?.comments,
                rejectedReasons: approval?.verification_reason?.rejected,
                requestReasons: approval?.notes?.map((note) => note?.note_text || '')
            }}
            //@ts-ignore
            name={fieldKey}
            application={application}
            customer={customer}
            user={customer}
            userType="Customer"
            actionableId={actionable?.id}
            actionableType={actionable?.type}
            approval_id={approvalId}
            hidePreviousFile
            onChangeCurrentAttachment={onUpload}
            uploadAttachment={async (attachment: any, application: any, id: string) => {
                return await ApplicationService.patchPath(`${application?.id}/answers/${id}/manage`, attachment)
            }}
            upsert={data => {
                dispatch(providersActions.krediUpsert(data))
            }}
            onError={details => {
                dispatch(snackbarActions.add('error', 'Lo sentimos, ocurrió un error al procesar tu documento'))
            }}
        />
    )
}