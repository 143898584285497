import { takeLatest, put, call, select } from "redux-saga/effects";
import { sagaWrapper, cookies } from "src/helpers";
import { getSubdomain, isKrediDomain, getHost, isDev } from 'src/helpers';
import { OrganizationService } from "src/services";
import { ORGANIZATION_TYPES } from "./types";
import { organizationActions } from "./actions";
import { Organization, Response } from "src/types";
import { RootState } from "..";

function* get() {
    const slug = !isKrediDomain() && !isDev() ? getHost() : getSubdomain();
    const response: Response = yield call(OrganizationService.findBySlugOrDomain, slug);
    yield put(organizationActions.set(response.data));
}

function* getToken() {
    const organization: Organization = yield select((state: RootState) => state.organization);
    if (organization.id) {
        const tokenResponse: Response = yield call(OrganizationService.getToken, organization.id!);
        yield cookies.set('org_auth', JSON.stringify(tokenResponse.data), {
            path: '/',
            domain: window.location.hostname
        });
    }
}

export function* organizationSagas() {
    yield takeLatest(ORGANIZATION_TYPES.GET, sagaWrapper(get));
    yield takeLatest(ORGANIZATION_TYPES.GET_TOKEN, sagaWrapper(getToken));
}