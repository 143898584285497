import { tss } from "tss-react/mui";
//@ts-ignore
import tinycolor from "tinycolor2";

export const useStyles = tss.create(({ theme }) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		gap: 8,
		'& p': {
			fontSize: 16,
			color: '#8892A0',
			// width: '40%',
			// paddingRight: 8,
			'& span': {
				color: '#242C37',
			},
		},
		[theme.breakpoints.down('sm')]: {
			gap: 2
		}
	},
	progressLine: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		width: '60%',
		maxWidth: 84,
		'& > div': {
			position: 'absolute',
			top: '50%',
			height: 4,
			borderRadius: 8,
		},
		'& > :first-child': {
			background: theme.palette.primary.main,
			// width: '20%',
			zIndex: 2,
		},
		'& > :last-child': {
			background: tinycolor(theme.palette.primary.main).setAlpha(.3),
			width: '100%',
		},
	},
	progressLineDisabled: {
		'& > :last-child': {
			background: 'rgba(117, 139, 183, 0.08)',
			// width: '20%',
			zIndex: 2,
		},
	},
	disabled: {
		'& p': {
			color: '#B4BAC3'

		},
		'& p span': {
			color: '#B4BAC3'
		}
	}
}));
