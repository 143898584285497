import { tss } from "tss-react/mui";
//@ts-ignore
import tinycolor from "tinycolor2";

export const useStyles = tss.create(({ theme }) => ({
	addArea: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		height: 100,
		width: '100%',
		border: '2px dashed #CFD5E2',
		borderRadius: 4,
		cursor: 'pointer',
		transition: '.2s',
		marginBottom: '1em',
		marginTop: 16,
		background: tinycolor(theme.palette.primary.main).setAlpha(.03),
		'& h6': {
			margin: 0,
			marginTop: 8,
			fontSize: 14,
			color: '#8892A0',
			fontWeight: 400
		},
		'& p': {
			margin: 0,
			fontSize: 12,
			color: '#8892A0',
		}
	},
	addAreaError: {
		border: '2px dashed #F23A30',
		background: '#FFF1F1',
		'&:hover': {
			border: '2px dashed #F23A30 !important',
			transition: '.2s',
		}
	},
	hover: {
		'&:hover': {
			border: '2px dashed' + theme.palette.primary.main,
			transition: '.2s',
		}
	},
	icon: {
		fill: theme.palette.primary.main + "!important"
	},
	iconError: {
		fill: "#F23A30 !important"
	},
	file: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid #CFD5E2',
		background: 'rgba(117, 139, 183, 0.04)',
		borderRadius: 4,
		minHeight: 40,
		marginBottom: 16,
		padding: '8px 16px',
		'& svg': {
			fill: '#323232'
		}
	},
	loader: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& > p': {
			marginBottom: 4
		}
	},
	errorLabel: {
		color: '#F23A30'
	}
}));