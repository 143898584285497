import { Fragment } from "react"
import { Button, Grid } from "@mui/material"
import { Link } from "src/components"
import { MainSmallCard } from "../components"
import { mainCardItems } from "../mainCardsItems"
import { useIsMobile } from "src/helpers"

import { useSelector } from "react-redux"

import { useStyles } from "./styles"
import _ from "lodash"

export function ApplicationCardsMenu() {
    const { classes, cx } = useStyles()
    const isMobile = useIsMobile()

    const organization = useSelector(state => state.organization)
    const menuItems = mainCardItems(organization)

    return (
        <Grid
            container
            alignItems="stretch"
            className={classes.smallCardsContainer}
            spacing={!isMobile ? 4 : 2}
        >
            {_.map(menuItems, (item, index) => {
                const { isExternalLink, linkTo, linkTitle, image: Icon, title, description, disabled, onClick } = item
                const LinkComponent = isExternalLink ? 'a' : (onClick ? Fragment : Link);

                const linkProps = isExternalLink ? {
                    href: linkTo,
                    target: '_blank',
                    rel: 'noreferrer',
                    className: classes.link
                } : {
                    to: linkTo,
                    native: linkTo.includes('http'),
                    onClick: () => {
                        if (onClick) {
                            onClick()
                        }
                    }
                }

                return (
                    <MainSmallCard
                        key={index}
                        Icon={Icon}
                        title={title}
                        description={description}
                        actionElement={(
                            //@ts-ignore
                            <LinkComponent {...linkProps}>
                                <Button
                                    variant="text"
                                    color="primary"
                                    className={classes.button}
                                    disabled={disabled}
                                    onClick={() => {
                                        if (onClick) {
                                            onClick()
                                        }
                                    }}
                                >
                                    {linkTitle}
                                </Button>
                            </LinkComponent>
                        )}
                        rootGridProps={{
                            item: true,
                            container: true,
                            xs: 6,
                            lg: 3,
                        }}
                    />
                )
            })}
        </Grid>
    )
}