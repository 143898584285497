import { Button, Typography } from '@mui/material';
import { useStyles } from './styles';
import ExpiredLinkImage from "./expired_link.svg"
import { Link } from 'src/components';
import { useSelector } from 'react-redux';

export function ExpiredLinkPage() {
    const { classes, cx } = useStyles();
    const organization = useSelector(state => state.organization)

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.imageContainer}>
                    <ExpiredLinkImage />
                </div>
                <div>
                    <Typography component='h1' className={classes.title}>
                        ¡Lo sentimos!
                    </Typography>
                </div>
                <div>
                    <Typography component='h2' className={classes.subtitle}>
                        El link que intentas abrir ha expirado
                    </Typography>
                </div>
                <div>
                    <Link native to={organization.website || "/"}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Ir a {organization.name}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}