import { PRODUCT_APPLICATION_TYPES } from "./types";
import { ProductApplicationState } from "./reducer";

function getAll() {
    return {
        type: PRODUCT_APPLICATION_TYPES.GET_ALL
    }
}

function setAll(productApplications: any[]) {
    return {
        type: PRODUCT_APPLICATION_TYPES.SET_ALL,
        payload: { productApplications }
    }
}

function destroy(id: number) {
    return {
        type: PRODUCT_APPLICATION_TYPES.DESTROY,
        payload: { id }
    }
}

function set(key: keyof (ProductApplicationState), value: any) {
    return {
        type: PRODUCT_APPLICATION_TYPES.SET,
        payload: { key, value }
    }
}

function sendResult(id: string) {
    return {
        type: PRODUCT_APPLICATION_TYPES.SEND_RESULT,
        payload: { id }
    }
}

function getOne(id: string) {
    return {
        type: PRODUCT_APPLICATION_TYPES.GET_ONE,
        payload: { id }
    }
}

function setOne(quotation?: any) {
    return {
        type: PRODUCT_APPLICATION_TYPES.SET_ONE,
        payload: { quotation }
    }
}

function getData(type: 'amortizationTable' | 'donut' | 'area', amortizationTableId: any) {
    return {
        type: PRODUCT_APPLICATION_TYPES.GET_DATA,
        payload: { amortizationTableId, type }
    }
}

function setData(type: string, data?: any) {
    return {
        type: PRODUCT_APPLICATION_TYPES.SET_DATA,
        payload: { type, data }
    }
}

export const productApplicationActions = {
    getAll,
    setAll,
    destroy,
    set,
    sendResult,
    getOne,
    setOne,
    getData,
    setData
}