import { tss } from "tss-react/mui";
//@ts-ignore
import tinycolor from "tinycolor2";

export const useStyles = tss.create(({ theme }) => ({
    summaryData: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            width: '100%',
            padding: '16px 18px 16px 18px',
            background: '#fff',
            border: '1px solid #CFD5E2',
            borderRadius: 8,
            margin: '16px 0px 0px'
        }
    },
    stageTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
        '& h4': {
            fontSize: 20,
            fontWeight: 'bold',
            color: theme.palette.primary.dark
        },
        '& p': {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 24,
            padding: '4px 10px',
            border: '1px solid ' + theme.palette.primary.main,
            color: theme.palette.primary.main,
            fontSize: 12,
            margin: 0,
            '& svg': {
                marginLeft: 8,
                height: 18,
                width: 12
            },
            '&:hover': {
                backgroundColor: '#fff !important',
                boxShadow: `0px 16px 24px ${tinycolor(theme.palette.primary.main).setAlpha(.14)}, 0px 8px 10px ${tinycolor(theme.palette.primary.main).setAlpha(.26)}`,
            }
        }
    },
    values: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 16
    },
    input: {
        fontWeight: 'bold',
        color: '#242C37',
        fontSize: 16,
    },
    value: {
        fontSize: 16,
        // letterSpacing: '0.5px',
        color: '#8892A0',
        marginTop: 4
    },
    titleContainer: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
    },
    chipContainer: {
        display: 'flex',
        height: '100%',
        alignItems: 'flex-start',
        "&:hover": {
            cursor: 'pointer'
        }
    },
}));