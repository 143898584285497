import { Organization, Action } from "src/types";
import { ORGANIZATION_TYPES } from "./types";

const INITIAL_STATE: Organization = {
    credit_report_authorization_link: '',
    description: '',
    favicon: '',
    id: '',
    legal_name: '',
    logo: '',
    name: '',
    page_title: '',
    primary_color: '',
    privacy_link: '',
    secondary_color: '',
    slug: '',
    terms_and_conditions_link: '',
    website: '',
    white_logo: '',
    workflows: [],
    isLoading: true,
    login_alternatives: []
}

export const organizationReducer = (state: Organization = INITIAL_STATE, action: Action): Organization => {
    const { type, payload } = action;
    switch (type) {

        case ORGANIZATION_TYPES.GET: {
            return {
                ...state,
                isLoading: true
            }
        }

        case ORGANIZATION_TYPES.SET: {
            return {
                ...state,
                ...payload.organization,
                isLoading: false
            }
        }

        default:
            return state;
    }
}