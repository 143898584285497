import { SECTION_TYPES } from "./types";

function setCurrentSection(sectionId: string) {
    return {
        type: SECTION_TYPES.SET_CURRENT_SECTION,
        payload: { sectionId }
    }
}

export const sectionActions = {
    setCurrentSection
}