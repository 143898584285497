import { CreditProposalType } from "src/types"

export const getHelperText = (existsProposalAccepted: boolean, proposal?: CreditProposalType): string => {
    if (proposal?.status === 'accepted') {
        return 'Esta propuesta ya fue aceptada'
    }

    if (existsProposalAccepted) {
        return 'Ya existe una propuesta aceptada'
    }

    if (proposal?.status === 'rejected') {
        return 'Esta propuesta ya fue rechazada'
    }

    if (['expired'].includes(proposal?.status || '')) {
        return 'Esta propuesta ya expiró'
    }

    return ''
}