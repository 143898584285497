import { Helmet as ReactHelmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

/** Use to set, change, and manage document head values */
export function Helmet() {
    const organization = useSelector((state: RootState) => state.organization);
    const {
        page_title,
        favicon,
        logo,
        primary_color,
        secondary_color,
    } = organization;
    return (
        <ReactHelmet>
            <title>{page_title}</title>
            <link type="image/png" rel="icon" href={favicon} />
            <link type="image/png" rel="icon" href={favicon} sizes="64x64 48x48 32x32 24x24 16x16" />
            <link type="image/png" rel="icon" href={favicon} sizes="192x192" />
            <link type="image/png" rel="icon" href={favicon} sizes="512x512" />
            <link type="image/png" rel="apple-touch-icon" href={logo} />
            <link rel="mask-icon" href={logo} color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <style type="text/css">
                {`:root {
                    --primary-color: ${primary_color} !important;
                    --secondary-color: ${secondary_color} !important;
                }`}
            </style>

            {import.meta.env.REACT_APP_ENV != 'staging' && (
                <script
                    key="google-optimize"
                    src={`https://www.googleoptimize.com/optimize.js?id=${import.meta.env.REACT_APP_GOOGLE_OPTIMIZE_CONTAINER_ID}`}
                />
            )}

        </ReactHelmet>
    );
}
