import { call, put, takeLatest, debounce, select } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { ApplicationService, Service } from "src/services";
import { RootState } from "src/store";
import { snackbarActions } from "src/store/snackbar/actions";
import { Action, Response } from "src/types";
import { attachmentsActions } from "./actions";
import { ATTACHMENTS_TYPES } from "./types";

const attachmentService = new Service('attachments')

function* create({ payload }: Action) {
    const { applicationId, attachment, getAll, options } = payload
    const response: Response = yield call(ApplicationService.createPath, `${applicationId}/attachments/manage`, attachment)
    if (getAll) {
        yield put(attachmentsActions.getAll(applicationId))
    }
    yield put(attachmentsActions.setOne(response.data))
    yield put(snackbarActions.add('success', 'Se ha creado el documento'))
}

function* update({ payload }: Action) {
    const { applicationId, attachmentId, attachment, getAll } = payload
    const response: Response = yield call(attachmentService.update, attachmentId, attachment)
    if (getAll) {
        yield put(attachmentsActions.getAll(applicationId))
    }
    yield put(attachmentsActions.setOne(response.data))
    yield put(snackbarActions.add('success', 'Se ha actualizado el documento'))
}

function* getAll({ payload }: Action) {
    const { applicationId } = payload
    const { meta, search } = yield select((state: RootState) => state.attachments)
    const response: Response = yield call(ApplicationService.allPath, `${applicationId}/attachments/all`, { ...meta, search })
    yield put(attachmentsActions.setAll(response.data));
}

function* download({ payload }: Action) {
    yield call(attachmentService.download, `${payload.id}/download`, payload.file_name, { disableMimeType: true })
    yield put(attachmentsActions.set('isDownloading', false))
    yield put(snackbarActions.add('success', 'Se ha descargado el archivo'))
}

export function* attachmentsSagas() {
    yield takeLatest(ATTACHMENTS_TYPES.CREATE, sagaWrapper(create))
    yield takeLatest(ATTACHMENTS_TYPES.UPDATE, sagaWrapper(update))
    yield debounce(800, ATTACHMENTS_TYPES.GET_ALL, sagaWrapper(getAll, attachmentsActions.setAll([])))
}