import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    titleContainer: {
        alignItems: 'center',
        '&.flex': {
            justifyContent: 'space-between',
        }
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '32px',
    },
    divider: {
        margin: '8px 0px 24px',
    },
    emptyState: {
        backgroundColor: 'white',
        borderRadius: 8,
        border: '1px solid #CFD5E2',
        height: '100%',
        padding: '16px 20px 0px',
        '&.withoutChildren': {
            paddingBottom: 16,
            minHeight: 190.5
        }
    }
}))