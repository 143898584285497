import { EMPLOYMENTS_TYPES } from "./types"

export type EmploymentResourceType = 'application' | 'customer' | 'creditParticipant'

function getAll(resource: EmploymentResourceType, resourceId: string) {
    return {
        type: EMPLOYMENTS_TYPES.GET_ALL,
        payload: { resource, resourceId }
    }
}

function setAll(resource?: EmploymentResourceType, employments?: any[]) {
    return {
        type: EMPLOYMENTS_TYPES.SET_ALL,
        payload: { employments, resource }
    }
}

function getOne(id: string, resource?: EmploymentResourceType, resourceId?: string) {
    return {
        type: EMPLOYMENTS_TYPES.GET_ONE,
        payload: { id, resource, resourceId }
    }
}

function setOne(employment?: any) {
    return {
        type: EMPLOYMENTS_TYPES.SET_ONE,
        payload: { employment }
    }
}

function create(resource: EmploymentResourceType, resourceId: string, employment: any) {
    return {
        type: EMPLOYMENTS_TYPES.CREATE,
        payload: { resource, resourceId, employment }
    }
}

function update(id: string, employment: any, resource?: EmploymentResourceType, resourceId?: string, from?: 'form' | 'list') {
    return {
        type: EMPLOYMENTS_TYPES.UPDATE,
        payload: { id, employment, resource, resourceId, from }
    }
}

function destroy(resource: EmploymentResourceType, resourceId: string, id: string, from?: 'form' | 'list') {
    return {
        type: EMPLOYMENTS_TYPES.DESTROY,
        payload: { resource, resourceId, id, from }
    }
}

export const employmentsActions = {
    getAll,
    setAll,
    getOne,
    setOne,
    create,
    update,
    destroy,
}