import { Button, Grid } from "@mui/material"
import { useStyles } from "./styles"
import { Link } from "src/components"
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlineOutlined"

export function SuccessfulPaymentPage() {
    const { classes } = useStyles()

    return (
        <Grid container className={classes.root}>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12} className={classes.iconContainer}>
                    <CheckCircleIcon color="primary" />
                </Grid>
                <Grid item xs={12}>
                    <h1 className={classes.title}>
                        Pago completado
                    </h1>
                </Grid>
                <Grid item xs={12}>
                    <p className={classes.description}>
                        Gracias por tu pago, si tienes alguna duda no dudes en contactarnos
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <Link to={`/home/main`}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.button}
                        >
                            Volver al inicio
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    )
}