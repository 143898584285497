import HomeSolicitudesImg from 'src/assets/img/home_solicitudes.svg'
import HomePerfilImg from 'src/assets/img/home_perfil.svg'
import HomePrivacidadImg from 'src/assets/img/home_privacidad.svg'
import HomeBlogImg from 'src/assets/img/home_blog.svg'
import { Organization } from "src/types"

export interface MainCardItem {
    image: any
    title: string
    description: string
    linkTitle: string
    linkTo: string
    isExternalLink?: boolean,
    disabled?: boolean,
    onClick?: () => void
}

export function mainCardItems(organization: Organization): MainCardItem[] {
    return ([
        {
            image: HomeSolicitudesImg,
            title: 'Solicitudes',
            description: 'Visualiza o completa tu solicitudes.',
            linkTitle: 'Ver detalles',
            linkTo: '/home/applications'
        },
        {
            image: HomePerfilImg,
            title: 'Perfil',
            description: 'Ve y edita los detalles de tu cuenta y perfil.',
            linkTitle: 'Ir a mi perfil',
            linkTo: '/home/profile'
        },
        {
            image: HomePrivacidadImg,
            title: 'Privacidad',
            description: 'Conoce nuestras políticas de privacidad y seguridad.',
            linkTitle: 'Ver más',
            linkTo: organization.privacy_link || '/',
            isExternalLink: true
        },
        {
            image: HomeBlogImg,
            title: 'Visita nuestro blog',
            description: 'Conoce las últimas noticias sobre el mercado hipotecario.',
            linkTitle: 'Visitar blog',
            linkTo: '/'
        },
    ])
}