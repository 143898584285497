import { MouseEvent, useEffect, useState, Fragment } from "react";
import {
	TableContainer,
	Table as MuiTable,
	TableHead, TableBody,
	TableCell, TableRow,
	IconButton,
	TableProps as MuiTableProps,
	TableContainerProps,
	TableCellProps,
	TableHeadProps,
	TableBodyProps,
	Zoom,
	Tooltip,
} from "@mui/material";
import { Card, EmptyState, EmptyStateProps, Link } from "src/components";
import { CardList, CardViewProps } from "./CardList";
import { ActionMenu } from "./ActionMenu";
import { FilterAltOutlinedIcon } from "src/assets/icons";
import ArrowIcon from "@mui/icons-material/ArrowUpward";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useIsMobile } from "src/helpers";
import { FilterMenuType, getTooltipTitle, TableActionsType, TableColumnType } from "./Table.helpers";
import { useStyles } from "./Table.styles";
import _ from "lodash";

export interface TableProps<T = any> {
	/** Items to display */
	data: T[];
	columns: TableColumnType[];
	/** List of actions displayed at the right position inside row */
	actions?: TableActionsType[];
	onItemClick?: (row: any) => void;
	isLink?: boolean;
	emptyStateText?: string;
	CustomMenu?: any
	emptyStateProps?: EmptyStateProps;
	getHref?: (row: any) => string;
	tableProps?: MuiTableProps;
	tableContainerProps?: TableContainerProps;
	tableCellProps?: TableCellProps;
	tableHeadProps?: TableHeadProps;
	tableBodyProps?: TableBodyProps;
	showEmptyStateInsideTable?: boolean;
	onSortBy?: (key: string) => void;
	/** Order rows ascendent or descendent (asc or desc)*/
	sortOrder?: string
	/** Related to sortOrder, is indicated to know which column to sort by name */
	sortBy?: string,
	/** If is necessary, use to convert data key to column key for styling purposes */
	getSortByToKey?: (key: string) => void,
	/** If is true the table not will become in card list (when device in use is mobile) */
	disableCardMobile?: boolean;
	cardViewOptions?: CardViewProps;
	/** Disable card wrapper component */
	disableCard?: boolean
}

/** Use to show an items list (if the device in use is mobile the table becomes a card list) */
export function Table(props: TableProps) {
	const { classes, cx } = useStyles();
	const isMobile = useIsMobile();
	const {
		data = [],
		columns,
		onItemClick,
		actions,
		emptyStateText,
		CustomMenu,
		emptyStateProps,
		getHref,
		isLink,
		tableProps,
		tableContainerProps,
		tableCellProps,
		tableHeadProps,
		tableBodyProps,
		showEmptyStateInsideTable,
		onSortBy,
		sortOrder,
		sortBy: externalSortBy,
		getSortByToKey,
		disableCardMobile,
		cardViewOptions,
		disableCard
	} = props;
	const [menu, setMenu] = useState<{ anchorEl: null | HTMLElement, row: any }>({ anchorEl: null, row: {} });
	const [filterMenu, setFilterMenu] = useState<FilterMenuType>({ anchorEl: null, options: [], onClick: () => { } });
	const [sortBy, setSortBy] = useState({ key: '', order: 'asc' });
	const WrapperComponent = !disableCard ? Card : Fragment

	useEffect(() => {
		if (sortOrder || externalSortBy) {
			setSortBy({ key: externalSortBy!, order: sortOrder! })
		}
	}, [sortOrder, externalSortBy])


	function closeMenu() {
		setMenu({ anchorEl: null, row: {} })
	}

	function handleSortBy(key: string) {
		if (_.isFunction(onSortBy)) {
			setSortBy((prevState) => ({
				key,
				order: key === prevState.key && prevState.order === 'asc' ? 'desc' : 'asc',
			}));
			onSortBy(key);
		}
	}

	const Table = (
		<>
			<WrapperComponent
				disableDivider
				hideHeader
				contentGridProps={{
					className: classes.cardContainer
				}}
			>
				<TableContainer {...tableContainerProps} className={cx(classes.tableContainer, tableContainerProps?.className)}>
					<MuiTable {...tableProps}>

						<TableHead {...tableHeadProps}>
							<TableRow>
								{columns.filter(column => !column.hidden).map(({ key, label, filterOptions, onFilterSelect, hidden, disableSortBy, ...rest }) => {
									const formatSortByKey = _.isFunction(getSortByToKey) ? getSortByToKey(sortBy.key) : sortBy.key;
									return (
										<TableCell
											key={key as React.Key}
											className={cx(classes.columnName, { [classes.columnNameSortBy]: onSortBy && !disableSortBy })}
											onClick={() => {
												if (!disableSortBy) {
													handleSortBy(key?.toString())
												}
											}}
											{...tableCellProps}
											{...rest}
										>
											<div className={classes.header}>
												{(!disableSortBy && _.isFunction(onSortBy)) ? (
													<Tooltip
														title={getTooltipTitle(label, sortBy)}
														TransitionComponent={Zoom}
														hidden={true}
														classes={{
															tooltip: classes.tooltip
														}}
													>
														<span className={classes.subheader}>
															{label}
															<ArrowIcon
																className={cx(classes.arrowIcon, {
																	[classes.arrowClose]: key !== formatSortByKey,
																	[classes.arrowOpen]: key === formatSortByKey,
																})}
																style={{ transform: sortBy.order === 'asc' ? 'none' : 'rotate(180deg)' }}
															/>
														</span>
													</Tooltip>
												) : (
													<span>
														{label}
													</span>
												)}

												{filterOptions && (
													<IconButton
														size="small"
														classes={{ sizeSmall: classes.filterIconButton }}
														onClick={(event: MouseEvent<HTMLElement>) => {
															if (filterOptions) {
																setFilterMenu({
																	anchorEl: event.currentTarget,
																	options: filterOptions,
																	onClick: onFilterSelect ? onFilterSelect : () => { }
																});
															}
														}}
													>
														<FilterAltOutlinedIcon />
													</IconButton>
												)}
											</div>
										</TableCell>
									)
								})}
								{!_.isEmpty(actions) && <TableCell className={classes.columnName} />}
							</TableRow>
						</TableHead>

						<TableBody {...tableBodyProps}>
							{data?.map((row, index) => {
								const RowComponent = isLink && getHref!(row) ? Link : 'tr'
								const rowProps = isLink && getHref!(row) ? { to: getHref!(row) } : {
									onClick: () => {
										if (_.isFunction(onItemClick))
											onItemClick(row)
									}
								}

								return (
									<TableRow id={`table-row-${index + 1}`} key={index} className={classes.row} component={RowComponent} {...rowProps}>
										{columns.filter(column => !column.hidden).map(({ key, render, tableCellProps: columnTableCellProps }) => {
											const value = _.get(row, key);
											const result = _.isFunction(render) ? render(value, row) : _.isEmpty(value) ? "" : value;
											return (
												<TableCell
													key={key as React.Key}
													{...tableCellProps}
													{...columnTableCellProps}
												>
													{result}
												</TableCell>
											)
										})}
										{(!_.isEmpty(actions) || CustomMenu) && (
											<TableCell align="right">
												<IconButton
													size="small"
													onClick={e => {
														e.stopPropagation();
														e.preventDefault();
														setMenu({ anchorEl: e.currentTarget, row });
													}}
													disabled={actions?.every(action => action.hidden ? action.hidden(row) : false)}
													data-cy='actions-menu'
												>
													<MoreVertIcon />
												</IconButton>
											</TableCell>
										)}
									</TableRow>

								)
							})}
						</TableBody>
					</MuiTable>
				</TableContainer>
			</WrapperComponent>

			{data?.length === 0 && showEmptyStateInsideTable && (
				<EmptyState {...emptyStateProps} text={emptyStateText || 'No se encontraron resultados'} />
			)}
		</>
	);

	return (
		<>
			<ActionMenu
				{...props}
				closeMenu={closeMenu}
				menu={menu}
			/>

			{(isMobile && !disableCardMobile) ? (
				<CardList
					{...props}
					{...cardViewOptions}
					setMenu={setMenu}
				/>
			) : Table}

			{((data?.length || 0) <= 0 && !showEmptyStateInsideTable) && (
				<EmptyState {...emptyStateProps} text={emptyStateText || 'No se encontraron resultados'} />
			)}
		</>
	);
}