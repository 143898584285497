import { Workflow } from "src/types";
import { WorkflowState } from "./reducer";
import { WORKFLOW_TYPES } from "./types";

type Key = keyof (WorkflowState);

function set(key: Key, value: any) {
    return {
        type: WORKFLOW_TYPES.SET,
        payload: { key, value }
    }
}

function getOne(id: string) {
    return {
        type: WORKFLOW_TYPES.GET_ONE,
        payload: { id }
    }
}

function setOne(workflow: Workflow) {
    return {
        type: WORKFLOW_TYPES.SET_ONE,
        payload: { workflow }
    }
}

function loginAgain(email: string) {
    return {
        type: WORKFLOW_TYPES.LOGIN_AGAIN,
        payload: { email }
    }
}

export const workflowActions = {
    set,
    getOne,
    setOne,
    loginAgain,
}