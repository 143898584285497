import createCache from '@emotion/cache';

/** @type {import('@emotion/cache').Options} */
export const cacheProps = {
    key: 'mui',
    prepend: true,
};

/** @type {import("@emotion/cache").EmotionCache | undefined} */
export let muiCache: any;

export const makeMuiCache = () => {
    if (!muiCache) {
        muiCache = createCache(cacheProps);
    }

    return muiCache;
};

export const tssCacheProps = {
    key: 'tss',
};

/** @type {import("@emotion/cache").EmotionCache | undefined} */
export let tssCache: any;

export const makeTssCache = () => {
    if (!tssCache) {
        tssCache = createCache(tssCacheProps);
    }

    return tssCache;
};