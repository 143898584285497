import { DefaultWorkflowsParamsType, GetOptionsType } from "src/types";
import { DEFAULT_WORKFLOWS_TYPES } from "./types";


function getAll(options?: GetOptionsType<DefaultWorkflowsParamsType>) {
    return {
        type: DEFAULT_WORKFLOWS_TYPES.GET_ALL,
        payload: { options }
    }
}

function setAll(workflows?: any, meta?: any) {
    return {
        type: DEFAULT_WORKFLOWS_TYPES.SET_ALL,
        payload: { workflows, meta }
    }
}

export const defaultWorkflowsActions = {
    getAll,
    setAll
}