import { Grid, IconButton, Typography } from "@mui/material";
import { Card, TextField } from "src/components";
import AttachIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { useStyles } from "./styles"

import { messages } from "./data";

export function CommentsCard() {
    const { classes, cx } = useStyles()

    return (
        <Card
            title="Comentarios"
            titleAction={(
                <Typography color="primary"> Mensajes nuevos (1) </Typography>
            )}
            contentGridProps={{
                className: classes.container
            }}
        >
            <Grid container className={classes.conversationRoot}>
                <Grid container item xs={12} className={classes.conversation}>
                    {messages.map((message, index) => {
                        const Icon = message.icon;
                        return (
                            <Grid key={index} item xs={12} className={cx({ [classes.messageContainer]: !message.isMine, [classes.messageContainerMine]: message.isMine })}>
                                <Icon />
                                <div className={cx(classes.message, { [classes.isMine]: message.isMine })}>
                                    <div className={classes.messageHeader}>
                                        <Typography variant="h3"> {message.username} </Typography>
                                        <Typography className={cx({ [classes.dateMine]: message.isMine })}> {message.date} </Typography>
                                    </div>
                                    <div>
                                        {message.contents.map((content, index) => (
                                            <Typography key={index} style={index === 0 ? { marginBottom: 24 } : {}} > {content} </Typography>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>

            <div className={classes.sendRoot}>
                <Grid item xs={12} className={classes.sendContainer}>
                    <TextField placeholder="Responder mensaje" />
                    <IconButton className={classes.iconButton}>
                        <AttachIcon />
                    </IconButton>
                    <div className={classes.sendIconContainer}>
                        <SendIcon />
                    </div>
                </Grid>
            </div>
        </Card>
    )
}