import { NavigateOptions } from "react-router-dom";
import { store } from "src/store";
import { navigationActions } from "src/store/navigation/actions";

/**
 * Navigation helper
 * Replaces history
 */
export const navigate = {
    /**
     * Dispatches a navigation action to the store, for using outside of react components
     * 
     * Replacement for history
     * @param path 
     * @param options
     */
    to: function (path: string, options?: NavigateOptions) {
        store.dispatch(navigationActions.to(path, options))
    },
    /**
     * Dispatches a navigation action to the store, for using outside of react components
     * 
     * Replacement for history.goBack
     */
    goBack: function () {
        store.dispatch(navigationActions.to(-1))
    }
}