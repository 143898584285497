import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    primaryColor: {
        color: theme.palette.primary.main,
        marginBottom: 24
    },
    text: {
        marginBottom: 24
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    card: {
        border: '1px solid #CFD5E2',
        padding: 16,
        borderRadius: 8,
        marginBottom: 24
    }
}))