import { Action, ProcedureType } from "src/types";
import { PROCEDURES_TYPES } from "./types";

interface ProceduresStateType {
    procedure?: ProcedureType,
    isLoading: boolean
}

const INITIAL_STATE: ProceduresStateType = {
    isLoading: false
}

export function proceduresReducer(state: ProceduresStateType = INITIAL_STATE, action: Action): ProceduresStateType {
    const { type, payload } = action
    switch (type) {

        case PROCEDURES_TYPES.GET_ONE: {
            return {
                ...state,
                isLoading: true
            }
        }

        case PROCEDURES_TYPES.SET_ONE: {
            return {
                ...state,
                isLoading: false,
                procedure: payload.procedure
            }
        }

        default:
            return state
    }
}