import {
	InitialSummary,
	AmortizationTable,
	AmortizationChart,
	DonutChart,
	EmptyState,
	ValuesCard,
	Footer
} from "@kredimx/abacus-components";

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Grid, Typography } from "@mui/material";
import { Breadcrumbs } from "src/components";
import { segment } from "src/helpers";
import { useStyles } from "./styles";
import { DownloadOptions } from "./components";

import { useDispatch, useSelector } from "react-redux";
import { productApplicationActions } from "src/store/productApplication/actions";

export function ProductApplication() {
	const { id }: any = useParams();
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();

	const organizationId = useSelector(state => state.organization.id || '')

	useEffect(() => {
		segment.track('Quotation', 'Viewed', { QuotationId: id })
		window.scrollTo({ top: 0 });
	}, [])
	function send() {
		dispatch(productApplicationActions.sendResult(id))
		segment.track('Quotation', 'Clicked', { QuotationId: id, action: 'Download Quotation' })
	}

	return (
		<div className={classes.root}>

			<Grid container spacing={3} className={classes.container}>

				<Grid item xs={12}>
					<Breadcrumbs
						items={[
							{ to: '/home/product_applications', name: 'Cotizaciones' },
							{ name: 'Ver detalles' }
						]}
					/>
				</Grid>

				<Grid item xs={12} className={classes.titleContainer}>
					<Typography variant="h3"> Detalle de cotización </Typography>
				</Grid>

				<Grid item xs={12}>
					<Card className={classes.card} elevation={0}>

						<EmptyState containerProps={{ justifyContent: 'center' }} />

						<ValuesCard
							className={classes.valuesCardComponent}
							optionsComponent={productApplicationId => (
								<DownloadOptions
									productApplicationId={productApplicationId}
									organizationId={organizationId}
								/>
							)}
						/>

						<InitialSummary
							containerProps={{ className: classes.abacusComponent }}
							hideDownloadButton
							onClickDownload={send}
							useParams={useParams}
						/>

						<AmortizationTable
							containerProps={{ className: classes.abacusComponent }}
							showCompleteTableButton
						/>

						<AmortizationChart containerProps={{ className: classes.abacusComponent }} />

						<DonutChart containerProps={{ className: classes.abacusComponent }} />

						<Footer containerProps={{ className: classes.abacusComponent }} />

					</Card>
				</Grid>

			</Grid>
		</div >
	)
}