import { ButtonProps } from "@mui/material";
import { ComponentType } from "react"
import { Action } from "src/types";
import { MESSAGE_TYPES } from "./types";

interface MessageStateType {
    open: boolean,
    icon?: ComponentType
    title: string
    description?: string,
    buttonProps?: ButtonProps
}

const INITIAL_STATE: MessageStateType = {
    open: false,
    title: ''
}

export function messageReducer(state: MessageStateType = INITIAL_STATE, action: Action): MessageStateType {
    const { payload, type } = action

    switch (type) {

        case MESSAGE_TYPES.SHOW: {
            return {
                ...state,
                open: true,
                title: payload.title,
                icon: payload.icon,
                description: payload.description,
                buttonProps: payload.buttonProps
            }
        }

        case MESSAGE_TYPES.CLEAN: {
            return INITIAL_STATE
        }

        default:
            return state;
    }
}