import { useEffect } from "react"
import { Grid, IconButton, Typography } from "@mui/material";
import { History as HistoryIcon } from "@mui/icons-material";
import { Card, Link, Loading, StagePage, Tooltip } from "src/components";
import { AmortizationTable } from "../components";
import { currencyString, isValidId, useIsMobile, formatDate } from "src/helpers";
import { useStyles } from "./styles";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { amortizationTablesActions, creditsActions } from "src/store/credits/actions";

export function CreditDetailsPage() {
    const { creditId }: any = useParams()
    const { classes, cx } = useStyles();
    const dispatch = useDispatch()
    const isMobile = useIsMobile();
    const {
        amount,
        internal_id,
        outstanding_balance,
        signature_date,
        interest_rate,
        cat_without_vat,
    } = useSelector(state => state.credits.credits.credit) || {}
    const isLoading = useSelector(state => state.credits.amortizationTables.isLoading)
    const amortizationTable = useSelector(state => state.credits.amortizationTables.amortizationTable)

    useEffect(() => {
        if (isValidId(creditId)) {
            dispatch(creditsActions.getOne(creditId))
            dispatch(amortizationTablesActions.getCurrent(creditId))
        }

        return () => {
            dispatch(amortizationTablesActions.setOne())
        }
    }, [creditId])

    return (
        <StagePage
            title='Información de mi crédito'
            breadcrumbs={[
                {
                    name: 'Crédito',
                    to: '/home/credit'
                },
                {
                    name: 'Información de mi crédito',
                },
            ]}
        >
            <Loading isLoading={isLoading} variant="skeleton">
                <Grid container spacing={isMobile ? 2 : 3}>

                    <Grid item xs={12}>
                        <Card title="Resumen">
                            <Grid item xs={12} className={cx(classes.card)}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>Saldo inicial</Typography>
                                        <Typography className={classes.value}> {currencyString(amount)} </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>Saldo insoluto</Typography>
                                        <Typography className={classes.value}> {currencyString(outstanding_balance)} </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>Fecha de Formalización</Typography>
                                        <Typography className={classes.value}> {formatDate(signature_date, 'DD/MMM/YYYY')} </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>Número de crédito</Typography>
                                        <Typography className={classes.value}> {internal_id} </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>Tasa ordinaria anual</Typography>
                                        <Typography className={classes.value}> {interest_rate ? `${interest_rate}%` : ''} </Typography>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>Tasa moratoria anual</Typography>
                                    <Typography className={classes.value}>29.80%</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>IVA de intereses</Typography>
                                    <Typography className={classes.value}>0.00%</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>IVA en accesorios</Typography>
                                    <Typography className={classes.value}>16.00%</Typography>
                                </Grid> */}
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>CAT</Typography>
                                        <Typography className={classes.value}> {cat_without_vat ? `${cat_without_vat}%` : ''} </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>Moneda</Typography>
                                        <Typography className={classes.value}>MXN</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Card
                            title="Progreso"
                        // titleAction={(
                        //     <Tooltip title='Historial de tablas de amortización'>
                        //         <IconButton className={classes.iconButton}>
                        //             <Link to='/home/credit/details/history'>
                        //                 <HistoryIcon />
                        //             </Link>
                        //         </IconButton>
                        //     </Tooltip>
                        // )}
                        >
                            <AmortizationTable
                                className={classes.amortizationTable}
                                data={amortizationTable}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Loading>
        </StagePage>
    );
}