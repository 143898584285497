import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    card: {
        borderRadius: 8,
        border: '1px solid #CFD5E2',
        background: '#fff'
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        '& > p': {
            marginTop: 4
        }
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        color: '#242C37',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        }
    },
    titleContainer: {
        padding: '16px 24px',
        [theme.breakpoints.down('sm')]: {
            padding: 16
        }
    },
    borderBottom: {
        borderBottom: '1px solid #CFD5E2'
    },
    spaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    contentContainer: {
        padding: 24,
        [theme.breakpoints.down('sm')]: {
            padding: 16
        }
    }
}))