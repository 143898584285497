import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    item: {
        background: "#FFFFFF",
        border: "1px solid #CFD5E2",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: '24px',
        position: 'relative',
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
            maxWidth: '100%',
            padding: 16
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 450,
        marginBottom: 24,
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    imgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            maxWidth: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            '& img': {
                maxHeight: 62,
            },
        }
    }
}));
