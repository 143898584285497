import { IconButton, Grid, Typography } from "@mui/material";
import { Card } from "src/components";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined"
import { UploadInput } from "src/components/Dropzone";
import { useStyles } from "./styles"
import { PDFIcon } from "src/assets/icons";

export function DocumentsCard() {
    const { classes, cx } = useStyles()

    return (
        <Card
            title="Documentos"
            className={classes.card}
            titleAction={(
                <IconButton size="small">
                    <DownloadIcon />
                </IconButton>
            )}
        >
            <Grid container>
                <Grid item xs={12} md={4} className={classes.uploadedItem}>
                    <PDFIcon />
                    <Typography> Comprobante de pago-enero.pdf </Typography>
                </Grid>
            </Grid>
            <UploadInput
                onChange={() => { }}
                config={{
                    file_types: '.pdf',
                    max_file_size: 500000,
                    allowed_files: 1
                }}
            />
        </Card>
    )
}