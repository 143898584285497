import { ActionableType } from "src/types";

export const personalActionableTypes: ActionableType[] = ['Customer', 'ApplicationWorkflow', 'Address']

/*
    const actions: MenuActionProps[] = [
        {
            title: 'Eliminar',
            handleAction(actionableId, actionableType) {
                if (isValidId(actionableId)) {
                    setDestroyConfirmation({
                        open: true,
                        actionableId: actionableId,
                        actionableType: actionableType
                    })
                }
            },
        },
        {
            title: 'Editar',
            handleAction(actionableId, actionableType) {
                if (isValidId(actionableId)) {
                    dispatch(applicationEvaluatorFieldsActions.getActionable(actionableId!, actionableType!))

                    setDocumentsDialog({
                        open: true,
                        showOnlyDocuments: false,
                        types: ['Employment']
                    })
                }
            },
        }
    ]

*/