import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 24
    },
    button: {
        padding: '10px 16px',
    },
    loadingContainer: {
        height: '100%'
    },
    form: {
        width: '100%',
        padding: '0px 16px'
    },
    message: {
        maxWidth: '608px !important',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '343px !important',

        }
    }
}));