import { call, put, takeLatest } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { Action, Response } from "src/types";
import { APPLICATION_PAYMENTS_TYPES } from "./types";
import { applicationPaymentsActions } from "./actions";

const paymentsService = new Service('payments');
const applicationService = new Service('application_workflows');

function* getProducts({ payload }: Action) {
    const response: Response = yield call(paymentsService.findOnePath, `products`, payload.productType, false);
    yield put(applicationPaymentsActions.setProducts(response));
}

function* create({ payload }: Action) {
    const { applicationId, airtable_id } = payload
    const response: Response = yield call(applicationService.createPath, `${applicationId}/payments`, {
        airtable_id
    }, false);

    // @ts-ignore
    if (response.url) {
        // @ts-ignore
        window.location.href = response.url
    }
}

export function* applicationsPaymentsSagas() {
    yield takeLatest(APPLICATION_PAYMENTS_TYPES.GET_PRODUCTS, sagaWrapper(getProducts));
    yield takeLatest(APPLICATION_PAYMENTS_TYPES.CREATE, sagaWrapper(create));
}