import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    title: {
        fontWeight: 'bold'
    },
    buttonContainer: {
        paddingTop: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    button: {
        width: 166,
        maxWidth: '100%',
    }
}))