import { Route } from "src/types";
import {
    MagicLink,
    MagicLinkSent,
    CreateAccount,
    SigninConfirmation,
    Application,
    SessionExpired,
    LoginAgain,
    DefaultFlowPage,
    ApplicationCancellation,
    MaintenancePage,
    SigninTokenPage,
    ExpiredLinkPage,
} from "src/pages";
import { CreateApplicationByRootPage } from "src/pages/CreateApplicationByRoot";

export function getPublicRoutes(hideAccountCreation?: boolean): Route[] {
    return [
        // Login Kredi
        {
            path: '/signin/confirmation',
            component: SigninConfirmation
        },
        // Login Bancoppel
        {
            path: '/oauth/signin',
            component: SigninConfirmation
        },
        {
            path: '/signin/token',
            component: SigninTokenPage
        },
        {
            path: '/magic_link',
            component: MagicLink
        },
        {
            path: '/magic_link/sent',
            component: MagicLinkSent
        },
        {
            path: '/create_account',
            component: CreateAccount,
            hidden: hideAccountCreation
        },
        {
            path: '/application/:applicationId/:stageSlug/:sectionSlug',
            component: Application
        },
        {
            path: '/session_expired',
            component: SessionExpired
        },
        {
            path: '/login_again',
            component: LoginAgain
        },
        {
            path: '/workflow/:workflowSlug',
            component: DefaultFlowPage
        },
        {
            path: '/workflow/root/:workflowSlug',
            component: CreateApplicationByRootPage
        },
        {
            path: '/application/:applicationId/cancel',
            component: ApplicationCancellation,
        },
        {
            path: '/maintenance',
            component: MaintenancePage,
        },
        {
            path: '/expired-link',
            component: ExpiredLinkPage
        }
    ]
}