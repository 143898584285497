import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 24,
        '& > p': {
            fontSize: 24,
            fontWeight: 'bold',
        },
        '& svg': {
            fill: '#8892A0'
        }
    },
    content: {
        padding: '0px 24px 40px',
        '& .pdf-viewer-thumbs': {
            display: 'none !important'
        }
    }
}));