import { ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material";
import { useStyles } from "./OptionsMenu.styles"

export interface MenuOption {
    label: string;
    icon?: JSX.Element;
    onClick: () => void;

}

interface OptionsMenuProps extends Omit<MenuProps, 'open'> {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    options: MenuOption[]
}

/**
 * Renders a menu with options related with the given HTML element
 */
export function OptionsMenu({ anchorEl, onClose, options, ...rest }: OptionsMenuProps) {
    const { classes, cx } = useStyles()

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            PaperProps={{
                style: {
                    minWidth: 200
                }
            }}
            {...rest}
        >
            {options.map((option, index) => (
                <MenuItem
                    key={index}
                    onClick={() => {
                        option.onClick()
                        onClose()
                    }}
                >
                    {option.icon && (
                        <ListItemIcon className={classes.icon}>
                            {option.icon}
                        </ListItemIcon>
                    )}
                    <ListItemText primary={option.label} />
                </MenuItem>
            ))}
        </Menu>
    )
}