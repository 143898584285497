import { put, takeLatest, call, select } from "redux-saga/effects";
import { navigate, sagaWrapper } from "src/helpers";
import { Action, Organization } from "src/types";
import { applicationActions } from "../application/actions";
import { messageActions } from "../message/actions";
import { CUSTOM_ACTIONS } from "./types";
import { RootState } from "..";

import AccountAlreadyExistsIcon from "src/assets/img/messages/account_already_exists.svg"
import ApplicationInProgressIcon from "src/assets/img/messages/application_in_progress.svg"
import { AuthService } from "src/services";

/**
 * Send magic link to existing email and show custom error message
 * when email already exists
 */
function* customerEmailAlreadyExists({ payload }: Action) {
    const email = payload?.answers?.email || '';
    const organization: Organization = yield select((state: RootState) => state.organization);

    yield put(applicationActions.sendCurrentSectionMagicLink(email))
    yield put(messageActions.show({
        icon: AccountAlreadyExistsIcon,
        title: `Ya tienes una cuenta con ${organization?.name || 'nosotros'}`,
        description: `Hemos encontrado una cuenta con el correo <b>${email || ''}</b>, te enviamos un link mágico para que puedas continuar`
    }))
}

/**
 * Show global dialog when customer has application in progress
 */
function* customerHasApplicationInProgress() {
    const showButton = window.location.pathname != '/home/applications'

    yield put(messageActions.show({
        icon: ApplicationInProgressIcon,
        title: '¡Hey! Ya tienes una solicitud en proceso',
        description: `Dirígete a tu solicitud y continúa hoy mismo.`,
        buttonProps: showButton ? {
            size: 'large',
            children: 'Ir a mis solicitudes',
            onClick: () => navigate.to('/home/applications'),
        } : {}
    }))
}

/**
 * Send magic link to existing email and show custom error message
 * when email already exists and has pending applications
 */
function* customerExistsWithRequests({ payload }: Action) {
    const organization: Organization = yield select((state: RootState) => state.organization);
    const email = payload?.answers?.email || '';

    yield call(AuthService.sendMagicLink, {
        username: email,
        client_id: organization.id!,
        response_type: 'code',
        redirect_uri: `/home/applications`
    });

    yield put(messageActions.show({
        icon: AccountAlreadyExistsIcon,
        title: `Ya tienes una cuenta con ${organization?.name || 'nosotros'}`,
        description: `Hemos encontrado una cuenta con el correo <b>${email || ''}</b>, te enviamos un link mágico para que puedas continuar`
    }))
}

export function* customActionsSagas() {
    yield takeLatest(CUSTOM_ACTIONS.CUSTOMER_EMAIL_ALREADY_EXISTS_ERROR, sagaWrapper(customerEmailAlreadyExists))
    yield takeLatest(CUSTOM_ACTIONS["CUSTOMER.ALREADY_EXISTS_APPLICATION_WORKFLOW"], sagaWrapper(customerHasApplicationInProgress))
    yield takeLatest(CUSTOM_ACTIONS.EXISTING_CUSTOMER_WITH_REQUESTS, sagaWrapper(customerExistsWithRequests))
}