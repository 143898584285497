import { tss } from "tss-react/mui";
//@ts-ignore
import tinycolor from "tinycolor2";
export const useStyles = tss.create(({ theme }) => ({
	paper: {
		width: '25%',
		boxShadow: '0px 8px 10px rgba(196, 196, 196, 0.8)',
		borderLeft: '1px solid #CFD5E2',
		[theme.breakpoints.between('sm', 'md')]: {
			width: '55%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	divider: {
		borderBottom: '1px solid #CFD5E2',
		width: '90%',
		margin: 'auto'
	},
	header: {
		padding: '10px'
	},
	title: {
		fontSize: 20,
		lineHeight: '24px',
		fontWeight: 'bold',
		letterSpacing: '0.15px'
	},
	item: {
		width: '100%',
		minHeight: 40,
		padding: '8px 18px 8px 37px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		cursor: 'pointer',
		'& p': {
			color: '#242C37',
			fontSize: 16,
			fontWeight: 400,
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px 16px'
		}
	},
	hover: {
		'&:hover': {
			background: 'rgba(117, 139, 183, 0.08)'
		}
	},
	selectedItem: {
		background: tinycolor(theme.palette.primary.main).setAlpha(.1) + ' !important',
		borderLeft: '4px solid ' + theme.palette.primary.main,
		paddingLeft: '33px',
		'& svg': {
			fill: theme.palette.primary.main + ' !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px 13px'
		}
	},
	itemIconContainer: {
		display: 'flex',
		alignItems: 'center',
		'& > svg': {
			fill: '#B4BAC3',
			height: 16,
			width: 16,
			marginRight: 8,
		},
	},
	disabledText: {
		color: '#B4BAC3 !important'
	},
	statusIconContainer: {
		display: 'flex',
		alignItems: 'center',
		'& > svg': {
			height: 18,
			width: 18,
			marginRight: 8,
		}
	},
	sectionsContainer: {
		marginTop: 21
	}
}))