import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    textField: {
        fontWeight: 500,
    },
    errorColor: {
        '& .MuiOutlinedInput-root:hover fieldset': {
            borderColor: theme.palette.error.main
        }
    },
    primaryColor: {
        '& .MuiOutlinedInput-root:hover fieldset': {
            borderColor: theme.palette.primary.main
        }
    },
    input: {
        fontWeight: 400,
        background: '#fff',
        fontFamily: 'Circular Std',
        '&.MuiOutlinedInput-adornedStart': {
            paddingLeft: 0
        }
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#242C37',
        marginBottom: 16
    },
}));