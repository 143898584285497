import { Action, CountryStateType } from "src/types"
import { BelvoCredentialType, IMatiData, IBank, IDocumentType } from "@kredimx/form-builder";
import { AssociationType } from "./actions";
import { PROVIDERS_TYPES } from "./types";

export interface ProvidersStateType {
    weesign: {
        isDetailFetched: boolean;
        creditReports: any[];
        details: {
            status?: 'processing' | 'sent_to_sign' | 'canceled' | 'partially_signed' | 'signed' | 'failed';
            document_id?: string;
            signatory_id?: string;
            api_key?: string;
        };
    },
    matiData?: IMatiData[];
    belvo?: {
        isLoading?: boolean;
        accessToken?: string;
        updateAccessToken?: string;
        credentials?: BelvoCredentialType[];
    },
    countries: {
        data?: CountryStateType[];
        isLoading: boolean;
    },
    states: {
        data?: CountryStateType[];
        isLoading: boolean;
    },
    municipalities: {
        data?: CountryStateType[];
        isLoading: boolean;
    },
    associations?: {
        [association in AssociationType]?: any;
    },
    banks: {
        data?: IBank[],
        isLoading: boolean
    },
    documentTypes: {
        data?: IDocumentType[],
        isLoading: boolean
    },
    incode: {
        data?: any
        isLoading: boolean,
        isLoadingSession: boolean
        verificationFlows?: any[]
        verificationFlow?: any
        sessions?: any[]
    }
}

const INITIAL_STATE: ProvidersStateType = {
    weesign: {
        isDetailFetched: false,
        creditReports: [],
        details: {}
    },
    countries: {
        isLoading: false,
    },
    states: {
        isLoading: false,
    },
    municipalities: {
        isLoading: false
    },
    banks: {
        isLoading: false,
    },
    documentTypes: {
        isLoading: false
    },
    incode: {
        isLoading: false,
        isLoadingSession: false
    }
}

export function providersReducer(state: ProvidersStateType = INITIAL_STATE, action: Action): ProvidersStateType {
    const { type, payload } = action;
    switch (type) {

        case PROVIDERS_TYPES.SET_APPLICATION_CREDIT_REPORTS: {
            return {
                ...state,
                weesign: {
                    ...state.weesign,
                    creditReports: payload.creditReports
                }
            }
        }

        case PROVIDERS_TYPES.SET_CONTRACT_DETAILS: {
            return {
                ...state,
                weesign: {
                    ...state.weesign,
                    isDetailFetched: payload.details?.status != 'processing',
                    details: payload.details
                }
            }
        }

        case PROVIDERS_TYPES.CLEAN_WEESIGN: {
            return {
                ...state,
                weesign: {
                    isDetailFetched: false,
                    creditReports: [],
                    details: {}
                }
            }
        }

        case PROVIDERS_TYPES.SET_MATI_DATA: {
            const matiData = [...state.matiData || []];

            if (payload.matiData?.flowId && !matiData.find(data => data.flowId === payload.matiData?.flowId)) {
                matiData.push(payload.matiData)
            }

            return {
                ...state,
                matiData
            }
        }

        case PROVIDERS_TYPES.SET_BELVO_DATA: {
            return {
                ...state,
                belvo: {
                    ...state.belvo,
                    [payload.key]: payload.data
                }
            }
        }

        case PROVIDERS_TYPES.SET_BELVO_CREDENTIALS: {
            return {
                ...state,
                belvo: {
                    ...state.belvo,
                    credentials: payload.credentials
                }
            }
        }

        case PROVIDERS_TYPES.CLEAN_BELVO: {
            return {
                ...state,
                belvo: undefined
            }
        }

        case PROVIDERS_TYPES.SET_BELVO_ACCOUNTS: {
            const credentials = [...state.belvo?.credentials || []];
            const index = credentials.findIndex(credential => credential.id === payload.credentialId);

            if (index > -1) {
                credentials[index].accounts = payload.accounts;
            }

            return {
                ...state,
                belvo: {
                    ...state.belvo,
                    credentials
                }
            }
        }

        case PROVIDERS_TYPES.GET_COUNTRIES: {
            return {
                ...state,
                countries: {
                    ...state.countries,
                    isLoading: true
                }
            }
        }

        case PROVIDERS_TYPES.SET_COUNTRIES: {
            return {
                ...state,
                countries: {
                    ...state.countries,
                    isLoading: false,
                    data: payload.countries
                }
            }
        }

        case PROVIDERS_TYPES.GET_STATES: {
            return {
                ...state,
                states: {
                    ...state.states,
                    isLoading: true,
                }
            }
        }

        case PROVIDERS_TYPES.SET_STATES: {
            return {
                ...state,
                states: {
                    ...state.states,
                    isLoading: false,
                    data: payload.states
                }
            }
        }

        case PROVIDERS_TYPES.GET_MUNICIPALITIES: {
            return {
                ...state,
                municipalities: {
                    ...state.municipalities,
                    isLoading: true
                }
            }
        }

        case PROVIDERS_TYPES.SET_MUNICIPALITIES: {
            return {
                ...state,
                municipalities: {
                    ...state.municipalities,
                    isLoading: false,
                    data: payload.municipalities
                }
            }
        }

        case PROVIDERS_TYPES.SET_ASSOCIATIONS: {
            return {
                ...state,
                associations: {
                    ...state.associations,
                    [payload.type]: payload.associations
                }
            }
        }

        case PROVIDERS_TYPES.DESTROY_ASSOCIATION: {
            return {
                ...state,
                associations: {
                    ...state.associations,
                    [payload.type]: state.associations![payload.type].filter((association: any) => association.id !== payload.id)
                }
            }
        }

        case PROVIDERS_TYPES.GET_BANKS: {
            return {
                ...state,
                banks: {
                    isLoading: true
                }
            }
        }

        case PROVIDERS_TYPES.SET_BANKS: {
            return {
                ...state,
                banks: {
                    isLoading: false,
                    data: payload.banks
                }
            }
        }

        case PROVIDERS_TYPES.GET_DOCUMENT_TYPES: {
            return {
                ...state,
                documentTypes: {
                    isLoading: true
                }
            }
        }

        case PROVIDERS_TYPES.SET_DOCUMENT_TYPES: {
            return {
                ...state,
                documentTypes: {
                    isLoading: false,
                    data: payload.documentTypes
                }
            }
        }

        case PROVIDERS_TYPES.SET_INCODE_DATA: {
            return {
                ...state,
                incode: {
                    ...state.incode,
                    data: payload.data
                }
            }
        }

        case PROVIDERS_TYPES.GET_INCODE_VERIFICATION_FLOWS: {
            return {
                ...state,
                incode: {
                    ...state.incode,
                    isLoading: true
                }
            }
        }

        case PROVIDERS_TYPES.SET_INCODE_VERIFICATION_FLOWS: {
            return {
                ...state,
                incode: {
                    ...state.incode,
                    isLoading: false,
                    verificationFlows: payload.verificationFlows
                }
            }
        }

        case PROVIDERS_TYPES.GET_INCODE_VERIFICATION_FLOW: {
            return {
                ...state,
                incode: {
                    ...state.incode,
                    isLoading: true
                }
            }
        }

        case PROVIDERS_TYPES.SET_INCODE_VERIFICATION_FLOW: {
            return {
                ...state,
                incode: {
                    ...state.incode,
                    isLoading: false,
                    verificationFlow: payload.verificationFlow
                }
            }
        }

        case PROVIDERS_TYPES.GET_INCODE_SESSION: {
            return {
                ...state,
                incode: {
                    ...state.incode,
                    isLoadingSession: true
                }
            }
        }

        case PROVIDERS_TYPES.SET_INCODE_SESSION: {
            return {
                ...state,
                incode: {
                    ...state.incode,
                    sessions: payload.sessions,
                    isLoadingSession: false
                }
            }
        }

        default:
            return state;
    }
}