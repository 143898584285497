import { call, put, select, takeLatest, debounce } from "redux-saga/effects";
import { cookies, encrypt, getCookieDomain, getCookieName, getFullName, navigate, sagaWrapper, segment } from "src/helpers";
import { PublicCustomersService, CustomerService, PublicCustomerService, TokenResponseType, AuthService } from "src/services";
import { Action, Organization, Response } from "src/types";
import { RootState } from "..";
import { authActions } from "./actions";
import { AUTH_TYPES } from "./types";
import moment from "moment";
import { snackbarActions } from "../snackbar/actions";
import { RedirectConfig } from "./actions";
import { customerActions } from "../customer/actions";

function* sendMagicLink({ payload }: Action) {
    const organization: Organization = yield select((state: RootState) => state.organization);
    yield put(authActions.set('email', payload.email));
    yield call(AuthService.auth, {
        username: payload.email,
        client_id: organization.id!,
        response_type: 'code'
    });
    yield navigate.to('/magic_link/sent');
    if (payload.isResend) {
        yield put(snackbarActions.add('success', 'Link mágico enviado'));
    }
    yield put(customerActions.set('isLoading', false));
}

function* getToken(action: Action) {
    const { key, code, client_id, redirectUrl } = action.payload;

    const response: Response<TokenResponseType> = yield call(AuthService.token, {
        client_id,
        code: code || key,
        grant_type: "authorization_code",
        redirect_uri: "urn:ietf:wg:oauth:2.0:oob"
    });

    localStorage.setItem('refresh_token', encrypt(response.data?.refresh_token));
    localStorage.setItem('code', encrypt(code || key));
    localStorage.setItem('client_id', encrypt(client_id))

    yield call(setToken, response.data!, { url: redirectUrl });
}

export function* setToken(token: TokenResponseType, redirectConfig?: RedirectConfig) {
    yield cookies.set(getCookieName(), JSON.stringify(token.access_token), {
        path: '/',
        domain: getCookieDomain(),
        expires: moment().add(7200, 'seconds').utc().toDate(),
    });

    if (token.code)
        localStorage.setItem('code', encrypt(token.code));

    if (token.client_id)
        localStorage.setItem('client_id', encrypt(token.client_id))

    if (token.refresh_token)
        localStorage.setItem('refresh_token', encrypt(token.refresh_token));

    yield put(authActions.successLogin(token.access_token))
    yield put(authActions.getCustomer(redirectConfig));
}

function* getCustomer({ payload }: Action) {
    const { redirectConfig } = payload;

    const response: Response = yield call(CustomerService.findOne, '');
    const organization: Organization = yield select((state: RootState) => state.organization)
    const customer = response.data

    segment.identify(response.data?.id, organization, {
        customerId: response.data?.id,
        name: getFullName(customer),
        email: customer.email,
    });

    yield put(authActions.set('customer', customer));
    if (!redirectConfig.disable) {
        yield navigate.to(redirectConfig.url || '/home/main');
    }
}

function* logout() {
    const organization: Organization = yield select(state => state.organization)
    const redirectUrl = organization.login_config?.logout_redirect_url
    const isExternal = organization.login_config?.is_external_logout_redirect

    yield cookies.remove(getCookieName(), {
        path: '/',
        domain: getCookieDomain()
    })

    if (isExternal && redirectUrl) {
        yield window.location.href = redirectUrl
    } else {
        yield call(redirectLogin)
    }
}

function* createAccount({ payload }: Action) {
    const organization: Organization = yield select((state: RootState) => state.organization);
    const response: Response = yield call(PublicCustomersService.createCustomerAccount, organization.id!, payload.email);
    yield put(snackbarActions.add('success', '¡Hemos creado tu cuenta!'));
    yield put(authActions.sendMagicLink(response.data?.email));
    yield navigate.to('/magic_link/sent');
}

function* redirectLogin(action?: Action) {
    yield navigate.to(action?.payload?.path || '/magic_link');
}

function* isSegmentLoaded() {
    yield put(authActions.set('isSegmentLoaded', true));
}

function* me() {
    const response: Response = yield call(CustomerService.findOne, '');
    yield put(authActions.setCustomer(response.data));
}

function* login({ payload }: Action) {
    const { gid, applicationId, rt } = payload
    const response: Response = yield call(PublicCustomerService.createPath, 'login', {
        customer: {
            gid,
            application_workflow_id: applicationId,
            rt
        }
    }, false)

    const { jwt, redirect_to } = response.data
    yield call(setToken, jwt, { url: redirect_to })
}

export function* authSagas() {
    yield takeLatest(AUTH_TYPES.SEND_MAGIC_LINK, sagaWrapper(
        sendMagicLink,
        customerActions.set('isLoading', false),
        'No hemos encontrado alguna cuenta con este correo'
    ));
    yield takeLatest(AUTH_TYPES.GET_TOKEN, sagaWrapper(
        getToken,
        authActions.redirectLogin('/magic_link/sent'),
        'Lo sentimos tu link expiró, te hemos enviado otro para que continúes tu solicitud'
    ));
    yield takeLatest(AUTH_TYPES.GET_CUSTOMER, sagaWrapper(getCustomer, authActions.logout()));
    yield takeLatest(AUTH_TYPES.LOGOUT, sagaWrapper(logout));
    yield takeLatest(AUTH_TYPES.CREATE_ACCOUNT, sagaWrapper(createAccount));
    yield takeLatest(AUTH_TYPES.REDIRECT_LOGIN, sagaWrapper(redirectLogin));
    yield debounce(1000, AUTH_TYPES.IS_SEGMENT_LOADED, isSegmentLoaded);
    yield takeLatest(AUTH_TYPES.ME, sagaWrapper(me))
    yield takeLatest(AUTH_TYPES.LOGIN, sagaWrapper(login))
}