import { getBaseUrl, getServiceUrl } from 'src/helpers';
import { Service } from './service';

const { REACT_APP_IAM_URL, REACT_APP_SAM_SECRET } = import.meta.env;
class Organization extends Service {
    findBySlugOrDomain = async (slug: string) => {
        const response = await this.service.get(`${this.pathname}/${slug}`, {
            baseURL: getBaseUrl(this.service, this.config)
        });
        return this.formatResponse(response);
    }

    getToken = async (id: string) => {
        const response = await this.service.post(`${REACT_APP_IAM_URL}/sam/organizations/${id}/authorize`, {}, {
            headers: {
                Authorization: 'Bearer ' + REACT_APP_SAM_SECRET
            }
        });
        return response;
    }
}

export const OrganizationService = new Organization('organizations', { isPublic: true });
