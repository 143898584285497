import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigationActions } from 'src/store/navigation/actions';

interface NavigationProviderProps {
    children: React.ReactNode;
}

/**
 * Provider for handling navigation from store actions
 */
export function NavigationProvider({ children }: NavigationProviderProps) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { to, options } = useSelector(state => state.navigation)

    useEffect(() => {
        if (to) {
            navigate(to, options)
            dispatch(navigationActions.clear())
        }
    }, [to, options])

    return (
        <> {children} </>
    )
}