
export const salarySchemes = [
    { value: "assalaried", caption: "Asalariado" },
    { value: "freelance", caption: "Profesionista Independiente o Empresario" },
    { value: "businessman", caption: "Recibo dividendos de mi empresa (utilidades)" },
    { value: "cash", caption: "Recibo dinero en Efectivo (No tengo depósitos bancarios)" },
]

export const paymentFrequencies = [
    { caption: 'Semanal', value: 'weekly' },
    // { caption: 'Bisemanal', value: 'biweekly' },
    { caption: 'Quincenal', value: 'fortnightly' },
    { caption: 'Mensual', value: 'monthly' },
]

export const checkingMethods = [
    { caption: 'Nómina', value: 'paysheet' },
    { caption: 'Estado de cuenta', value: 'statement' },
    { caption: 'Declaración de impuestos', value: 'tax_return' },
]

export const activityTypes = [
    { caption: 'Arrendamiento', value: 'leasing' },
    { caption: 'Comercio', value: 'commerce' },
    { caption: 'Servicios', value: 'services' },
    { caption: 'Manufactura', value: 'manufacture' },
    { caption: 'Transporte', value: 'transportation' },
    { caption: 'Gasolineros', value: 'gas_stations' },
]

export const schemeTypes = [
    { caption: 'Nómina tradicional', value: 'traditional_payroll' },
    { caption: 'Asimilados a salarios', value: 'assimilated_to_salary' },
]

export function filterSalarySchemes(nationality: string) {
    const salarySchemes = [
        { value: "assalaried", caption: "Asalariado / Asimilado a salarios" },
        { value: "freelance", caption: "Independiente / Actividad empresarial" },
    ]
    // @ts-ignore
    if (nationality === 'México' || nationality === 'Mexico') {
        salarySchemes.push({ value: "pensioner", caption: "Pensionado" })
    }

    return salarySchemes
}

export const filingFrequencies = [
    { caption: 'Declaración anual', value: 'yearly' },
    { caption: 'Declaraciones bimestrales', value: 'bimonthly' },
    { caption: 'Declaraciones mensuales', value: 'monthly' },
]

export const checkingMethodsForeignerAssalaried = [
    { caption: 'Recibos de nómina + Estados de cuenta', value: 'paysheet_and_statement' },
    { caption: 'Declaración de impuestos', value: 'tax_return' },
]

// Mex checkingMethods

export const checkingMethodsMexPensioner = []

export const checkingMethodsMexPfae = [
    { caption: 'Estado de cuenta', value: 'statement' },
    { caption: 'Declaración de impuestos', value: 'tax_return' },
]

export const checkingMethodsMexAssalaried = [
    { caption: 'Recibos de nómina + Estados de cuenta', value: 'paysheet_and_statement' },
    { caption: 'Declaración de impuestos', value: 'tax_return' },
]

// Foreigner checkingMethods

export const checkingForeignerPfae = [
    { caption: 'Estado de cuenta', value: 'statement' },
    { caption: 'Declaración de impuestos', value: 'tax_return' },
]

export const translateProofSeniority: { [key: string]: string } = {
    letterhead: 'Carta laboral membretada',
    paysheet_and_statement: 'Recibo de nómina y Estado de cuenta',
    indefinite_contract: 'Contrato laboral indeterminado',
    tax_return: 'Constancia de semanas cotizadas',
    weeks_quoted: 'Declaración de impuestos',
    visa_or_work_permit: 'Visa o Permiso de trabajo'
}

export const proofSeniorityTypesMexAssalaried = [
    { caption: 'Carta laboral membretada', value: 'letterhead' },
    { caption: 'Recibo de nómina y Estado de cuenta', value: 'paysheet_and_statement' },
    { caption: 'Contrato laboral indeterminada', value: 'indefinite_contract' },
    { caption: 'Constancia de semanas cotizadas', value: 'weeks_quoted' },
    { caption: 'Declaración de impuestos', value: 'tax_return' }
]

export const proofSeniorityTypesForeignerAssalaried = [
    { caption: 'Carta laboral membretada', value: 'letterhead' },
    { caption: 'Recibo de nómina y Estado de cuenta', value: 'paysheet_and_statement' },
    { caption: 'Contrato laboral indeterminada', value: 'indefinite_contract' },
    { caption: 'Visa o Permiso de trabajo', value: 'visa_or_work_permit' },
    { caption: 'Declaración de impuestos', value: 'tax_return' }
]