import AvatarIcon from "src/assets/img/avatar.svg";
import KrediAvatarIcon from "src/assets/img/kredi_avatar.svg";

export const messages = [
    // {
    //     icon: KrediAvatarIcon,
    //     username: 'Equipo Kredi',
    //     contents: [
    //         "Hola Eduardo, para terminar tu solicitud es necesario que nos proporciones unos últimos documentos:",
    //         "- Último estado de cuenta",
    //         "- Fotografía de INE o IFE por ambos lados."
    //     ],
    //     date: 'Lunes 22 Marzo 9:19 AM',
    //     isMine: false
    // },
    // {
    //     icon: AvatarIcon,
    //     username: 'Tú',
    //     contents: [
    //         "Hola Eduardo, para terminar tu solicitud es necesario que nos proporciones unos últimos documentos:",
    //         "- Último estado de cuenta",
    //         "- Fotografía de INE o IFE por ambos lados."
    //     ],
    //     date: 'Lunes 22 Marzo 9:19 AM',
    //     isMine: true
    // },
    // {
    //     icon: KrediAvatarIcon,
    //     username: 'Equipo Kredi',
    //     contents: [
    //         "Hola Eduardo, para terminar tu solicitud es necesario que nos proporciones unos últimos documentos:",
    //         "- Último estado de cuenta",
    //         "- Fotografía de INE o IFE por ambos lados."
    //     ],
    //     date: 'Lunes 22 Marzo 9:19 AM',
    //     isMine: false
    // },
    // {
    //     icon: AvatarIcon,
    //     username: 'Tú',
    //     contents: [
    //         "Hola Eduardo, para terminar tu solicitud es necesario que nos proporciones unos últimos documentos:",
    //         "- Último estado de cuenta",
    //         "- Fotografía de INE o IFE por ambos lados."
    //     ],
    //     date: 'Lunes 22 Marzo 9:19 AM',
    //     isMine: true
    // },
    {
        icon: KrediAvatarIcon,
        username: 'Equipo Kredi',
        contents: [
            "Hola Eduardo, para terminar tu solicitud es necesario que nos proporciones unos últimos documentos:",
            "- Último estado de cuenta",
            "- Fotografía de INE o IFE por ambos lados."
        ],
        date: 'Lunes 22 Marzo 9:19 AM',
        isMine: false
    },
    {
        icon: AvatarIcon,
        username: 'Tú',
        contents: [
            "Hola Eduardo, para terminar tu solicitud es necesario que nos proporciones unos últimos documentos:",
            "- Último estado de cuenta",
            "- Fotografía de INE o IFE por ambos lados."
        ],
        date: 'Lunes 22 Marzo 9:19 AM',
        isMine: true
    }
]