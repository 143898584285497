import { Stage } from "src/types";
import { STAGE_TYPES } from "./types";

function getStage(id: string) {
    return {
        type: STAGE_TYPES.GET_STAGE,
        payload: { id }
    }
}

function setStage(stage: Stage) {
    return {
        type: STAGE_TYPES.SET_STAGE,
        playooad: { stage }
    }
}

function getStageSections(stageId: string) {
    return {
        type: STAGE_TYPES.GET_STAGE_SECTIONS,
        payload: { stageId }
    }
}

function getStageSummary(applicationId?:string, stageId?:string){
    return {
        type: STAGE_TYPES.GET_SUMMARY,
        payload: { applicationId, stageId }
    }
}

function setStageSummary(stageSummary:any){
    return {
        type: STAGE_TYPES.SET_SUMMARY,
        payload: { stageSummary }
    }
}

export const stageActions = {
    getStage,
    setStage,
    getStageSections,
    getStageSummary,
    setStageSummary
}