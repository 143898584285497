import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, FormHelperText } from "@mui/material";
import { useScript } from "src/helpers";
import { ContractDialog } from "./Dialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { useStyles } from "./Contract.styles";
import { providersActions } from "src/store/providers/actions";

/** Use to display a contract document and be able to sign it */
export function ContractInput({ id, name, onChange, value, touched, ...rest }: any) {
    const { applicationId }: any = useParams();
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const creditReports = useSelector((state: RootState) => state.providers.weesign.creditReports);
    const isFetched = useSelector((state: RootState) => state.providers.weesign.isDetailFetched);
    const getContractDetails = (creditReportId: string) => dispatch(providersActions.getContractDetails(creditReportId));

    const [open, setOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    let timer: any = null;

    useScript("https://widget-test.weesign.com.mx/");

    useEffect(() => {
        if (window.Weesign && !isLoaded) {
            setIsLoaded(true);
        }
    }, [window.Weesign])

    useEffect(() => {
        dispatch(providersActions.getApplicationCreditReports(applicationId));

        return () => {
            dispatch(providersActions.cleanWeesign());
        }
    }, [])

    useEffect(() => {
        if (creditReports.length > 0 && !isFetched) {
            timer = setInterval(() => {
                getContractDetails(creditReports[0].credit_report_id);
            }, 1000)
        }

        if (isFetched) {
            clearInterval(timer);
        }

        return () => {
            clearInterval(timer);
        }

    }, [creditReports, isFetched])

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="outlined"
                color="primary"
                size="small"
                disabled={!isLoaded || !isFetched}
                classes={{
                    disabled: classes.disabled
                }}
            >
                Firmar documento
            </Button>
            {(touched && !value) && (
                <FormHelperText className={classes.error}> La firma del contrato es requerida </FormHelperText>
            )}
            <ContractDialog
                open={open}
                onClose={() => setOpen(false)}
                onChange={onChange}
                name={name}
            />
        </>
    )
}