import { useSelector } from "react-redux";
import LoginAgainIcon from "src/assets/img/login-again.svg";
import { MessagePage } from "src/components";
import { RootState } from "src/store";
import { useStyles } from "./styles";

export function LoginAgain() {
    const { classes, cx } = useStyles();
    const email = useSelector((state: RootState) => state.auth.email);

    return (
        <MessagePage
            icon={LoginAgainIcon}
            title="Inicia sesión nuevamente"
            message={`Te hemos enviado un correo a <strong> ${email} </strong> con el que podrás continuar con el progreso de tu solicitud.`}
            messageContainerProps={{
                className: classes.message
            }}
        />
    )
}