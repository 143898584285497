import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    box: {
        height: '100%'
    },
    tooltip: {
        backgroundColor: '#FFFFFF',
        border: '0.5px solid #CFD5E2',
        boxShadow: '0px 1px 1px rgba(57, 74, 97, 0.14), 0px 2px 1px rgba(57, 74, 97, 0.12), 0px 1px 3px rgba(57, 74, 97, 0.2)',
        color: '#000000',
        fontSize: 12,
        fontWeight: 300,
        padding: '4px 8px',
        textAlign: 'center',
        lineHeight: '12px',
    },
    tooltipArrow: {
        color: '#FFFFFF',
        "&:before": {
            border: '0.5px solid #CFD5E2',
            boxShadow: '0px 1px 1px rgba(57, 74, 97, 0.14), 0px 2px 1px rgba(57, 74, 97, 0.12), 0px 1px 3px rgba(57, 74, 97, 0.2)',
        },
    }
}))