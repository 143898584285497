import { useEffect } from "react";
import { Dialog, useTheme, Typography, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./ContractDialog.styles";
import { Service } from "src/services";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

interface DocumentSignedType {
    addedOn: number;
    country: string;
    documentID: string;
    documentURL: string;
    status: string;
    title: string;
    version: number;
    documentFileObj: {
        key: string;
        originalKey: string;
        size: string;
        url?: string;
        version: string;
    };
    createdBy: {
        adminID: string;
        userID: string;
        emailID: string;
        fullName: string;
    };
    signatory: {
        emailID: string;
        isSigned: number;
        name: string;
        signing: {
            expiry: number;
            url: string;
        }
    }
}

interface ContractDialogProps {
    open: boolean;
    onClose: () => void;
    onChange: any
    name: string;
}

export function ContractDialog({ open, onClose, onChange, name, }: ContractDialogProps) {
    const theme = useTheme();
    const { classes, cx } = useStyles();
    const details = useSelector((state: RootState) => state.providers.weesign.details);

    useEffect(() => {
        try {
            const config = {
                documentID: details?.document_id,
                signatoryID: details?.signatory_id,
                apikey: details?.api_key,
                color: theme.palette.primary.main,
                onDocumentLoaded: (data: any) => {
                    /** TESTING */
                    // onChange({
                    //     target: {
                    //         name,
                    //         value: true
                    //     }
                    // });
                },
                onDocumentSigned: (data: DocumentSignedType) => {
                    onChange({
                        target: {
                            name,
                            value: data?.status == "COMPLETED"
                        }
                    });
                },
                onError: (error: any) => {
                    console.log(error);
                }
            };

            setTimeout(() => {
                window.Weesign?.init('el', config);
            }, 500)

        } catch (error) {
            console.log('Contract Error', error);
        }
    }, [open])

    return (
        <Dialog fullWidth maxWidth="xl" onClose={onClose} open={open}>
            <div className={classes.titleContainer}>
                <Typography> Firma de documento </Typography>
                <IconButton size="small" onClick={() => onClose()}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.content}>
                <Divider />
                <div id="el" />
            </div>
        </Dialog>
    )
}