import { call, put, takeLatest } from "redux-saga/effects";
import { sagaWrapper, segment } from "src/helpers";
import { ApplicationService, Service } from "src/services";
import { customerActions } from "src/store/customer/actions";
import { snackbarActions } from "src/store/snackbar/actions";
import { Action, Response } from "src/types";
import { applicationActions } from "../actions";
import { applicationCategoriesActions } from "./actions";
import { APPLICATION_CATEGORIES_TYPES } from "./types";

const categoryService = new Service('categories');
const applicationService = new Service('application_workflows');

function* getAll({ payload }: Action) {
    const response: Response = yield call(categoryService.all, payload);
    yield put(applicationCategoriesActions.setAll(response.data));
}

function* create({ payload }: Action) {
    const { applicationId, sub_category_id, type } = payload;

    //Cancel application
    yield call(ApplicationService.patchPath, `${applicationId}/cancel`, {});
    yield segment.track('Application', 'Cancelled', {
        id: payload.id,
        action: 'Application Cancelled',
        location: 'Web App',
    })

    yield put(customerActions.hasPendingApplication());

    if (type === 'list') {
        yield put(applicationActions.getApplications());
        yield put(snackbarActions.add('success', 'Solicitud cancelada'));
    } else {
        yield put(applicationActions.getApplication(applicationId));
    }

    //Create application subcategories
    yield call(applicationService.createPath, `${applicationId}/application_workflow_sub_categories`, {
        application_workflow: {
            application_workflow_sub_categories_attributes: [{ sub_category_id: sub_category_id }
            ]
        }
    }, false);
}

export function* applicationCategoriesSagas() {
    yield takeLatest(APPLICATION_CATEGORIES_TYPES.GET_ALL, sagaWrapper(getAll, applicationCategoriesActions.setAll()));
    yield takeLatest(APPLICATION_CATEGORIES_TYPES.CREATE, sagaWrapper(create));
}