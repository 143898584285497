import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    icon: {
        minWidth: 36,
        width: 36,
        '& svg': {
            height: 20,
        },
        '& path': {
            fill: theme.palette.text.secondary
        }
    }
}))