import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoadingBackdrop } from "src/components";
import { useQueryParams } from "src/helpers";
import { authActions } from "src/store/auth/actions";

export function SigninConfirmation() {
    const dispatch = useDispatch();
    const {
        key,
        code,
        client_id,
        rt,
        access_token,
        state
    } = useQueryParams();

    //Kredi
    useEffect(() => {
        if (key && client_id && !state)
            dispatch(authActions.getToken({
                key,
                client_id,
                redirectUrl: rt ? atob(rt) : undefined
            }))
    }, [key, client_id, rt])

    //Bancoppel
    useEffect(() => {
        if (access_token && state)
            dispatch(authActions.getToken({
                code: access_token,
                client_id: import.meta.env.REACT_APP_SSO_BANCOPPEL_CLIENT_ID!,
                redirectUrl: rt ? atob(rt) : undefined
            }))
    }, [access_token, state])

    return (
        <LoadingBackdrop />
    )
}
