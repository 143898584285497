import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    container: {
    },
    drawerDescription: {
        fontSize: 14,
        fontWeight: 400,
    },
    gridContainer: {
        '& > *': {
            marginBottom: 24
        }
    },
    button: {
        height: 36,
        maxWidth: '100%',
        width: '100%',
        padding: '6px 16px',
    },
    radio: {
        marginBottom: 8,
        '& .MuiFormControlLabel-label': {
            fontSize: 14,
            fontWeight: 400,
        }
    },
    statusMessage: {
        color: 'red',
        fontSize: 14,
        fontWeight: 400,
    }
}));