import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    imageAndText: {
        flexWrap: 'nowrap',
        marginBottom: 32,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginBottom: 40,
            flexWrap: 'wrap',
        }
    },
    avatar: {
        minHeight: 64,
        minWidth: 64,
        marginRight: 16
    },
    dataContainer: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    data: {
        display: 'flex',
        flexDirection: 'column',
        '& > h3': {
            fontSize: 20,
            color: '#242C37',
            fontWeight: 700,
        },
        [theme.breakpoints.down('sm')]: {
            '& > p': {
                paddingTop: 16
            }
        }
    },
    itemActions: {
        gap: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            marginBottom: 24
        }
    },
    skeleton: {
        maxWidth: '100%'
    }
}));
