import { useEffect } from "react"
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { Card, Loading, StagePage } from "src/components";
import { AddressType } from "src/types";
import { getFullName, isValidId, useIsMobile, formatAddress } from "src/helpers";
import { participantsActions } from "src/store/credits/actions";
import { useStyles } from "./styles";


export function ParticipantsPage() {
    const { creditId }: any = useParams()
    const { classes, cx } = useStyles();
    const dispatch = useDispatch()
    const isMobile = useIsMobile();
    const participants = useSelector(state => state.credits.participants.data)
    const isLoading = useSelector(state => state.credits.participants.isLoading)

    useEffect(() => {
        if (isValidId(creditId)) {
            dispatch(participantsActions.getAll(creditId))
        }
    }, [creditId])

    return (
        <StagePage
            title='Participantes de crédito'
            breadcrumbs={[
                { name: 'Crédito', to: '/home/credit' },
                { name: 'Participantes de crédito' },
            ]}
        >
            <Loading isLoading={isLoading} variant="skeleton">
                <Grid container spacing={isMobile ? 2 : 3}>
                    {participants?.map(participant => {
                        const address: AddressType = participant?.addresses?.find(n => n.predetermined) || {}
                        return (
                            <Grid key={participant.id} item xs={12}>
                                <Card title={participant.role_name}>
                                    <Grid item xs={12} className={cx(classes.card)}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <Typography className={classes.label}>Nombre</Typography>
                                                <Typography className={classes.value}> {getFullName(participant)} </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <Typography className={classes.label}>RFC</Typography>
                                                <Typography className={classes.value}> {participant.rfc} </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <Typography className={classes.label}>CURP</Typography>
                                                <Typography className={classes.value}> {participant.curp} </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <Typography className={classes.label}>Ciudad</Typography>
                                                <Typography className={classes.value}>{address?.city ? `${address?.city},` : ''} {address?.state}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <Typography className={classes.label}>Dirección</Typography>
                                                <Typography className={classes.value}> {formatAddress(address)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </Loading>
        </StagePage>
    );
}