import { takeLatest, put, call, select } from "redux-saga/effects";
import { navigate, sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { Action, Application, Employment, Response } from "src/types";
import { snackbarActions } from "../snackbar/actions";
import { EmploymentResourceType, employmentsActions } from "./actions";
import { EMPLOYMENTS_TYPES } from "./types";

const customerService = new Service('customers')
const applicationService = new Service('application_workflows')
const employmentService = new Service('employments');
const creditParticipantsService = new Service('participants', { api: 'kronos', namespace: 'admin' })

interface EmploymentPayloadType {
    id: string
    resource: EmploymentResourceType,
    resourceId: string
    employment: Employment
    from?: 'form' | 'list'
}

const customService: { [service in EmploymentResourceType]: Service } = {
    application: applicationService,
    creditParticipant: creditParticipantsService,
    customer: customerService,
}

function* getAll({ payload }: Action) {
    const { resource, resourceId }: EmploymentPayloadType = payload
    let response: Response

    if (resource == 'application') {
        response = yield call(applicationService.allPath, `${resourceId}/application_workflow_customer_resources`, {
            search: {
                customer_resource_type_eq: 'Employment'
            }
        })
    } else {
        response = yield call(customService[resource].allPath, `${resourceId}/employments`, {})
    }

    yield put(employmentsActions.setAll(resource, response?.data))
}

function* getOne({ payload }: Action) {
    const { id, resource, resourceId }: EmploymentPayloadType = payload
    let response: Response

    if (resource === 'creditParticipant') {
        response = yield call(creditParticipantsService.findOnePath, resourceId, `employments/${id}`)
    } else {
        response = yield call(employmentService.findOne, id)
    }

    yield put(employmentsActions.setOne(response.data))
}

function* create({ payload }: Action) {
    const { resource, resourceId, employment }: EmploymentPayloadType = payload
    let response: Response

    if (resource === 'creditParticipant') {
        response = yield call(creditParticipantsService.createPath, `${resourceId}/employments`, { employment })
        yield navigate.goBack()
    } else {

        response = yield call(customerService.createPath, `${resourceId}/employments`, {
            employment,
            customer_id: resourceId
        })
    }

    yield put(snackbarActions.add('success', 'Se ha creado el empleo'))
    yield put(employmentsActions.setOne(response.data))
}

function* update({ payload }: Action) {
    const { id, employment, resource, resourceId, from }: EmploymentPayloadType = payload
    let response: Response

    if (resource === 'creditParticipant') {
        response = yield call(creditParticipantsService.updatePath, resourceId, { employment }, `employments/${id}`)
    } else {
        response = yield call(employmentService.update, id, { employment })
    }

    if (from === 'list') {
        yield put(employmentsActions.getAll(resource, resourceId))
    }

    yield put(snackbarActions.add('success', 'Se ha actualizado el empleo'));
    yield put(employmentsActions.setOne(response.data))
}

function* destroy({ payload }: Action) {
    const { resource, resourceId, id, from }: EmploymentPayloadType = payload

    if (resource === 'creditParticipant') {
        yield call(creditParticipantsService.destroyCustomPath, `${resourceId}/employments/${id}`)
    } else {
        yield call(employmentService.destroy, id);
    }

    if (from == 'form') {
        if (resource === 'creditParticipant') {
            yield navigate.goBack()
        } else {
            yield navigate.to(`/applications/${resourceId}/details`)
        }
    } else {
        yield put(employmentsActions.getAll(resource, resourceId))
    }

    yield put(snackbarActions.add('success', 'Se ha eliminado el empleo'))
    yield put(employmentsActions.setOne())
}

export function* employmentsSagas() {
    yield takeLatest(EMPLOYMENTS_TYPES.GET_ALL, sagaWrapper(getAll, employmentsActions.setAll()))
    yield takeLatest(EMPLOYMENTS_TYPES.GET_ONE, sagaWrapper(getOne, employmentsActions.setOne()))
    yield takeLatest(EMPLOYMENTS_TYPES.CREATE, sagaWrapper(create, employmentsActions.setOne()))
    yield takeLatest(EMPLOYMENTS_TYPES.UPDATE, sagaWrapper(update))
    yield takeLatest(EMPLOYMENTS_TYPES.DESTROY, sagaWrapper(destroy))
}