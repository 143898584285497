import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import lottie from "lottie-web";
import { useParams } from "react-router-dom";
import { isValidId } from "src/helpers";
import { useDispatch } from "react-redux";
import { applicationActions } from "src/store/application/actions";
import loader from "src/assets/img/animacion-gris.json";
import { useStyles } from "./styles";

export function ApplicationRunningPublishing() {
	const { applicationId }: any = useParams();
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	let timer: NodeJS.Timeout;

	useEffect(() => {
		if (isValidId(applicationId)) {
			dispatch(applicationActions.getApplication(applicationId));
		}
	}, [applicationId])

	useEffect(() => {
		timer = setTimeout(() => {
			dispatch(applicationActions.publish(applicationId));
		}, 1000);
		return (() => {
			if (timer) {
				clearTimeout(timer);
			}
		})
	}, [])

	useEffect(() => {
		lottie.loadAnimation({
			container: document.querySelector("#loader-publishing")!,
			animationData: loader,
			renderer: "svg",
			autoplay: true,
		});
	}, []);

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="center"
			direction="column"
			className={classes.loadingContainer}
		>
			<div id="loader-publishing" className={classes.loader} />
			<Typography
				className={classes.text}
				dangerouslySetInnerHTML={{ __html: 'Estamos procesando tu información, esto puede tardar unos minutos...' }}
			/>
		</Grid>
	)
}