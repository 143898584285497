import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    item: {
        background: "#FFFFFF",
        border: "1px solid #CFD5E2",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: '24px',
        position: 'relative',
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
            maxWidth: '100%',
            padding: 16
        },
    },
    itemActions: {
        gap: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between'
        }
    },
    imageAndText: {
        display: 'flex',
        gap: '16px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            textAlign: 'center'
        }
    },
    data: {
        display: 'flex',
        flexDirection: 'column',
        '& > h3': {
            fontSize: 22,
            color: '#242C37',
            fontWeight: 'bold',
        },
        [theme.breakpoints.down('sm')]: {
            '& > p': {
                paddingTop: 8
            }
        }
    },
    contactData: {
        display: 'flex',
        gap: 50,
        '& > p': {
            display: 'flex',
            alignItems: 'center'
        },
        '& svg': {
            height: 14,
            fill: theme.palette.primary.main
        },
        [theme.breakpoints.down('sm')]: {
            gap: 8,
            flexDirection: 'column',
            '& > p': {
                justifyContent: 'center'
            }
        },
        [theme.breakpoints.down('sm')]: {
            padding: '30px 0',
        }
    },
    breadcrumbs: {
        padding: '60px 16px 0',
        zIndex: 1,
    },
    avatar: {
        height: 90,
        width: 90,
        backgroundColor: theme.palette.primary.light,
    },
    dataContainer: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.palette.primary.dark,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        }
    }
}));
