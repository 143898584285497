import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingBackdrop } from "src/components";
import { isValidId } from "src/helpers";
import { applicationActions } from "src/store/application/actions";

export function AssignCustomerPage() {
    const dispatch = useDispatch();
    const { applicationId }: any = useParams();

    useEffect(() => {
        if (isValidId(applicationId))
            dispatch(applicationActions.assignCustomer(applicationId));
    }, [applicationId])

    return (
        <LoadingBackdrop />
    )
}