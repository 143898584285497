import { currencyString, percentage } from "src/helpers"
import { CreditType } from "src/types"

export const creditColumns: {
    label: string
    key: keyof CreditType,
    render?: (value: any, row: CreditType) => string
}[] = [
        {
            label: 'Monto de crédito',
            key: 'amount',
            render: value => currencyString(value)
        },
        {
            label: 'Mensualidad',
            key: 'monthly_payment',
            render: value => currencyString(value)
        },
        {
            label: 'Tasa de interés',
            key: 'interest_rate',
            render: value => percentage(value)
        },
        {
            label: 'Plazo',
            key: 'months'
        },
        {
            label: 'Enganche',
            key: 'down_payment',
            render: value => currencyString(value)
        },
        {
            label: 'CAT sin IVA',
            key: 'cat_without_vat',
            render: value => percentage(value)
        },
        {
            label: 'Comisión por apertura',
            key: 'opening_fee',
            render: value => currencyString(value)
        }
    ]