import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    icon: {
        width: 28,
        height: 28,
    },
    link: {
        textDecoration: 'none',
    },
    loginAlternativeContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        padding: '12px 16px',
        borderRadius: 4,
        border: '1px solid #B4BAC3',
        width: '100%',
        '&:hover': {
            backgroundColor: '#F5F7FA'
        }
    },
    title: {
        fontWeight: 700,
    }
}))