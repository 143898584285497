import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Theme } from "src/assets/theme";
import { Layout } from "src/layout";
import { LoadingBackdrop, Helmet } from "src/components";
import _ from "lodash";
import "./App.styles.css"

import { useDispatch, useSelector } from "react-redux";
import { organizationActions } from "./store/organization/actions";
import { authActions } from "./store/auth/actions";
import { NavigationProvider } from "./providers";

import {
	ErrorBoundary,
	cookies,
	getCookieName,
	getKrediParams,
	useInstalledApps
} from "./helpers";

function App({ location }: any) {
	const dispatch = useDispatch();
	const organization = useSelector(state => state.organization);

	useInstalledApps(organization);

	useEffect(() => {
		const kredi_data = getKrediParams();
		if ((!_.isEmpty(kredi_data.referral_code) || !_.isEmpty(kredi_data.utm_data)) && window.location.search) {
			if (
				window.location.search.includes('utm') ||
				window.location.search.includes('code') ||
				window.location.search.includes('referral_code')
			)
				sessionStorage.setItem('kredi_data', window.location.search.replace('?', ''))
		}
	}, [location])

	useEffect(() => {
		dispatch(organizationActions.get())
		if (cookies.get(getCookieName())) {
			dispatch(authActions.me());
		}

		document.dispatchEvent(new Event('custom-render-trigger'))
	}, []);

	return organization.isLoading ? (
		<LoadingBackdrop />
	) : (
		<ErrorBoundary>
			<Theme>
				<CssBaseline />
				<Helmet />
				<BrowserRouter>
					<NavigationProvider>
						<Layout />
					</NavigationProvider>
				</BrowserRouter>
			</Theme>
		</ErrorBoundary>
	);
}

export default App;
