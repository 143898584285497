import { Typography, Grid, FormControlLabel, Checkbox, GridProps } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { termsConditionsItems } from "./termsConditionsItems";
import { usetStyles } from './TermsConditions.styles'
import _ from "lodash";

export interface TermsConditionsProps {
    titleGridProps?: GridProps;
    titleContainerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    /** invoked when a checkbox is checked or unchecked and send a parameter indicates if all checboxs is checked */
    onCheckToggle?: (isAllChecked: boolean) => void;
}

export function TermsConditions({ titleGridProps, titleContainerProps, onCheckToggle }: TermsConditionsProps) {
    const { classes, cx } = usetStyles();
    const organization = useSelector((state: RootState) => state.organization)
    const termsConditions = termsConditionsItems(organization)
    const [termsConditionsValues, setTermsConditionsValues] = useState<boolean[]>([])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const values = [...termsConditionsValues];
        values[index] = event.target.checked;
        setTermsConditionsValues(values);
    };

    useEffect(() => {
        if (_.isFunction(onCheckToggle)) {
            onCheckToggle(isAllChecked());
        }
    }, [termsConditionsValues]);

    const isAllChecked = () => {
        if (termsConditionsValues.length < termsConditions.length) {
            return false;
        }
        return termsConditionsValues.filter(n => n).length == termsConditions.length;
    }

    return (
        <Grid container>
            <Grid item xs={12} {...titleGridProps}>
                <div {...titleContainerProps}>
                    <Typography variant="h4">Aceptación de términos</Typography>
                </div>
            </Grid>
            <Grid container spacing={2} className={classes.termsConditionsContainer}>
                {termsConditions.map((item, index) => (
                    <Grid key={index} item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) => { handleChange(e, index) }}
                                    checked={termsConditionsValues[index] ?? false}
                                />
                            }
                            label={item.content}
                            className={classes.termCondition}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}