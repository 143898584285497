//@ts-nocheck
import _ from 'lodash';

/**
 * Get data attributes from response and formated by relationships and included data
 * @param param0 data, included object
 * @param notIncluded if is true ignore included data
 * @returns normalized data
 */
export function normalizer({ data, included }, notIncluded?) {
    if (!data) return;
    const getRelationship = (relationships, object = {}) => {
        if (!relationships) return {};
        _.keys(relationships).forEach((key) => {
            if (Array.isArray(relationships[key].data)) {
                object[_.camelCase(key)] = relationships[key].data.map((e) => {
                    const entity = _.find(included, e);
                    if (!entity) return notIncluded ? e : {};
                    if (entity.relationships) {
                        const { relationships: rel, ..._item } = entity;
                        const embeddedEntity = getRelationship(rel, _item);
                        if (!entity) return {};
                        const { id, attributes, ...rest } = embeddedEntity;
                        return { ...attributes, ...rest, id, };
                    } else {
                        const { id, attributes } = entity;
                        return { ...attributes, id, };
                    }
                });
            } else {
                const entity = _.find(included, relationships[key].data);
                if (!entity) return {};
                const { id, attributes } = entity;
                object[_.camelCase(key)] = relationships[key].data
                    ? { ...attributes, id, }
                    : {};
            }
        });
        return object;
    };
    if (Array.isArray(data)) {
        return data.map((item) => {
            const _data = getRelationship(item.relationships);
            return { ...item.attributes, ..._data, id: item.id };
        });
    } else {
        const _data = getRelationship(data.relationships);
        return { ...data.attributes, ..._data, id: data.id, };
    }
}

export function formatResponse({ data }: any) {
    const _data = normalizer(data);
    return { data: _data, meta: data.meta };
}
