import { useState } from "react";
import {
    Grid,
    GridProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { ExpandMore as ExpandIcon, ExpandLess as CollapseIcon, Done as DoneIcon } from "@mui/icons-material";
import { currencyString, getTotal } from "src/helpers";
import { useStyles } from "./styles";
import { AmortizacionTableType } from "src/types";
import { amortizationTableColumns, getInsuranceTotal } from "./columns";


interface AmortizationTableProps extends GridProps {
    hideTitle?: boolean
    data?: AmortizacionTableType
}

export function AmortizationTable({ hideTitle, className, data, ...rest }: AmortizationTableProps) {
    const { classes, cx } = useStyles();
    const [expanded, setExpanded] = useState(false);
    const periods = data?.amortization_table_periods || []

    return (
        <Grid container className={cx(classes.container, className)} {...rest}>
            {!hideTitle && (
                <Grid item xs={12}>
                    <Typography className={classes.title}> Tabla de amortización </Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <TableContainer
                    className={cx(classes.table, {
                        [classes.scrollbar]: true,
                        [classes.tableExpanded]: expanded,
                        [classes.tableCollapsed]: !expanded
                    })}
                >
                    <Table stickyHeader size="small" >
                        <TableHead>
                            <TableRow>
                                {amortizationTableColumns?.map((column, index) => (
                                    <TableCell
                                        key={index}
                                        className={classes.headCell}
                                        align="center"
                                        style={{
                                            minWidth: (column.label.length * 10) > 250 ? 250 : (column.label.length * 10),
                                            background: '#fff'
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell
                                    className={classes.headCell}
                                    align="center"
                                    style={{
                                        width: 10,
                                        background: '#fff'
                                    }}
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {periods?.map((period, index) => (
                                <TableRow
                                    key={index}
                                    className={cx(classes.row, {
                                        // [classes.paidout]: index < 2
                                    })}
                                >
                                    {amortizationTableColumns.map(({ key, render }) => (
                                        <TableCell
                                            key={key}
                                            className={classes.cell}
                                            align="center"
                                        >
                                            {render ? render(period[key], period) : currencyString(period[key])}
                                        </TableCell>
                                    ))}
                                    {/* {period?.map((value: any, childIndex: number) => (
                                        <TableCell
                                            key={childIndex}
                                            className={classes.cell}
                                            align="center"
                                        >
                                            {getValueFormat(value, childIndex + 1)}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        className={classes.cell}
                                        align="center"
                                    >
                                        {(index < 2) && (
                                            <DoneIcon color="primary" className={classes.paidoutIcon} />
                                        )}
                                    </TableCell> */}
                                </TableRow>
                            ))}
                            <TableRow className={classes.stickyFooter}>
                                <TableCell
                                    className={classes.totalCell}
                                    align="center"
                                >
                                    Total
                                </TableCell>
                                <TableCell
                                    className={classes.totalCell}
                                    align="center"
                                >
                                    {getTotal(periods, 'capital_payment')}
                                </TableCell>
                                <TableCell
                                    className={classes.totalCell}
                                    align="center"
                                >
                                    {getTotal(periods, 'interest')}
                                </TableCell>
                                <TableCell
                                    className={classes.totalCell}
                                    align="center"
                                >
                                    {getTotal(periods, 'final_balance')}
                                </TableCell>
                                <TableCell
                                    className={classes.totalCell}
                                    align="center"
                                >
                                    {getInsuranceTotal(periods)}
                                </TableCell>
                                <TableCell
                                    className={classes.totalCell}
                                    align="center"
                                >
                                    {getTotal(periods, 'admin_fee')}
                                </TableCell>
                                <TableCell
                                    className={classes.totalCell}
                                    align="center"
                                >
                                    {getTotal(periods, 'total_payment')}
                                </TableCell>
                                <TableCell
                                    className={classes.totalCell}
                                    align="center"
                                />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {14 > 13 && (
                    <div className={classes.expandContainer}>
                        <div className={classes.expandToggle} onClick={() => setExpanded(!expanded)}>
                            <Typography> Clic para {expanded ? 'minimizar' : 'expandir'} </Typography>
                            {expanded ? <CollapseIcon color="primary" /> : <ExpandIcon color="primary" />}
                        </div>
                    </div>
                )}
            </Grid>
        </Grid>
    );
}