import { Action } from "src/types"
import { PRODUCT_APPLICATION_TYPES } from "./types"
import { ProductApplication } from "src/types/productApplication"

export interface ProductApplicationState {
    productApplication?: ProductApplication
    productApplications: ProductApplication[]
    isLoading: boolean

    amortizationTable: {
        isLoading: boolean
        data?: any
    },
    donut: {
        isLoading: boolean
        data?: any
    },
    area: {
        isLoading: boolean
        data?: any
    },
}

const INITIAL_STATE: ProductApplicationState = {
    productApplications: [],
    isLoading: false,
    amortizationTable: {
        isLoading: false,
    },
    donut: {
        isLoading: false,
    },
    area: {
        isLoading: false,
    },
}

export const productApplicationReducer = (state: ProductApplicationState = INITIAL_STATE, action: Action): ProductApplicationState => {
    const { type, payload } = action
    switch (type) {

        case PRODUCT_APPLICATION_TYPES.DESTROY:
        case PRODUCT_APPLICATION_TYPES.GET_ALL: {
            return {
                ...state,
                isLoading: true
            }
        }

        case PRODUCT_APPLICATION_TYPES.SET_ALL: {
            return {
                ...state,
                isLoading: false,
                productApplications: payload.productApplications
            }
        }

        case PRODUCT_APPLICATION_TYPES.SET: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }

        case PRODUCT_APPLICATION_TYPES.GET_DATA: {
            return {
                ...state,
                [payload.type]: {
                    isLoading: true,
                }
            }
        }

        case PRODUCT_APPLICATION_TYPES.SET_DATA: {
            return {
                ...state,
                [payload.type]: {
                    isLoading: false,
                    data: payload.data
                }
            }
        }

        case PRODUCT_APPLICATION_TYPES.SET_ONE: {
            return {
                ...state,
                productApplication: payload.quotation
            }
        }

        default:
            return state
    }
}