import { Field } from "src/types";

export const fields: Field[] = [
    {
        key: 'email',
        name: 'email',
        label: 'Correo',
        required: false,
        field_type: 'email',
        sort_order: 2,
        placeholder: '',
        row: 2,
        width: 12,
        hidden: false,
        disabled: true
    },
    {
        key: 'phone_with_country_code',
        name: 'phone_with_country_code',
        label: 'Teléfono',
        required: false,
        field_type: 'phone',
        sort_order: 1,
        placeholder: '',
        row: 1,
        width: 12,
        hidden: false,
        disabled: true
    },
]

export let initialValues = {
    phone_with_country_code: {
        phone: '',
        phone_country_code: ''
    },
    email: ""
};
