import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    button: {
        marginBottom: 24
    },
    buttonText: {
        fontSize: 14,
        color: theme.palette.primary.main
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        padding: '8px 0px',
        '& svg': {
            height: 18
        }
    }
}));