import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, CircularProgress, Grid, Hidden, Typography } from "@mui/material";
import PreapprovedImg from "src/assets/img/application_preapproved.svg"
import PreapprovedSmImg from "src/assets/img/application_preapproved_sm.svg"

import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { Confetti, Link } from "src/components";
import { STAGE_STATUS, isCancelledOrRejected, useApplicationPusher, useIsKredi } from "src/helpers";
import { getCustomer, getDefaultCustomerEmployment } from "../../../helpers";

import { ConsultantCard } from "../components";
import { Calculator } from "../components/Calculator";
import { NextStepsCard } from "../components/NextStepsCard";
import { AuthoritiesCard } from "../components/AuthoritiesCard";

export function PreapprovedPage() {
	const { status }: any = useParams();
	const { classes, cx } = useStyles();
	const isKredi = useIsKredi()
	const { application, isLoading } = useSelector(state => state.applications);

	const { id, current_section, current_stage, applicationWorkflowStages } = application || {}
	const currentApplicationWorkflowStage = applicationWorkflowStages?.find(stage => stage.stage_id === current_stage?.id)

	useApplicationPusher({ getDefaultCustomerEmployment, getCustomer })

	return (
		<>
			<Confetti />

			<Grid
				id="preapproved-hero"
				item
				xs={12}
				className={classes.hero}
			>
				<div className={classes.heroTexts}>

					<Typography variant="h3" className={cx({ [classes.krediHeroTexts]: isKredi })}>
						¡Tu solicitud ha sido preaprobada!
					</Typography>

					{isKredi && (
						<Typography>
							Un ejecutivo va a revisar tu expediente y en unas horas te informaremos sobre tus resultados vía mensaje de texto y correo electrónico.
						</Typography>
					)}

					{isKredi && (
						<Hidden mdUp>
							<div className={classes.image}>
								<PreapprovedSmImg />
							</div>
						</Hidden>
					)}

					{(
						Boolean(status) || (
							!isCancelledOrRejected(application) &&
							currentApplicationWorkflowStage?.status !== STAGE_STATUS.IN_REVIEW &&
							currentApplicationWorkflowStage?.visibility == 'customer'
						)
					) && (
							<Link
								to={`/application/${id}/${currentApplicationWorkflowStage?.slug}/${current_section?.slug}?from=summary`}
								disabled={isLoading}
								className={classes.buttonLink}
							>
								<Button
									color="primary"
									variant="contained"
									size="small"
									className={classes.button}
									disabled={isLoading}
								>
									{!isLoading && 'Continuar mi solicitud'}
									{isLoading && <CircularProgress size={24} />}
								</Button>
							</Link>
						)}
				</div>

				{isKredi && (
					<Hidden smDown>
						<div className={classes.image}>
							<PreapprovedImg />
						</div>
					</Hidden>
				)}
			</Grid>

			{isKredi && (
				<AuthoritiesCard />
			)}

			{isKredi ? (
				!Boolean(status) && (
					<>

						<ConsultantCard
							applicationOrigin="current"
							showDelayedLoader
						/>

						<NextStepsCard />

						<Calculator
							loader={{
								show: true,
								duration: 10000
							}}
						/>

					</>
				)
			) : (
				<>
					<NextStepsCard />

					<ConsultantCard
						applicationOrigin="current"
						showDelayedLoader
					/>
				</>
			)}

		</>
	)
}