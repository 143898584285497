import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoadingBackdrop } from "src/components";
import { useQueryParams } from "src/helpers";
import { authActions } from "src/store/auth/actions";

export function SigninTokenPage() {
    const dispatch = useDispatch();
    const { gid, application_workflow_id, rt } = useQueryParams();

    useEffect(() => {
        if (gid && application_workflow_id)
            dispatch(authActions.login(gid, application_workflow_id, rt))
    }, [gid, application_workflow_id])

    return (
        <LoadingBackdrop />
    )
}