import { Grid, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { WarningRedIcon } from "src/assets/icons";
import { useSelector } from "react-redux";
import { Loading } from "src/components";
import { Fragment } from "react";

export function RejectedReasons() {
    const { classes, cx } = useStyles();
    const scoreResult = useSelector(state => state.applications.scoreResult)
    const isLoading = useSelector(state => state.applications.isLoadingScoreResult)

    return (
        <Grid container className={classes.card}>
            <Typography className={classes.title}> Encontramos las siguientes posibles razones </Typography>
            <Loading isLoading={isLoading} variant="skeleton">
                {scoreResult?.ko_reasons ? (
                    scoreResult?.ko_reasons.map(({ ko_label, ko_reason }, index) => (
                        <Grid key={index} item xs={12} className={classes.reason}>
                            <div className={classes.reasonTitle}>
                                <WarningRedIcon />
                                <Typography> {ko_label} </Typography>
                            </div>
                            <Typography
                                className={classes.reasonDescription}
                                dangerouslySetInnerHTML={{ __html: ko_reason }}
                            />
                        </Grid>
                    ))
                ) : (
                    <Fragment />
                )}
            </Loading>
        </Grid>
    )
}