import unlinkIcon from "./unlink.svg"
import editIcon from "./edit_mar.svg"
import contentCopyIcon from "./content_copy.svg"
import addIcon from "./add.svg"
import deleteIcon from "./delete.svg"
import previewIcon from './preview.svg'
import uploadIcon from './upload.svg'
import arrowRightIcon from './arrow_right.svg'
import chevronRightIcon from './chevron_right.svg'
import downloadIcon from './download.svg'
import filterIcon from './filter.svg'
import searchIcon from './search.svg'
import closeIcon from './close.svg'
import unpreviewIcon from './unpreview.svg'
import archiveIcon from './archive.svg'
import emailIcon from './email.svg'
import saveIcon from './save.svg'
import refreshIcon from './refresh.svg'
import mainDocIcon from './main_doc.svg'
import migrateIcon from './migrate.svg'
import sortIcon from './sort.svg'
import moreIcon from './more.svg'
import checkIcon from './check.svg'
import unviewIcon from './unview.svg'
import viewIcon from './view.svg'
import moveIcon from './move_element.svg'

export const EditIcon = editIcon;
export const DeleteIcon = deleteIcon;
export const UnlinkIcon = unlinkIcon;
export const ContentCopyIcon = contentCopyIcon;
export const AddIcon = addIcon;
export const PreviewIcon = previewIcon;
export const UploadIcon = uploadIcon;
export const ArrowRightIcon = arrowRightIcon;
export const ChevronRightIcon = chevronRightIcon;
export const DownloadIcon = downloadIcon;
export const FilterIcon = filterIcon;
export const SearchIcon = searchIcon;
export const CloseIcon = closeIcon;
export const UnpreviewIcon = unpreviewIcon;
export const ArchiveIcon = archiveIcon;
export const EmailIcon = emailIcon;
export const SaveIcon = saveIcon;
export const RefreshIcon = refreshIcon;
export const MainDocIcon = mainDocIcon;
export const MigrateIcon = migrateIcon;
export const SortIcon = sortIcon;
export const MoreIcon = moreIcon;
export const CheckIcon = checkIcon;
export const UnviewIcon = unviewIcon;
export const ViewIcon = viewIcon;
export const MoveIcon = moveIcon;

// export const EditIcon = withSvg(editIcon);
// export const DeleteIcon = withSvg(deleteIcon);
// export const UnlinkIcon = withSvg(unlinkIcon);
// export const ContentCopyIcon = withSvg(contentCopyIcon);
// export const AddIcon = withSvg(addIcon);
// export const PreviewIcon = withSvg(previewIcon);
// export const UploadIcon = withSvg(uploadIcon);
// export const ArrowRightIcon = withSvg(arrowRightIcon);
// export const ChevronRightIcon = withSvg(chevronRightIcon);
// export const DownloadIcon = withSvg(downloadIcon);
// export const FilterIcon = withSvg(filterIcon);
// export const SearchIcon = withSvg(searchIcon);
// export const CloseIcon = withSvg(closeIcon);
// export const UnpreviewIcon = withSvg(unpreviewIcon);
// export const ArchiveIcon = withSvg(archiveIcon);
// export const EmailIcon = withSvg(emailIcon);
// export const SaveIcon = withSvg(saveIcon);
// export const RefreshIcon = withSvg(refreshIcon);
// export const MainDocIcon = withSvg(mainDocIcon);
// export const MigrateIcon = withSvg(migrateIcon);
// export const SortIcon = withSvg(sortIcon);
// export const MoreIcon = withSvg(moreIcon);
// export const CheckIcon = withSvg(checkIcon);
// export const UnviewIcon = withSvg(unviewIcon);
// export const ViewIcon = withSvg(viewIcon);
// export const MoveIcon = withSvg(moveIcon);