import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        flexWrap: 'nowrap',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            flexWrap: 'wrap',
        },
    },
    infoContainer: {
    },
    title: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '24px',
        marginBottom: 4,
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            lineHeight: '28px',
        },
    },
    description: {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '24px',
        marginBottom: 22,
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: '24px',
        },
    },
    imageContainer: {
        marginLeft: 'auto',
        '& svg': {
            marginTop: -24,
            marginBottom: -24,
            marginRight: -24,
            display: 'block'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginBottom: 10,
            marginRight: 0,
            '& svg': {
                marginTop: 0,
                marginBottom: 0,
                maxWidth: 140,
                maxHeight: 108
            },
        },
    },
    actionsContainer: {
        '& a + button, & button + button': {
            marginLeft: 33,
        }
    },
    chip: {
        height: 24,
    },
}))