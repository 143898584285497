import { useSelector } from "react-redux";
import { StagePage } from "src/components";
import { ConsultantCard } from "src/pages/Application/Status/Response/components";
import { RootState } from "src/store";
import { useStyles } from "./styles";

export function Consultant() {
    const { classes, cx } = useStyles();
    const user = useSelector((state: RootState) => state.auth.customer.user);

    return (
        <StagePage
            title="Contacta a tu asesor"
            className={classes.container}
        >
            <ConsultantCard
                title="Asesoría"
                requestLastOneApplication
            />
        </StagePage>
    )
}