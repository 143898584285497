import { Grid, Typography } from '@mui/material';
import KimEnOficinaImg from '../../assets/img/kim_en_oficina.svg';
import MaintenanceImage from '../../assets/img/maintenance.svg';
import { useStyles } from './styles';

interface MaintenancePageProps { }

export function MaintenancePage({ }: MaintenancePageProps) {
    const { classes, cx } = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid container spacing={4} className={classes.content}>
                <Grid item xs={12}>
                    <MaintenanceImage />
                </Grid>
                <Grid item xs={12}>
                    <Typography color='primary' component='h1' className={classes.title}> ¡Portal en mantenimiento! </Typography>
                </Grid>
                <Grid item xs={12} className={classes.imageContainer}>
                    <KimEnOficinaImg />
                </Grid>
                <Grid item xs={12}>
                    <Typography component='h2' className={classes.subtitle}> Estamos actualizando la plataforma para brindarte un mejor servicio </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component='h2' className={classes.subtitle}> ¡Regresaremos muy pronto! </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}