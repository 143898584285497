import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	emptyState: {
		paddingTop: '19%'
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center'
	},
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center'
	},
	container: {
		padding: '60px 30px',
		width: '100%',
		maxWidth: 1440,
		[theme.breakpoints.down('sm')]: {
			padding: '65px 16px'
		}
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& > h3': {
			color: theme.palette.primary.dark,
			fontSize: 34,
			fontWeight: 'bold'
		},
		'& > div': {
			display: 'flex',
			'& > :first-child': {
				marginRight: 14
			}
		},
		[theme.breakpoints.down('sm')]: {
			'& > h3': {
				fontSize: 24,
			},
			'& > div': {
				'& > :first-child': {
					marginRight: 0
				}
			},
		},
	},
	titleButton: {
		maxHeight: 36,
		padding: '10px 14px'
	},
	abacusComponent: {
		padding: '8px 0px'
	},
	loadingContainer: {
		paddingTop: 50,
		height: 500
	},
	card: {
		border: "1px solid #CFD5E2",
		borderRadius: 8,
		padding: "24px",
		[theme.breakpoints.down('sm')]: {
			padding: "8px",
		}
	},
	valuesCardComponent: {
		marginTop: 16,
		marginBottom: 28,
	}
}));