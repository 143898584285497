import { call, put, takeLatest } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { Action, Response } from "src/types";

import { creditsActions } from "./actions";
import { CREDITS_TYPES } from "./types";

const creditService = new Service('credits', { api: 'kronos', namespace: 'customer' })
const oneCreditService = new Service('credit', { api: 'kronos', namespace: 'customer' })

function* getOne({ payload }: Action) {
    const response: Response = yield call((payload.id ? creditService : oneCreditService).getPath, payload.id || '')
    yield put(creditsActions.setOne(response.data))
}

export function* creditsSagas() {
    yield takeLatest(CREDITS_TYPES.GET_ONE, sagaWrapper(getOne, creditsActions.setOne()))
}