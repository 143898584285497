import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        padding: '64px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '64px 16px',
            paddingTop: '80px'

        }
    },
    breadcrumbs: {
        margin: 0,
    },
    title: {
        color: theme.palette.primary.dark,
        fontSize: 34,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
        }
    },
    resultContainer: {
        marginTop: 32
    },
    item: {
        alignItems: 'center',
        background: "#FFFFFF",
        border: "1px solid #CFD5E2",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: '24px',
        position: 'relative',
        // marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
            maxWidth: '100%',
            padding: 16,
            flexWrap: 'wrap-reverse'
        },
    },
    itemData: {
        marginRight: 'auto'
    },
    itemTitle: {
        alignItems: 'center',
        color: theme.palette.primary.dark,
        display: 'flex',
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 8,
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            marginBottom: 4,
            lineHeight: '24px',
        },
        '& > svg': {
            marginLeft: 8
        }
    },
    itemDescription: {
        fontSize: 16,
        marginBottom: 0,
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            marginBottom: 0,
            lineHeight: '24px',
        }
    },
    itemIcon: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16
        }
    },
    inputsContainer: {
        marginTop: 32
    }
}))