import { PaymentProductType } from "src/types";
import { APPLICATION_PAYMENTS_TYPES } from "./types";

export interface PaymentsStateType {
    products?: PaymentProductType[]
    isLoading: boolean
}

export const initialState: PaymentsStateType = {
    isLoading: false
}

export function applicationPaymentsReducer(state = initialState, action: any): PaymentsStateType {
    const { type, payload } = action
    switch (type) {
        case APPLICATION_PAYMENTS_TYPES.GET_PRODUCTS:
            return {
                ...state,
                isLoading: true
            }
        case APPLICATION_PAYMENTS_TYPES.SET_PRODUCTS:
            return {
                ...state,
                isLoading: false,
                products: payload.products
            }
        default:
            return state
    }
}