import { Link, MessagePage } from "src/components";
import { useStyles } from "./styles";
import Icon404 from 'src/assets/img/404.svg'
import { Button } from "@mui/material";

interface NotFoundProps {
    backgroundColor?: string;
}

export function NotFound({ backgroundColor }: NotFoundProps) {
    const { classes, cx } = useStyles();
    return (
        <MessagePage
            title="Página no encontrada"
            message="Lo sentimos, no podemos encontrar la página que estás buscando."
            icon={Icon404}
            containerProps={{
                className: classes.dataContainer
            }}
            style={backgroundColor ? {
                backgroundColor
            } : {}}
        >
            <Link to={'/'}>
                <Button
                    variant='contained'
                    size='large'
                    color='primary'
                >
                    Ir a inicio
                </Button>
            </Link>
        </MessagePage>
    )
}