//@ts-ignore
import CurrencyFormat from "react-currency-format";

export const fontFamilies = 'Circular Std, Roboto, -apple-system,BlinkMacSystemFont,"Segoe UI"';

export function nFormatter(num: number, digits: number) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export function getMax(array: number[], addExtra?: boolean) {
    const max = Math.max.apply(null, array) || 0;
    return addExtra ? max + (max * .05) : max;
}

export function getConceptColors(): string[] {
    return ['#2CC1A6', '#1DAA6C', '#045834', '#EBBA3C', '#2CC1A6']
}

export function getConceptColor(concept: string): string {
    switch (concept) {
        case 'Intereses': return '#2CC1A6';
        case 'Capital': return '#1DAA6C';
        case 'Seguro de vida': return '#045834';
        case 'Comisión por administración': return '#EBBA3C';
        case 'Seguro de daños': return '#2CC1A6';
        default: return '';
    }
}

export const currency = (val: any) => (
    <CurrencyFormat
        value={parseFloat(val || 0)}
        decimalScale={2}
        fixedDecimalScale={false}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
    />
);