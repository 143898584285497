import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { useParams } from "react-router-dom";
import { StagePage, FormBuilder } from "src/components";
import { fields, initialValues } from "./fields";
import { useStyles } from "./styles";
import { isValidId, navigate, segment } from "src/helpers";
import { getValidationSchema } from "@kredimx/form-builder";
import { ConfirmModal } from "src/components/ConfirmModal";
import { Address } from 'src/types';

import { useSelector, useDispatch } from "react-redux";
import { customerActions } from 'src/store/customer/actions';
import { Card } from "@mui/material";
import { providersActions } from "src/store/providers/actions";

export function AddressForm() {
	const { address_id }: any = useParams();
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const address = useSelector(state => state.customer.address);
	const { data: states, isLoading: isLoadingStates } = useSelector(state => state.providers.states);

	const createAddress = (address: Address) => dispatch(customerActions.createAddress(address));
	const updateAddress = (address: Address) => dispatch(customerActions.updateAddress(address));
	const getAddress = (id: string) => dispatch(customerActions.getAddress(id));
	const destroyAddress = (address: Address) => dispatch(customerActions.destroyAddress(address?.id || ''));
	const [openModal, setOpenModal] = useState<boolean>(false);

	function onSubmit(values: any) {
		if (address.id) {
			updateAddress(values);
			segment.track('Customer', 'Updated', { view: 'AddressesForm', action: 'Updated', id: address_id })
		} else {
			createAddress(values);
			segment.track('Customer', 'Updated', { view: 'AddressesForm', action: 'Created' })
		}
	}

	useEffect(() => {
		if (isValidId(address_id)) {
			getAddress(address_id)
			segment.track('Customer', 'Viewed', { view: 'AddressesForm', id: address_id })
		}

		return () => { dispatch(customerActions.setAddress({})) }
	}, [address_id])

	const onCancel = () => {
		setOpenModal(false)
	}

	const onConfirm = () => {
		destroyAddress(address)
	}

	return (
		<>
			<ConfirmModal
				title="Eliminar dirección"
				description="¿Estás seguro de que deseas eliminar esta dirección?"
				onCancel={onCancel}
				onConfirm={onConfirm}
				open={openModal}
			/>
			<StagePage
				title={`${isValidId(address_id) ? 'Editar' : 'Agregar'} dirección`}
				className={classes.stagePage}
				breadcrumbs={[
					{ to: '/home/profile', name: 'Perfil' },
					{ to: '/home/profile/addresses', name: 'Direcciones' },
					{ name: isValidId(address_id) ? 'Editar dirección' : 'Agregar dirección' }
				]}
				containerProps={{
					md: 8
				}}
			>
				<Card className={classes.card} elevation={0}>
					<FormBuilder
						fields={fields}
						onSubmit={onSubmit}
						initialValues={address.id ? address : initialValues}
						submitCaption={address.id ? 'Guardar' : 'Agregar'}
						submitButtonProps={{
							dataCy: 'submit-button',
							className: classes.submitButton
						}}
						hideHelpers
						cancelCaption={address.id ? 'Eliminar' : 'Cancelar'}
						cancelButtonProps={address.id ? {
							variant: 'text',
							startIcon: <DeleteIcon />,
							color: 'inherit',
							className: classes.deleteButton,
							size: 'medium',
							onClick: () => setOpenModal(true),
						} : {
							onClick: () => navigate.to('/home/profile/addresses'),
						}}
						validationSchema={getValidationSchema(fields)}
						actionContainerProps={{
							justifyContent: 'space-between'
						}}
						specialInputsProps={{
							stateInputProps: {
								isLoading: isLoadingStates,
								countries: [],
								states: states || [],
								getStates: (countryCode?: string) => dispatch(providersActions.getStates(countryCode)),
								setStates: (states?: any[]) => dispatch(providersActions.setStates(states)),
								getMunicipalities: (state?: string) => dispatch(providersActions.getMunicipalities(state)),
							},
						}}
					/>
				</Card>
			</StagePage>
		</>
	)
}