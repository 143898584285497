import { useEffect } from "react";
import { IconButton, Grid, Typography } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import EmailIcon from "@mui/icons-material/EmailOutlined"
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined"
import { Card, Loading, StagePage } from "src/components";
import { useStyles } from "./styles";
import { AmortizationChart, AmortizationTable, DonutChart } from "../../../components";

import chartData from "./data.json"
import donutChartData from "./donutChart.json"

import { useDispatch, useSelector } from "react-redux";
import { isValidId } from "src/helpers";
import { amortizationTablesActions } from "src/store/credits/amortizationTables/actions";
import { creditsActions } from "src/store/credits/actions";

import { creditColumns } from "./columns";

export function AmortizationDetailsPage() {
    const { creditId }: any = useParams()
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()
    const location = useLocation()
    const isHistory = location.pathname.includes('details')

    const credit = useSelector(state => state.credits.credits.credit)
    const isLoading = useSelector(state => state.credits.amortizationTables.isLoading)
    const amortizationTable = useSelector(state => state.credits.amortizationTables.amortizationTable)

    useEffect(() => {
        if (isValidId(creditId)) {
            dispatch(creditsActions.getOne(creditId))
            dispatch(amortizationTablesActions.getCurrent(creditId))
        }

        return () => {
            dispatch(amortizationTablesActions.setOne())
        }
    }, [creditId])

    return (
        <StagePage
            title={isHistory ? 'Tabla de amortización 03/Dic/22' : 'Tabla de amortization'}
            breadcrumbs={isHistory ? [
                { name: 'Información de mi crédito', to: '/home/credit/details' },
                { name: 'Historial de tablas de amortización', to: '/home/credit/details/history' },
                { name: 'Ver tabla de amortización', to: '' },
            ] : [
                { name: 'Crédito', to: '/home/credit' },
                { name: 'Tabla de amortización', to: '' },
            ]}
        >
            <Card
                title="Desglose"
                disableDivider
            // titleAction={(
            //     <div className={classes.iconsContainer}>
            //         <IconButton size="small">
            //             <EmailIcon />
            //         </IconButton>
            //         <IconButton size="small">
            //             <DownloadIcon />
            //         </IconButton>
            //     </div>
            // )}
            >
                <Loading isLoading={isLoading} variant="skeleton">
                    <Grid container className={classes.card}>
                        <Grid container spacing={3}>
                            {creditColumns.map(({ label, key, render }, index) => (
                                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        {label}
                                    </Typography>
                                    <Typography className={classes.value}>
                                        {credit?.id ? (render ? render(credit[key], credit) : credit[key]) : '' as any}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <AmortizationTable
                        className={classes.amortizationTable}
                        data={amortizationTable}
                    />

                    <AmortizationChart
                        className={classes.amortizationTable}
                        data={amortizationTable}
                    />

                    <DonutChart
                        className={classes.amortizationTable}
                        data={amortizationTable}
                    />
                </Loading>
            </Card>
        </StagePage>
    )
}