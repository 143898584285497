import { Service } from "./service";
import _ from "lodash";
import { normalizer } from "../helpers/normalizer";
import { getBaseUrl } from "src/helpers";

class CustomApplicationService extends Service {
    getConditionedQuestions = async (applicationId: string, dataset: any) => {
        const response = await this.service.patch(`${this.pathname}/${applicationId}/questions`, dataset, {
            baseURL: getBaseUrl(this.service, this.config)
        });

        if (!_.isEmpty(response.data)) {
            return {
                data: {
                    shownFields: normalizer(response.data.shown_fields),
                    hiddenFields: normalizer(response.data.hidden_fields),
                }
            }
        } else {
            return {
                data: {
                    shownFields: [],
                    hiddenFields: []
                }
            }
        }
    }
}

export const ApplicationService = new CustomApplicationService('application_workflows', { isOptionalToken: true });