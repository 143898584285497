import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    container: {
        padding: '45px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px 60px',
        }
    },
    card: {
        background: "#FFFFFF",
        border: "1px solid #CFD5E2",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: '24px 24px',
        // marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            padding: '16px 16px',
        },
    },
    cardTitleContainer: {
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
        },
    },
    cardTitle: {
        fontSize: 24,
        lineHeight: '24px',
        letterSpacing: '0.18px',
        fontWeight: 700,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '24px',
            letterSpacing: '0.15px',
            fontWeight: 700,
        },
    },
    cardItemTitle: {
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: '0.15px',
        fontWeight: 700,
        marginLeft: 7,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: '24px',
            letterSpacing: '0.15px',
            fontWeight: 700,
        },
    },
    cardItemsContainer: {
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    cardItemContainer: {
        marginBottom: 4,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 24,
        },
    },
    cardItemValue: {
        marginLeft: 32
    },
    chip: {
        borderRadius: 24,
        fontSize: 12,
        letterSpacing: .4,
        maxHeight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 8px',
        '& > svg': {
            height: 14
        }
    },
    cardActionsContainer: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'right'
        },
    },
    smallCardButton: {
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
    },
    smallCard: {
        [theme.breakpoints.down('sm')]: {
            minHeight: 230
        }
    }
}));