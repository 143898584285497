import { APPLICATION_REJECTED_DOCUMENTS } from "./types"

function getAll(applicationId: string) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.GET_ALL,
        payload: { applicationId }
    }
}

function getAllDelayed(applicationId: string) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.GET_ALL_DELAYED,
        payload: { applicationId }
    }
}

function setAll(documents?: any[]) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.SET_ALL,
        payload: { documents }
    }
}

function upload(applicationId: string, documentId: string, attachment: any) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.UPLOAD,
        payload: { applicationId, documentId, attachment }
    }
}

function hasAny(applicationId: string) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.HAS_ANY,
        payload: { applicationId }
    }
}

function setHasAny(value: boolean) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.SET_HAS_ANY,
        payload: { value }
    }
}

export const applicationRejectedDocuments = {
    getAll,
    getAllDelayed,
    setAll,
    upload,
    hasAny,
    setHasAny,
}