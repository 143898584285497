import { call, takeLatest, select } from "redux-saga/effects";
import { sagaWrapper, getKrediParams } from "src/helpers";
import { ApplicationService } from "src/services";
import { Action, Response, Application } from "src/types";
import { RootState } from "..";
import { applicationActions } from "../application/actions";
import { SECTION_TYPES } from "./types";
import { setApplication } from "../application/sagas";

function* setCurrentSection({ payload }: Action) {
    const application: Application = yield select((state: RootState) => state.applications.application);
    const logRocketUrl: string = yield select((state: RootState) => state.auth.logRocketSession);
    const { referral_code, utm_data } = getKrediParams();

    const response: Response = yield call(ApplicationService.update, application.id!, {
        application_workflow: {
            section_id: payload.sectionId,
            session: {
                screen_recording: logRocketUrl,
                utm_data,
                code: referral_code
            }
        }
    });
    yield call(setApplication, response.data);
}

export function* sectionSagas() {
    yield takeLatest(SECTION_TYPES.SET_CURRENT_SECTION, sagaWrapper(setCurrentSection, applicationActions.applicationFailed()));
}