import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingBackdrop } from "src/components";
import { applicationActions } from "src/store/application/actions";

export function CreateApplicationByRootPage() {
    const dispatch = useDispatch();
    const { workflowSlug }: any = useParams();

    useEffect(() => {
        if (workflowSlug)
            dispatch(applicationActions.createApplicationByRootSlug(workflowSlug));
    }, [workflowSlug])

    return (
        <LoadingBackdrop />
    )
}