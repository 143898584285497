import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Breadcrumbs } from "src/components";
import { useScript } from "src/helpers"
import { RootState } from "src/store";
import { useStyles } from "./styles";

export function ConsultantSchedule() {
    const { classes, cx } = useStyles();
    const customer = useSelector(state => state.auth.customer)
    const user = customer?.user;

    //Calendly
    //useScript('https://assets.calendly.com/assets/external/widget.js');

    //Hubspot
    useScript('https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js');

    return (
        <>
            <Grid container className={classes.root}>
                <Breadcrumbs
                    className={classes.breadcrumbs}
                    items={[
                        // { to: '/home/main', name: 'Volver' },
                        { to: '/home/consultant', name: 'Contacta a tu asesor' },
                        { name: 'Agendar asesoría' }
                    ]}
                />
            </Grid>

            <Grid container className={classes.container}>
                {/* Hubspot */}
                <div
                    className="meetings-iframe-container"
                    data-src={`https://meetings.hubspot.com/${user?.calendly_user}?embed=true&email=${customer.email}&firstname=${customer.first_name}&lastname=${customer.last_name}`}
                    style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        overflowY: 'auto'
                    }}
                />
                {/* Calendly */}
                {/* <div
                    className="calendly-inline-widget"
                    data-url={`https://calendly.com/${user?.calendly_user}`}
                    style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                    }}
                /> */}
            </Grid>
        </>
    )
}