import axios, { AxiosInstance } from "axios";
import { qs, ApiType, getServiceUrl, NamespaceType, normalizeSearch, getBaseUrl } from "../helpers";
import { normalizer } from "../helpers/normalizer";
import { interceptors } from "./interceptors";

import { store } from "../store";
import { refreshTokenActions } from "src/store/refreshToken/actions";

import mime from "mime-types";
import _ from "lodash";

export interface IndexParams {
    page?: number;
    perPage?: number;
    search?: object;
}

export interface ServiceConfig {
    api?: ApiType;
    isPublic?: boolean;
    namespace?: NamespaceType;
    isOptionalToken?: boolean;
    disableNamespace?: boolean
}

export interface DownloadOptionsType {
    /** Open the file in new tab */
    newTab?: boolean
    /** Set the mime type from the name, disable auto mime type */
    disableMimeType?: boolean
    /** Set the given filename and set the auto mime type */
    enableFileName?: boolean
}

export class Service {
    protected pathname: string;
    service: AxiosInstance;
    config: ServiceConfig;

    constructor(pathname: string, serviceConfig?: ServiceConfig) {
        this.pathname = `/${pathname}`;
        this.config = serviceConfig || {};
        this.service = axios.create({
            baseURL: getServiceUrl(serviceConfig?.api || 'apollo', serviceConfig?.namespace, { ...serviceConfig }),
        });
        interceptors(serviceConfig!, this)
    }

    processQueue = (error: any, token = null) => {
        const failedQueue = [...store.getState()?.refreshToken?.failedRequestsQueue];
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        store.dispatch(refreshTokenActions.set('failedRequestsQueue', []))
    };

    all = async ({ page, perPage, search }: IndexParams, normalize: boolean = true, notIncluded: boolean = false) => {
        const response = await this.service.get(this.pathname, {
            baseURL: getBaseUrl(this.service, this.config),
            params: { q: normalizeSearch(search), page, per_page: perPage },
            paramsSerializer: (params) => {
                return qs.stringify(params, { indices: false, arrayFormat: 'brackets' });
            },
        });
        return normalize ? this.formatResponse(response, notIncluded) : response.data;
    }

    allPath = async (path: string, { page, perPage, search }: IndexParams, normalize: boolean = true, notIncluded: boolean = false) => {
        const response = await this.service.get(`${this.pathname}/${path}`, {
            baseURL: getBaseUrl(this.service, this.config),
            params: { q: normalizeSearch(search), page, per_page: perPage },
            paramsSerializer: (params) => {
                return qs.stringify(params, { indices: false, arrayFormat: 'brackets' });
            },
        });
        return normalize ? this.formatResponse(response, notIncluded) : response.data;
    }

    findOne = async (id: string, normalize: boolean = true, notIncluded: boolean = false) => {
        const response = await this.service.get(`${this.pathname}/${id}`, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        return normalize ? this.formatResponse(response, notIncluded) : response.data;
    }

    findOnePath = async (id: string, path: string, normalize: boolean = true) => {
        const response = await this.service.get(`${this.pathname}/${id}/${path}`, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        return normalize ? this.formatResponse(response) : response.data;
    }

    getPath = async (path: string, normalize: boolean = true) => {
        const response = await this.service.get(`${this.pathname}/${path}`, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        return normalize ? this.formatResponse(response) : response.data;
    }

    create = async (params: any, normalize: boolean = true, notIncluded: boolean = false) => {
        const response = await this.service.post(`${this.pathname}`, params, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        return normalize ? this.formatResponse(response, notIncluded) : response.data;
    }

    createPath = async (path: string, params: any, normalize: boolean = true, notIncluded: boolean = false) => {
        const response = await this.service.post(`${this.pathname}/${path}`, params, {
            baseURL: getBaseUrl(this.service, this.config)
        });
        return normalize ? this.formatResponse(response, notIncluded) : response.data;
    }

    update = async (id: string, params: any, normalize: boolean = true, notIncluded: boolean = false) => {
        const response = await this.service.put(`${this.pathname}/${id}`, params, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        return normalize ? this.formatResponse(response, notIncluded) : response.data;
    }

    updatePath = async (id: string, params: any, path: string, normalize: boolean = true) => {
        const response = await this.service.put(`${this.pathname}/${id}/${path}`, params, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        if (!_.isEmpty(response.data)) {
            return normalize ? this.formatResponse(response) : response.data
        } else {
            return null
        }
    }

    updateCustomPath = async (path: string, params: any, normalize: boolean = true) => {
        const response = await this.service.put(`${this.pathname}/${path}`, params, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        if (!_.isEmpty(response.data)) {
            return normalize ? this.formatResponse(response) : response.data
        } else {
            return null
        }
    }

    patch = async (id: string, params: any, normalize: boolean = true, notIncluded: boolean = false) => {
        const response = await this.service.patch(`${this.pathname}/${id}`, params, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        return normalize ? this.formatResponse(response, notIncluded) : response.data;
    }

    patchPath = async (path: string, params: any, normalize: boolean = true) => {
        const response = await this.service.patch(`${this.pathname}/${path}`, params, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        if (!_.isEmpty(response.data)) {
            return normalize ? this.formatResponse(response) : response.data
        } else {
            return null
        }
    }

    destroy = async (id: string) => {
        const response = await this.service.delete(`${this.pathname}/${id}`, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        return response.data;
    }

    destroyPath = async (path: string, id: string) => {
        const response = await this.service.delete(`${this.pathname}/${path}/${id}`, {
            baseURL: getBaseUrl(this.service, this.config),
        });
        return response.data;
    }

    destroyCustomPath = async (path: string, params?: any) => {
        const response = await this.service.delete(`${this.pathname}/${path}`, {
            baseURL: getBaseUrl(this.service, this.config),
            headers: {},
            data: params
        });
        return response.data;
    }

    download = async (path: string, filename: string, headers?: any, options?: DownloadOptionsType, params?: any) => {
        return this.service.get(`${this.pathname}/${path}`, {
            responseType: 'blob',
            headers,
            baseURL: getBaseUrl(this.service, this.config),
        }).then((response) => {
            const url = window.URL.createObjectURL(response.data);

            if (options?.newTab) {
                window.open(url, '_blank')
            } else {
                const _filename = options?.disableMimeType
                    ? filename
                    : `${(options?.enableFileName ? filename : filename.substring(0, filename.lastIndexOf('.'))) || filename}.${mime.extension(response.data?.type)}`

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', _filename);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url)
            }
        });
    }

    downloadPath = async (url: string, filename: string, headers?: any) => {
        return this.service.get(url, {
            responseType: 'blob',
            headers,
            baseURL: getBaseUrl(this.service, this.config),
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url)
        });
    }

    formatResponse({ data }: any, notIncluded?: boolean) {
        const _data = normalizer(data, notIncluded);
        return { data: _data, meta: data.meta };
    }

}