import { Attachment, AttachmentParamsType, GetOptionsType } from "src/types"
import { ATTACHMENTS_TYPES } from "./types"
import { AttachmentsStateType } from "./reducer"

function set(key: keyof (AttachmentsStateType), value: any) {
    return {
        type: ATTACHMENTS_TYPES.SET,
        payload: { key, value }
    }
}

function setOne(attachment: Attachment) {
    return {
        type: ATTACHMENTS_TYPES.SET_ONE,
        payload: { attachment }
    }
}

function create(applicationId: string, attachment: any, getAll?: boolean) {
    return {
        type: ATTACHMENTS_TYPES.CREATE,
        payload: { applicationId, attachment, getAll }
    }
}

function getAll(applicationId: string, params?: GetOptionsType<AttachmentParamsType>) {
    return {
        type: ATTACHMENTS_TYPES.GET_ALL,
        payload: { applicationId, params }
    }
}

function setAll(attachments: Attachment[]) {
    return {
        type: ATTACHMENTS_TYPES.SET_ALL,
        payload: { attachments }
    }
}

function update(applicationId: string, attachmentId: string, attachment: any, getAll?: boolean) {
    return {
        type: ATTACHMENTS_TYPES.UPDATE,
        payload: { applicationId, attachmentId, attachment, getAll }
    }
}

function download(attachment: Attachment) {
    return {
        type: ATTACHMENTS_TYPES.DOWNLOAD,
        payload: { ...attachment }
    }
}

export const attachmentsActions = {
    set,
    setOne,
    create,
    getAll,
    setAll,
    update,
    download,
}