import Bugsnag from "@bugsnag/browser";
import { Grid, Typography } from "@mui/material";
import { CloudDownloadOutlined as DownloadIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { parseAnswer } from "src/helpers";
import { Service } from "src/services";
import { useStyles } from "./styles";

import { useDispatch } from "react-redux";
import { questionActions } from "src/store/questions/actions";
import { snackbarActions } from "src/store/snackbar/actions";

interface StageSummaryFieldProps {
	field: any
	value: any
}
const applicationService = new Service('application_workflows')

/** Use to show answers from field */
export function StageSummaryField({ field, value }: StageSummaryFieldProps) {
	const { applicationId }: any = useParams()
	const { classes, cx } = useStyles()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(true)
	const [answer, setAnswer] = useState<any>({})

	async function getAnswer() {
		try {
			setIsLoading(true)
			const { data } = await applicationService.findOnePath(applicationId, `answers/${field.id}`)
			setAnswer(data)
		} catch {
			Bugsnag.notify('Error get answer')
			dispatch(snackbarActions.add('error', 'Hubo un error al consultar la pregunta'))
		} finally {
			setIsLoading(false)

		}
	}

	useEffect(() => {
		if (field.field_type === "file_upload") {
			getAnswer()
		}
	}, [field])

	function download(fileName: string, fileUrl: string) {
		dispatch(questionActions.downloadFile(fileName, fileUrl))
	}

	return (
		<Grid item xs={12} sm={6} md={4} className={classes.values}>
			<Typography className={classes.input}> {field.label} </Typography>

			{field.field_type === "file_upload" ? (
				<div
					onClick={() => { download(answer?.attachment?.file_name, answer?.attachment?.file_url) }}
					className={classes.fileContainer}
				>
					{answer?.attachment?.file_name ? (
						<>
							<Typography className={cx(classes.value, classes.fileName)}>
								{!isLoading && answer?.attachment?.file_name}
							</Typography>
							<DownloadIcon className={classes.value} />
						</>
					) : (
						<Typography className={cx(classes.value, classes.fileName)}>
							{!isLoading && 'No hay archivo'}
						</Typography>
					)}
				</div>
			) : (
				<Typography className={classes.value}>
					{parseAnswer(field.field_type!, value, JSON.parse(field.config))}
				</Typography>
			)}

		</Grid >
	)
}