import Cookies from 'universal-cookie';
// @ts-ignore
import _qs from 'qs';
import _ from 'lodash'
import { Customer } from 'src/types';

export * from "./reduxSaga";
export * from "./app";
export * from "./normalizer";
export * from "./hooks";
export * from "./constants";
export * from "./format";
export * from "./service";
export * from "./status";
export * from "./workflow";
export * from './parseAnswer';
export * from "./campaign";
export * from "./segment";
export * from "./hasInstalledApp";
export * from "./formatPhone";
export * from "./sendgrid"
export * from "./application"
export * from "./scoring"
export * from "./attachment"
export * from "./credit"
export * from "./documents"
export * from "./formatAddress"
export * from "./employment"
export * from "./auth"
export * from "./abacus"
export * from "./crypto"
export * from "./files"
export * from "./navigation"
export * from "./creditProposal"
export * from "./bugnsag"

export const qs = _qs
export const cookies = new Cookies();

export function parseRubyObject(object: string) {
	try {
		if (_.isEmpty(object)) return {}
		return JSON.parse(object.replaceAll(/=>/g, ':').replaceAll('nil', '""'));
	} catch {
		return object
	}
}

export function numberWithCommas(number: number) {
	if (!isFinite(number)) return 0;
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getFullName(customer: Customer) {
	return `${customer?.first_name || ''} ${customer?.middle_name || ''} ${customer?.last_name || ''} ${customer?.maternal_last_name || ''}`
}