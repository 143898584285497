import { useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StagePage, FormBuilder } from "src/components";
import { customerActions } from "src/store/customer/actions";
import { Employment } from "src/types";
import { fields, initialValues } from "./fields";
import { useStyles } from "./styles";
import _ from "lodash";
import { useEffect } from "react";
import { isValidId, navigate, segment } from "src/helpers";
import { getValidationSchema } from '@kredimx/form-builder'
import { ConfirmModal } from "src/components/ConfirmModal";
import { Card } from "@mui/material";

export function EmploymentForm() {
	const { employment_id }: any = useParams();
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const employment = useSelector(state => state.customer.employment);
	const createEmployment = (employment: Employment) => dispatch(customerActions.createEmployment(employment));
	const updateEmployment = (employment: Employment) => dispatch(customerActions.updateEmployment(employment));
	const getEmployment = (id: string) => dispatch(customerActions.getEmployment(id));
	const destroyEmployment = (employment: Employment) => dispatch(customerActions.destroyEmployment(employment?.id || ''));
	const [openModal, setOpenModal] = useState<boolean>(false);
	const isNew = !isValidId(employment_id);

	function onSubmit(values: any) {
		if (!isNew) {
			updateEmployment(values);
			segment.track('Customer', 'Updated', {
				view: 'EmploymentsForm',
				action: 'Updated',
				...values
			})
		} else {
			createEmployment(values);
			segment.track('Customer', 'Updated', {
				view: 'EmploymentsForm',
				action: 'Created',
				...values
			})
		}

	}

	useEffect(() => {
		if (isValidId(employment_id)) {
			getEmployment(employment_id)
			segment.track('Customer', 'Viewed', { view: 'EmploymentsForm', id: employment_id })
		}
		return () => { dispatch(customerActions.setEmployment({})) }
	}, [employment_id])

	const onCancel = () => {
		setOpenModal(false)
	}

	const onConfirm = () => {
		destroyEmployment(employment)
	}

	return (
		<>
			<ConfirmModal
				open={openModal}
				title="Eliminar empleo"
				description="¿Estás seguro de que deseas eliminar este empleo?"
				onCancel={onCancel}
				onConfirm={onConfirm}
			/>
			<StagePage
				title={!isNew ? 'Editar empleo' : 'Agregar empleo'}
				className={classes.stagePage}
				breadcrumbs={[
					{ to: '/home/profile', name: 'Perfil' },
					{ to: '/home/profile/employments', name: 'Empleos' },
					{ name: !isNew ? 'Editar empleo' : 'Agregar empleo' }
				]}
				containerProps={{
					md: 8
				}}
			>
				<Card className={classes.card} elevation={0}>

					<FormBuilder
						fields={fields}
						onSubmit={onSubmit}
						hideHelpers
						initialValues={!isNew ? employment : initialValues}
						submitCaption={!isNew ? 'Guardar' : 'Agregar'}
						cancelCaption={!isNew ? 'Eliminar' : 'Cancelar'}
						cancelButtonProps={!isNew ? {
							variant: 'text',
							startIcon: <DeleteIcon />,
							color: 'inherit',
							className: classes.deleteButton,
							size: 'medium',
							onClick: () => setOpenModal(true)
						} : {
							onClick: () => navigate.to('/home/profile/employments')
						}}
						submitButtonProps={{
							dataCy: 'submit-button',
							className: classes.submitButton
						}}
						validationSchema={getValidationSchema(fields)}
						actionContainerProps={{
							justifyContent: 'space-between'
						}}
					/>
				</Card>
			</StagePage>
		</>
	)
}