export const getStatus = (totals: {
    inReview: number,
    finished: number,
    approvalsTotal: number
}) => {
    const { inReview, finished, approvalsTotal } = totals

    if (finished === approvalsTotal) {
        return {
            label: 'Aprobado',
            bgColor: '#E8F4EC',
            color: '#51A871'
        }
    }

    if (inReview > 0) {
        return {
            label: 'Revisión',
            bgColor: '#E7F3FE',
            color: '#64A8F8'
        }
    }

    return {
        label: 'En proceso',
        bgColor: '#E7F3FE',
        color: '#64A8F8'
    }
}