import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        backgroundColor: '#d9d9d9',
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'row',
        height: 8,
        overflow: 'hidden',
        width: '100%'
    }
}))