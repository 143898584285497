import { AUTH_TYPES } from "./types";
import { AuthState } from "./reducer";
import { Employment } from "src/types";
import { ScoringSocketDataType } from "src/helpers";

function sendMagicLink(email: string, isResend?: boolean) {
    return {
        type: AUTH_TYPES.SEND_MAGIC_LINK,
        payload: { email, isResend }
    }
}

export type SetAuthState = keyof (AuthState);

function set(name: SetAuthState, value: any) {
    return {
        type: AUTH_TYPES.SET,
        payload: { name, value }
    }
}

function getToken(params: {
    key?: string,
    code?: string,
    client_id: string,
    redirectUrl?: string
}) {
    return {
        type: AUTH_TYPES.GET_TOKEN,
        payload: { ...params }
    }
}

function successLogin(token: string) {
    return {
        type: AUTH_TYPES.SUCCESS_LOGIN,
        payload: { token }
    }
}

export interface RedirectConfig {
    disable?: boolean;
    url?: string;
}

function getCustomer(redirectConfig?: RedirectConfig) {
    return {
        type: AUTH_TYPES.GET_CUSTOMER,
        payload: { redirectConfig }
    }
}

function setCustomer(customer?: any) {
    return {
        type: AUTH_TYPES.SET_CUSTOMER,
        payload: { customer }
    }
}

function logout() {
    return {
        type: AUTH_TYPES.LOGOUT
    }
}

function createAccount(email: string) {
    return {
        type: AUTH_TYPES.CREATE_ACCOUNT,
        payload: { email }
    }
}

function setEmployments(employments: Employment[]) {
    return {
        type: AUTH_TYPES.SET_EMPLOYMENTS,
        payload: { employments }
    }
}

function redirectLogin(path?: string) {
    return {
        type: AUTH_TYPES.REDIRECT_LOGIN,
        payload: { path }
    }
}

function setLogRocketSession(url: any) {
    return {
        type: AUTH_TYPES.SET_LOGROCKET_SESSION,
        payload: { url }
    }
}

function isSegmentLoaded() {
    return {
        type: AUTH_TYPES.IS_SEGMENT_LOADED
    }
}

function me() {
    return {
        type: AUTH_TYPES.ME
    }
}

function setScoringData(data?: ScoringSocketDataType) {
    return {
        type: AUTH_TYPES.SET_SCORING_DATA,
        payload: { data }
    }
}

function login(gid: string, applicationId: string, rt?: string) {
    return {
        type: AUTH_TYPES.LOGIN,
        payload: { gid, applicationId, rt }
    }
}

export const authActions = {
    set,
    getToken,
    sendMagicLink,
    successLogin,
    getCustomer,
    setCustomer,
    logout,
    createAccount,
    setEmployments,
    redirectLogin,
    setLogRocketSession,
    isSegmentLoaded,
    me,
    setScoringData,
    login,
}