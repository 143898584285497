
import { ComponentType, Fragment } from "react";
import { Card, CardProps, Grid, GridProps, IconButton, Typography } from "@mui/material";
import { MoreVert as ActionsIcon } from "@mui/icons-material";
import { Link } from "src/components";
import { TableProps } from "../Table";
import { useStyles } from './CardList.styles';
import _ from 'lodash';

export interface CardViewProps {
    getTitle?: (row: any) => string;
    variant?: 'standard' | 'striped';
    hideTitle?: boolean;
    FooterCard?: ComponentType<{ row: any }>;
    cardContainerProps?: CardProps;
    headerContainerProps?: GridProps;
    canClickRow?: boolean;
    /** Callback to set menu anchor value with data row */
    setMenu?: (props: { anchorEl: null | HTMLElement, row: any }) => void;
}

export function CardList({
    getTitle,
    variant,
    hideTitle,
    FooterCard,
    cardContainerProps,
    headerContainerProps,
    canClickRow,
    setMenu,
    ...rest
}: CardViewProps & TableProps) {
    const { classes, cx } = useStyles();
    const { data, getHref, actions, CustomMenu, columns } = rest || {};
    return (
        <Grid container>
            {data?.map((row: any, index) => {
                const Component = getHref && canClickRow ? Link : Fragment
                const componentsProps = getHref ? { to: getHref(row) } : {}
                const title = getTitle ? getTitle(row) : '';
                const isHasActions = ((actions?.length || 0) > 0);
                return (
                    <Grid xs={12} sm={12} item key={index}>
                        {/* @ts-ignore */}
                        <Component {...componentsProps}>
                            <Card
                                elevation={0}
                                {...cardContainerProps}
                                className={cx(classes.card, cardContainerProps?.className)}
                            >
                                {(!hideTitle || isHasActions) && (
                                    <Grid
                                        container
                                        {...headerContainerProps}
                                        className={cx(classes.titleContainer, headerContainerProps?.className)}
                                    >
                                        {!hideTitle && (
                                            <Typography className={classes.title}>{title}</Typography>
                                        )}
                                        {(isHasActions || CustomMenu) && (
                                            <IconButton
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    if (_.isFunction(setMenu)) {
                                                        setMenu({ anchorEl: e.currentTarget, row });
                                                    }
                                                }}
                                                disabled={actions?.every(action => action.hidden ? action.hidden(row) : false)}
                                                className={classes.cardIconButton}
                                            >
                                                <ActionsIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                )}
                                <Grid container className={cx(classes.infoContainer, { [classes.inforContainerStriped]: variant === "striped" })}>
                                    {columns?.filter(column => !column.hidden)
                                        .filter(column => !column.hiddenSm)
                                        .map(({ render, label, key }, indexColumn) => {
                                            const value = _.get(row, key);
                                            const _render = _.isFunction(render) ? render(value, row) : _.isEmpty(value) ? "" : value;
                                            const result = _.isString(_render)
                                                ? <Typography className={classes.value}> {_render} </Typography>
                                                : _render;
                                            return (
                                                <>
                                                    <Typography
                                                        className={
                                                            cx(classes.label, {
                                                                [classes.labelStripedGray]: variant === "striped" && indexColumn % 2 === 0,
                                                                [classes.labelStripedWhite]: variant === "striped" && indexColumn % 2 === 1,
                                                            })}
                                                    >
                                                        {label}
                                                    </Typography>
                                                    {result}
                                                </>
                                            )
                                        })}
                                </Grid>
                                {FooterCard && <FooterCard row={row} />}
                            </Card>
                        </Component>
                    </Grid>
                )
            })}
        </Grid>
    )
};