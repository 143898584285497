import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    item: {
        marginBottom: 24
    },
    card: {
        border: '1px solid #CFD5E2',
        borderRadius: 8,
        padding: 24,
        background: '#fff',
        [theme.breakpoints.down('sm')]: {
            padding: 16
        }
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        '& > h3': {
            fontSize: 20,
            fontWeight: 'bold',
        },
    },
    spaceBetween: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    button: {
        maxWidth: '100%',
        width: 166,
        marginTop: 24,
    },
    account: {
        margin: '8px 0'
    },
    accountCard: {
        position: 'relative',
    },
    accountTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            marginBottom: 8
        }
    },
    accountBankName: {
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        }
    },
    moreIcon: {
        position: 'absolute',
        top: 6,
        right: 2,
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 'auto',
            right: 'auto'
        }
    },
    activeChip: {
        height: 24,
        background: '#E5F4EC',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    inactiveChip: {
        height: 24,
        background: 'rgba(117, 139, 183, 0.12)',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    imgContainer: {
        [theme.breakpoints.down('sm')]: {
            order: -1,
            paddingBottom: 8
        }
    },
    bottomButton: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            right: 8,
            bottom: 0
        }
    },
    accountContainer: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 48
        }
    }
}))