import { Grid } from "@mui/material"
import { StagePage } from "src/components"
import { CreditProposalsCardList } from "./CardList"
import { useStyles } from "./styles"

export function CreditProposalsPage() {
    const { classes, cx } = useStyles()

    return (

        <StagePage
            title="Propuestas de crédito"
            titleContainerProps={{
                style: { marginBottom: 32 }
            }}
            titleProps={{
                className: classes.stageTitle
            }}
            breadcrumbs={[
                { name: 'Inicio', to: '/home/main' },
                { name: 'Propuestas de crédito' },
            ]}
        >
            <Grid container>

                <Grid item xs={12}>
                    <CreditProposalsCardList />
                </Grid>

            </Grid>
        </StagePage>
    )
}