import { Box, Tooltip } from "@mui/material"
import { useStyles } from "./styles"

interface DocumentPercentBoxProps {
    percent: number,
    tooltip: string,
    bgColor: string,
    total: number,
    className?: string
}

export function DocumentPercentBox({
    percent,
    tooltip,
    bgColor,
    total,
    className
}: DocumentPercentBoxProps) {
    const { classes, cx } = useStyles()

    if (total <= 0) {
        return <></>
    }

    return (
        <Tooltip
            title={tooltip}
            placement='top'
            arrow
            classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow
            }}
        >
            <Box
                className={cx(classes.box, className)}
                style={{
                    backgroundColor: bgColor,
                    flex: percent
                }}
            />
        </Tooltip>
    )
}