import { Link } from "@mui/material";
import { Organization } from "src/types";

interface TermsConditionsItem {
    content: JSX.Element
}

export function termsConditionsItems(organization: Organization): TermsConditionsItem[] {
    return ([
        {
            content: <>Acepto que los datos proporcionados son verdaderos.</>
        },
    ])
}