import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    item: {
        marginBottom: 24
    },
    card: {
        border: '1px solid #CFD5E2',
        borderRadius: 8,
        padding: 24,
        background: '#fff',
        [theme.breakpoints.down('sm')]: {
            padding: 16
        }
    },
    content: {
        [theme.breakpoints.down('sm')]: {
            padding: '12px 8px !important'
        }
    },
    bankItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        '& > h5': {
            fontWeight: 'bold',
            fontSize: 16
        },
        [theme.breakpoints.down('sm')]: {
            '& > h5': {
                fontSize: 14
            },
        }
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        '& > h3': {
            fontSize: 20,
            fontWeight: 'bold',
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        color: '#242C37'
    },
    button: {
        maxWidth: '100%',
        width: 166,
        marginTop: 24,
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 'bold',
        padding: '16px 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    mainDataContainer: {
        paddingBottom: 24,
        borderBottom: '1px solid #CFD5E2',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 12
        }
    },
    mainData: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '& > p': {
            fontSize: 20,
        },
        '& > h3': {
            fontSize: 20,
            fontWeight: 'bold'
        },
        [theme.breakpoints.down('sm')]: {
            '& > p': {
                fontSize: 14,
            },
            '& > h3': {
                fontSize: 14,
                fontWeight: 'bold'
            },
        }
    },
    table: {
        padding: '8px 16px',
        marginBottom: 24,
        '& > div:nth-child(even)': {
            backgroundColor: 'rgba(117, 139, 183, 0.08)',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px',
        borderBottom: '1px solid #CFD5E2'
    },
    totalRow: {
        width: '100%',
        display: 'flex',
        padding: '8px 16px',
        justifyContent: 'flex-end',
        background: 'transparent !important'
    },
    totalContainer: {
        padding: '0 32px',
        marginTop: -16,
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px'
        }
    },
    total: {
        fontWeight: 'bold'
    },
    accountTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            marginBottom: 8
        }
    },
    imgContainer: {
        [theme.breakpoints.down('sm')]: {
            order: -1,
            paddingBottom: 8
        }
    },
    titleActionText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    chip: {
        maxHeight: 16
    },
    divider: {
        height: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))