import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	root: {
		display: 'flex',
		height: '100vh',
		overflow: 'hidden',
		width: '100%',
		['@media (max-height: 615px)']: {
			height: '100%',

		},
		[theme.breakpoints.down('sm')]: {
			height: '100%',
		}
	},
	content: {
		flex: '1 1 auto',
		height: '100%',
		// background: "#FAFAFC",
		['@media (max-height: 615px)']: {
			height: '100vh',

		},
		overflow: 'auto',
		paddingTop: 70,
		['@media (max-width: 1023px)']: {
			paddingTop: 50
		},
	},
}));