import { getBaseUrl } from "src/helpers";
import { Service } from "./service";

interface CustomerAuthParams {
    username: string;
    client_id: string;
    response_type: 'code';
}

export interface CustomerTokenParams {
    client_id: string;
    code: string;
    grant_type: 'authorization_code' | 'refresh_token';
    redirect_uri?: string;
    refresh_token?: string;
}

export interface TokenResponseType {
    id: string
    type: string
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    code?: string
    client_id?: string
}

interface SendMagicLinkBodyType {
    username: string,
    client_id: string,
    response_type: string,
    redirect_uri: string
}

interface SendMagicLinkBodyType {
    username: string,
    client_id: string,
    response_type: string,
    redirect_uri: string
}

class AuthorizationService extends Service {
    auth = async (customerAuth: CustomerAuthParams) => {
        const response = await this.service.post(`${this.pathname}/oauth/authorize`, customerAuth, {
            baseURL: getBaseUrl(this.service, this.config)
        });
        return response;
    }

    token = async (customerToken: CustomerTokenParams) => {
        const response = await this.service.post(`${this.pathname}/oauth/token`, customerToken, {
            baseURL: getBaseUrl(this.service, this.config)
        });
        return this.formatResponse(response);
    }

    revokeToken = async () => {
        const response = await this.service.delete(`${this.pathname}/oauth/token`, {
            baseURL: getBaseUrl(this.service, this.config)
        });
        return response;
    }

    sendMagicLink = async (body: SendMagicLinkBodyType) => {
        const response = await this.service.post(`${this.pathname}/oauth/authorize`, body, {
            baseURL: getBaseUrl(this.service, this.config)
        });
        return response;
    }
}

export const AuthService = new AuthorizationService('customers', { api: 'iam', isPublic: true });