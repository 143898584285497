import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    title: {
        fontWeight: 600,
        fontSize: 18,
        color: '#242C37', // '#394A61',
        padding: '8px 0px'
    },
    container: {
        padding: '8px 16px',
        [theme.breakpoints.between('sm', 'md')]: {
            padding: theme.spacing(1, 0),
        },
        [theme.breakpoints.down('sm')]: {
            padding: 8
        }
    },
}));