import { ReactChild } from "react";
import { Grid, Typography, GridProps } from "@mui/material";
import { useStyles } from "./styles";

export interface ApplicationResponseCardProps extends GridProps {
    title?: string;
    children: ReactChild | ReactChild[] | any;
    titleClassName?: string;
}

export function ApplicationResponseCard({ title, children, className, titleClassName, ...rest }: ApplicationResponseCardProps) {
    const { classes, cx } = useStyles();

    return (
        <Grid container className={cx(classes.item, className)} {...rest}>
            {title && (
                <Grid item xs={12}>
                    <Typography variant="h2" className={cx(classes.title, titleClassName)}> {title} </Typography>
                </Grid>
            )}
            {children}
        </Grid>
    )
}