import { Action, AmortizacionTableType } from "src/types";
import { AMORTIZATION_TABLES_TYPES } from "./types";

interface AmortizationTabledStateType {
    amortizationTable?: AmortizacionTableType,
    isLoading: boolean
}

const INITIAL_STATE: AmortizationTabledStateType = {
    isLoading: false
}

export function amortizationTabledReducer(state: AmortizationTabledStateType = INITIAL_STATE, action: Action): AmortizationTabledStateType {
    const { type, payload } = action
    switch (type) {

        case AMORTIZATION_TABLES_TYPES.GET_CURRENT:
        case AMORTIZATION_TABLES_TYPES.GET_ONE: {
            return {
                ...state,
                isLoading: true
            }
        }

        case AMORTIZATION_TABLES_TYPES.SET_ONE: {
            return {
                ...state,
                isLoading: false,
                amortizationTable: payload.amortizationTable
            }
        }

        default:
            return state
    }
}