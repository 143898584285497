import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    iconsContainer: {
        display: 'flex',
        gap: 16
    },
    card: {
        border: '1px solid #CFD5E2',
        borderRadius: 8,
        padding: 24,
        marginBottom: 24
    },
    label: {
        fontWeight: 900,
        lineHeight: '24px',
        maxWidth: '100%',
        wordBreak: 'break-word',
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
        }
    },
    value: {
        fontWeight: 300,
        lineHeight: '24px',
        maxWidth: '100%',
        wordBreak: 'break-word',
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
        }
    },
    amortizationTable: {
        padding: 0
    }
}))