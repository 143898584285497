import { SNACKBAR_TYPES } from "./types";
import { SnackbarVariant } from "src/components";

function add(variant: SnackbarVariant, message: string, duration?: number) {
    return {
        type: SNACKBAR_TYPES.ADD,
        payload: { variant, message, duration }
    }
}

function clean() {
    return {
        type: SNACKBAR_TYPES.CLEAN,
    }
}

export const snackbarActions = {
    add,
    clean
}