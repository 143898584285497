import { SelectInput, TextAreaInput } from "@kredimx/form-builder";
import { Button, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { Card, StagePage } from "src/components";
import { UploadInput } from "src/components/Dropzone";
import { snackbarActions } from "src/store/snackbar/actions";
import { useStyles } from "./styles"
import { navigate } from "src/helpers";

export function ProcedureNewPage() {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    return (
        <StagePage
            title="Nuevo trámite"
            breadcrumbs={[
                { name: 'Crédito', to: '/home/credit' },
                { name: 'Trámites y aclaraciones', to: '/home/credit/procedures' },
                { name: 'Nuevo trámite', to: '' },
            ]}
        >
            <Card hideHeader>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <SelectInput
                            label="Tipo de trámite"
                            options={["Pagos", "Documentos"]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SelectInput
                            label="Procedimiento"
                            options={["Aclaración de pago no aplicado", "Cancelación simple"]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextAreaInput
                            label="Descripción o comentarios"
                            rows={2}
                            placeholder="Escribe aquí"
                        />
                    </Grid>
                </Grid>

                <Card hideHeader style={{ marginTop: 32 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className={classes.title}> Comprobante de pago </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography> Por favor comparte tu documento en formato JPG, PNG o PDF. </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <UploadInput
                                onChange={() => { }}
                                config={{
                                    file_types: '.pdf',
                                    max_file_size: 500000,
                                    allowed_files: 1
                                }}
                            />
                        </Grid>
                    </Grid>
                </Card>

                <Grid container className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            navigate.to('/home/credit/procedures')
                            dispatch(snackbarActions.add('info', '¡Hemos recibido tu trámite!. Estamos trabajando para ofrecerte la mejor solución. Gracias por contactarnos.'))
                        }}
                    >
                        Iniciar trámite
                    </Button>
                </Grid>
            </Card>
        </StagePage>
    )
}