import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    loadingContainer: {
        height: 'calc(100% - 60px)',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 50px)',
        }
    },
    container: {
        height: 'calc(100vh - 50px)',
        [theme.breakpoints.between('sm', 'md')]: {
            height: 'calc(100vh - 350px)'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0px 16px'
        }
    },
    text: {
        fontWeight: 700,
        fontSize: 20,
        color: '#394A61',
        marginTop: 16,
        maxWidth: 480,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    loader: {
        width: 400,
        height: 400,
        maxWidth: '100%'
    }
})) 