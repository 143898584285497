import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    container: {
        padding: '45px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px 60px',
        }
    },
    button: {
        minWidth: 166,
        maxWidth: '100%',
        marginTop: 33
    }
}));