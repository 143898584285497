import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    inputAdornment: {
        width: "65px",
        height: "56px",
        margin: '0px 8px 0px -16px',
        background: "rgba(117, 139, 183, 0.08)",
        maxHeight: 'unset',
        maxWidth: 'unset',
        justifyContent: 'center',
        "& p": {
            fontSize: '17px',
            fontWeight: 'bold',
            color: 'black'
        }
    },
    buttonContainer: {
        marginTop: 8,
        display: 'flex !important',
        alignItems: 'center  !important',
        justifyContent: 'flex-end  !important'
    },
    button: {
        padding: '16px 88px'
    },

}))