import CryptoJS from 'crypto-js';

const key = import.meta.env.REACT_APP_PRIVATE_KEY!;

/**
 * Encrypts a string using AES encryption with a env variable key by default
 * @param value 
 * @param customKey Custom key to encrypt the string
 * @returns Encrypted string
 */
export const encrypt = (value?: string, customKey?: string) => {
    if (!value) return '';
    return CryptoJS.AES.encrypt(value, customKey || key).toString();
}

/**
 * Decrypts a string using AES encryption with a env variable key by default
 * @param value 
 * @param customKey Custom key to decrypt the string, if custom key is provided, must be the same used to encrypt the string
 * @returns 
 */
export const decrypt = (value?: string, customKey?: string) => {
    if (!value) return '';
    return CryptoJS.AES.decrypt(value, customKey || key).toString(CryptoJS.enc.Utf8);
}