import { Service } from './service';

class PublicCustomer extends Service {
    createCustomerAccount = async (organization_id: string, email: string) => {
        const response = await this.service.post(this.pathname, { organization_id, email });
        return this.formatResponse(response);
    }
}

export const CustomerService = new Service('customer');
export const PublicCustomerService = new PublicCustomer('customer', { isPublic: true });
export const PublicCustomersService = new PublicCustomer('customers', { isPublic: true });