import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    container: {
        padding: '45px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '32px 16px 60px',
        }
    },
    card: {

    },
    label: {
        fontWeight: 900,
        lineHeight: '24px',
        maxWidth: '100%',
        wordBreak: 'break-word',
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
        }
    },
    value: {
        fontWeight: 300,
        lineHeight: '24px',
        maxWidth: '100%',
        wordBreak: 'break-word',
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
        }
    },
    iconButton: {
        height: 24,
        width: 24,
    },
    amortizationTable: {
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    }
}));