import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    item: {
        background: "#FFFFFF",
        border: "1px solid #CFD5E2",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: '24px',
        position: 'relative',
        // marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
            maxWidth: '100%',
            padding: 16
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.palette.primary.dark,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            marginBottom: 16,
        }
    }
}));
