import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        padding: '8px 16px',
        [theme.breakpoints.between('sm', 'md')]: {
            padding: theme.spacing(1, 0),
        },
        [theme.breakpoints.down('sm')]: {
            padding: 8
        }
    },
    tableItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 48
    },
    tableItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 48,
        '& p': {
            fontWeight: 500,
            fontSize: 16
        },
        '& > :first-child': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    title: {
        fontWeight: 600,
        fontSize: 18,
        color: '#242C37', // '#394A61',
    },
    tableTotal: {
        width: '100%',
        textAlign: 'right',
        color: '#242C37', // '#394A61',
        fontWeight: 600,
        fontSize: 16,
        marginTop: 16
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '8px 0px'
    },
    amount: {
        display: 'flex',
        justifyContent: 'space-between',
        '& svg': {
            fill: theme.palette.primary.main
        }
    },
    dot: {
        height: 16,
        width: 16,
        minWidth: 16,
        borderRadius: '50%',
        marginRight: 8
    },
    itemContainer: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'space-between',
        '& > : firts-child': {
            display: 'flex',
            justifyContent: 'flex-start'
        }
    }
}));