import CoacreditadoImg from 'src/assets/img/coacreditado_circle.svg'
import DocumentsImg from 'src/assets/img/home_documents.svg'
import CreditProposalImg from 'src/assets/img/home_credit_proposal.svg'
import CancelApplicationImg from 'src/assets/img/home_cancel_application.svg'
import PerfilIcon from "src/assets/img/home/documents/perfil.svg"
import DocumentImage from "src/assets/img/home/documents/document.svg"
import HomeIcon from "src/assets/img/home/documents/home.svg"
import ComprarTuCasaImg from "src/assets/img/inicio-comprar-tu-casa.svg"
import ProfileAddressImg from 'src/assets/img/profile_address.svg';
import ProfileContactImg from 'src/assets/img/profile_contact.svg';
import ProfilePersonalImg from 'src/assets/img/profile_personal.svg';

import StartApplicationImg from 'src/assets/img/bancoppel/star_application.svg'
import BancoppelCoacreditadoImg from 'src/assets/img/bancoppel/coaccredited.svg'
import BancoppelDocumentsImg from 'src/assets/img/bancoppel/documents.svg'
import BancoppelCreditProposalImg from 'src/assets/img/bancoppel/credit_proposal.svg'
import BancoppelPersonalDOcumentsImg from 'src/assets/img/bancoppel/personal_documents.svg'
import BancoppelEmploymentDocumentsImg from 'src/assets/img/bancoppel/employment_documents.svg'
import BancoppelProperyDocumentsImg from 'src/assets/img/bancoppel/property_documents.svg'
import BancoppelProfileAddressImg from 'src/assets/img/bancoppel/address.svg'
import BancoppelProfileContactImg from 'src/assets/img/bancoppel/contact.svg'
import BancoppelProfilePersonalImg from 'src/assets/img/bancoppel/personal_data.svg';

export const images: {
    [key: string]: {
        startApplication: any,
        coaccredited: any,
        documents: any,
        creditProposal: any,
        cancelApplication?: any,
        personalDocuments: any,
        employmentDocuments: any
        propertyDocuments?: any,
        profile?: {
            personal: any,
            contact: any,
            address: any
        }
    }
} = {
    app: {
        startApplication: ComprarTuCasaImg,
        coaccredited: CoacreditadoImg,
        documents: DocumentsImg,
        creditProposal: CreditProposalImg,
        cancelApplication: CancelApplicationImg,
        personalDocuments: PerfilIcon,
        employmentDocuments: DocumentImage,
        propertyDocuments: HomeIcon,
        profile: {
            personal: ProfilePersonalImg,
            contact: ProfileContactImg,
            address: ProfileAddressImg
        }
    },
    bancoppel: {
        startApplication: StartApplicationImg,
        coaccredited: BancoppelCoacreditadoImg,
        documents: BancoppelDocumentsImg,
        creditProposal: BancoppelCreditProposalImg,
        personalDocuments: BancoppelPersonalDOcumentsImg,
        employmentDocuments: BancoppelEmploymentDocumentsImg,
        propertyDocuments: BancoppelProperyDocumentsImg,
        profile: {
            personal: BancoppelProfilePersonalImg,
            contact: BancoppelProfileContactImg,
            address: BancoppelProfileAddressImg
        }
    }
}