import { Box, Typography } from "@mui/material"
import { concepts } from "./helpers"
import { CreditProposalType } from "src/types"
import { useStyles } from "./styles"

interface ConceptListProps {
    proposal: CreditProposalType
}

export function ConceptList({ proposal }: ConceptListProps) {
    const { classes, cx } = useStyles()

    return (
        <Box className={classes.root}>
            {concepts.map((concept, index) => {
                const { key, title, render: conceptRender } = concept

                const render = conceptRender ||
                    ((proposal: CreditProposalType): string => `${proposal[key]}`)

                return (
                    <Box key={index} className={classes.concept}>
                        <Typography className={cx(classes.text, classes.title)}>
                            {title}
                        </Typography>

                        <Typography className={cx(classes.text, classes.value)}>
                            {render(proposal)}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    )
}