import { Grid, Typography, GridProps } from "@mui/material";
import { Fragment } from "react";
import { TitleCard, EmptyState } from "src/components"
import { CreditProposalType } from "src/types"
import { CreditProposalFieldType } from "../helpers"
import { useStyles } from "./styles"

interface CreditProposalSectionProps {
    title: string
    proposal?: CreditProposalType
    fields: CreditProposalFieldType[]
}

/**
 * Renders given fields in a title card component
 * @returns 
 */
export function CreditProposalSection({ title, proposal, fields }: CreditProposalSectionProps) {
    return (
        <TitleCard title={title}>
            {proposal?.id ? (
                <CreditProposalSectionFields
                    fields={fields}
                    entity={proposal}
                />
            ) : (
                <EmptyState
                    size="small"
                    text="No hay información"
                    style={{ marginTop: 32 }}
                />
            )}
        </TitleCard>
    )
}

interface CreditProposalSectionFieldsProps extends GridProps {
    fields: any[]
    entity: any
}

export function CreditProposalSectionFields({ fields, entity, ...rest }: CreditProposalSectionFieldsProps) {
    const { classes, cx } = useStyles()

    if (!entity?.id) return <Fragment />

    return (
        <Grid container spacing={2} {...rest}>
            {fields.map(({ label, key, width, render }) => (
                <Grid key={key} item xs={12} md={width}>
                    <Typography component="h6" className={classes.label}> {label} </Typography>
                    <Typography className={classes.value}>
                        {render ? render(entity[key], entity) : entity[key]}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    )
}