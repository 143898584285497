import { ThemeProvider, CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';
import { createCustomTheme } from './theme';

import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
import { makeMuiCache, makeTssCache } from './cache';

const muiCache = makeMuiCache();
const tssCache = makeTssCache();

export function Theme({ children }: { children: React.ReactNode | React.ReactNode[] }) {
    const organization = useSelector(state => state.organization);

    return (
        <CacheProvider value={muiCache}>
            <TssCacheProvider value={tssCache}>
                <ThemeProvider theme={createCustomTheme(organization)}>
                    <CssBaseline />
                    {children}
                </ThemeProvider>
            </TssCacheProvider>
        </CacheProvider>
    );
}