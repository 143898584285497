import { ReactNode } from "react";
import { Grid, Typography, GridProps, Divider, TypographyProps } from "@mui/material";
import { MarkdownText } from "@kredimx/form-builder";
import { Breadcrumb, Breadcrumbs } from "../Breadcrumbs";
import { useIsMobile } from "src/helpers/hooks/useIsMobile";
import { useStyles } from './StagePage.styles';
import "./StagePage.css"

export interface StagePageProps extends GridProps {
	title?: string;
	description?: string;
	breadcrumbs?: Breadcrumb[];
	children?: ReactNode | ReactNode[] | any;
	containerProps?: GridProps;
	titleContainerProps?: GridProps;
	breadcrumbsContainerProps?: GridProps;
	action?: ReactNode;
	showDivider?: boolean;
	rootContainerProps?: GridProps;
	titleProps?: TypographyProps;
	isApplication?: boolean;
}

/** Use to show a children component. Contains default components like title, breadcrumbs, divider, etc...  */
export function StagePage({
	title,
	description,
	children,
	containerProps,
	breadcrumbs,
	breadcrumbsContainerProps,
	titleContainerProps,
	action,
	className,
	showDivider,
	rootContainerProps,
	titleProps,
	isApplication,
	...rest
}: StagePageProps) {
	const { classes, cx } = useStyles();
	const isMobile = useIsMobile()
	const { className: rootContainerClassName, ...restRootContainerProps } = rootContainerProps || {};
	const { className: titleClassName } = titleProps || {};

	return (
		<Grid className={cx(classes.root, rootContainerClassName)} {...restRootContainerProps}>
			<Grid
				container
				className={cx(classes.container, {
					[classes.containerApplication]: isApplication
				}, className)}
				{...rest}
			>
				{breadcrumbs && (
					<Grid item xs={12} {...breadcrumbsContainerProps}>
						<Breadcrumbs items={breadcrumbs} />
					</Grid>
				)}

				<Grid
					container
					alignItems="center"
					justifyContent={(isMobile && !action) ? 'flex-start' : "space-between"}
					{...titleContainerProps}
				>
					<div style={{ maxWidth: '100%' }}>
						{title && (
							<Typography
								className={cx(classes.name, {
									[classes.nameApplication]: isApplication
								}, titleClassName)}
							>
								{title}
							</Typography>
						)}

						{description && (
							isApplication ? (
								<MarkdownText
									className={classes.description}
									value={description}
								/>
							) : (
								<Typography
									align={isMobile ? "center" : "inherit"}
									className={classes.description}
									dangerouslySetInnerHTML={{ __html: description || '' }}
								/>
							)
						)}

					</div>
					{action ? action : <div />}
				</Grid>

				{showDivider && <Divider className={classes.divider} />}

				<Grid item md={12} className={classes.content} {...containerProps}>
					{children}
				</Grid>
			</Grid>
		</Grid>
	)
}