import { RefreshTokenStateType } from "./reducer";
import { REFRESH_TOKEN_TYPES } from "./types";

export type SetAuthState = keyof (RefreshTokenStateType);

function set(name: SetAuthState, value: any) {
    return {
        type: REFRESH_TOKEN_TYPES.SET,
        payload: { name, value }
    }
}

function addFailedAction(request: any) {
    return {
        type: REFRESH_TOKEN_TYPES.ADD_FAILED_QUEUE,
        payload: { request }
    }
}

function clearFailedActions() {
    return {
        type: REFRESH_TOKEN_TYPES.CLEAR_FAILED_QUEUE
    }
}

export const refreshTokenActions = {
    set,
    addFailedAction,
    clearFailedActions
}