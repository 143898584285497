import { Grid, Typography, GridProps, TypographyProps } from "@mui/material";
import BackgroundTopImg from "src/assets/img/background_top.svg"
import BackgroundBottomImg from "src/assets/img/background_bottom.svg"
import { useStyles } from "./MessagePage.styles";
import { useIsMobile } from "src/helpers";

export interface MessagePageProps extends GridProps {
    containerProps?: GridProps;
    /** If is true show two images of spirals */
    showBackgroundImages?: boolean;
    icon?: any;
    title?: string;
    message?: string;
    messageContainerProps?: TypographyProps;
    children?: React.ReactChild | React.ReactChild[] | any;
}

/** Show a message inside the parent component */
export function MessagePage({
    containerProps,
    showBackgroundImages,
    icon: Icon,
    title,
    message,
    children,
    messageContainerProps,
    className,
    ...rest
}: MessagePageProps) {
    const { classes, cx } = useStyles();
    const { className: containerClassName, ...restContainerProps } = containerProps || {};
    const isMobile = useIsMobile()

    return (
        <Grid
            container
            className={cx(classes.container, className)}
            {...rest}
        >
            {showBackgroundImages && !isMobile && (
                <>
                    <BackgroundTopImg /*className={classes.backgroundTop}*/ />
                    <BackgroundBottomImg /*className={classes.backgroundBottom}*/ />
                </>
            )}
            <Grid className={cx(classes.data, containerClassName)} {...restContainerProps}>
                {Icon && <Icon />}
                {title && <Typography variant="h3" align="center"> {title} </Typography>}
                {message && <Typography dangerouslySetInnerHTML={{ __html: message }} {...messageContainerProps} />}
                {children}
            </Grid>
        </Grid>
    )
}