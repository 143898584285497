import { Fragment } from 'react';
import { Snackbar as MuiSnackbar, SnackbarProps as MuiSnackbarProps, Typography, Slide } from "@mui/material";
import Alert from "@mui/material/Alert";
import { TransitionProps } from '@mui/material/transitions';
import { WarningIcon } from "src/assets/icons";
import { ErrorOutline as ErrorIcon, Check as CheckIcon } from "@mui/icons-material";
import { useStyles } from "./Snackbar.styles";
import { useDispatch, useSelector } from 'react-redux';
import { snackbarActions } from 'src/store/snackbar/actions';

export type SnackbarVariant = 'info' | 'success' | 'error' | 'warning';

function SlideTransition(props: TransitionProps) {
	// @ts-ignore
	return <Slide {...props} direction="right" />;
}

/** Use to show temporary messages in window */
export function Snackbar() {
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const { open, message, variant, duration } = useSelector(state => state.snackbar);

	function getIcon(variant: SnackbarVariant) {
		switch (variant) {
			case 'success': return <CheckIcon />;
			case 'error': return <ErrorIcon />;
			case 'warning': return <WarningIcon />;
			default: return <Fragment />;
		}
	}

	return (
		<MuiSnackbar
			open={open}
			autoHideDuration={duration || 10000}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			onClose={() => dispatch(snackbarActions.clean())}
			TransitionComponent={SlideTransition}
			ClickAwayListenerProps={{
				mouseEvent: false
			}}
		>
			<Alert
				icon={<Fragment />}
				className={cx(classes.alert, {
					[classes.success]: variant === 'success',
					[classes.warning]: variant === 'warning',
					[classes.error]: variant === 'error',
					[classes.info]: variant === 'info'
				})}
				action={getIcon(variant)}
			>
				<Typography
					className={classes.typography}
					dangerouslySetInnerHTML={{ __html: message || '' }}
				/>
			</Alert>
		</MuiSnackbar>
	)
}