import { CUSTOM_ACTIONS } from "./types"

function customerHasApplicationInProgress() {
    return {
        type: CUSTOM_ACTIONS["CUSTOMER.ALREADY_EXISTS_APPLICATION_WORKFLOW"]
    }
}

export const customActions = {
    customerHasApplicationInProgress
}