import {
	AssignmentIndOutlined as ProfileIcon,
	PermPhoneMsgOutlined as PhoneIcon,
	PlaceOutlined as MarkerIcon,
	WorkOutlineOutlined as WorkIcon,
	CreditCardOutlined as CreditCardIcon
} from "@mui/icons-material";

import { PersonalData } from "./PersonalData";
import { ContactData } from "./ContactData";
import { Addresses } from "./Addresses";
import { AddressForm } from "./Addresses/Form";
import { Employments } from "./Employments";
import { EmploymentForm } from "./Employments/Form";
import { CreditReports } from "./CreditReports";
import { ProfileMain } from "./Main";

export const sidebarItems = [
	{
		to: 'profile',
		isMenuItem: false,
		component: ProfileMain
	},
	{
		name: 'Datos personales',
		icon: ProfileIcon,
		to: 'personal-data',
		isMenuItem: true,
		component: PersonalData
	},
	{
		name: 'Datos de contacto',
		icon: PhoneIcon,
		to: 'contact-data',
		isMenuItem: true,
		component: ContactData
	},
	{
		name: 'Direcciones',
		icon: MarkerIcon,
		to: 'addresses',
		isMenuItem: true,
		component: Addresses
	},
	{
		name: 'Direcciones',
		to: 'addresses/:address_id',
		isMenuItem: false,
		component: AddressForm
	},
	{
		name: 'Direcciones',
		to: 'addresses/new',
		isMenuItem: false,
		component: AddressForm
	},
	{
		name: 'Empleos',
		icon: WorkIcon,
		to: 'employments',
		isMenuItem: true,
		component: Employments
	},
	{
		name: 'Empleos',
		to: 'employments/:employment_id',
		isMenuItem: false,
		component: EmploymentForm
	},
	{
		name: 'Empleos',
		to: 'employments/new',
		isMenuItem: false,
		component: EmploymentForm
	},
	// {
	//     name: 'Historial crediticio',
	//     icon: CreditCardIcon,
	//     to: 'history',
	//     isMenuItem: true,
	//     component: CreditReports
	// }
]