import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./styles"
import RejectedImg from "src/assets/img/rejected_reason.svg"
import { RejectedReasons } from "./Reasons";
import { Link, Video } from "src/components";
import { useParams } from "react-router-dom";
import { useIsKredi } from "src/helpers";

export function RejectedPage() {
    const { applicationId }: any = useParams()
    const { classes, cx } = useStyles();
    const isKredi = useIsKredi()

    return (
        <>
            <Grid container item className={classes.hero}>
                <Grid item xs={12} sm={12} md={isKredi ? 8 : 12}>
                    <Typography variant="h2"> Lo sentimos, por el momento no es posible continuar con tu solicitud </Typography>
                    <Typography> ¿Por qué mi solicitud de crédito fue rechazada?  </Typography>
                </Grid>
                {isKredi && (
                    <Grid item xs={12} sm={12} md={4} className={classes.heroImage}>
                        <RejectedImg />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} className={classes.center}>
                <RejectedReasons />
            </Grid>
            {/* <Grid item xs={12} className={cx(classes.center, classes.messageContainer)}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h3"> Conoce más sobre tu proceso </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.videoContainer}>
                        <Video
                            src="https://www.youtube.com/embed/huRPDiPTKPk"
                            isIframe
                            width={isMobile ? '100%' : 595}
                            height={isMobile ? 216 : 375}
                        />
                    </Grid>
                    <Grid item xs={12} md={7} className={classes.errorMessageContainer}>
                        <Typography variant="h4"> ¿Crees que hemos cometido un error? </Typography>
                        <Typography> Por favor, ponte en contacto con nosotros y revisaremos tu caso, estamos para ayudarte. </Typography>
                        <Link to={`/home/consultant`}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{ minWidth: 200 }}
                            >
                                Contactar
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid> */}
            {/* <Grid item xs={12} className={cx(classes.center, classes.messageContainer)}>
                <Typography variant="h3"> ¿Crees que hemos cometido un error? </Typography>
                <Typography> Si es así, ponte en contacto con nosotros para revisar tu caso. </Typography>
                <Link to={`${match.url}/consultant`}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                    >
                        Contactar
                    </Button>
                </Link>
            </Grid> */}
        </>
    )
}