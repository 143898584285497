import { NavigateOptions } from "react-router-dom"
import { NAVIGATION_TYPES } from "./types"

function to(to: string | number, options?: NavigateOptions) {
    return {
        type: NAVIGATION_TYPES.TO,
        payload: { to, options }
    }
}

function clear() {
    return {
        type: NAVIGATION_TYPES.CLEAR
    }
}

export const navigationActions = {
    to,
    clear
}