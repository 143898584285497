import { APPLICATION_PAYMENTS_TYPES } from "./types";

function getProducts(productType: string) {
    return {
        type: APPLICATION_PAYMENTS_TYPES.GET_PRODUCTS,
        payload: { productType }
    }
}

function setProducts(products: any) {
    return {
        type: APPLICATION_PAYMENTS_TYPES.SET_PRODUCTS,
        payload: { products }
    }
}

function createPayment({ applicationId, airtable_id }: any) {
    return {
        type: APPLICATION_PAYMENTS_TYPES.CREATE,
        payload: { applicationId, airtable_id }
    }
}

export const applicationPaymentsActions = {
    getProducts,
    setProducts,
    createPayment
}