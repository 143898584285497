import { CloudDownloadOutlined as CloudDownloadIcon } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { TableColumnType } from "src/components";

export const getColumns = (onClickDownload: (value: any, row: any) => void, isMobile: boolean): TableColumnType[] => [
    {
        label: 'Nombre',
        key: 'name',
        hiddenSm: true
    },
    {
        label: 'Fecha',
        key: 'date',
        render: isMobile ? function Component(value, row) {
            return (
                <Box display='flex' alignItems='center'>
                    <Typography style={{ color: 'grey' }}> {value} </Typography>
                    <IconButton
                        style={{
                            padding: 0,
                            marginLeft: 'auto',
                        }}
                        onClick={() => onClickDownload(value, row)}
                    >
                        <CloudDownloadIcon />
                    </IconButton>
                </Box>
            );
        } : undefined,
    },
    {
        label: 'Descargar',
        key: 'download',
        hiddenSm: true,
        tableCellProps: {
            style: {
                textAlign: 'center',
                width: 48
            }
        },
        render: function Component(value, row) {
            return (
                <IconButton style={{ padding: 0, marginLeft: 'auto' }} onClick={() => onClickDownload(value, row)}>
                    <CloudDownloadIcon />
                </IconButton>
            )
        }
    }
];