import { CloudDownloadOutlined as CloudDownloadIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TableColumnType } from "src/components";

export const columns: TableColumnType[] = [
    {
        label: 'Última actualización',
        key: 'last_updated',
    },
    {
        label: 'Descargar',
        key: 'download',
        tableCellProps: {
            style: {
                textAlign: 'center',
                width: 48
            }
        },
        render: function Component() {
            return (
                <IconButton style={{ padding: 0 }}>
                    <CloudDownloadIcon />
                </IconButton>
            )
        }
    }
];