import { UploadInput as KrediUploadInput } from "@kredimx/form-builder";
import { UploadComponentProps } from "..";
import { questionActions } from "src/store/questions/actions";

export function UploadInput({
    onUpload,
    approval,
    actionable,
    application,
    customer,
    dispatch,
    applicationService
}: UploadComponentProps) {
    return (
        <KrediUploadInput
            id={approval?.field?.id}
            actionableId={actionable?.id}
            actionableType={actionable?.type}
            label={approval?.field?.label}
            config={{
                ...approval?.field?.config,
                comments: approval?.comments,
                rejectedReasons: approval?.verification_reason?.rejected,
                requestReasons: approval?.notes?.map((note) => note?.note_text || '')
            }}
            user={customer}
            application={application}
            userType='Customer'
            visibility='customer'
            useFileName
            enableLocal
            // @ts-ignore
            required
            onChangeCurrentAttachment={onUpload}
            uploadAttachment={async (attachment: FormData, application: any, id: string) => {
                // onUpload(attachment, application, id)
            }}
            getAttachment={async (application: any, id: string) => {
                return await applicationService.getPath(`${application.id}/answers/${id}`)
            }}
            downloadAttachment={(attachment: any) => {
                dispatch(questionActions.downloadFile(attachment.file_name, attachment.file_url))
            }}
            resetAnswer={async (application: any, answer: any) => {
                return await applicationService.destroyPath(`${application.id}/answers/${answer.id}/purge`, '');
            }}
        />
    )
}