import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    content: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '100%',
        '& > div': {
            textAlign: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 16
        }
    },
    logoContainer: {
        padding: 40,
        position: 'absolute',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            padding: 32,
        }
    },
    logo: {
        [theme.breakpoints.down('sm')]: {
            width: 91
        }
    },
    imageContainer: {
        marginBottom: 18,
        '& > svg': {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
        }
    },
    title: {
        fontSize: 48,
        fontWeight: 'bold',
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            lineHeight: '24px',
            marginBottom: 11,
        }
    },
    subtitle: {
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        }
    },
}));