import { AmortizacionTableType } from "src/types"
import { AMORTIZATION_TABLES_TYPES } from "./types"

function getCurrent(creditId: string) {
    return {
        type: AMORTIZATION_TABLES_TYPES.GET_CURRENT,
        payload: { creditId }
    }
}

function getOne(id: string) {
    return {
        type: AMORTIZATION_TABLES_TYPES.GET_ONE,
        payload: { id }
    }
}

function setOne(amortizationTable?: AmortizacionTableType) {
    return {
        type: AMORTIZATION_TABLES_TYPES.SET_ONE,
        payload: { amortizationTable }
    }
}

export const amortizationTablesActions = {
    getCurrent,
    getOne,
    setOne
}