import { useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import { Check as CheckIcon } from "@mui/icons-material"
import { Link } from "src/components"
import { CreditProposalType } from "src/types"
import { WarningIcon } from "src/assets/icons"
import { CreditProposalStatusType, StatusConfirmation } from "../../components"
import { getHelperText } from "./helpers"
import { useStyles } from "./styles"

import { useSelector } from "react-redux"

interface ActionsFooterProps {
    applicationId: string
    proposal?: CreditProposalType
}

export function ActionsFooter({ applicationId, proposal }: ActionsFooterProps) {
    const { classes, cx } = useStyles()

    const proposals = useSelector(state => state.creditProposals?.data || [])

    const [confirmationModal, setConfirmationModal] = useState<{
        open: boolean
        status?: CreditProposalStatusType
    }>({ open: false })

    const proposalAccepted = proposals.find(p => p.status === 'accepted')
    const isCurrentProposalAccepted = proposal?.status === 'accepted'
    const existsProposalAccepted = Boolean(proposalAccepted?.id)
    const helperText = getHelperText(existsProposalAccepted, proposal)

    const disableAction = [
        'accepted',
        'rejected',
        'expired'
    ].includes(proposal?.status || '') || existsProposalAccepted

    return (
        <>
            <StatusConfirmation
                open={confirmationModal.open}
                newStatus={confirmationModal.status}
                applicationId={applicationId}
                proposalId={proposal?.id || ''}
                handleConfirm={() => setConfirmationModal({ open: false })}
                handleCancel={() => setConfirmationModal({ open: false })}
                from="details"
            />

            <Box className={classes.root}>
                {helperText && (
                    <Link
                        to={`/home/main/${applicationId}/proposals/${proposalAccepted?.id}`}
                        underline="none"
                        disabled={isCurrentProposalAccepted || !existsProposalAccepted}
                    >
                        <Typography
                            className={cx(
                                classes.helperText,
                                { [classes.helperSuccess]: isCurrentProposalAccepted },
                            )}
                        >
                            {isCurrentProposalAccepted ? <CheckIcon /> : <WarningIcon />}
                            {helperText}
                        </Typography>
                    </Link>
                )}

                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={disableAction}
                    onClick={() => setConfirmationModal({
                        open: true,
                        status: 'rejected'
                    })}
                >
                    Rechazar propuesta
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={disableAction}
                    onClick={() => setConfirmationModal({
                        open: true,
                        status: 'accepted'
                    })}
                >
                    Aceptar propuesta
                </Button>
            </Box>
        </>
    )
}