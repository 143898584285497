import { ParticipantType } from "src/types"
import { PARTICIPANTS_TYPES } from "./types"

function getAll(creditId: string) {
    return {
        type: PARTICIPANTS_TYPES.GET_ALL,
        payload: { creditId }
    }
}

function setAll(participants?: ParticipantType[]) {
    return {
        type: PARTICIPANTS_TYPES.SET_ALL,
        payload: { participants }
    }
}

function getOne(id: string) {
    return {
        type: PARTICIPANTS_TYPES.GET_ONE,
        payload: { id }
    }
}

function setOne(accountStatement?: ParticipantType) {
    return {
        type: PARTICIPANTS_TYPES.SET_ONE,
        payload: { accountStatement }
    }
}

export const participantsActions = {
    getAll,
    setAll,
    getOne,
    setOne
}