import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	hero: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		padding: '0px 10px 50px',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		}
	},
	heroTexts: {
		'& > h3': {
			color: theme.palette.primary.main,
			fontSize: 60,
			fontWeight: 'bold',
			maxWidth: 743,
			marginBottom: 16,
			[theme.breakpoints.down('sm')]: {
				fontSize: 40,
				textAlign: 'center'
			}
		},
		'& > p': {
			maxWidth: 743,
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center'
			}
		},
	},
	bancoppelHeroTexts: {
		'& > h3': {
			color: theme.palette.primary.main,
			fontSize: 60,
			fontWeight: 'bold',
			marginBottom: 16,
			[theme.breakpoints.down('sm')]: {
				fontSize: 40,
				textAlign: 'center'
			}
		},
		'& > p': {
			maxWidth: 743,
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center'
			}
		},
	},
	center: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	messageContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		'& > h3': {
			fontSize: 24,
			fontWeight: 'bold',
			marginBottom: 8
		},
		'& > p': {
			fontSize: 20,
			marginBottom: 32
		}
	},
	image: {
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 20,
			marginBottom: 14
		}
	}
}));