import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    breadcrumbs: {
        padding: '60px 16px 0',
        zIndex: 1,
        maxWidth: 1440
    },
    container: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(150vh - 440px)'
        },
        ['@media (max-width : 425px)']: {
            height: 'calc(100vh - 138px)'
        },
        ['@media (min-height : 616px) and (max-height : 720px)']: {
            height: 'calc(100vh - 150px)'
        },
        ['@media (min-height : 720px)']: {
            height: 'calc(130vh - 440px)'
        }

        //
    }
}));
