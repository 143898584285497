import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { Breadcrumbs, Link, Loading } from "src/components";
import { isValidId, useIsMobile, useRouteMatch } from "src/helpers";
import { ConsultantCard } from "src/pages/Application/Status/Response/components";

import { useDispatch, useSelector } from "react-redux";
import { applicationActions, applicationRejectedDocuments } from "src/store/application/actions";

import { Status } from "./Status";
import { MainSmallCard } from "../../Main/components";
import { CardItem, smallCardItems } from "./smallCards";
import { useStyles } from "./styles";

export function ApplicationSummaryPage() {
	const { applicationId }: any = useParams()
	const { classes, cx } = useStyles()
	const dispatch = useDispatch()
	const match = useRouteMatch()
	const isMobile = useIsMobile()

	const application = useSelector((state) => state.applications.application)
	const hasRejectedDocuments = useSelector(state => state.applications.rejectedDocuments.hasAny)
	const isLoading = useSelector((state) => state.applications.isLoading)
	const user = useSelector((state) => state.customer.customer.user)
	const cardItems = smallCardItems(application, hasRejectedDocuments)

	useEffect(() => {
		if (application.id !== applicationId || _.isEmpty(application))
			dispatch(applicationActions.getApplication(applicationId))
		if (isValidId(applicationId))
			dispatch(applicationRejectedDocuments.hasAny(applicationId))
	}, [applicationId])

	return (
		<Grid container className={classes.root} direction="column">
			<Loading isLoading={isLoading}>

				<Grid item>
					<Breadcrumbs items={[
						{
							name: "Inicio",
							to: (match.path.split('/').includes('summary') ? "/home/applications" : "/home")
						},
						{
							name: "Detalles de tu solicitud",
							to: ""
						},
					]} />
					<Typography className={classes.title} variant="h3"> Detalles de tu solicitud </Typography>
				</Grid>

				<Grid item className={classes.resultContainer}>
					<Status
						workflowTitle={application.workflow_title!}
						scoringStatus=""
						application={application}
						status={application.status!}
						applicationWorkflowStages={application.applicationWorkflowStages!}
						currentStage={application.current_stage!}
						hasRejectedDocuments={hasRejectedDocuments}
					/>
				</Grid>
				<Grid item className={classes.resultContainer}>
					{
						(!(["rejected"].includes(application?.status || '')) && user) && (
							<ConsultantCard
								title={application.status === "rejected" && !isMobile ? "¿Crees que hemos cometido un error? Contacta a tu asesor" : "Asesoría"}
								applicationOrigin='current'
							/>
						)
					}
				</Grid>

				<Grid container spacing={!isMobile ? 4 : 2} >
					{cardItems.map((item: CardItem, index: number) => (
						<MainSmallCard
							key={index}
							Icon={item.image}
							title={item.title}
							description={item.description}
							badgeProps={item.badgeProps}
							chipProps={item.chipProps}
							actionElement={(
								<Link
									to={item.linkTo}
									native={item.linkTo.includes('http')}
									data-cy={`card-option-${item.title}`}
								>
									<Button
										variant="text"
										color="primary"
										className={classes.button}

									>
										{item.linkTitle}
									</Button>
								</Link>
							)}
							rootGridProps={{
								item: true,
								container: true,
								xs: 6,
								lg: 3,
								md: 3
							}}
						/>
					))}
				</Grid>

			</Loading>
		</Grid>
	)
}