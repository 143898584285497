import { call, put, takeLatest } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { ApplicationService, Service } from "src/services";
import { Action, Response, SectionSummary } from "src/types";
import { stageActions } from "../stages/actions";
import { STAGE_TYPES } from "./types";

const StageService = new Service('stages');

function* getStage({ payload }: Action) {
    const response: Response = yield call(StageService.findOne, payload.id);
    yield put(stageActions.setStage(response.data));
}

function* getStageSections({ payload }: Action) {
    const response: Response = yield call(StageService.findOnePath, payload.stageId, 'sections');
}

function* getStageSummary({ payload }: Action) {
    const { applicationId, stageId } = payload
    const response: SectionSummary[] = yield call(ApplicationService.findOnePath, applicationId, `stage_resume?stage_id=${stageId}`, false)
    yield put(stageActions.setStageSummary(response))
}

export function* stageSagas() {
    yield takeLatest(STAGE_TYPES.GET_STAGE, sagaWrapper(getStage));
    yield takeLatest(STAGE_TYPES.GET_STAGE_SECTIONS, sagaWrapper(getStageSections));
    yield takeLatest(STAGE_TYPES.GET_SUMMARY, sagaWrapper(getStageSummary, stageActions.setStageSummary([])))
}