import { useEffect } from 'react';
import { StagePage, EmptyState, Link, Loading } from 'src/components';
import { Button, Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from 'src/store';
import { useStyles } from "./styles";
import { customerActions } from 'src/store/customer/actions';
import { segment } from 'src/helpers';
import { Add } from '@mui/icons-material';

export function Addresses() {
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const { addresses, isLoading } = useSelector((state: RootState) => state.customer);
	const getAddresses = () => dispatch(customerActions.getAddresses());

	useEffect(() => {
		getAddresses();
		segment.track('Customer', 'Viewed', { view: 'Addresses' })
	}, [])

	return (
		<StagePage
			title="Direcciones"
			breadcrumbs={[
				{
					name: "Perfil",
					to: "/home/profile"
				},
				{
					name: "Direcciones",
					to: ""
				},
			]}
			className={classes.container}
			titleContainerProps={{
				justifyContent: 'space-between'
			}}
		>
			<Loading isLoading={isLoading}>
				{addresses?.length === 0 ? (
					<EmptyState text="No tienes direcciones registradas" className={classes.emptyState} />
				) : (
					<>
						<Grid container className={classes.addresses}>
							{addresses?.map(address => (
								<>
									<Grid key={address.id} item xs={12} className={classes.item}>
										<Grid container>
											<Typography className={classes.addressTitle}>{address.street} #{address.street_number || address.interior_number}</Typography>
										</Grid>
										<Grid
											container
											className={classes.addressDetial}
											justifyContent="space-between" alignItems="center"
										>
											<Typography> {`Colonia ${address.neighborhood}. ${address.municipality}, ${address.state}.  ${address.zipcode ? `C.P ${address.zipcode}` : ''}`} </Typography>
											<Link to={`/home/profile/addresses/${address.id}`}>
												<Button color="primary">
													Editar
												</Button>
											</Link>
										</Grid>
									</Grid>
								</>
							))}
						</Grid>
						<Grid container>
							<Link to="/home/profile/addresses/new">
								<Button
									variant="contained"
									color="primary"
									startIcon={<Add />}
								>
									Agregar
								</Button>
							</Link>
						</Grid>
					</>
				)}

			</Loading>

		</StagePage>
	)
}