
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Grid, GridProps, Typography } from "@mui/material";
import { EmailOutlined as EmailIcon, PhoneIphoneOutlined as PhoneIcon } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { Link } from "src/components";
import { MainCard } from "../Card";
import { getWhatsAppLinkWithMessage, initialsFor } from "src/helpers";
import { applicationActions } from "src/store/application/actions";
import { useStyles } from "./styles";

interface MainConsultantCardProps extends GridProps {
    text?: string;
    /** if is lastOne get latest application */
    applicationOrigin?: 'lastOne' | 'current',
    /** if is true get request lastest application */
    requestLastOneApplication?: boolean;
}

export function MainConsultantCard({ text, applicationOrigin = 'lastOne', requestLastOneApplication, ...rest }: MainConsultantCardProps) {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.customer.user);
    const application = useSelector(state => state.applications.application);
    const lastOneApplication = useSelector(state => state.applications.lastApplication);
    const isLoading = useSelector(state => state.customer.isLoading);

    useEffect(() => {
        if (applicationOrigin === 'lastOne' && requestLastOneApplication) {
            dispatch(applicationActions.getLastOneApplication());
        }
    }, []);

    return (
        <MainCard title={text || "¿Tienes dudas? Agenda tu asesoría."} {...rest}>
            {isLoading ? (
                <Grid container item xs={12} className={classes.imageAndText}>
                    <Skeleton variant="circular" width={90} height={90} className={classes.skeleton} />
                    <div className={classes.dataContainer}>
                        <div className={classes.data}>
                            <Skeleton variant="text" width={500} className={classes.skeleton} />
                            <Skeleton variant="text" width={500} className={classes.skeleton} />
                        </div>
                    </div>
                </Grid>
            ) : (
                <>
                    <Grid container item xs={12} className={classes.imageAndText}>
                        <Avatar
                            color="primary"
                            className={classes.avatar}
                            src={user?.profile_image}
                        >
                            {initialsFor(`${user?.first_name} ${user?.last_name}`)}
                        </Avatar>
                        <div className={classes.dataContainer}>
                            <div className={classes.data}>
                                <Typography variant="h3"> {user?.first_name || ''} {user?.last_name || ''} </Typography>
                                <Typography> — ¡Hola! Soy {`${user?.first_name} ${user?.last_name}`} y te acompañaré durante todo tu proceso. </Typography>
                            </div>
                            <div className={classes.contactData}>
                                {user?.email && <Typography> <EmailIcon /> {user?.email} </Typography>}
                                {user?.phone && <Typography> <PhoneIcon /> {user?.phone} </Typography>}
                            </div>
                        </div>
                    </Grid>
                </>
            )}
            {!isLoading && (
                <Grid item xs={12} className={classes.itemActions}>
                    {user?.calendly_user && (
                        <Link to={`/home/consultant/schedule`}>
                            <Button
                                color="primary"
                                variant="text"
                                size="small"
                            >
                                Agendar asesoría
                            </Button>
                        </Link>
                    )}
                    {user?.phone && (
                        <a
                            href={getWhatsAppLinkWithMessage({
                                application: applicationOrigin === 'lastOne' ? lastOneApplication : application,
                                user,
                                origin: applicationOrigin
                            })}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button
                                color="inherit"
                                variant="text"
                                size="small"
                            >
                                Enviar WhatsApp
                            </Button>
                        </a>
                    )}
                </Grid>
            )}
        </MainCard>
    )
}