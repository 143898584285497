import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {},
    smallCardsContainer: {
    },
    link: {
        textDecoration: 'none'
    },
    button: {

    },
}))