import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    caption: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    }
}));