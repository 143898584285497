import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    card: {
        background: '#fff',
        border: '1px solid #CFD5E2',
        borderRadius: 8,
        padding: 24,
        textAlign: 'left',
        '& > :last-child': {
            borderBottom: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 16,
            // justifyContent: 'flex-start',
        },
    },
    title: {
        fontSize: 34,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 23.5,
            lineHeight: '24px'
        },
    },
    reason: {
        padding: '24px 0',
        borderBottom: '1px solid #CFD5E2',
    },
    reasonTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 10,
        paddingBottom: 8,
        '& > p': {
            fontSize: 20,
            fontWeight: 'bold'
        },
        '& svg': {
            minWidth: 24
        }
    },
    reasonDescription: {
        fontWeight: 400,
        '& > a': {
            color: '#4AAAFF',
            textDecoration: 'none'
        }
    }
}));