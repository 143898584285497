import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        height: 'calc(100vh - 70px)',
        padding: '32px 16px'
    },
    card: {
        border: '1px solid #CFD5E2',
        boxShadow: '0px 5px 22px rgba(57, 74, 97, 0.12)',
        borderRadius: 8,
        padding: 24,
        width: 802,
        minHeight: 396,
        maxWidth: '100%'
    },
    imgContainer: {
        padding: '8px 14px 8px 0px',
        borderRight: '1px solid #CFD5E2',
        textAlign: 'center',
        '& > h3': {
            fontSize: 24,
            fontWeight: 'bold',
            width: '100%'
        },
        '& > p': {
            fontSize: 14,
            fontWeight: 400,
        },
        '& > svg': {
            margin: '24px 12px'
        },
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            marginBottom: 24
        },
    },
    radioContainer: {
        padding: '8px 8px 8px 24px'
    },
    radio: {
        marginBottom: 8,
        '& .MuiFormControlLabel-label': {
            fontSize: 14,
            fontWeight: 400,
        }
    },
    button: {
        marginTop: 24
    }
}))