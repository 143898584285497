import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    button: {
        padding: '10px 16px',
    },
    text: {
        fontSize: 12,
        lineHeight: '16px',
        marginTop: 0,
        '& > span': {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            fontWeight: 500,
            cursor: 'pointer',
            marginLeft: 4,
            textUnderlinePosition: 'under',
            '&:hover': {
                color: theme.palette.primary.dark
            }
        }
    },
    subContainer: {
        [theme.breakpoints.down('sm')]: {
            position: 'unset',
            padding: '0px 16px'
        }
    }
}));