import { call, put, takeLatest } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { snackbarActions } from "src/store/snackbar/actions";
import { Action, Response } from "src/types";
import { creditAccountStatementsActions } from "./actions";
import { CREDIT_ACCOUNT_STATEMENTS_TYPES } from "./types";

const creditsService = new Service('credits', { api: 'kronos', namespace: 'customer' })
const accountStatementsService = new Service('account_statements', { api: 'kronos', namespace: 'customer' })

function* getAll({ payload }: Action) {
    const { creditId, options } = payload
    const response: Response = yield call(creditsService.allPath, `${creditId}/account_statements`, {})
    yield put(creditAccountStatementsActions.setAll(response?.data, response?.meta))
}

function* getOne({ payload }: Action) {
    const { id } = payload
    const response: Response = yield call(accountStatementsService.findOne, id)
    yield put(creditAccountStatementsActions.setOne(response?.data))
}

function* getLastOneAccountStatement({ payload }: Action) {
    const { id } = payload
    const response: Response = yield call(accountStatementsService.findOne, id)
    yield put(creditAccountStatementsActions.setOne(response?.data))
}

function* downloadByUrl({ payload }: Action) {
    const response: { blob: any } = yield call(fetch, payload.fileUrl)
    const blob: Blob = yield response.blob()
    const url = URL.createObjectURL(blob)

    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = payload.fileName
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)

    URL.revokeObjectURL(url)

    yield put(creditAccountStatementsActions.set('isDownloading', false))
    yield put(snackbarActions.add('success', 'Se ha descargado el estado de cuenta'))
}

export function* accountStatementsSagas() {
    yield takeLatest(CREDIT_ACCOUNT_STATEMENTS_TYPES.GET_ALL, sagaWrapper(getAll, creditAccountStatementsActions.setAll()))
    yield takeLatest(CREDIT_ACCOUNT_STATEMENTS_TYPES.GET_ONE, sagaWrapper(getOne, creditAccountStatementsActions.setOne()))
    yield takeLatest(CREDIT_ACCOUNT_STATEMENTS_TYPES.DOWNLOAD_URL, sagaWrapper(downloadByUrl, creditAccountStatementsActions.set('isDownloading', false)))
}