
import { useState, ChangeEvent } from "react";
import { SelectInput } from "@kredimx/form-builder";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Card, StagePage } from "src/components";
import { useStyles } from "./styles";

interface InteresConstancyPageProps {
}

export function InteresConstancyPage({ }: InteresConstancyPageProps) {
    const { classes, cx } = useStyles();
    const [value, setValue] = useState(new Date().getFullYear());

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        setValue(parseInt(event.target.value));
    }

    return (
        <StagePage
            title='Constancia de interés'
            breadcrumbs={[
                {
                    name: 'Crédito',
                    to: '/home/credit'
                },
                {
                    name: 'Constancia de interés',
                },
            ]}
        >
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Card
                        disableDivider
                        hideHeader
                    >
                        <SelectInput
                            label="Selecciona el año"
                            onChange={handleChange}
                        />
                        <Box textAlign='right'>
                            <Button
                                className={classes.button}
                                color="primary"
                                variant="contained"
                            >
                                Descargar
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </StagePage>
    );
}