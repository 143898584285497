import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '28px',
        marginTop: 24,
        marginBottom: 8,
    },
    stepperWrapper: {
        overflow: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    stepper: {
        '& .MuiStepLabel-root': {
            // flexDirection: 'column',
            // '& .MuiStepLabel-iconContainer': {
            //     marginBottom: 4,
            // },
            '& .MuiStepLabel-label': {
                // whiteSpace: 'nowrap',
                textDecoration: 'none',
                marginTop: 8,
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                // display: '-webkit-box',
                // '-webkit-line-clamp': 2,
                // '-webkit-box-orient': 'vertical',
                // whiteSpace: 'normal',
            },
        },
        '& .MuiStepConnector-lineHorizontal': {
            borderColor: '#CFD5E2 !important'
        },
        '& .currentStep .MuiStepLabel-labelContainer': {
            backgroundColor: 'transparent',
            borderRadius: 0,
            minWidth: 132,
        },
        '& .MuiStepLabel-labelContainer': {
            minWidth: 132,
        }
    },
    stepperConnector: {
        '&.MuiStepConnector-alternativeLabel': {
            top: 8,
            left: 'calc(-50% + 8px)',
            right: 'calc(50% + 8px)',
        },
    }
}))