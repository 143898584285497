import { Action, AccountStatementType, MetaDataType } from "src/types"
import { CREDIT_ACCOUNT_STATEMENTS_TYPES } from "./types"

export interface CreditAccountStatementStateType {
    meta: MetaDataType
    data?: AccountStatementType[]
    accountStatement: AccountStatementType
    isLoading: boolean
    isLoadingOne: boolean
    isUpdating: boolean
    isDownloading: boolean
}

const INITIAL_STATE: CreditAccountStatementStateType = {
    meta: {
        page: 1,
        perPage: 20,
        total: 0,
        totalPages: 0
    },
    data: [],
    accountStatement: {},
    isLoading: false,
    isLoadingOne: false,
    isUpdating: false,
    isDownloading: false,
}

export function accountStatementsReducer(state: CreditAccountStatementStateType = INITIAL_STATE, action: Action): CreditAccountStatementStateType {
    const { payload, type } = action
    switch (type) {

        default: return state

        case CREDIT_ACCOUNT_STATEMENTS_TYPES.GET_ALL: {
            return {
                ...state,
                isLoading: true,
                meta: {
                    page: payload?.options?.page || state.meta.page,
                    perPage: payload?.options?.perPage || state.meta.perPage,
                    total: 0,
                    totalPages: 0,
                }
            }
        }

        case CREDIT_ACCOUNT_STATEMENTS_TYPES.SET_ALL: {
            return {
                ...state,
                isLoading: false,
                data: payload?.accountStatements || [],
                meta: {
                    page: payload.meta?.current_page || state.meta.page,
                    perPage: payload.meta?.per_page || state.meta.perPage,
                    totalPages: payload.meta?.total_pages || state.meta.totalPages,
                    total: payload.meta?.total || state.meta.total,
                }
            }
        }

        case CREDIT_ACCOUNT_STATEMENTS_TYPES.GET_ONE:
        case CREDIT_ACCOUNT_STATEMENTS_TYPES.GET_LAST_ONE: {
            return {
                ...state,
                isLoadingOne: true,
            }
        }

        case CREDIT_ACCOUNT_STATEMENTS_TYPES.SET_ONE: {
            return {
                ...state,
                isLoadingOne: false,
                isUpdating: false,
                accountStatement: payload?.accountStatement,
            }
        }

        case CREDIT_ACCOUNT_STATEMENTS_TYPES.DOWNLOAD_URL: {
            return {
                ...state,
                isDownloading: true,
            }
        }
    }
}