import { ReactChild } from "react"
import {
    Dialog as MuiDialog,
    DialogProps as MuiDialogProps,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogTitleProps,
    DialogContentProps,
    IconButtonProps,
    Typography
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useStyles } from "./Dialog.styles"

export interface DialogProps extends MuiDialogProps {
    children?: ReactChild | ReactChild[]
    onClose: () => void
    dialogTitleProps?: DialogTitleProps
    dialogContentProps?: DialogContentProps
    closeButtonProps?: IconButtonProps
    backAction?: JSX.Element
}

/** Show a modal/dialog with children component(s) */
export function Dialog({
    open,
    onClose,
    children,
    dialogTitleProps,
    dialogContentProps,
    closeButtonProps,
    title,
    backAction,
    ...rest
}: DialogProps) {
    const { classes, cx } = useStyles()

    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            {...rest}
        >
            <DialogTitle className={classes.dialogTitleContainer} {...dialogTitleProps}>
                {Boolean(backAction) && backAction}
                {title && (
                    <Typography className={classes.dialogTitle}> {title}</Typography>
                )}
                <IconButton
                    onClick={onClose}
                    data-testid="dialog-close-button"
                    {...closeButtonProps}
                    className={cx(classes.iconButton, closeButtonProps?.className)}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent {...dialogContentProps}>
                {children}
            </DialogContent>
        </MuiDialog>
    )
}