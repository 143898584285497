import { SelectInput, TextInput } from "@kredimx/form-builder";
import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, StagePage, TitleCard } from "src/components";
import { getFullName } from "src/helpers";
import { applicationPaymentsActions } from "src/store/payments/actions";
import { useStyles } from "./styles";

export function ApplicationPaymentsPage() {
    const { applicationId, productType } = useParams()
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const organization = useSelector(state => state.organization);
    const customer = useSelector(state => state.auth.customer)
    const paymentProducts = useSelector(state => state.payments.products)
    const [product, setProduct] = useState(null)

    useEffect(() => {
        if (productType) {
            dispatch(applicationPaymentsActions.getProducts(productType))
        }
    }, [productType])

    function onSubmit() {
        dispatch(applicationPaymentsActions.createPayment({
            applicationId,
            airtable_id: product
        }))
    }

    return (
        <StagePage>
            <TitleCard title={`Pagos de ${organization.name}`}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextInput
                            label="Nombre completo"
                            name="fullName"
                            value={getFullName(customer)}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Correo"
                            name="email"
                            value={customer.email}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Teléfono"
                            name="phone"
                            value={customer.phone_with_country_code?.phone}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            label="Selecciona un producto"
                            name="productType"
                            value={product}
                            onChange={(e: any) => {
                                setProduct(e.target.value)
                            }}
                            options={paymentProducts?.map(product => ({
                                value: product.airtable_id,
                                label: product.description
                            }))}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Link to={organization.terms_and_conditions_link!} target="_blank">
                            <Typography variant="body2" className={classes.terms}>
                                Términos y condiciones
                            </Typography>
                        </Link>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!product}
                            onClick={onSubmit}
                            size="large"
                        >
                            Continuar
                        </Button>
                    </Grid>
                </Grid>
            </TitleCard>
        </StagePage>
    )
}