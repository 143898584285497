import { Action, Employment } from "src/types";
import { EMPLOYMENTS_TYPES } from "./types";

export interface EmploymentsStateType {
    data?: Employment[]
    employment?: Employment,
    isLoading: boolean
}

const INITIAL_STATE: EmploymentsStateType = {
    isLoading: false
}

export function employmentsReducer(state: EmploymentsStateType = INITIAL_STATE, action: Action): EmploymentsStateType {
    const { type, payload } = action
    switch (type) {

        case EMPLOYMENTS_TYPES.GET_ALL: {
            return {
                ...state,
                isLoading: true
            }
        }

        case EMPLOYMENTS_TYPES.SET_ALL: {
            return {
                ...state,
                isLoading: false,
                data: payload.resource == 'application' ? payload.employments.map((e: any) => e.customerResource) : payload.employments
            }
        }

        case EMPLOYMENTS_TYPES.CREATE:
        case EMPLOYMENTS_TYPES.GET_ONE: {
            return {
                ...state,
                isLoading: true
            }
        }

        case EMPLOYMENTS_TYPES.SET_ONE: {
            return {
                ...state,
                isLoading: false,
                employment: payload.employment
            }
        }

        default:
            return state
    }
}