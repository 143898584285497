import { IMatiData } from "@kredimx/form-builder";
import { PROVIDERS_TYPES } from "./types"

//#region CreditReports

function getApplicationCreditReports(applicationId: string) {
    return {
        type: PROVIDERS_TYPES.GET_APPLICATION_CREDIT_REPORTS,
        payload: { applicationId }
    }
}

function setCreditReports(creditReports: any) {
    return {
        type: PROVIDERS_TYPES.SET_APPLICATION_CREDIT_REPORTS,
        payload: { creditReports }
    }
}

//#endregion

//#region Contracts/Weesign

function getContractDetails(creditReportId: string) {
    return {
        type: PROVIDERS_TYPES.GET_CONTRACT_DETAILS,
        payload: { creditReportId }
    }
}

function setContractDetails(details: any) {
    return {
        type: PROVIDERS_TYPES.SET_CONTRACT_DETAILS,
        payload: { details }
    }
}

function cleanWeesign() {
    return {
        type: PROVIDERS_TYPES.CLEAN_WEESIGN,
    }
}

//#endregion

//#region Mati

function getMatiData(flow_id: string) {
    return {
        type: PROVIDERS_TYPES.GET_MATI_DATA,
        payload: { flow_id }
    }
}

function setMatiData(matiData?: IMatiData) {
    return {
        type: PROVIDERS_TYPES.SET_MATI_DATA,
        payload: { matiData }
    }
}

//#endregion

//#region Belvo

function getBelvoToken(credentialId?: string) {
    return {
        type: PROVIDERS_TYPES.GET_BELVO_TOKEN,
        payload: { credentialId }
    }
}

function setBelvoData(key: string, data: any) {
    return {
        type: PROVIDERS_TYPES.SET_BELVO_DATA,
        payload: { key, data }
    }
}

function sendBelvoData(credential: any) {
    return {
        type: PROVIDERS_TYPES.SEND_BELVO_DATA,
        payload: { credential }
    }
}

function getBelvoCredentials(linkId: string) {
    return {
        type: PROVIDERS_TYPES.GET_BELVO_CREDENTIALS,
        payload: { linkId }
    }
}

function setBelvoCredentials(credentials?: any) {
    return {
        type: PROVIDERS_TYPES.SET_BELVO_CREDENTIALS,
        payload: { credentials }
    }
}

function getBelvoAccounts(credentialId: string) {
    return {
        type: PROVIDERS_TYPES.GET_BELVO_ACCOUNTS,
        payload: { credentialId }
    }
}

function setBelvoAccounts(credentialId: string, accounts?: any[]) {
    return {
        type: PROVIDERS_TYPES.SET_BELVO_ACCOUNTS,
        payload: { credentialId, accounts }
    }
}

function destroyBelvoCredential(credentialId: string, linkId?: string) {
    return {
        type: PROVIDERS_TYPES.DESTROY_BELVO_CREDENTIAL,
        payload: { credentialId, linkId }
    }
}

function cleanBelvo() {
    return {
        type: PROVIDERS_TYPES.CLEAN_BELVO,
    }
}

//#endregion

//#region Address

function getCountries() {
    return {
        type: PROVIDERS_TYPES.GET_COUNTRIES
    }
}

function setCountries(countries?: any[]) {
    return {
        type: PROVIDERS_TYPES.SET_COUNTRIES,
        payload: { countries }
    }
}

function getStates(countryCode?: string) {
    return {
        type: PROVIDERS_TYPES.GET_STATES,
        payload: { countryCode }
    }
}

function setStates(states?: any[]) {
    return {
        type: PROVIDERS_TYPES.SET_STATES,
        payload: { states }
    }
}

function getMunicipalities(state?: string) {
    return {
        type: PROVIDERS_TYPES.GET_MUNICIPALITIES,
        payload: { state }
    }
}

function setMunicipalities(municipalities?: any[]) {
    return {
        type: PROVIDERS_TYPES.SET_MUNICIPALITIES,
        payload: { municipalities }
    }
}

//#endregion

//#region Associations

export type AssociationType = 'properties' | 'cars' | 'guarrantees' | 'participants' | 'references' | string;

function getAssociations(type: AssociationType, applicationId: string) {
    return {
        type: PROVIDERS_TYPES.GET_ASSOCIATIONS,
        payload: { type, applicationId }
    }
}

function setAssociations(type: AssociationType, associations?: any[]) {
    return {
        type: PROVIDERS_TYPES.SET_ASSOCIATIONS,
        payload: { type, associations }
    }
}

function createAssociation(type: AssociationType, applicationId: string, association: any) {
    return {
        type: PROVIDERS_TYPES.CREATE_ASSOCIATION,
        payload: { type, applicationId, association }
    }
}

function updateAssociation(type: AssociationType, applicationId: string, id: string, association: any) {
    return {
        type: PROVIDERS_TYPES.UPDATE_ASSOCIATION,
        payload: { type, applicationId, id, association }
    }
}

function destroyAssociation(type: AssociationType, applicationId: string, id: string) {
    return {
        type: PROVIDERS_TYPES.DESTROY_ASSOCIATION,
        payload: { type, applicationId, id }
    }
}

//#endregion

//#region Banks

function getBanks() {
    return {
        type: PROVIDERS_TYPES.GET_BANKS
    }
}

function setBanks(banks?: any[]) {
    return {
        type: PROVIDERS_TYPES.SET_BANKS,
        payload: { banks }
    }
}

//#endregion

//#region Document types

function getDocumentTypes() {
    return {
        type: PROVIDERS_TYPES.GET_DOCUMENT_TYPES
    }
}

function setDocumentTypes(documentTypes?: any[]) {
    return {
        type: PROVIDERS_TYPES.SET_DOCUMENT_TYPES,
        payload: { documentTypes }
    }
}

//#endregion

//#region Incode

function getIncodeVerificationFlows() {
    return {
        type: PROVIDERS_TYPES.GET_INCODE_VERIFICATION_FLOWS
    }
}

function setIncodeVerificationFlows(verificationFlows?: any[]) {
    return {
        type: PROVIDERS_TYPES.SET_INCODE_VERIFICATION_FLOWS,
        payload: { verificationFlows }
    }
}

function getIncodeVerificationFlow(id: string) {
    return {
        type: PROVIDERS_TYPES.GET_INCODE_VERIFICATION_FLOW,
        payload: { id }
    }
}

function setIncodeVerificationFlow(verificationFlow?: any) {
    return {
        type: PROVIDERS_TYPES.SET_INCODE_VERIFICATION_FLOW,
        payload: { verificationFlow }
    }
}

function incodeUpsert(data?: any) {
    return {
        type: PROVIDERS_TYPES.INCODE_UPSERT,
        payload: { data }
    }
}

function krediUpsert(data?: any) {
    return {
        type: PROVIDERS_TYPES.KREDI_UPSERT,
        payload: { data }
    }
}

function getIncodeData() {
    return {
        type: PROVIDERS_TYPES.GET_INCODE_DATA
    }
}

function setIncodeData(data?: any) {
    return {
        type: PROVIDERS_TYPES.SET_INCODE_DATA,
        payload: { data }
    }
}

function getIncodeSessions(applicationWorkflowId: string, applicationId?: string) {
    return {
        type: PROVIDERS_TYPES.GET_INCODE_SESSION,
        payload: { applicationWorkflowId, applicationId }
    }
}

function setIncodeSessions(sessions?: any) {
    return {
        type: PROVIDERS_TYPES.SET_INCODE_SESSION,
        payload: { sessions }
    }
}

//#endregion

export const providersActions = {
    getApplicationCreditReports,

    getContractDetails,
    setCreditReports,
    setContractDetails,
    cleanWeesign,
    getMatiData,
    setMatiData,

    getBelvoToken,
    setBelvoData,
    sendBelvoData,
    getBelvoCredentials,
    setBelvoCredentials,
    getBelvoAccounts,
    setBelvoAccounts,
    destroyBelvoCredential,
    cleanBelvo,

    getCountries,
    setCountries,
    getStates,
    setStates,
    getMunicipalities,
    setMunicipalities,

    getAssociations,
    setAssociations,
    createAssociation,
    updateAssociation,
    destroyAssociation,

    getBanks,
    setBanks,

    getDocumentTypes,
    setDocumentTypes,

    getIncodeVerificationFlows,
    setIncodeVerificationFlows,
    getIncodeVerificationFlow,
    setIncodeVerificationFlow,
    incodeUpsert,
    getIncodeData,
    setIncodeData,
    getIncodeSessions,
    setIncodeSessions,

    krediUpsert,
}