import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    card: {
        marginBottom: 24
    },
    uploadedItem: {
        border: '1px solid #CFD5E2',
        borderRadius: 4,
        padding: '16px 24px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 8
    }
}));