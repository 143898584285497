import { Grid, Typography } from "@mui/material";
import { useStyles } from "./styles"
import DoubtsImg from "src/assets/img/application_doubts.svg"
import { ConsultantCard } from "../components";
import { useIsMobile } from "src/helpers";

export function ConstultantPage() {
    const { classes, cx } = useStyles();
    const isMobile = useIsMobile();

    return (
        <Grid container className={classes.root}>
            <Grid container className={classes.mainContainer}>
                <Grid container className={classes.container}>
                    <Grid container item xs={12} className={classes.hero}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Typography variant="h3">¿Tienes dudas sobre tu proceso de solicitud? </Typography>
                            <Typography> Nosotros podemos ayudarte. </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} className={classes.heroImage}>
                            <DoubtsImg />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.center}>
                        <ConsultantCard
                            text={!isMobile ? "Agenda tu asesoría" : "Asesoría"}
                            className={classes.consultantCard}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}