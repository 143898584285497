import { Backdrop, CircularProgress } from "@mui/material";
import { tss } from "tss-react/mui"

const useStyles = tss.create(({ theme }) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

/** Use to show a modal/dialog over window with a loading animation inside */
export function LoadingBackdrop() {
    const { classes, cx } = useStyles();

    return (
        <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
