import { useState } from "react";
import { Button, Collapse, Grid, Typography, Chip } from "@mui/material";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowIcon from "@mui/icons-material/ArrowForward"
import { getStatusColor, STAGE_STATUS, translateStatus } from "src/helpers";
import { StageStepper } from "src/pages/Summary/Main/StageStepper";
import { Application, ApplicationWorkflowStage } from "src/types";
import { getStatusInfo } from "./helper";
import { useStyles } from "./styles";
import { Link } from "src/components";
import _ from "lodash";

interface StatusProps {
	status: string
	scoringStatus: string
	applicationWorkflowStages: ApplicationWorkflowStage[],
	currentStage: ApplicationWorkflowStage
	workflowTitle: string;
	application: Application
	hasRejectedDocuments: boolean
}

export function Status({ status, scoringStatus, applicationWorkflowStages, currentStage, workflowTitle, application, hasRejectedDocuments }: StatusProps) {
	const { classes, cx } = useStyles()
	const [open, setOpen] = useState(false)
	const applicationStatus = getStatusColor(status); // application status
	const { title, description, Icon } = getStatusInfo(status, hasRejectedDocuments); // scoring status
	const currentApplicationWorkflowStage = applicationWorkflowStages?.find(stage => stage.stage_id === currentStage?.id)

	return (
		<Grid container className={classes.root}>

			<Grid container className={classes.header}>
				<Grid item>
					<Typography className={classes.title}>{title}</Typography>
					<Typography>{description}</Typography>
				</Grid>
				<Grid item>
					<Icon /*className={classes.image}*/ />
				</Grid>
			</Grid>

			<Grid container direction="row" justifyContent="space-between">
				<Grid item className={classes.details}>
					<Grid item>
						<Typography className={classes.dividerDetails}><strong>Solicitud</strong> {workflowTitle}</Typography>
					</Grid>
					{hasRejectedDocuments ? (
						<Link to={`/home/applications/summary/${application?.id}/documents`}>
							<Chip
								className={cx(classes.chip, classes.rejectedDocumentsChip)}
								color="default"
								label="Por favor valida los siguientes documentos"
								deleteIcon={<ArrowIcon fontSize="small" />}
								onDelete={() => { }}
							/>
						</Link>
					) : (
						<Grid item>
							<Typography className={classes.status}>
								<strong>Estatus</strong>
								<div className={classes.statusChip} style={{ color: applicationStatus.color, background: applicationStatus.background }}>
									{translateStatus(status)}
								</div>
							</Typography>
						</Grid>
					)}
				</Grid>

				<Grid item className={classes.actions}>
					{status !== "rejected" && <Grid item>
						<Button
							onClick={() => { setOpen(!open) }}
							color="primary"
							size="small"
							className={cx(classes.stepperButton, { [classes.stepperOpen]: open })}
							endIcon={<ArrowDownIcon />}
						>
							Ver etapas
						</Button>
					</Grid>}
					{(
						application.status !== 'cancelled' &&
						application.status !== 'rejected' &&
						currentApplicationWorkflowStage?.status !== STAGE_STATUS.IN_REVIEW &&
						currentApplicationWorkflowStage?.visibility == 'customer'
					) && (
							<Grid item>
								<Link
									to={`/application/${application.id}/${application.current_stage?.slug}/${application?.current_section?.slug}?from=summary`}
									disabled={currentApplicationWorkflowStage?.status === STAGE_STATUS.IN_REVIEW}
								>
									<Button color="primary" variant="contained">Continuar solicitud</Button>
								</Link>
							</Grid>
						)}
				</Grid>
			</Grid>

			<Collapse in={open} className={cx(classes.collapse, { [classes.stepperMargin]: open })}>
				<Grid container className={cx({ [classes.stepperDivider]: open })}>
					<Grid item>
						<Typography className={classes.stepperTitle}>Etapas de tu solicitud</Typography>
					</Grid>
					<StageStepper
						activeStep={currentApplicationWorkflowStage?.sort_order || 0}
						stages={_.sortBy(applicationWorkflowStages || [], 'sort_order')}
						currentStage={currentApplicationWorkflowStage}
					/>
				</Grid>
			</Collapse>
		</Grid>
	)
}