import { CreditProposalType, GetOptionsType } from "src/types"
import { CreditProposalsStateType } from "./reducer"
import { CREDIT_PROPOSAL_TYPES } from "./types"

function set(key: keyof CreditProposalsStateType, value: any) {
    return {
        type: CREDIT_PROPOSAL_TYPES.SET,
        payload: { key, value }
    }
}

function getOne(applicationId: string, creditProposalId: string) {
    return {
        type: CREDIT_PROPOSAL_TYPES.GET_ONE,
        payload: { applicationId, creditProposalId }
    }
}

function setOne(creditProposal?: CreditProposalType) {
    return {
        type: CREDIT_PROPOSAL_TYPES.SET_ONE,
        payload: { creditProposal }
    }
}

function getAll(applicationId: string, params: GetOptionsType) {
    return {
        type: CREDIT_PROPOSAL_TYPES.GET_ALL,
        payload: { applicationId, params }
    }
}

function setAll(creditProposals?: any[], meta?: any) {
    return {
        type: CREDIT_PROPOSAL_TYPES.SET_ALL,
        payload: { creditProposals, meta }
    }
}

function download(applicationId: string, creditProposal?: CreditProposalType, withRealExpirationDate?: boolean) {
    return {
        type: CREDIT_PROPOSAL_TYPES.DOWNLOAD,
        payload: { applicationId, creditProposal, withRealExpirationDate }
    }
}

function downloadAmortizationTable(applicationId: string, creditProposal?: CreditProposalType, options?: {
    withTaxes?: boolean
    format?: 'pdf' | 'excel'
}) {
    return {
        type: CREDIT_PROPOSAL_TYPES.DOWNLOAD_AMORTIZATION_TABLE,
        payload: { applicationId, creditProposal, options }
    }
}

function sendEmail(creditProposalId: string) {
    return {
        type: CREDIT_PROPOSAL_TYPES.SEND_EMAIL,
        payload: { creditProposalId }
    }
}

function updateStatus(
    applicationId: string,
    creditProposalId: string,
    status: 'accepted' | 'rejected',
    from?: 'list' | 'details'
) {
    return {
        type: CREDIT_PROPOSAL_TYPES.UPDATE_STATUS,
        payload: {
            applicationId,
            creditProposalId,
            status,
            from
        }
    }
}

export const creditProposalsActions = {
    set,
    getOne,
    setOne,
    getAll,
    setAll,
    download,
    sendEmail,
    downloadAmortizationTable,
    updateStatus
}