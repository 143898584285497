import { useEffect, useState, MouseEvent } from "react";
import { Divider, MenuItem, Typography, Grid, Button, IconButton } from "@mui/material";
import { ExpandMore as ExpandMoreIcon, CloudDownloadOutlined as DownloadIcon } from "@mui/icons-material";
import { Dialog, DialogProps, PdfViewer, EmptyState, Menu } from "src/components";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import _ from "lodash";

interface PdfDialogProps extends DialogProps {
    data?: any | any[];
}

export function PdfDialog({ open, onClose, data, ...rest }: PdfDialogProps) {
    const { classes, cx } = useStyles();
    // const dispatch = useDispatch();
    const [attachment, setAttachment] = useState<any | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (data && !_.isArray(data)) {
            setAttachment(data);
        }
    }, [data])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            {...rest}
        >
            <Divider style={{ marginBottom: 32 }} />

            <Grid container>
                <Grid item xs={6}>
                    {_.isArray(data) && data.length > 0 && (
                        <>
                            <Button
                                variant="outlined"
                                color="primary"
                                endIcon={<ExpandMoreIcon />}
                                size="small"
                                className={classes.button}
                                onClick={(event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                            >
                                <Typography noWrap className={classes.buttonText}>
                                    {attachment?.label || 'Selecciona un documento'}
                                </Typography>
                            </Button>

                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                            >
                                {data.map(attachment => (
                                    <MenuItem
                                        key={attachment.id}
                                        onClick={() => {
                                            setAttachment(attachment);
                                            setAnchorEl(null);
                                        }}
                                    >
                                        {attachment.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                </Grid>
                <Grid item xs={6} className={classes.buttonContainer}>
                    <IconButton
                        disabled={!attachment?.id}
                        onClick={() => {
                            // dispatch(customerFiscalActions.download(attachment?.id!, attachment?.file_name!));
                        }}
                    >
                        <DownloadIcon />
                    </IconButton>
                </Grid>
            </Grid>

            {/* { attachment ? ( */}
            {true ? (
                <PdfViewer
                    url={attachment?.file_url || ''}
                    containerProps={{
                        style: {
                            minHeight: 600
                        }
                    }}
                />
            ) : (
                <EmptyState
                    text="No hay información para mostrar"
                    style={{
                        margin: '50px 0'
                    }}
                />
            )}
        </Dialog>
    )
}