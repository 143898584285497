import { Action, Workflow } from "src/types";
import { WORKFLOW_TYPES } from "./types";

export interface WorkflowState {
	data: Workflow[];
	workflow: Workflow;
	isLoading: boolean;
}

const INITIAL_STATE: WorkflowState = {
	data: [],
	workflow: {},
	isLoading: false,
}

export const workflowReducer = (state: WorkflowState = INITIAL_STATE, action: Action): WorkflowState => {
	const { type, payload } = action;
	switch (type) {

		case WORKFLOW_TYPES.SET: {
			return {
				...state,
				[payload.key]: payload.value
			}
		}

		case WORKFLOW_TYPES.GET_ONE: {
			return {
				...state,
				isLoading: true
			}
		}

		case WORKFLOW_TYPES.SET_ONE: {
			return {
				...state,
				isLoading: false,
				workflow: payload.workflow
			}
		}

		case WORKFLOW_TYPES.LOGIN_AGAIN: {
			return {
				...state,
				isLoading: false
			}
		}

		default:
			return state;
	}
}