import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	tooltip: {
		backgroundColor: 'black'
	},
	tableContainer: {
		'& tbody a:last-child td': {
			borderBottom: 'none'
		}
	},
	columnName: {
		color: '#8892A0',
		fontWeight: 400,
		borderBottom: 'none',
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 16,
		paddingBottom: 8,
	},
	columnNameSortBy: {
		//cursor: 'pointer',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		gap: 4,

	},
	subheader: {
		alignItems: 'center',
		borderRadius: 4,
		display: 'inline-flex',
		cursor: 'pointer',
		gap: 4,
		transition: theme.transitions.create('', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		padding: '3px 4px',
		'&:hover': {
			background: 'rgba(117, 139, 183, 0.12)',
		},
		'&:hover $arrowIcon': {
			opacity: 1,
		}
	},
	cell: {
		fontSize: 14,
		color: '#242C37'
	},
	row: {
		cursor: 'pointer',
		'&:hover': {
			background: 'rgba(117, 139, 183, 0.04)'
		},
		'&:focus': {
			background: 'rgba(117, 139, 183, 0.04)'
		},
		'& p': {
			margin: 0
		}
	},
	filterIconButton: {
		'& path': {
			fill: '#242C37'
		}
	},
	arrowIcon: {
		fontSize: 16,
		height: 16,
		width: 16,
		'& svg': {
			height: 16,
			width: 16,
		},
		transition: theme.transitions.create('', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	arrowClose: {
		opacity: 0,
	},
	arrowOpen: {
		opacity: 1,
	},
	sortDesc: {
		transform: 'rotate(180deg)',
		'& svg': {
			transform: 'rotate(180deg)',
		}
	},
	arrowIconContainer: {
		transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
	},
	rowCounter: {
		marginBottom: 24
	},
	pagination: {
		marginBottom: 24
	},
	cardContainer: {
		padding: 0
	}
}));