import { Action, Field } from "src/types"
import { QUESTION_TYPES } from "./types";
import { APPLICATION_TYPES } from "../application/types";

export interface QuestionStageType {
    data: Field[]
    isLoading: boolean
    answersBackup: any;
}

const INITIAL_STATE: QuestionStageType = {
    data: [],
    isLoading: false,
    answersBackup: {}
}

export function questionReducer(state: QuestionStageType = INITIAL_STATE, action: Action): QuestionStageType {
    const { type, payload } = action;
    switch (type) {

        case APPLICATION_TYPES.SET_APPLICATION: {
            return {
                ...state,
                data: payload.application?.section_fields || []
            }
        }

        case QUESTION_TYPES.GET_CONDITIONED_QUESTIONS: {
            return {
                ...state,
                isLoading: true
            }
        }

        case QUESTION_TYPES.SET_QUESTIONS: {
            return {
                ...state,
                isLoading: false,
                data: payload.questions
            }
        }

        case QUESTION_TYPES.ANSWERS_BACKUP:
            return {
                ...state,
                answersBackup: payload.answers
            }

        default:
            return state
    }
}