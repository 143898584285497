import { IconButton, Box, Typography } from "@mui/material";
import { formatDate } from "src/helpers";
import _ from "lodash"
import { AccountStatementType } from "src/types";
import { TableColumnType } from "src/components";

export const getColumns = ({
    classes,
    downloadPdf,
}: {
    classes: any,
    downloadPdf: (accountsStatement: AccountStatementType) => void
}): TableColumnType[] => [
        {
            label: 'Mes',
            key: 'cutoff_date',
            render: (value, row) => _.capitalize(formatDate(value, 'MMMM'))
        },
        {
            label: 'Año',
            key: 'cutoff_date',
            render: (value, row) => formatDate(value, 'YYYY')
        },
        {
            label: 'Descargar',
            key: 'id',
            tableCellProps: {
                style: {
                    textAlign: 'center',
                    width: 122
                }
            },
            render: function Component(value, row) {
                return (
                    <Box display='flex' alignItems='center'>
                        {/* <IconButton className={classes.downloadIcon} disabled>
                            <Typography color="textSecondary"> XML </Typography>
                        </IconButton> */}
                        <IconButton
                            className={classes.downloadIcon}
                            onClick={() => row?.pdf_url && downloadPdf(row)}
                        >
                            <Typography color="primary"> PDF </Typography>
                        </IconButton>
                    </Box>
                )
            }
        }
    ];