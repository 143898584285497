import { ReactChild, ReactNode } from 'react';
import { Drawer, Grid, Typography, DrawerProps, IconButton, TypographyProps } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import ArrowIcon from "@mui/icons-material/ArrowBack"
import { useStyles } from "./DrawerRight.styles";
import { GridProps } from '@mui/material';
export interface DrawerRightProps extends DrawerProps {
	title?: string;
	open: boolean;
	onClose?: () => void;
	children?: ReactChild | ReactChild[] | ReactNode,
	containerProps?: GridProps
	titleContainerProps?: GridProps
	titleProps?: TypographyProps
	closeVariant?: 'arrow' | 'close'
	disableDivider?: boolean
}

/** Use to show a custom component(s) in parent component right position */
export const DrawerRight = ({
	children,
	title,
	onClose,
	open,
	containerProps,
	titleContainerProps,
	titleProps,
	closeVariant = 'close',
	disableDivider,
	PaperProps,
	...rest
}: DrawerRightProps) => {
	const { classes, cx } = useStyles();
	const { className: containerClassName, ...restContainerProps } = containerProps || {}
	const { className: titleContainerClassName, ...restTitleContainerProps } = titleContainerProps || {}

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			variant="temporary"
			PaperProps={{
				className: classes.paper,
				style: {
					boxShadow: '0px 8px 10px rgba(196, 196, 196, 0.8)',
					borderLeft: '1px solid #CFD5E2',
				},
				...PaperProps
			}}
			BackdropProps={{
				invisible: true
			}}
			{...rest}
		>
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
				className={cx(classes.titleContainer, {
					[classes.divider]: !disableDivider,
					[classes.titleContainerArrow]: closeVariant === 'arrow'
				}, titleContainerClassName)}
				{...titleContainerProps}
			>
				{closeVariant === 'close' ? (
					<>
						<Typography variant="h3" className="drawerTitle" {...titleProps}> {title} </Typography>
						<IconButton data-testid="close-button" size="small" onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</>
				) : (
					<Grid container>
						<Grid item xs={12} className={classes.arrowContainer}>
							<IconButton data-testid="close-button" size="small" onClick={onClose} style={{ marginBottom: 16 }}>
								<ArrowIcon />
							</IconButton>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h3" className="drawerTitle" {...titleProps}> {title} </Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid
				container
				className={cx(classes.container, containerClassName)}
				{...restContainerProps}
			>
				{children}
			</Grid>
		</Drawer>
	)
}