import { call, put, takeLatest } from "redux-saga/effects"
import { sagaWrapper } from "src/helpers"
import { Service } from "src/services"
import { Action, Response } from "src/types"
import { defaultWorkflowsActions } from "./actions"
import { DEFAULT_WORKFLOWS_TYPES } from "./types"

const defaultWorkflowService = new Service('workflows_predetermined')

function* getAll({ payload }: Action) {
    const response: Response = yield call(defaultWorkflowService.all, payload?.options || {})
    yield put(defaultWorkflowsActions.setAll(response.data))
}

export function* defaultWorkflowsSagas() {
    yield takeLatest(DEFAULT_WORKFLOWS_TYPES.GET_ALL, sagaWrapper(getAll, defaultWorkflowsActions.setAll()))
}