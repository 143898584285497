import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse'
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '24px',
        color: theme.palette.primary.main,
        marginBottom: 4,
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '28px',
        },
    },
    description: {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: '24px',
        },
    },
    dataContainer: {
        marginTop: 24,
        marginBottom: 24,
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    applicationDataContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        },
    },
    statusContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 8
        }
    },
    chip: {
        // padding: '4px 1px'
        height: 24
    },
    imageContainer: {
        marginLeft: 'auto',
        '& svg': {
            marginTop: -24,
            marginBottom: -24,
            display: 'block'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginBottom: 10,
            '& svg': {
                marginTop: 0,
                marginBottom: 0,
                maxWidth: 140,
                maxHeight: 108
            },
        },
    },
    fontWeightBold: {
        fontWeight: 700,
        marginRight: 8,
    },
    dataText: {
        fontSize: 14,
    },
    divider: {
        marginLeft: 16,
        marginRight: 16,
        backgroundColor: '#CFD5E2'
    },
    actionsContainer: {
        marginTop: 'auto',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            display: 'flex',
            marginTop: 23,
            marginBottom: 6,
        }
    },
    primaryButton: {
        minWidth: 156,
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 156,
            marginRight: 16
        }
    },
    secondaryButton: {
        marginLeft: 16,
        width: 156,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: 130,
        },
    },
    secondaryButtonWithoutApplications: {
        marginLeft: 0,
        width: 156
    },
    secondSecondarybutton: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: '100%',
        },
    },
    link: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    rejectedDocumentsChip: {
        cursor: 'pointer',
        color: '#F44336',
        background: 'rgba(244, 67, 54, 0.1)',
        '&:hover': {
            background: 'rgba(244, 67, 54, 0.2)',
        },
        '& svg': {
            color: '#FF782D',
            height: 16,
            '&:hover': {
                color: '#FF782D',
            }
        }
    },
}))