import { Breadcrumbs as MuiBreadcrumbs, Grid, GridProps, Typography } from "@mui/material";
import BackIcon from "@mui/icons-material/NavigateBefore";
import { Link } from "../Link";
import { useStyles } from "./Breadcrumbs.styles";

export interface Breadcrumb {
    /** Path or link to which is redirect on clicking selected breadcrumb */
    to?: string;
    /** Label to show */
    name: string;
}

export interface BreadcrumbsProps extends GridProps {
    items?: Breadcrumb[];
}

/** Show breadcrumbs by items param */
export function Breadcrumbs({ items, ...rest }: BreadcrumbsProps) {
    const { classes, cx } = useStyles();

    return (
        <Grid container className={classes.container} {...rest}>
            <Link to={((items?.length || 0) > 0 ? items![0].to : '')!}>
                <BackIcon />
            </Link>
            <MuiBreadcrumbs className={classes.breadcrumbs}>
                {items?.map((breadcrumb: Breadcrumb, index: number) => (
                    index === items.length - 1 ? (
                        <Typography key={index} color="textPrimary"> {breadcrumb.name} </Typography>
                    ) : (
                        <Link
                            key={index}
                            to={breadcrumb.to!}
                            className={classes.link}
                        >
                            {breadcrumb.name}
                        </Link>
                    )
                ))}
            </MuiBreadcrumbs>
        </Grid>
    )
}