import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    button: {
        padding: '16px 10px',
        width: 171,
        [theme.breakpoints.down('sm')]: {
            width: 164,
            padding: '16px 10px',
        },

    },
    onlySubmitButton: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '16px 10px',
        },
    }
}));