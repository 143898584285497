import { CatalogType, SelectValueType } from "src/types"
import { capitalize, currencyString } from "./format"

/**
 * Returns summary of the collection by given object property
 * @param data 
 * @param property 
 */
export function getTotal<T, K extends keyof T>(data: T[], property: K, noFormat?: boolean): string | number {
    if (!data) return ''
    const total = data?.reduce((accumulator, currentValue) => accumulator + Number(currentValue[property]), 0)
    return noFormat ? total : currencyString(total)
}


/**
 * Returns the options from the catalogs to map in a select field
 * @param catalogs 
 * @returns 
 */
export function mapCatalogToOptions(catalogs?: CatalogType[], isBoolean?: true): SelectValueType[] {
    if (isBoolean) {
        return catalogs?.map(catalog => ({
            value: catalog.value == 'true' ? true : false,
            caption: catalog.label
        })) || []
    } else {
        return catalogs?.map(catalog => ({
            value: catalog.value,
            caption: catalog.label
        })) || []
    }
}

export function renderCatalogValue(catalogs?: CatalogType[], value?: any) {
    if (value == null) return value;

    const label = catalogs?.find(catalog => catalog.value.toString() === value.toString())?.label;
    return capitalize(label || value);
}
