import { Action, BankAccountType } from "src/types";
import { BANK_ACCOUNTS_TYPES } from "./types"

interface BankAccountsStateType {
    data?: BankAccountType[]
    bankAccount?: BankAccountType
    isLoading: boolean
    isLoadingOne: boolean
}

const initialState: BankAccountsStateType = {
    isLoading: false,
    isLoadingOne: false
}

export function bankAccountsReducer(state: BankAccountsStateType = initialState, action: Action): BankAccountsStateType {
    const { type, payload } = action
    switch (type) {

        case BANK_ACCOUNTS_TYPES.GET_ALL: {
            return {
                ...state,
                isLoading: true
            }
        }

        case BANK_ACCOUNTS_TYPES.SET_ALL: {
            return {
                ...state,
                isLoading: false,
                data: payload.data
            }
        }

        case BANK_ACCOUNTS_TYPES.GET_ONE: {
            return {
                ...state,
                isLoadingOne: true
            }
        }

        case BANK_ACCOUNTS_TYPES.SET_ONE: {
            return {
                ...state,
                isLoadingOne: false,
                bankAccount: payload.bankAccount
            }
        }

        case BANK_ACCOUNTS_TYPES.DESTROY:
        case BANK_ACCOUNTS_TYPES.UPDATE:
        case BANK_ACCOUNTS_TYPES.CREATE: {
            return {
                ...state,
                isLoading: true
            }
        }

        default:
            return state;
    }
}