import { PAYMENT_TYPES } from "./types"

function getOne(id: string) {
    return {
        type: PAYMENT_TYPES.GET_ONE,
        payload: { id }
    }
}

function setOne(payment?: any) {
    return {
        type: PAYMENT_TYPES.SET_ONE,
        payload: { payment }
    }
}

export const paymentsActions = {
    getOne,
    setOne
}