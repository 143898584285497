import MagicLinkSentIcon from "src/assets/img/magic-link-enviado.svg";
import { Typography, Button } from "@mui/material";
import { MessagePage } from "src/components";
import { useStyles } from "./styles";

import { authActions } from "src/store/auth/actions";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "src/helpers";

export function MagicLinkSent() {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const email = useSelector(state => state.auth.email);
    const sendMagicLink = (email: string, isResend?: boolean) => dispatch(authActions.sendMagicLink(email, isResend));

    return (
        <MessagePage
            icon={MagicLinkSentIcon}
            title="Revisa tu correo"
            message={`Te hemos enviado un correo a <strong> <u style="text-underline-position: under;"> ${email || ''} </u> </strong> que contiene un link mágico con el que podrás ingresar.`}
            containerProps={{ className: classes.subContainer }}
        >
            {/* <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
            >
                Revisar mi email
            </Button> */}
            <Typography className={classes.text}>
                ¿Aún no recibes tu link?
                <span
                    onClick={() => {
                        if (email) {
                            sendMagicLink(email, true)
                        } else {
                            navigate.to('/magic_link')
                        }
                    }}
                >
                    Volver a enviar link mágico
                </span>
            </Typography>
        </MessagePage>
    )
}