import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        height: '100%',
        display: 'flex'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: 'calc(100% - 364px)',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    breadcrumbs: {
        padding: '60px 16px 0',
        zIndex: 1,
    },
    routeContainer: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: -25
        }
    },
    skeletons: {
        padding: '60px 30px'
    }
}));