import { Application } from "src/types";
import ApplicationDocumentsImg from 'src/assets/img/documents_icon.svg'
import ApplicationAditionalsImg from 'src/assets/img/aditionals_icon.svg'
import { BadgeProps, ChipProps } from "@mui/material";
import { WarningIcon } from "src/assets/icons";

export interface CardItem {
	image: any;
	title: string;
	description: string;
	linkTitle: string;
	linkTo: string;
	badgeProps?: BadgeProps;
	chipProps?: ChipProps;
}

export function smallCardItems(application: Application, hasRejectedDocuments: boolean): CardItem[] {
	return ([
		{
			image: ApplicationDocumentsImg,
			title: 'Documentos',
			description: 'Actualiza o comparte tus documentos de solicitud.',
			linkTitle: 'Ir a documentos',
			linkTo: `/home/applications/summary/${application.id}/documents`,
			chipProps: hasRejectedDocuments ? {
				label: 'Pendiente',
				icon: <WarningIcon />,
				style: {
					backgroundColor: '#FFF1F1',
					color: '#F44336'
				}
			} : undefined
		},
		// {
		// 	image: ApplicationAditionalsImg,
		// 	title: 'Adicionales',
		// 	description: 'Complementa tu solicitud con procesos adicionales.',
		// 	linkTitle: 'Ir a procesos',
		// 	linkTo: ''
		// },

	]);
}