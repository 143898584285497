import { select } from "@redux-saga/core/effects";
import _ from "lodash";
import { RootState } from "src/store";
import { Application } from "src/types";

export const STAGE_STATUS = {
    CURRENT: 'current',
    COMPLETED: 'completed',
    INCOMPLETED: 'incompleted',
    FAILED: 'failed',
    PUBLISHED: 'published',
    IN_REVIEW: 'in_review',
    PENDING: 'pending'
}

/** Merge questions and shownQuestions and return if none exist in hideQuestions */
export function getShownQuestions(questions: any[], shownQuestions: any[], hiddenQuestions: any[]): any[] {
    let _questions = [...questions, ...shownQuestions];
    _questions = _questions.filter((question: any) => !Boolean(hiddenQuestions.find((hidden: any) => hidden.id === question.id)))
    return _.uniqBy(_questions, 'id');
}

export function* getApplicationSegmentProperties(action: string): object {
    const application: Application = yield select((state: RootState) => state.applications.application);
    const stage = application.applicationWorkflowStages?.find(stage => stage.stage_id === application.current_stage?.id) || {};
    const section = application.currentStageSections?.find(section => section.section_id === application.current_section?.id) || {};

    return {
        action,
        category: application?.workflow_title,
        stage: application?.current_stage?.name,
        stage_sort_order: stage.sort_order,
        section: application?.current_section?.title,
        section_sort_order: section?.sort_order,
        requested_amount: application?.requested_amount,
        approved_amount: application?.approved_amount,
        credit_amount: application?.credit_amount,
        location: 'Web App',
    }
}