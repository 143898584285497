import { useSelector } from "react-redux"

import {
	Drawer,
	Grid,
	DrawerProps,
	IconButton,
	Button,
} from "@mui/material"

import {
	Close as CloseIcon,
	DashboardOutlined as HomeIcon,
	MonetizationOnOutlined as MoneyIcon,
	FolderSharedOutlined as ApplicationIcon,
	PersonOutline as PersonIcon,
} from "@mui/icons-material"

import { LogoutIcon, SupportAgentIcon } from "src/assets/icons"
import { Link } from "../../../components"
import { getCookieName } from "src/helpers"
import { useStyles } from "./TopbarMenu.styles"

export interface TopbarMenuProps extends DrawerProps {
	open: boolean
	onClose: () => void
	isAuthenticated: boolean
	cookies: any
	logout: () => void
}

export const TopbarMenu = ({
	onClose,
	open,
	isAuthenticated,
	cookies,
	logout,
	...rest
}: TopbarMenuProps) => {
	const { classes, cx } = useStyles()

	const organization = useSelector(state => state.organization)
	const customer = useSelector(state => state.auth.customer)
	const lastOneApplication = useSelector(state => state.applications.lastApplication)

	const isNotAuthenticated = (!isAuthenticated && !Boolean(cookies.get(getCookieName())))

	return (
		<Drawer
			anchor="left"
			open={open}
			onClose={onClose}
			variant="temporary"
			PaperProps={{
				className: classes.paper,
				style: {
					boxShadow: "0px 8px 10px rgba(196, 196, 196, 0.8)",
					borderLeft: "1px solid #CFD5E2",
				},
			}}
			BackdropProps={{
				invisible: true,
			}}
			{...rest}
		>

			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
				className={classes.titleContainer}
			>

				<Link
					native={isNotAuthenticated}
					to={isNotAuthenticated && organization.website || "/"} >
					<img
						style={{ width: '72px', aspectRatio: 'auto' }}
						src={organization.logo}
						alt="Logo"
					/>
				</Link>

				<IconButton data-testid="close-button" size="small" onClick={onClose}>
					<CloseIcon />
				</IconButton>

			</Grid>

			<Grid container className={classes.container}>
				{isAuthenticated && Boolean(cookies.get(getCookieName())) ? (
					<div className={classes.itemsContainer}>

						<div>
							<Link to="/home/main" onClick={() => onClose()}>
								<Button className={classes.button} startIcon={<HomeIcon />}>
									Inicio
								</Button>
							</Link>
							{lastOneApplication?.credit_id && (
								<Link to="/home/credit" onClick={() => onClose()}>
									<Button className={classes.button} startIcon={<ApplicationIcon />}>
										Créditos
									</Button>
								</Link>
							)}
							{/* <Link to="/home/applications" onClick={() => onClose()}>
								<Button className={classes.button} startIcon={<ApplicationIcon />}>
									Solicitudes
								</Button>
							</Link> */}
							<Link to="/home/product_applications" onClick={() => onClose()}>
								<Button className={classes.button} startIcon={<MoneyIcon />}>
									Cotizaciones
								</Button>
							</Link>
							{(lastOneApplication?.status !== "rejected" && customer?.user) && (
								<Link to="/home/consultant" onClick={() => onClose()}>
									<Button className={classes.button} startIcon={<SupportAgentIcon />}>
										Asesoría
									</Button>
								</Link>
							)}
							<Link to="/home/profile" onClick={() => onClose()}>
								<Button className={classes.button} startIcon={<PersonIcon />}>
									Perfil
								</Button>
							</Link>
						</div>

						<Link to="/magic_link" onClick={() => logout()}>
							<Button startIcon={<LogoutIcon />} className={cx(classes.button, classes.logout)}>
								Cerrar sesión
							</Button>
						</Link>

					</div>
				) : (
					<div style={{ display: "flex", flexDirection: "column", gap: '16px' }}>

						<Link to={location.pathname === "/magic_link" ? "/create_account" : "/magic_link"} onClick={() => onClose()}>
							<Button startIcon={<PersonIcon color="primary" />}>
								{location.pathname === "/magic_link" ? "Crear una cuenta" : "Iniciar sesión"}
							</Button>
						</Link>

						{(isNotAuthenticated && location.pathname != "/") && (
							<Link to="/" onClick={() => onClose()}>
								<Button color="primary">
									Aplicar
								</Button>
							</Link>
						)}

					</div>
				)}
			</Grid>

		</Drawer>
	)
}
