import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        backgroundColor: 'white',
        border: '1px solid #CFD5E2',
        borderRadius: 8,
        marginBottom: '24px !important',
    },
    accordion: {
        border: '1px solid #CFD5E2',
        boxShadow: 'none',
        borderTopLeftRadius: '8px !important',
        borderTopRightRadius: '8px !important',
        borderBottomLeftRadius: '8px !important',
        borderBottomRightRadius: '8px !important',
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '& .MuiAccordionSummary-root': {
            borderBottom: 'none'
        },
        '&.active': {
            '& .MuiAccordionSummary-root': {
                borderBottom: '1px solid #CFD5E2'
            },
            '& .MuiAccordionSummary-root, .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 54,
            },
            '& .MuiAccordionSummary-content': {
                margin: 0,
            }
        }
    },
    accordionSummary: {
        minHeight: 54,
        margin: '0px !important',
        '& .MuiIconButton-root': {
            padding: 0
        }
    },
    sectionNameContainer: {
        minHeight: 48,
        padding: '12px 24px',
        borderBottom: '1px solid #CFD5E2',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '12px 20px 10px'
        }
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        wordBreak: 'break-word',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            lineHeight: '14px'
        }
    },
    summaryData: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 20px 24px',
    },
    spaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));