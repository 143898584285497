import { MouseEvent, useEffect, useState } from "react";
import { Button, Grid, Typography, Card, IconButton, Chip } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert"
import ArrowIcon from "@mui/icons-material/ArrowForward"
import { Link } from "src/components";
import { getStatusColor, isCancelledOrRejected, STAGE_STATUS, translateStatus } from "src/helpers";
import { Application } from "src/types";
import { useStyles } from "./styles"
import moment from "moment"
import { Service } from "src/services";

const scoringCustomerService = new Service('customer', { api: 'scoring', disableNamespace: true })

interface ApplicationItemProps extends Application {
    onMoreClick: (e: MouseEvent<HTMLButtonElement>) => void
}

export function ApplicationItem({ onMoreClick, ...application }: ApplicationItemProps) {
    const { classes, cx } = useStyles()
    const {
        id,
        workflow_title,
        current_stage,
        applicationWorkflowStages,
        status,
        current_section,
        created_at,
    } = application;
    const statusColor = getStatusColor(status || '')
    const currentApplicationWorkflowStage = applicationWorkflowStages?.find(stage => stage.stage_id === current_stage?.id)
    const [hasRejectedDocuments, setHasRejectedDocuments] = useState(false)

    useEffect(() => {
        if (status === 'in_process') {
            hasAny(id)
        }
    }, [id])

    async function hasAny(applicationId?: string) {
        if (!applicationId) return
        const response: { any: boolean } = await scoringCustomerService.allPath(`rejected_documents/${id}/any`, {}, false)
        setHasRejectedDocuments(response.any)
    }

    return (
        <Grid item className={classes.cardItem}>
            <Card className={classes.card}>

                <Grid container className={classes.header}>
                    <Grid item>
                        <Typography className={classes.workflowTitle}>
                            {workflow_title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            edge="end"
                            size="small"
                            className={classes.iconButton}
                            onClick={onMoreClick}
                        >
                            <MoreIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid container className={classes.details}>
                    <Grid item>
                        <Typography className={classes.firstDetail}>
                            <strong>Fecha</strong> {moment(created_at).format("DD/MMM/YY")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            <strong>Etapa</strong> {current_stage?.name}
                        </Typography>
                    </Grid>
                    {!hasRejectedDocuments && (
                        <Grid item>
                            <Typography className={classes.status}>
                                <strong>Estatus</strong>
                                <div
                                    className={classes.statusChip}
                                    style={{
                                        color: statusColor.color,
                                        background: statusColor.background
                                    }}
                                >
                                    {translateStatus(status || '')}
                                </div>
                            </Typography>
                        </Grid>
                    )}
                    {hasRejectedDocuments ? (
                        <Grid item>
                            <Link to={`/home/applications/summary/${id}/documents`}>
                                <Chip
                                    className={cx(classes.chip, classes.rejectedDocumentsChip)}
                                    color="default"
                                    label="Por favor valida los siguientes documentos"
                                    deleteIcon={<ArrowIcon fontSize="small" />}
                                    onDelete={() => { }}
                                />
                            </Link>
                        </Grid>
                    ) : (
                        <Grid item>
                            <Typography className={classes.lastDetail}> <strong>Siguiente paso</strong> Un ejecutivo se encuentra analizando tu credito </Typography>
                        </Grid>
                    )}
                </Grid>

                <Grid container justifyContent="flex-end" className={classes.buttonsContainer}>
                    <Link to={`/home/applications/summary/${id}/main`} data-cy="summary-link">
                        <Button color="primary">
                            Ver detalles
                        </Button>
                    </Link>
                    {(
                        !isCancelledOrRejected(application) &&
                        current_stage?.status !== STAGE_STATUS.IN_REVIEW &&
                        current_stage?.visibility == 'customer'
                    ) && (
                            <Grid item>
                                <Link
                                    to={`/application/${id}/${current_stage?.slug}/${current_section?.slug}?from=summary`}
                                    disabled={currentApplicationWorkflowStage?.status === STAGE_STATUS.IN_REVIEW}
                                >
                                    <Button color="primary" variant="contained">Continuar solicitud</Button>
                                </Link>
                            </Grid>
                        )}
                </Grid>

            </Card>
        </Grid>
    )
}