import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    emptyState: {
        paddingTop: 48
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        padding: '60px 30px',
        width: '100%',
        maxWidth: 1440,
        ['@media (max-width: 1023px)']: {
            padding: '32px 0px'
        },
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 32,
        maxWidth: '100%',
        '& > h3': {
            color: theme.palette.primary.dark,
            fontSize: 34,
            fontWeight: 'bold'
        },
        '& > div': {
            display: 'flex',
        },
        ['@media (min-width: 601px) and (max-width: 1023px)']: {
            padding: theme.spacing(0, 4)
        },
        ['@media (max-width: 600px)']: {
            padding: '0px 16px',
            '& > h3': {
                fontSize: 24,
            },
        }
    },
    titleButton: {
        maxHeight: 36,
        minWidth: 130,
        padding: '10px 14px'
    },
    headerRow: {
        '& th': {
            color: '#242C37',
            fontSize: 16,
            fontWeight: 'bold',
            padding: '16px 16px 25px',
            '& > strong': {
                color: '#8892A0',
            }
        },
        '& svg': {
            color: theme.palette.primary.main,
            with: 16,
            height: 16
        }
    },
    row: {
        '& td': {
            color: '#242C37',
            fontSize: 18,
            fontWeight: 400,
            minHeight: 51,
            padding: '16px 16px'
        },

        // '& > :last-child': {
        //     borderRight: '1px solid #CFD5E2',
        //     position: 'relative'
        // }
    },
    monthlyContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // gap: '18px',
        width: '100%',
        padding: '0px 16px'
    },
    imgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 51,
        maxHeight: 51,
        padding: 16,
        '& > img': {
            width: 67
        },
        ['@media (min-width: 601px) and (max-width: 1023px)']: {
            padding: theme.spacing(2, 4, 3)
        },
        ['@media (max-width: 1023px)']: {
            '& > img': {
                width: 118
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            minHeight: 0,
            maxHeight: 0,
        }
    },
    more: {
        borderRadius: '50%',
        height: 30,
        width: 30,
        maxWidth: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& svg': {
            width: 24,
            [theme.breakpoints.down('sm')]: {
                fill: '#394A61',
                height: 24,
                width: 24,
            }
        },
        '&:hover': {
            background: 'background: rgba(117, 139, 183, 0.12)',
        },
        ['@media (max-width: 600px)']: {
            // padding: 16
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            marginRight: 16,
        }
    },
    moreActive: {
        background: 'background: rgba(117, 139, 183, 0.12)',
    },
    menu: {
        '& .MuiList-padding': {
            padding: 0,
            '& a': {
                textDecoration: 'none'
            }
        }
    },
    menuItem: {
        fontSize: 14,
        color: '#242C37',
        fontWeight: 400,
        minHeight: 43,
        display: 'flex',
        justifyContent: 'flex-start',
        '&:hover': {
            background: 'rgba(117, 139, 183, 0.08)'
        }
    },
    divider: {
        padding: '24px 0px 0px',
        width: '100%',
        '& > hr': {
            width: '100%',
        },
        ['@media (min-width: 601px) and (max-width: 1023px)']: {
            padding: theme.spacing(3, 4)
        },
        ['@media (max-width: 600px)']: {
            padding: theme.spacing(3, 2),
            paddingBottom: '0px',

        }
    },
    table: {
        backgroundColor: 'white !important',
        border: '1px solid #CFD5E2',
        borderRadius: 8,
        borderCollapse: 'separate',
    },
    linkButton: {
        padding: 0,
        color: theme.palette.primary.main,
        '& span': {
            fontWeight: 700 + '!important',
        },
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
        '&:hover span': {
            textDecoration: 'underline',
        }
    },

    //MOBILE
    itemContainerMobile: {
        borderBottom: '1px solid #CFD5E2',
        '& > :first-child': {
            borderBottom: '1px solid #CFD5E2',
            padding: '16px 0px',
        },
    },
    firstElement: {
    },
    headerMobile: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        '& $more': {
            marginRight: 4,
            marginTop: 4,
            marginBottom: 4
        }
    },
    amountMobile: {
        fontSize: 12,
        color: '242C37',
        letterSpacing: 0,
        padding: '0px 16px 16px',
        paddingBottom: '8px',
        borderBottom: '1px solid #CFD5E2',
        '& > span': {
            color: 'rgba(57, 74, 97, 0.95)',
            fontWeight: 'bold',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '0px 32px 16px',
        }
    },
    rowsMobile: {
        backgroundColor: 'white',
        '& > div:nth-child(odd)': {
            backgroundColor: '#F4F6F9',
        },
        '& > div:last-child': {
            backgroundColor: 'white',
        },
        '& > div:nth-last-child(2)': {
            borderBottom: '1px solid #F4F6F9'
        }
    },
    rowMobile: {
        textAlign: 'left',
        padding: '8px 16px',
        '& p': {
            fontSize: 14,
            letterSpacing: '0.15px',
            fontWeight: 700
        },
        '& span': {
            fontSize: 14,
            letterSpacing: '0.15px',
            fontWeight: 400
        },
        '&:last-child': {
            padding: '16px 16px',
            '& span': {
                fontSize: 14,
                lineHeight: '17.71px',
            },
        }
    },
    mobileContainer: {
        '& > div': {
            padding: 16,
            borderBottom: '1px solid #CFD5E2',
        },
        '& p': {
            fontSize: 14
        },
    },
    item: {
        border: '1.8px solid #CFD5E2',
        // boxShadow: '0px 2px 2px rgba(117, 139, 183, 0.1);',
        boxShadow: '0px 2px 2px rgba(118, 118, 118, 0.1)',
        borderRadius: 8,
        marginBottom: 24,
        marginRight: '16px',
        marginLeft: '16px',
        overflow: 'hidden'
    },
    blueBackground: {
        background: 'rgba(117, 139, 183, 0.08)'
    },
    skeletonsContainer: {
        padding: '24px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 30px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '24px 16px'
        }
    },
    menuPaper: {
        width: 164,
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 32px)',
            '& li': {
                justifyContent: 'center',
                fontSize: 18
            }
        }
    },
}));