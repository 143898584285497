import { genderOptions, maritalStatusOptions } from "src/helpers";
import { Field } from "src/types";

export const fields: Field[] = [
	{
		key: 'first_name',
		name: 'first_name',
		label: 'Primer nombre',
		required: false,
		field_type: 'text_field',
		sort_order: 1,
		placeholder: '',
		row: 1,
		width: 6,
		hidden: false,
		disabled: true
	},
	{
		key: 'middle_name',
		name: 'middle_name',
		label: 'Segundo nombre',
		required: false,
		field_type: 'text_field',
		sort_order: 2,
		placeholder: '',
		row: 1,
		width: 6,
		hidden: false,
		disabled: true
	},
	{
		key: 'last_name',
		name: 'last_name',
		label: 'Apellido paterno',
		required: false,
		field_type: 'text_field',
		sort_order: 3,
		placeholder: '',
		row: 2,
		width: 6,
		hidden: false,
		disabled: true
	},
	{
		key: 'maternal_last_name',
		name: 'maternal_last_name',
		label: 'Apellido materno',
		required: false,
		field_type: 'text_field',
		sort_order: 4,
		placeholder: '',
		row: 2,
		width: 6,
		hidden: false,
		disabled: true
	},
	{
		key: 'birth_date',
		name: 'birth_date',
		label: 'Fecha de nacimiento',
		required: false,
		field_type: 'date',
		sort_order: 5,
		placeholder: '',
		row: 3,
		width: 12,
		hidden: false,
		disabled: true,
	},
	{
		key: 'state_of_birth',
		name: 'state_of_birth',
		label: '¿En qué estado de la república naciste?',
		required: false,
		field_type: 'state_select',
		sort_order: 6,
		placeholder: '',
		row: 4,
		width: 12,
		hidden: false,
		disabled: true,
	},
	{
		key: 'marital_status',
		name: 'marital_status',
		label: 'Estado civil',
		required: false,
		field_type: 'select_field',
		sort_order: 7,
		placeholder: '',
		row: 5,
		width: 6,
		hidden: false,
		options: maritalStatusOptions.map((status) => ({
			value: status.value,
			caption: status.caption,
		})),
		disabled: true
	},
	{
		key: 'gender',
		name: 'gender',
		label: 'Sexo',
		required: false,
		field_type: 'gender',
		sort_order: 8,
		placeholder: '',
		value: '',
		row: 6,
		width: 12,
		hidden: false,
		options: genderOptions.map((gender) => ({
			value: gender.value,
			caption: gender.caption,
		})),
		disabled: true
	},
	{
		key: 'rfc',
		name: 'rfc',
		label: 'RFC',
		required: true,
		field_type: 'rfc',
		sort_order: 9,
		placeholder: '',
		value: '',
		row: 7,
		width: 6,
		hidden: false,
		disabled: true

	},
	{
		key: 'curp',
		name: 'curp',
		label: 'CURP',
		required: true,
		field_type: 'curp',
		sort_order: 9,
		placeholder: '',
		value: '',
		row: 7,
		width: 6,
		hidden: false,
		disabled: true
	}
]

export let initialValues = {
	first_name: "",
	middle_name: "",
	last_name: "",
	maternal_last_name: "",
	state_of_birth: "",
	birthdate: "",
	civil_status: "",
	gender: ""
};
