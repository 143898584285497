import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    terms: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            textUnderlinePosition: 'under'
        }
    }
}));