import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '32px',
        textAlign: 'center',
    },
    predeterminedLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 8
    },
    helIconContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    helpIcon: {
        fontSize: '17px'
    }
}))