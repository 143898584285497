import { ComponentProps, forwardRef, useEffect, useMemo, useRef, useState } from "react"
import { CircularProgress } from "@mui/material"
import { BrokenImage as BrokenImageIcon } from "@mui/icons-material"
import { fetchSvgFromUrl, isSvgByUrl, mergeAttributes, parseStrToSvg } from "./FetchSvg.helpers"

import SvgIcon from "@mui/material/SvgIcon"

interface FetchSvgProps extends ComponentProps<typeof SvgIcon> {
    /** Can be an any image (jpg, png, gif) or svg */
    url: string

    /** The default icon to show when there error */
    defaultIcon?: JSX.Element
}

/** Fetch an svg from an url and render it */
export function FetchSvg({ url, defaultIcon, ...rest }: FetchSvgProps) {
    const svgRef = useRef<SVGSVGElement>(null)

    const [svg, setSvg] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>()

    const Svg = useMemo(() => {
        if (svg) {
            return forwardRef<SVGSVGElement, ComponentProps<typeof SvgIcon>>((props, ref) => (
                <SvgIcon {...props} ref={ref}>
                    {/* The svg content will insert by svgRef.innerHTML at useEffect hook */}
                </SvgIcon>
            ))
        }
        return () => null
    }, [svg, svgRef?.current])

    useEffect(() => {
        if (isSvgByUrl(url)) {
            (async () => {
                const svgResponse = await fetchSvgFromUrl(url, (e) => setError(e))
                setSvg(svgResponse)
                setIsLoading(false)
            })()
        } else {
            setIsLoading(false)
        }
    }, [url])

    useEffect(() => {
        if (svg && svgRef?.current) {
            const svgElement = parseStrToSvg(svg)

            if (svgElement) {
                mergeAttributes(svgRef.current, svgElement)
                svgRef.current.innerHTML = svgElement.innerHTML
            }
        }
    }, [svg, svgRef?.current])

    if (isLoading) {
        return <CircularProgress size={24} />
    }

    if (!isLoading && error) {
        return defaultIcon || <BrokenImageIcon style={{ minWidth: 24 }} {...rest} />
    }

    // If the url is an svg, return the Svg component
    if (isSvgByUrl(url) && svg) {
        return <Svg {...rest} ref={svgRef} />
    }

    // If the url is not an svg, return an img tag by default
    return (
        <img
            src={url}
            alt=""
            style={{ display: 'block' }}
            className={rest?.className}
            onError={() => setError('FetchSvg: Error fetching the image')}
        />
    )
}