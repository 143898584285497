import { CreditType } from "src/types"
import { CREDITS_TYPES } from "./types"

/**
 * Get credit by id, if id is null, get by customer token
 * @param id 
 * @returns 
 */
function getOne(id?: string) {
    return {
        type: CREDITS_TYPES.GET_ONE,
        payload: { id }
    }
}

function setOne(credit?: CreditType) {
    return {
        type: CREDITS_TYPES.SET_ONE,
        payload: { credit }
    }
}

export const creditsActions = {
    getOne,
    setOne
}