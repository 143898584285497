import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    hero: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 10px',
        '& > div': {
            '& > h2': {
                color: theme.palette.primary.main,
                fontSize: 60,
                fontWeight: 'bold',
                maxWidth: 743
            },
            '& > p': {
                fontSize: 34,
                marginTop: 32
            }
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            '& > div': {
                '& > h2': {
                    fontSize: '40px !important',
                    maxWidth: '100% !important'
                },
                '& > p': {
                    fontSize: '18px !important',
                    marginTop: '24px !important'
                }
            },
        }
    },
    heroImage: {
        textAlign: 'right',
        '& svg': {
            maxWidth: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginTop: 24,
        }
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px 0px'
    },
    messageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& h3': {
            fontSize: 34,
            fontWeight: 'bold',
            marginBottom: 32,
            lineHeight: '36px',
            [theme.breakpoints.down('sm')]: {
                fontSize: 23,
                lineHeight: '24px',
                letterSpacing: '0.18px',
                marginBottom: 16,
            }
        },
        '& h4': {
            fontSize: 24,
            fontWeight: 'bold',
            marginBottom: 8,
            [theme.breakpoints.down('sm')]: {
                fontSize: 20,
                marginTop: 40,
                marginBottom: 4,
            }
        },
        '& p': {
            fontSize: 20,
            marginBottom: 32,
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                lineHeight: '24px',
                letterSpacing: '0.5px',
                marginBottom: 16,
            }
        },
    },
    videoContainer: {
    },
    errorMessageContainer: {
        paddingLeft: 66,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            paddingLeft: 0,
        }
    }
}));