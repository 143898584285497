import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPlugin from '@bugsnag/plugin-react';

Bugsnag.start({
    apiKey: import.meta.env.REACT_APP_BUGSNAG_KEY || "",
    releaseStage: import.meta.env.REACT_APP_ENV,
    enabledReleaseStages: ['production', 'sandbox', 'staging'],
    plugins: [new BugsnagPlugin()],
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

export {
    Bugsnag,
    ErrorBoundary
}