import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { isValidId, navigate } from "src/helpers"

export function ApplicationDocumentsPage() {
    const { applicationId }: any = useParams()

    useEffect(() => {
        if (isValidId(applicationId)) {
            navigate.to(`/home/main/${applicationId}/documents`)
        }
    }, [applicationId])

    return (
        <React.Fragment />
    )
}