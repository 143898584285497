import _ from "lodash"

export type TrackingResourceType =
    | 'Application'
    | 'Customer'
    | 'Quotation'
    | 'Banner'

export type TrackingActionType =
    | 'Clicked'
    | 'Viewed'
    | 'Created'
    | 'Updated'
    | 'Deleted'
    | 'Logout'
    | 'Continued'
    | 'Sent'
    | 'Pre-approved'
    | 'Cancelled'
    | 'Rejected'
    | 'Completed'

export type PageType =
    | 'Application'
    | 'Board'
    | 'CreateAccount'
    | 'Home'
    | 'MagicLink'
    | 'MagicLinkSent'
    | 'NotFound'
    | 'Profile'
    | 'SessionExpired'
    | 'SigninConfirmation'
    | 'Summary'
    | 'Applications'
    | 'Quotations'
    | 'Stages'
    | 'StageSummary'

function load(api_key: string) {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
        if (analytics.invoked)
            window.console &&
                console.error &&
                console.error('Segment snippet included twice.');
        else {
            analytics.invoked = !0;
            analytics.methods = [
                'trackSubmit',
                'trackClick',
                'trackLink',
                'trackForm',
                'pageview',
                'identify',
                'reset',
                'group',
                'track',
                'ready',
                'alias',
                'debug',
                'page',
                'once',
                'off',
                'on',
                'addSourceMiddleware',
                'addIntegrationMiddleware',
                'setAnonymousId',
                'addDestinationMiddleware',
            ];
            analytics.factory = function (e: any) {
                return function () {
                    var t = Array.prototype.slice.call(arguments);
                    t.unshift(e);
                    analytics.push(t);
                    return analytics;
                };
            };
            for (var e = 0; e < analytics.methods.length; e++) {
                var t = analytics.methods[e];
                analytics[t] = analytics.factory(t);
            }
            analytics.load = function (e: any, t: any) {
                var n = document.createElement('script');
                n.type = 'text/javascript';
                n.async = !0;
                n.src =
                    'https://cdn.segment.com/analytics.js/v1/' + e + '/analytics.min.js';
                var a = document.getElementsByTagName('script')[0];
                a?.parentNode?.insertBefore(n, a);
                analytics._loadOptions = t;
            };
            analytics.SNIPPET_VERSION = '4.1.0';
            analytics.load(api_key);
        }
}

function identify(id: string, organization: any, properties: Object) {
    if (window.analytics) {
        window.analytics.identify(id, properties);
        window.analytics.group(organization?.slug, organization)
    }
    //console.log("Idenfity", id, organization, properties)

}

function track(resource: TrackingResourceType, action: TrackingActionType, properties?: any) {
    if (window.analytics) {
        const event = `${resource} ${action}`;
        window.analytics.track(event, properties);
    }
    // console.log("Event", `${resource} ${action}`, properties);
}

/**
 * Native track event from Segment
 * @param event 
 * @param properties 
 */
function nativeTrack(event: string, properties?: any) {
    if (window.analytics) {
        window.analytics.track(event, properties);
    }
}

function page(resource: PageType) {
    if (window.analytics) {
        window.analytics.page(resource);
    }
    // console.log("Page", resource);
}

function lintrk(properties: { conversion_id: number }) {
    if (_.isFunction(window.lintrk) && import.meta.env.REACT_APP_ENV != 'staging') {
        window.lintrk('track', properties);
    }
}

export const segment = {
    load,
    identify,
    page,
    track,
    nativeTrack,
    lintrk
}