import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 16
    },
    actionButton: {
        border: '1px solid ' + theme.palette.primary.main,
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
        '& svg': {
            fill: theme.palette.primary.main,
            height: 20,
            width: 20,
            '& path': {
                fill: 'inherit'
            }
        }
    }
}))