import { Typography, Grid, Chip } from "@mui/material";
import { StagePage, Card } from "src/components";
import { CommentsCard } from "./CommentsCard";
import { DocumentsCard } from "./DocumentsCard";
import { useStyles } from "./styles"

export function ProcedureDetailsPage() {
    const { classes, cx } = useStyles()

    return (
        <StagePage
            title="Trámites y aclaraciones"
            breadcrumbs={[
                { name: 'Crédito', to: '/home/credit' },
                { name: 'Trámites y aclaraciones', to: '/home/credit/procedures' },
                { name: 'Detalles', to: '' }
            ]}
        >
            <Card title="Detalles del reporte" className={classes.item}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}> Tipo de trámite </Typography>
                        <Typography className={classes.value}> Pagos </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}> Procedimiento </Typography>
                        <Typography className={classes.value}> Aclaración de pago no aplicado </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}> Fecha </Typography>
                        <Typography className={classes.value}> 22/Ene/91 </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}> Estatus </Typography>
                        <Typography className={classes.value}>
                            <Chip
                                label="En proceso"
                                style={{
                                    background: '#E4F3FF',
                                    color: '#4AAAFF'
                                }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}> Descripción o comentarios </Typography>
                        <Typography className={classes.value}> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </Typography>
                    </Grid>
                </Grid>
            </Card>

            <DocumentsCard />

            <CommentsCard />

        </StagePage>
    )
}