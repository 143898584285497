import { tss } from "tss-react/mui"

export const useStyles = (creditProposalLength: number) => tss.create(({ theme }) => ({
    root: {
        overflow: 'auto',
        scrollbarWidth: 'none',
        WebkitOverflowScrolling: 'touch',
    },
    cardListContainer: {
        // display: 'inline-flex',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 24,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        }
    },
    proposalCard: {
        width: 400,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}))