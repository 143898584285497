import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	root: {
		padding: '64px 40px',
		[theme.breakpoints.down('sm')]: {
			padding: '32px 16px 96px',
		},
	},
	title: {
		fontSize: 34,
		color: theme.palette.primary.dark,
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			fontSize: 24
		}
	},
	cardsContainer: {
		marginTop: 32
	},
	button: {
	},
}))