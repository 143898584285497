export const columns = [
    {
        name: 'Enganche',
        description: '',
        key: 'hitch'
    },
    {
        name: 'Crédito',
        description: '',
        key: 'answers.valor_del_inmueble'
    },
    {
        name: 'Plazo',
        description: '',
        key: 'answers.plazo_en_anos'
    },
    {
        name: 'Interés',
        description: '',
        key: 'answers.tasa_de_interes'
    },
    {
        name: 'Mensualidad',
        description: '',
        key: 'monthly_payment'
    }
];

export const tooltipTitle: { [column: string]: string } = {
    plazo: 'Tiempo contractual al que se otorga una hipoteca, éste es un factor muy importante, ya que a mayor plazo el pago mensual se reduce y a menor plazo la mensualidad aumenta. Los plazos más comunes son 20, 15, 10 y 5 años (dependiendo cada institución y producto).',
    interes: 'Costo del dinero que se ha solicitado a la institución, es decir; es un porcentaje del saldo que adeuda la persona que solicitó el préstamo. Los intereses se cobran sobre saldo insoluto (el saldo insoluto es la deuda real que va quedando mes a mes después del pago de capital).'
}