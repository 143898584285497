import { NumericFormat, NumericFormatProps } from 'react-number-format';
import moment from "moment";
import "moment/locale/es";
import _ from 'lodash'

import dayjs from 'dayjs'
import "dayjs/locale/es"
dayjs.locale("es")

export interface CurrencyInputProps extends NumericFormatProps {
    onChange: any;
    inputRef: any;
}

export const CurrencyInput = (props: CurrencyInputProps) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumericFormat
            prefix="$"
            thousandSeparator
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: other.name,
                        value: values.value,
                    },
                });
            }}
        />
    );
}

/**
 * Validates if a string is uuid or mongo object id
 * @param val 
 * @returns 
 */
export function isValidId(val: any) {
    return (
        (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/).test(val) ||
        (/^[a-fA-F0-9]{24}$/).test(val)
    )
}


export const initialsFor = (str?: string) => {
    if (!str) return '';
    let initials: any = str.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
}

export function currencyString(value: any, hideDecimals?: boolean) {
    const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return hideDecimals ? currencyFormat.format(value || 0)?.split('.')[0] : currencyFormat.format(value || 0)
}

// export function formatDate(date: any, format?: string) {
//     if (!date) return '';
//     moment.locale('es');
//     return moment(date).format(format || 'DD/MM/YYYY');
// }

/**
 * Formats date in standard or given format format
 * @param date 
 * @param options Format or options
 * @returns 
 */
export function formatDate(date: any, options?: string | {
    format?: string,
    withTime?: boolean
}) {
    if (!date) return '';
    const month = dayjs(date)
        .locale('es')
        .format('MMMM')
        .substring(0, 3)
        .toLowerCase()

    if (!options) return dayjs(date)
        .locale('es')
        .format(`DD [${month}], YYYY`)

    if (typeof options === 'string') return dayjs(date)
        .locale('es')
        .format(options)

    return dayjs(date)
        .locale('es')
        .format(`${options.format || `DD [${month}], YYYY`}${options.withTime ? ' h:mm A' : ''}`)
}

/**
 * @deprecated Use formatDate without '_' prefix instead
 * @param date 
 * @param options 
 * @returns 
 */
export function _formatDate(date: any, options?: string | {
    format?: string,
    withTime?: boolean
}) {
    if (!date) return '';
    const month = moment(date).format('MMMM').substring(0, 3)

    if (!options) return moment(date).format(`DD [${month}], YYYY`)
    if (typeof options === 'string') return moment(date).format(options)

    return moment(date).format(`${options.format || `DD [${month}], YYYY`}${options.withTime ? ' h:mm A' : ''}`)
}

export function percentage(value?: any): string {
    if (!value) return ''
    const percentFormat = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2 })
    return percentFormat.format(value > 1 ? value / 100 : value)
}

/**
 * Function to capitalize a string
 * @param value string to format (HELLO WORLD)
 * @param ofEachWord if is true converts the first letter of each word to uppercase
 * @param firtsLetter if is true converts the first letter of the string to uppercase
 * @returns string formated (Hello world | Hello World)
 */
export function capitalize(value?: string, options: {
    eachWord?: boolean,
    firtsLetter?: boolean
} = {}) {

    if (!value) return ''

    if (options?.firtsLetter)
        return value
            .charAt(0)
            .toUpperCase() + value.slice(1)

    if (options?.eachWord)
        return value
            .toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())

    return _.capitalize(value)
}