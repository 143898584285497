import InReviewIcon from 'src/assets/img/application_in_review.svg'
import Approved from 'src/assets/img/application_approved.svg'
import Rejected from 'src/assets/img/application_rejected.svg'
import Preapproved from 'src/assets/img/application_preapproved.svg'

export function getStatusInfo(status: string, hasRejectedDocuments: boolean) {
	switch (status) {
		case 'pre_approved':
			return {
				title: "¡Tu solicitud ha sido preaprobada!",
				description: "Un ejecutivo se comunicará contigo para indicarte cuales son los siguientes pasos.",
				Icon: Preapproved
			}
		case 'rejected':
			return {
				title: "Lo sentimos, tu solicitud fue rechazada",
				description: "¿Crees que hemos cometido un error? Comunícate con tu asesor, podemos ayudarte.",
				Icon: Rejected
			}
		case 'approved':
			return {
				title: '¡Tu crédito ha sido aprobado!',
				description: "¡Felicidades! Haz recibido una propuesta de crédito.",
				Icon: Approved
			}
		default:
			if (hasRejectedDocuments) {
				return {
					title: "Tienes documentos pendientes",
					description: "Para poder continuar con tu proceso es necesario que actualices los documentos pendientes.",
					Icon: InReviewIcon,
				}
			} else {
				return {
					title: "Tu solicitud se encuentra en revisión",
					description: "Estamos revisando tu expediente para darte una respuesta lo más pronto posible.",
					Icon: InReviewIcon
				}
			}
	}
}