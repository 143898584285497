import { Action, Stage, SectionSummary } from "src/types";
import { STAGE_TYPES } from "./types";

export interface StageStateType {
	stage: Stage
	data: Stage[]
	isLoading: boolean
	isLoadingSummary: boolean
	stageSummary: SectionSummary[];
}

const INITIAL_STATE: StageStateType = {
	stage: {},
	data: [],
	isLoading: false,
	isLoadingSummary: false,
	stageSummary: [],
}

export function stageReducer(state: StageStateType = INITIAL_STATE, action: Action): StageStateType {
	const { type, payload } = action;
	switch (type) {

		case STAGE_TYPES.SET_STAGE: {
			return {
				...state,
				stage: payload.stage
			}
		}

		case STAGE_TYPES.GET_SUMMARY: {
			return {
				...state,
				isLoadingSummary: true
			}
		}

		case STAGE_TYPES.SET_SUMMARY: {
			return {
				...state,
				isLoadingSummary: false,
				stageSummary: payload.stageSummary
			}
		}

		default:
			return state;
	}
}