import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        marginLeft: -6,
        flexWrap: 'nowrap'
        // marginBottom: 8
    },
    breadcrumbs: {
        '& a': {
            fontSize: 12,
            fontWeight: 400,
            color: '#242C37',
            height: 24,
            display: 'flex',
            alignItems: 'center'
        },
        '& p': {
            fontSize: 12,
            fontWeight: 500,
            color: theme.palette.primary.main,
            height: 24,
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiBreadcrumbs-separator': {
            fontSize: 12
        }
    },
    link: {
        '&:hover': {
            textUnderlinePosition: 'under',
            textDecoration: 'underline'
        }
    }
}));
