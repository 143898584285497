import { currencyString } from "src/helpers"
import { AmortizationTablePeriodType } from "src/types"

export const amortizationTableColumns: {
    label: string
    key: keyof AmortizationTablePeriodType,
    render?: (value: any, row: AmortizationTablePeriodType) => string
}[] = [
        {
            label: 'Periodo',
            key: 'period',
            render: value => value
        },
        {
            label: 'Aportación a capital',
            key: 'capital_payment'
        },
        {
            label: 'Intereses',
            key: 'interest'
        },
        {
            label: 'Saldo insoluto',
            key: 'final_balance'
        },
        {
            label: 'Seguros',
            key: 'life_insurance',
            render: (_, row) => currencyString(row.life_insurance + row.damage_insurance)
        },
        {
            label: 'Comisiones',
            key: 'admin_fee'
        },
        {
            label: 'Mensualidad',
            key: 'total_payment'
        }
    ]

export function getInsuranceTotal(data?: AmortizationTablePeriodType[]): string {
    const lifeInsuranceTotal = data?.reduce((accumulator, currentValue) => accumulator + currentValue.life_insurance, 0) || 0
    const damageInsuranceTotal = data?.reduce((accumulator, currentValue) => accumulator + currentValue.damage_insurance, 0) || 0

    return currencyString(lifeInsuranceTotal + damageInsuranceTotal)
}