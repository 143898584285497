import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	inputFiels: {
		paddingRight: 24,
		borderRight: '1px solid #CFD5E2',
		paddingBottom: 40,
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0,
			borderRight: 'unset',
			width: "100%",
		},
		[theme.breakpoints.between(1023, 1439)]: {
			maxWidth: '40%'
		}
	},
	result: {
		paddingLeft: 24,
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			paddingLeft: 0,
		},
		[theme.breakpoints.between(1023, 1439)]: {
			maxWidth: '60%'
		}
	},
	valuesCardComponent: {
		padding: '0 16px 24px'
	}
})) 