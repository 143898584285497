import { SelectInput } from "@kredimx/form-builder"
import { ChangeEvent, useEffect, useState } from "react"
import { DrawerRight } from "src/components"
import { Button, Grid, } from "@mui/material"
import { Application, Workflow } from "src/types"
import { useStyles } from "./styles"
import _ from "lodash"

import { useDispatch, useSelector } from "react-redux"
import { defaultWorkflowsActions } from "src/store/defaultWorkflows/actions"
import { relatedApplcationsActions } from "src/store/relatedApplications/actions"

interface CoaccreditDrawerProps {
    application?: Application
    open: boolean
    onClose: () => void
}

export function CoaccreditDrawer({ application, open, onClose }: CoaccreditDrawerProps) {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    const defaultWorkflows = useSelector(state => state.default.workflows.data || [])

    const [workflow, setWorkflow] = useState<Workflow | undefined>(undefined)

    useEffect(() => {
        if (open) {
            dispatch(defaultWorkflowsActions.getAll({
                search: {
                    slug_cont: 'co-acreditado',
                    archived_eq: false,
                    status_eq: 'published'
                }
            }))
        } else {
            setWorkflow(undefined)
        }
    }, [open])

    function submit() {
        dispatch(relatedApplcationsActions.create(application!.id!, {
            application_workflow: {
                association_type: "co_accredited",
                workflow_id: workflow?.id
            }
        }))
        onClose()
    }

    return (
        <DrawerRight
            open={open || false}
            onClose={onClose}
            title="Agregar coacreditado"
            containerProps={{ className: classes.container }}
        >
            <Grid container className={classes.gridContainer}>

                <Grid item xs={12}>
                    <SelectInput
                        fullWidth
                        label="Asociación"
                        name="workflow_id"
                        value={workflow?.id}
                        options={defaultWorkflows.map(w => ({
                            caption: w.label,
                            value: w.id
                        }))}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setWorkflow(defaultWorkflows.find(w => w.id === e.target.value))
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        onClick={submit}
                        disabled={!Boolean(workflow?.id)}
                    >
                        Agregar
                    </Button>
                </Grid>

            </Grid>
        </DrawerRight>
    )
}