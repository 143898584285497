import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 200px)',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'fit-content',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            width: 50,
            height: 50
        }
    },
    title: {
        fontSize: 48,
        fontWeight: 700,
        color: theme.palette.primary.main,
        margin: 0
    },
    description: {
        fontSize: 16,
        margin: 0
    },
    button: {
        marginTop: 12
    }
}))