import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    smallImg: {
        height: 60,
        width: 60,
    },
    mediumImg: {
        height: 100,
        width: 100,
    },
    largeImg: {
        height: 190,
        width: 190,
    },
    text: {
        fontWeight: 700,
        color: '#8892A0',
        textAlign: 'center',
    },
    smallText: {
        fontSize: 12,
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        }
    },
    mediumText: {
        fontSize: 16,
        marginTop: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        }
    },
    largeText: {
        fontSize: 20,
        marginTop: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        }
    },
    secondaryText: {
        fontWeight: 400,
        fontSize: 14,
        color: '#8892A0',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        }
    },
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        // marginTop: 100
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    children: {
        padding: '24px 0px'
    }
}));