import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        height: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 50px)'
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FAFAFC',
        justifyContent: 'flex-start',
        width: 'calc(100% - 238px)',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    sidebar: {
        background: '#fff',
        width: 238,
        '& > div:first-child': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        '& a > div': {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            '& a:last-child': {
                marginTop: 'auto',
                marginBottom: 16
            },
        }
    },
    selectedItem: {
        paddingLeft: '12px !important'
    }
}));