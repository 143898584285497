import { useMemo, useState } from "react"
import { Box, Button, Grid, IconButton, Typography, MenuItem } from "@mui/material"
import { Menu } from "src/components"
import { DocumentsPercentBar } from "./DocumentBar"

import { MoreVert as MoreVertIcon } from "@mui/icons-material"
import DocumentImage from "src/assets/img/home/documents/document.svg"

import { ActionableType, CustomSwitchableEvaluatorQualifierField } from "src/types"
import { useStyles } from "./styles"
import { getStatus } from "./helpers"
import _ from "lodash"

export interface MenuActionProps {
    title: string,
    handleAction?: (actionableId?: string, actionableType?: ActionableType) => void,
    disabled?: boolean
}

export interface SectionCardProps {
    /** Title card */
    title: string

    /** Description card */
    description?: string

    /** Status to display when rejected documents total + pending documents total are less 0  */
    status?: string

    /** Title or text of principal button */
    actionTitle: string

    /** Principal icon, if not defined shows a default icon */
    Icon?: any

    /** Handle function of principal button */
    handleAction?: () => void

    /** Menu items of popup menu (top right dots) */
    menuActions?: MenuActionProps[]

    /** Hidden menu actions (top right dots) */
    hideMenuActions?: boolean

    /** Filtered approvals */
    approvals?: CustomSwitchableEvaluatorQualifierField[]

    /** Resource id related to card (applicationId, employmentId, etc.) */
    actionableId?: string

    actionableType?: ActionableType
}

export function SectionCard({
    title,
    description,
    actionTitle,
    Icon,
    approvals = [],
    handleAction,
    menuActions,
    hideMenuActions,
    actionableId,
    actionableType
}: SectionCardProps) {
    const { classes, cx } = useStyles()

    const [actionMenu, setActionMenu] = useState<HTMLButtonElement | null>(null)

    const totals = useMemo(() => {
        return {
            finished: approvals.filter(a => a?.status === 'finished').length,
            rejected: approvals.filter(a => a?.status === 'failed').length,
            inReview: approvals.filter(a => a?.status === 'input_required').length,
            pending: approvals.filter(a => a?.status === 'pending').length
        }
    }, [approvals])

    const status = getStatus({
        inReview: totals.inReview,
        finished: totals.finished,
        approvalsTotal: totals.finished + totals.inReview + totals.pending + totals.rejected
    })

    return (
        <>
            <Menu
                anchorEl={actionMenu}
                open={Boolean(actionMenu)}
                onClose={() => setActionMenu(null)}
            >
                {menuActions?.map((action, index) => {
                    const { handleAction, disabled } = action
                    return (
                        <MenuItem
                            key={index}
                            disabled={disabled}
                            onClick={() => {
                                if (_.isFunction(handleAction)) {
                                    handleAction(actionableId, actionableType)
                                }

                                setActionMenu(null)
                            }}
                        >
                            {action.title}
                        </MenuItem>
                    )
                })}
            </Menu>

            <Box className={classes.root}>

                {(!hideMenuActions && (menuActions?.length || 0) > 0) && (
                    <div className={classes.menuButton}>
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                setActionMenu(e.currentTarget)
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </div>
                )}

                <Box className={classes.container}>
                    <Grid container spacing={2} style={{ textAlign: 'center' }}>
                        <Grid
                            item
                            xs={12}
                            className={classes.iconContainer}
                        >
                            {Icon ? <Icon /> : <DocumentImage />}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}> {title} </Typography>
                            {description && (
                                <Typography className={classes.description}> {description} </Typography>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <DocumentsPercentBar totals={totals} />
                        </Grid>

                        <Grid item xs={12}>
                            {(totals.rejected + totals.pending) <= 0 ? (
                                <div
                                    className={classes.statusChip}
                                    style={{ color: status.color, background: status.bgColor }}
                                >
                                    {status.label}
                                </div>
                            ) : (
                                <Button
                                    variant="text"
                                    color="primary"
                                    className={classes.actionButton}
                                    onClick={() => {
                                        if (_.isFunction(handleAction)) {
                                            handleAction()
                                        }
                                    }}
                                >
                                    {actionTitle}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </>
    )
}