import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    arrow: {
        color: '#242C37',
        fontSize: 24
    },
    tooltip: {
        backgroundColor: '#242C37',
        fontSize: 14,
        padding: '18px 16px',
        textAlign: 'left'
    }
}));