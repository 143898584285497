import { useEffect, useState } from "react";
import { AmortizationChart, AmortizationTable, DonutChart, EmptyState, InitialSummary, InputFields, ValuesCard } from "@kredimx/abacus-components";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { PRODUCT_TYPES, segment, useQueryParams } from "src/helpers";
import { ApplicationResponseCard } from "../Card";
import { useStyles } from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { authActions } from "src/store/auth/actions";
import { productApplicationActions } from "src/store/productApplication/actions";
import { ProductApplication } from "src/types/productApplication";

interface CalculatorProps {
	/** Loader confiog */
	loader?: {
		show: boolean,
		duration?: number
	}
}

export function Calculator({ loader }: CalculatorProps) {
	const { applicationId }: any = useParams()
	const { productApplicationId } = useQueryParams();
	const { classes, cx } = useStyles()
	const dispatch = useDispatch()
	const scoringProductApplicationId = useSelector(state => state.auth.scoringProductApplicationId)
	const [isLoading, setIsLoading] = useState(false)

	function send(productApplicationId?: string) {
		if (productApplicationId) {
			dispatch(productApplicationActions.sendResult(productApplicationId || ''))
			segment.track('Quotation', 'Clicked', { QuotationId: productApplicationId, action: 'Download Quotation' })
		}
	}

	useEffect(() => {
		if (loader?.show) {
			setIsLoading(true)
			setTimeout(() => {
				setIsLoading(false)
			}, loader?.duration || 5000)
		}
	}, [loader?.show])

	useEffect(() => {
		return () => {
			dispatch(authActions.set('scoringProductApplicationId', undefined))
		}
	}, [])

	return (
		<ApplicationResponseCard title="Cotiza tu crédito">
			<Grid container>
				<Grid item md={4} className={classes.inputFiels}>
					<InputFields
						dryRun
						visibility="customer"
						externalId={applicationId}
						productApplicationId={scoringProductApplicationId || productApplicationId || ''}
						externalLoading={isLoading}
						textFieldProps={{
							size: "medium"
						}}
						buttonProps={{
							onClick: () => {
								segment.track('Quotation', 'Created')
							}
						}}
						onProductApplicationCreated={(productApplication: ProductApplication) => {
							if (productApplication?.id) {
								// history.push(`${match.url}?productApplicationId=${productApplication?.id}`)
								dispatch(authActions.set('scoringProductApplicationId', productApplication?.id))
							}
						}}
						productsSearchParams={{
							search: {
								'product_type_not_eq': PRODUCT_TYPES.legacy
							}
						}}
					/>
				</Grid>
				<Grid item md={8} className={classes.result}>
					{!isLoading && (
						<EmptyState />
					)}
					{!isLoading && (
						<Grid container className={classes.valuesCardComponent} >
							{/* @ts-ignore */}
							<ValuesCard externalLoading={isLoading} />
						</Grid>
					)}
					<InitialSummary
						hideDownloadButton
						useParams={useParams}
						onClickDownload={send}
						externalLoading={isLoading}
					/>
					<AmortizationTable externalLoading={isLoading} />
					<AmortizationChart externalLoading={isLoading} />
					<DonutChart externalLoading={isLoading} />
				</Grid>
			</Grid>
		</ApplicationResponseCard>
	)
}