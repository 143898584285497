import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    dialogTitleContainer: {
        paddingBottom: 0,
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    dialogTitle: {
        fontSize: 24,
        lineHeight: '24px',
        fontWeight: 700
    },
    iconButton: {
        marginLeft: 'auto',
        '& svg': {
            fill: '#758BB7',
            height: 32,
            width: 32
        }
    }
}));