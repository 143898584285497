import { Fragment, useEffect } from "react";
import { Typography, Grid, Button, Divider, Chip } from "@mui/material";
import MoneyIcon from "@mui/icons-material/MonetizationOnOutlined"
import CalendarIcon from "@mui/icons-material/EventNoteOutlined"
import { Card, Link, Loading, StagePage } from "src/components";
import { useStyles } from "./styles";

import PrepayImg from "src/assets/img/prepago_capital.svg"
import { AutomaticPayment } from "./Automatic";
import { useParams } from "react-router-dom";
import { currencyString, formatDate, isValidId } from "src/helpers";

import { useDispatch, useSelector } from "react-redux";
import { creditsActions } from "src/store/credits/actions";

// const paymentDetails = [
//     { label: 'Saldo vencido de capital', value: '$0.00' },
//     { label: 'Saldo vencido de intereses', value: '$0.00' },
//     { label: 'Saldo vencido de seguro', value: '$0.00' },
//     { label: 'Intereses moratorios', value: '$0.00' },
// ]

// const extraPaymentDetails = [
//     { label: 'Intereses ordinarios', value: '$43,862.90' },
//     { label: 'Amortización a capital', value: '$0.00' },
//     { label: 'Comisiones', value: '$0.00' },
//     { label: 'Seguro', value: '$2,766.45' },
//     { label: 'IVA de comisiones y gastos', value: '$2,766.45' },
// ]

export function PaymentPage() {
    const { creditId }: any = useParams()
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.credits.credits.isLoading)
    const { last_account_statement, ...credit } = useSelector(state => state.credits.credits.credit) || {}
    const {
        total_payment,
        due_date,
        pending_periods,
        period,
        admin_fee,
        vat_interest,
        period_interest,
        insurance_amounts,
    } = last_account_statement || {}

    const paymentAccount = credit?.payment_accounts?.find(account => account.predetermined)

    const paymentDetails = [
        { label: 'Comisión de Administración', value: currencyString(admin_fee) || 'Sin especificar' },
        { label: 'Intereses', value: currencyString(period_interest) || 'Sin especificar' },
        { label: 'IVA sobre intereses ordinarios', value: currencyString(vat_interest) || 'Sin especificar' },
        { label: 'Seguros', value: currencyString(insurance_amounts) || 'Sin especificar' },
    ]

    useEffect(() => {
        if (isValidId(creditId)) {
            dispatch(creditsActions.getOne(creditId))
        }
    }, [creditId])

    return (
        <StagePage
            title="Pago"
            breadcrumbs={[
                { name: 'Crédito', to: '/home/credit' },
                { name: 'Pago', to: '' },
            ]}
        >
            <Loading isLoading={isLoading} variant="skeleton">

                <Card
                    title="Resumen de mi pago"
                    className={classes.item}
                    titleAction={(period && pending_periods) && <Typography className={classes.titleActionText}> Pago {period} de {pending_periods} </Typography> || ''}
                    titleContainerProps={{
                        className: classes.content
                    }}
                    contentGridProps={{
                        className: classes.content
                    }}
                >
                    <Grid container className={classes.mainDataContainer}>
                        <div className={classes.mainData}>
                            <MoneyIcon color="primary" />
                            <Typography> Total a pagar: </Typography>
                            <Typography variant="h3"> {currencyString(total_payment) || 'Sin especificar'} </Typography>
                            {/* <Chip
                                label="Pendiente"
                                className={classes.chip}
                                style={{ color: '#F44336', background: '#FFF1F1' }}
                            /> */}
                        </div>
                        <Divider orientation="vertical" className={classes.divider} />
                        <div className={classes.mainData}>
                            <CalendarIcon color="primary" />
                            <Typography> Fecha límite de pago: </Typography>
                            <Typography variant="h3"> {formatDate(due_date, 'DD/MMM/YYYY') || 'Sin especificar'} </Typography>
                        </div>
                    </Grid>

                    <Grid container>
                        <Typography className={classes.subtitle}> Desglose del pago </Typography>
                        <Grid item xs={12} className={cx(classes.card, classes.table)}>
                            {paymentDetails.map((data, index) => (
                                <div key={index} className={classes.row}>
                                    <Typography> {data.label} </Typography>
                                    <Typography> {data.value} </Typography>
                                </div>
                            ))}
                        </Grid>
                        {/* <Grid item xs={12} className={cx(classes.card, classes.table)}>
                            {paymentDetails.map((data, index) => (
                                <div key={index} className={classes.row}>
                                    <Typography> {data.label} </Typography>
                                    <Typography> {data.value} </Typography>
                                </div>
                            ))}
                            <div className={classes.totalRow}>
                                <Typography className={classes.total}> Total= $0.00 </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} className={cx(classes.card, classes.table)}>
                            {extraPaymentDetails.map((data, index) => (
                                <div key={index} className={classes.row}>
                                    <Typography> {data.label} </Typography>
                                    <Typography> {data.value} </Typography>
                                </div>
                            ))}
                            <div className={classes.totalRow}>
                                <Typography className={classes.total}> Total= $49,395.80  </Typography>
                            </div>
                        </Grid> */}
                        <div className={cx(classes.totalRow, classes.totalContainer)}>
                            <Typography className={classes.total}>
                                Total del mes = {currencyString(total_payment) || 'Sin especificar'}
                            </Typography>
                        </div>
                    </Grid>
                </Card>

                {paymentAccount ? (<Card
                    title="Realizar mi pago de forma manual"
                    description="Realiza tu pago a la cuenta bancaria que te mostramos acontinuación."
                    className={classes.item}
                    disableDivider
                >

                    <Grid container className={classes.card}>
                        <Typography variant="h3" className={classes.accountTitle}> Cuenta bancaria </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3} className={classes.bankItem}>
                                <Typography variant="h5"> Banco </Typography>
                                <Typography> {paymentAccount?.bank?.alias} </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} className={classes.bankItem}>
                                <Typography variant="h5"> Cuenta </Typography>
                                <Typography> {paymentAccount?.clabe} </Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={3} className={classes.bankItem}>
                                <Typography variant="h5"> CLABE </Typography>
                                <Typography> 113180000006111975 </Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>

                </Card>) : <Fragment />}

                <AutomaticPayment />

                {/* <Grid container className={cx(classes.card, classes.item, classes.flex)}>
                    <Grid container justifyContent="space-between">
                        <Grid item className={classes.flex}>
                            <Typography variant="h3"> Prepago de capital </Typography>
                            <Typography> ¿Quieres realizar un pago a capital? Simula el prepago de tu crédito. </Typography>
                            <Link to="/home/credit/capital_prepaid">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                >
                                    Simular
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item className={classes.imgContainer}>
                            <PrepayImg />
                        </Grid>
                    </Grid>
                </Grid> */}

            </Loading>

        </StagePage>
    )
} 