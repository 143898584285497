import { useState } from 'react'
import { Grid, FormLabel, Typography, IconButton, LinearProgress } from "@mui/material"
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { useDropzone } from 'react-dropzone'
import { useStyles } from "./Dropzone.styles";
import _ from "lodash";
import { useIsMobile } from 'src/helpers';
import { convertAcceptedFilesToAccept } from '@kredimx/form-builder';

export interface UploadInputProps {
    id?: string,
    label?: string;
    uploadText?: string
    disabled?: boolean;
    enableDelete?: boolean;
    onChange: Function;
    config: {
        file_types: string;
        allowed_files: number;
        max_file_size: number;
    }
}

/** Input to upload file(s)  */
export function UploadInput({ id, label, disabled, config, enableDelete, uploadText, ...rest }: UploadInputProps) {
    const { classes, cx } = useStyles();
    const isMobile = useIsMobile()
    const [files, setFiles] = useState<File[]>([]);
    const { file_types, allowed_files, max_file_size } = config;

    async function onDrop(acceptedFiles: File[]) {
        setFiles(_files => ([..._files, ...acceptedFiles]));
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: allowed_files,
        disabled,
        accept: convertAcceptedFilesToAccept(file_types),
        maxSize: max_file_size
    });

    function handleRemove(index: number) {
        setFiles(files.filter((file: any, _index: number) => _index !== index));
    }

    return (
        <>
            {label && <FormLabel> {label} </FormLabel>}
            <div className={cx(classes.addArea, { [classes.hover]: !!!disabled })}>
                <Grid container direction="column" alignItems="center" {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <UploadIcon className={classes.icon} />
                    <h6> {uploadText || 'Arrastra tus documentos o da clic aquí'}  </h6>
                    {/* <p> (Máximo {allowed_files === 1 ? '1 archivo' : `${allowed_files} archivos`}) </p> */}
                </Grid>
            </div>
            <aside>
                {(files.length > 0) && (
                    files.map((file: any, index: number) => (
                        <div key={index} className={classes.file}>
                            <Typography> {file.path} </Typography>
                            {!enableDelete ? <div /> : (
                                <IconButton size="small" onClick={() => handleRemove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </div>
                    ))
                )}
            </aside>
            {false && (
                <aside>
                    {acceptedFiles.length > 0 && (
                        acceptedFiles.map((file: any, index: number) => (
                            <div key={index} className={classes.loader}>
                                <Typography> {file.path} </Typography>
                                <LinearProgress />
                            </div>
                        ))
                    )}
                </aside>
            )}
        </>
    )
}