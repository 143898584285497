import { Button, Grid, Typography } from "@mui/material";
import { Link } from "src/components";
import { useIsMobile } from "src/helpers";
import { MainSmallCard } from "src/pages/Home/Main/components";
import { cardItems } from "./cards";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";

export function ProfileMain() {
	const { classes, cx } = useStyles()
	const organization = useSelector(state => state.organization)
	const cards = cardItems(organization)
	const isMobile = useIsMobile()

	return (
		<Grid container className={classes.root}>
			<Grid container>
				<Typography variant="h3" className={classes.title}> Perfil </Typography>
			</Grid>
			<Grid container spacing={!isMobile ? 4 : 2} className={classes.cardsContainer}>
				{cards.map((item, index) => (
					<MainSmallCard
						key={index}
						Icon={item.image}
						title={item.title}
						description={item.description}
						actionElement={(
							<Link to={item.linkTo}>
								<Button
									variant="text"
									color="primary"
									onClick={() => { }}
									className={classes.button}
								>
									{item.linkTitle}
								</Button>
							</Link>
						)}
						rootGridProps={{
							item: true,
							container: true,
							xs: 6,
							lg: 3,
						}}
					/>
				))}
			</Grid>
		</Grid>
	)
}