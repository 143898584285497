import { Typography, Grid, GridProps, Button } from "@mui/material";
import { StageStepper } from "src/pages/Summary/Main/StageStepper";
import { ApplicationResponseCard } from "../Card";
import { useStyles } from "./styles";
import _ from "lodash";

import { RootState } from "src/store";
import { useSelector } from "react-redux";
import { Link, Loading } from "src/components";
import { STAGE_STATUS } from "src/helpers";

export function NextStepsCard(props: GridProps) {
    const { classes, cx } = useStyles();
    const { application, isLoading } = useSelector((state: RootState) => state.applications);
    const currentApplicationWorkflowStage = application.applicationWorkflowStages?.find(stage => stage.stage_id === application.current_stage?.id)

    const continueAction: { page: string, label: string, url: string } = (
        application.status !== 'cancelled' &&
        currentApplicationWorkflowStage?.status !== STAGE_STATUS.IN_REVIEW &&
        currentApplicationWorkflowStage?.visibility == 'customer'
    ) ? {
        page: 'application',
        label: 'Continuar mi solicitud',
        url: `/application/${application.id}/${application.current_stage?.slug}/${application?.current_section?.slug}?from=summary`,
    } : {
        page: 'summary',
        label: 'Volver al inicio',
        url: `/home/main`
    }

    return (
        <ApplicationResponseCard title="¿Cuáles son los siguientes pasos?" {...props}>
            <Grid container className={cx(classes.card, classes.stages)}>
                <Grid item xs={12}>
                    <Typography variant="h3"> Etapas de solicitud </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Loading isLoading={isLoading} variant="skeleton" skeletonsNumber={1}>
                        <StageStepper
                            activeStep={currentApplicationWorkflowStage?.sort_order || 0}
                            stages={_.sortBy(application?.applicationWorkflowStages || [], 'sort_order')}
                            currentStage={currentApplicationWorkflowStage}
                        />
                    </Loading>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
                {!isLoading && (
                    <Link to={continueAction.url}>
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                        >
                            {continueAction.label}
                        </Button>
                    </Link>
                )}
            </Grid>
        </ApplicationResponseCard>
    )
}