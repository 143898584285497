import {
    Action,
    Attachment,
    AttachmentParamsType,
    MetaDataType,
} from "src/types";
import { ATTACHMENTS_TYPES } from "./types";

export interface AttachmentsStateType {
    attachment?: Attachment
    data?: Attachment[]
    isLoading: boolean
    isLoadingOne: boolean
    isUpdating: boolean
    isDownloading: boolean
    meta?: MetaDataType
    search?: AttachmentParamsType
}

const INITIAL_STATE: AttachmentsStateType = {
    isLoading: false,
    isLoadingOne: false,
    isUpdating: false,
    isDownloading: false,
    attachment: {},
    meta: {
        page: 1,
        perPage: 100,
        total: 0,
        totalPages: 0
    },
    search: {}
}

export function attachmentsReducer(state: AttachmentsStateType = INITIAL_STATE, action: Action): AttachmentsStateType {
    const { type, payload } = action;
    switch (type) {

        case ATTACHMENTS_TYPES.CREATE: {
            return {
                ...state,
            }
        }

        case ATTACHMENTS_TYPES.UPDATE: {
            return {
                ...state,
                isUpdating: true,
            }
        }

        case ATTACHMENTS_TYPES.GET_ALL: {
            const { search, ...rest } = payload?.params || {}
            return {
                ...state,
                isLoading: true,
                meta: rest || state.meta,
                search: search || state.search
            }
        }

        case ATTACHMENTS_TYPES.SET_ALL: {
            return {
                ...state,
                data: payload?.attachments,
                isLoading: false,
            }
        }

        case ATTACHMENTS_TYPES.SET_ONE: {
            return {
                ...state,
                attachment: payload?.attachment,
                isLoadingOne: false,
                isUpdating: false
            }
        }

        case ATTACHMENTS_TYPES.DOWNLOAD: {
            return {
                ...state,
                isDownloading: true
            }
        }

        default:
            return state;
    }
}