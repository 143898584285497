import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 218,
    },
    button: {
        margin: '0 auto',
        marginBottom: 42
    },
    icon: {
    },
    text: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '18px',
        textAlign: 'center',
    }
}))