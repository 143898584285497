import { ConfirmModal } from "src/components"

import {
    CreditProposalStatusType,
    confirmationModalAction,
    confirmationModalDescription,
    confirmationModalTitle
} from "./helpers"

import { useDispatch } from "react-redux"
import { creditProposalsActions } from "src/store/creditProposals/actions"

interface StatusConfirmationProps {
    open: boolean,
    proposalId?: string
    applicationId?: string
    newStatus?: CreditProposalStatusType
    handleConfirm: () => void
    handleCancel: () => void
    from?: 'list' | 'details'
}

export function StatusConfirmation({
    open,
    applicationId,
    proposalId,
    newStatus,
    handleConfirm,
    handleCancel,
    from
}: StatusConfirmationProps) {
    const dispatch = useDispatch()
    return (
        <ConfirmModal
            open={open}
            title={confirmationModalTitle[newStatus || '']}
            description={confirmationModalDescription[newStatus || '']}
            confirmText={confirmationModalAction[newStatus || '']}
            onConfirm={() => {
                dispatch(creditProposalsActions.updateStatus(
                    applicationId!,
                    proposalId!,
                    newStatus!,
                    from
                ))
                handleConfirm()
            }}
            onCancel={() => handleCancel()}
        />
    )
}