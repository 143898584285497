export const PROVIDERS_TYPES = {
    GET_APPLICATION_CREDIT_REPORTS: "PROVIDERS/GET_APPLICATION_CREDIT_REPORTS",
    SET_APPLICATION_CREDIT_REPORTS: "PROVIDERS/SET_APPLICATION_CREDIT_REPORTS",
    GET_CONTRACT_DETAILS: "PROVIDERS/GET_CONTRACT_DETAILS",
    SET_CONTRACT_DETAILS: "PROVIDERS/SET_CONTRACT_DETAILS",
    CLEAN_WEESIGN: "PROVIDERS/CLEAN_WEESIGN",

    GET_MATI_DATA: "PROVIDERSS/GET_MATI_DATA",
    SET_MATI_DATA: "PROVIDERSS/SET_MATI_DATA",

    GET_BELVO_TOKEN: "PROVIDERSS/GET_BELVO_TOKEN",
    SET_BELVO_DATA: "PROVIDERSS/SET_BELVO_DATA",
    SEND_BELVO_DATA: "PROVIDERSS/SEND_BELVO_DATA",
    GET_BELVO_CREDENTIALS: "PROVIDERS/GET_BELVO_CREDENTIALS",
    SET_BELVO_CREDENTIALS: "PROVIDERS/SET_BELVO_CREDENTIALS",
    GET_BELVO_ACCOUNTS: "PROVIDERSS/GET_BELVO_ACCOUNTS",
    SET_BELVO_ACCOUNTS: "PROVIDERSS/SET_BELVO_ACCOUNTS",
    DESTROY_BELVO_CREDENTIAL: "PROVIDERS/DESTROY_BELVO_CREDENTIAL",
    CLEAN_BELVO: "PROVIDERS/CLEAN_BELVO",

    GET_COUNTRIES: "PROVIDERSS/GET_COUNTRIES",
    SET_COUNTRIES: "PROVIDERSS/SET_COUNTRIES",
    GET_STATES: "PROVIDERSS/GET_STATES",
    SET_STATES: "PROVIDERSS/SET_STATES",
    GET_MUNICIPALITIES: "PROVIDERSS/GET_MUNICIPALITIES",
    SET_MUNICIPALITIES: "PROVIDERSS/SET_MUNICIPALITIES",

    GET_ASSOCIATIONS: "PROVIDERSS/GET_ASSOCIATIONS",
    SET_ASSOCIATIONS: "PROVIDERSS/SET_ASSOCIATIONS",
    CREATE_ASSOCIATION: "PROVIDERSS/CREATE_ASSOCIATION",
    UPDATE_ASSOCIATION: "PROVIDERSS/UPDATE_ASSOCIATION",
    DESTROY_ASSOCIATION: "PROVIDERSS/DESTROY_ASSOCIATION",

    GET_BANKS: "PROVIDERS/GET_BANKS",
    SET_BANKS: "PROVIDERS/SET_BANKS",

    GET_DOCUMENT_TYPES: "PROVIDERS/GET_DOCUMENT_TYPES",
    SET_DOCUMENT_TYPES: "PROVIDERS/SET_DOCUMENT_TYPES",

    GET_INCODE_VERIFICATION_FLOWS: "PROVIDERS/GET_INCODE_VERIFICATION_FLOWS",
    SET_INCODE_VERIFICATION_FLOWS: "PROVIDERS/SET_INCODE_VERIFICATION_FLOWS",
    GET_INCODE_VERIFICATION_FLOW: "PROVIDERS/GET_INCODE_VERIFICATION_FLOW",
    SET_INCODE_VERIFICATION_FLOW: "PROVIDERS/SET_INCODE_VERIFICATION_FLOS",
    GET_INCODE_DATA: "PROVIDERS/GET_INCODE_DATA",
    SET_INCODE_DATA: "PROVIDERS/SET_INCODE_DATA",
    GET_INCODE_SESSION: "PROVIDERS/GET_INCODE_SESSION",
    SET_INCODE_SESSION: "PROVIDERS/SET_INCODE_SESSION",
    INCODE_UPSERT: "PROVIDERS/INCODE_UPSERT",

    KREDI_UPSERT: "PROVIDERS/KREDI_UPSERT",
}