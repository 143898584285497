import { call, put, takeLatest } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { Action, Response } from "src/types";

import { paymentsActions } from "./actions";
import { PAYMENT_TYPES } from "./types";

const paymentService = new Service('payments', { api: 'kronos', namespace: 'customer' })

function* getOne({ payload }: Action) {
    const response: Response = yield call(paymentService.findOne, payload.id)
    yield put(paymentsActions.setOne(response.data))
}

export function* paymentsSagas() {
    yield takeLatest(PAYMENT_TYPES.GET_ONE, sagaWrapper(getOne, paymentsActions.setOne()))
}