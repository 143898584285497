import { APPLICATION_CATEGORIES_TYPES } from "./types"

function getAll(options?: any) {
    return {
        type: APPLICATION_CATEGORIES_TYPES.GET_ALL,
        payload: { ...options }
    }
}

function setAll(categories?: any[]) {
    return {
        type: APPLICATION_CATEGORIES_TYPES.SET_ALL,
        payload: { categories }
    }
}

function create(applicationId: string, sub_category_id?: string, type?: 'list' | 'single') {
    return {
        type: APPLICATION_CATEGORIES_TYPES.CREATE,
        payload: { applicationId, sub_category_id, type }
    }
}
export const applicationCategoriesActions = {
    getAll,
    setAll,
    create,
}