import { DirectDebitType, GetOptionsType } from "src/types"
import { DIRECTS_DEBITS_TYPES } from "./types"

function getAll(creditId: string, options?: GetOptionsType) {
    return {
        type: DIRECTS_DEBITS_TYPES.GET_ALL,
        payload: { options, creditId }
    }
}

function setAll(directsDebits?: any[]) {
    return {
        type: DIRECTS_DEBITS_TYPES.SET_ALL,
        payload: { directsDebits }
    }
}

function getOne(id: string) {
    return {
        type: DIRECTS_DEBITS_TYPES.GET_ONE,
        payload: { id }
    }
}

function setOne(directDebit?: any) {
    return {
        type: DIRECTS_DEBITS_TYPES.SET_ONE,
        payload: { directDebit }
    }
}

function create(creditId: string, directDebit: DirectDebitType) {
    return {
        type: DIRECTS_DEBITS_TYPES.CREATE,
        payload: { creditId, directDebit }
    }
}

function update(creditId: string, id: string, directDebit: DirectDebitType) {
    return {
        type: DIRECTS_DEBITS_TYPES.UPDATE,
        payload: { id, directDebit, creditId }
    }
}

function destroy(creditId: string, id: string,) {
    return {
        type: DIRECTS_DEBITS_TYPES.DESTROY,
        payload: { id, creditId }
    }
}

export const directsDebitsActions = {
    getAll,
    setAll,
    getOne,
    setOne,
    create,
    update,
    destroy
}