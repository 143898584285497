import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	titleContainer: {
		padding: '24px 32px 10px',
		paddingLeft: '18px',
		paddingRight: '21px',
		'& > h3': {
			color: '#242C37',
			fontSize: 24,
			fontWeight: 'bold'
		},
		'& svg': {
			fill: '#8892A0',
			height: 28,
			width: 28
		}
	},
	container: {
		padding: '37px 18px',
		paddingRight: '21px',
		overflowY: 'auto',
		height: '100%',
	},
	itemsContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: 'space-between',
		'& > div': {
			display: "flex",
			flexDirection: "column",
			'& button': {
				marginBottom: 14
			},
			'& svg, & svg path': {
				fill: '#B4BAC3',
			}
		}
	},
	paper: {
		width: "80%",
		[theme.breakpoints.between('sm', 'md')]: {
			width: '35%',
		}
	},
	logout: {
		// color: '#8892A0',
		fontWeight: 400
	},
	anchorHome: {
		textDecoration: 'none'
	},
	button: {
		'& svg': {
			fill: '#B4BAC3',
			height: 16,
			width: 16,
			marginRight: 8,
		},
	}
}));