import { Box, useTheme } from "@mui/material"
import { DocumentPercentBox } from "./PercentBox";
import { useStyles } from "./styles"
// @ts-ignore
import tinycolor from "tinycolor2";

interface DocumentsBarProps {
    totals: {
        finished: number,
        rejected: number,
        inReview: number,
        pending: number
    }
}

export function DocumentsPercentBar({ totals }: DocumentsBarProps) {
    const { classes, cx } = useStyles()
    const theme = useTheme()

    const { finished, rejected, inReview, pending } = totals
    const documentsTotal = finished + rejected + inReview + pending

    const finishedPercent = (finished / documentsTotal) * 100
    const rejectedPercent = (rejected / documentsTotal) * 100
    const inReviewPercent = (inReview / documentsTotal) * 100
    const pendingPercent = (pending / documentsTotal) * 100

    return (
        <Box className={classes.root}>

            <DocumentPercentBox
                tooltip={`${finished} ${finished > 1 ? 'documentos aprobados' : 'documento aprobado'}`}
                total={finished}
                percent={finishedPercent}
                bgColor={theme.palette.primary.main}
            />

            <DocumentPercentBox
                tooltip={`${inReview} ${inReview > 1 ? 'documentos' : 'documento'} en revisión`}
                total={inReview}
                percent={inReviewPercent}
                bgColor={tinycolor(theme.palette.primary.main).setAlpha(0.5)}
            />

            <DocumentPercentBox
                tooltip={`${rejected} ${rejected > 1 ? 'documentos rechazados' : 'documento rechazado'}`}
                total={rejected}
                percent={rejectedPercent}
                bgColor="#F44336"
            />

            <DocumentPercentBox
                tooltip={`${pending} ${pending > 1 ? 'documentos pendientes' : 'documento pendiente'}`}
                total={pending}
                percent={pendingPercent}
                bgColor="#D9D9D9"
            />

        </Box>
    )
}