export const CREDIT_PROPOSAL_TYPES = {
    SET: 'CREDIT_PROPOSAL/SET',
    GET_ONE: 'CREDIT_PROPOSAL/GET_ONE',
    SET_ONE: 'CREDIT_PROPOSAL/SET_ONE',
    GET_ALL: 'CREDIT_PROPOSAL/GET_ALL',
    SET_ALL: 'CREDIT_PROPOSAL/SET_ALL',
    DOWNLOAD: 'CREDIT_PROPOSAL/DOWNLOAD',
    SEND_EMAIL: 'CREDIT_PROPOSAL/SEND_EMAIL',
    DOWNLOAD_AMORTIZATION_TABLE: 'CREDIT_PROPOSAL/DOWNLOAD_AMORTIZATION_TABLE',
    UPDATE_STATUS: 'CREDIT_PROPOSAL/UPDATE_STATUS',
}