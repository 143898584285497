import { Close } from "@mui/icons-material"
import { Box, Grid, Typography } from "@mui/material"
import { ErrorOutline as ErrorIcon } from "@mui/icons-material"
import { CustomCriteria } from "../helpers"
import { useStyles } from "./styles"

interface DocumentRejectedCommentsProps {
    rejectedComments?: CustomCriteria[]
}

export function DocumentRejectedComments({ rejectedComments }: DocumentRejectedCommentsProps) {
    const { classes, cx } = useStyles()

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    <ErrorIcon style={{ margin: '0px 12px' }} />
                    Documento rechazado
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {rejectedComments?.map((comment, index) => {
                        return (
                            <Grid key={index} item xs={12}>
                                <Box
                                    alignItems={'center'}
                                    display={'flex'}
                                    className={classes.rejectedComment}
                                >
                                    <Close style={{ marginRight: 15 }} />
                                    <Typography>
                                        {comment?.valueToShow || ''}
                                    </Typography>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}