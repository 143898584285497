import { CardItem } from "../Applications/Summary/smallCards";

import AccountStatusImg from "src/assets/img/credits/account_status.svg";
import AmortTableImg from "src/assets/img/credits/amort_table.svg";
import ContractsImg from "src/assets/img/credits/contracts.svg";
import FormalitiesImg from "src/assets/img/credits/formalities.svg";
import IncrementImg from "src/assets/img/credits/increment.svg";
import InterestConstImg from "src/assets/img/credits/interest_const.svg";
import ParticipantsImg from "src/assets/img/credits/participants.svg";
import PrepaidImg from "src/assets/img/credits/prepaid.svg";

export function getsmallCardsItems(creditId?: string): CardItem[] {
    return [
        {
            title: 'Tabla de amortización',
            description: 'Consulta tu tabla y su historial de cambios.',
            linkTitle: 'Ir a tabla',
            linkTo: `/home/credit/${creditId}/amortization_table`,
            image: AmortTableImg
        },
        {
            title: 'Estados de cuenta',
            description: 'Descarga tus estados de cuenta.',
            linkTitle: 'Ir a estados de cuenta',
            linkTo: `/home/credit/${creditId}/bank_statements`,
            image: AccountStatusImg
        },
        // {
        //     title: 'Prepago',
        //     description: 'Simula abonos a capital.',
        //     linkTitle: 'Ir a prepago',
        //     linkTo: `/home/credit/${creditId}/capital_prepaid`,
        //     image: PrepaidImg
        // },
        {
            title: 'Participantes',
            description: 'Información de los participantes de tu crédito.',
            linkTitle: 'Ir a participantes',
            linkTo: `/home/credit/${creditId}/participants`,
            image: ParticipantsImg
        },
        // {
        //     title: 'Tramites y aclaraciones',
        //     description: 'Solicita un trámite o aclaración.',
        //     linkTitle: 'Ir a tramites',
        //     linkTo: `/home/credit/${creditId}/procedures`,
        //     image: FormalitiesImg
        // },
        // {
        //     title: 'Contratos',
        //     description: 'Consulta los contratos relacionados a tu crédito.',
        //     linkTitle: 'Ir a contratos',
        //     linkTo: `/home/credit/${creditId}/contracts`,
        //     image: ContractsImg
        // },
        // {
        //     title: 'Constancia de intereses',
        //     description: 'Descarga tu constancia de intereses.',
        //     linkTitle: 'Ir a constancia',
        //     linkTo: `/home/credit/${creditId}/interest_constancy`,
        //     image: InterestConstImg
        // },
        // {
        //     title: 'Carta de incremento',
        //     description: 'Descarga tu carta de incremento.',
        //     linkTitle: 'Ir a carta',
        //     linkTo: `/home/credit/${creditId}/increment_card`,
        //     image: IncrementImg
        // },
    ]
}