import { ApexOptions } from 'apexcharts'
import { fontFamilies } from "../../helpers"
import { currencyString } from 'src/helpers'

export const defaultChartOptions: ApexOptions = {
    series: [],
    chart: {
        height: 350,
        type: 'area',
        toolbar: {
            show: false
        }
    },
    legend: {
        show: false
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: 'straight',
        show: false
    },
    tooltip: {
        x: {
            formatter: (val: number, opts?: any) => `Mes ${val}`,
        },
        y: {
            formatter: (val: number, opts?: any) => currencyString(val),
        }
    },
    fill: {
        type: 'solid',
        opacity: .2
    },
    xaxis: {
        title: {
            text: 'Plazo en meses',
            style: {
                fontFamily: fontFamilies,
                fontWeight: 500,
                color: '#8892A0',
                fontSize: '12px'
            }
        },
        type: 'numeric',
        categories: [],
        tooltip: {
            enabled: false
        },
        labels: {
            formatter: (value: string, timestamp?: number, opts?: any) => parseInt(value || '0').toFixed(0),
            style: {
                fontFamily: fontFamilies,
                fontWeight: 600,
                fontSize: '14px',
                colors: '#242C37'
            }
        },
        crosshairs: {
            stroke: {
                color: '#242C37',
                width: 1,
                dashArray: 0
            },
            fill: {
                type: 'solid'
            },
        },

    },
    yaxis: [],
    //REMANENTE, PRINCIPAL, INTERES
    colors: ['#319BFF', '#1DAA6C', '#2CC1A6'],
    grid: {
        padding: {
            left: -32,
            right: -8
        }
    }
}