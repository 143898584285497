import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from "@mui/material";
import { useStyles } from "./Tooltip.styles";

interface TooltipProps extends MuiTooltipProps {
}

/** Use to show a message on hover children component */
export function Tooltip({ classes: tooltipClasses = {}, ...rest }: TooltipProps) {
    const { classes, cx } = useStyles();
    return (
        <MuiTooltip arrow classes={{ ...classes, ...tooltipClasses }} {...rest} />
    )
}