import { useEffect, useState } from "react";
import { Grid, Typography, Radio, RadioGroup, FormControlLabel, Button } from "@mui/material"
import { Loading, MessagePage } from "src/components";
import { useParams } from "react-router-dom";
import { isValidId, useIsMobile } from "src/helpers";

import CancelApplicationImgSm from './cancel_img_sm.svg';
import CancelApplicationImgMd from './cancel_img_md.svg';
import CancelledIcon from "src/assets/img/application_cancelled.svg"

import { useDispatch, useSelector } from "react-redux";
import { applicationActions } from "src/store/application/actions";
import { useStyles } from "./styles";
import { applicationCategoriesActions } from "src/store/application/categories/actions";
import { ApplicationCategoryType } from "src/types";

export function ApplicationCancellation() {
    const { applicationId }: any = useParams();
    const dispatch = useDispatch();
    const { classes, cx } = useStyles()
    const isMobile = useIsMobile()

    const application = useSelector(state => state.applications.application)
    const isLoading = useSelector(state => state.workflow.isLoading)
    const canceledCategories = useSelector(state => state.applications.categories?.data || [])
    const subCategories: ApplicationCategoryType[] | undefined = canceledCategories.reduce((acc: any, val) => acc.concat(val.subCategories), [])
    const [subCategory, setSubCategory] = useState('')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubCategory(event.target.value);
    };

    useEffect(() => {
        if (isValidId(applicationId)) {
            dispatch(applicationActions.getApplication(applicationId))
        }
        dispatch(applicationCategoriesActions.getAll({
            search: {
                status_eq: 'cancelled'
            },
            perPage: 200
        }))
    }, [applicationId])

    return (
        <Loading isLoading={isLoading}>
            {application.status === 'cancelled' ? (
                <MessagePage
                    icon={CancelledIcon}
                    title="Tu solicitud ha sido cancelada"
                    message="Lamentamos que hayas cancelado tu solicitud. Te invitamos a aplicar de nuevo con Kredi muy pronto. ¡Muchas gracias!"
                    messageContainerProps={{ style: { maxWidth: 607 } }}
                    containerProps={{ style: { padding: '0px 16px' } }}
                />
            ) : (
                <Grid container alignItems="center" justifyContent="center" className={classes.root}>
                    <Grid container className={classes.card}>
                        <Grid item xs={12} md={5} className={classes.imgContainer}>
                            <Typography variant="h3"> Cancelar mi solicitud </Typography>
                            {isMobile ? <CancelApplicationImgSm /> : <CancelApplicationImgMd />}
                            <Typography>
                                Lamentamos que canceles tu solicitud, por favor indícanos el motivo de tu cancelación,
                                esto nos ayudará a ofrecerte un mejor servicio
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.radioContainer}>
                            <RadioGroup name="sub_category" value={subCategory} onChange={handleChange}>
                                {subCategories?.map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            value={item.id}
                                            control={<Radio />}
                                            label={item.name}
                                            className={classes.radio}
                                        />
                                    );
                                })}
                            </RadioGroup>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                disabled={!subCategory}
                                fullWidth={isMobile}
                                className={classes.button}
                                onClick={() => dispatch(applicationCategoriesActions.create(applicationId, subCategory, 'single'))}
                            >
                                Cancelar solicitud
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Loading>
    )
}