import { ComponentType, Fragment, forwardRef } from "react"
import { Button, Dialog, DialogProps, Grid, IconButton, Slide, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { TransitionProps } from '@mui/material/transitions'
import { useStyles } from "./MessageDialog.styles"
import _ from "lodash"

import { useSelector, useDispatch } from "react-redux"
import { messageActions } from "src/store/message/actions"

interface MessageDialogProps extends DialogProps {
    icon: ComponentType
    title: string
    description?: string
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    // @ts-ignore
    return <Slide direction="down" ref={ref} {...props} />;
});

export function MessageDialog() {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()
    const { open, icon, title, description, buttonProps } = useSelector(state => state.message)
    const Icon = _.isEmpty(icon) ? Fragment : icon

    function onClose() {
        dispatch(messageActions.clean())
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="sm"
        >
            <Grid container direction="column" className={classes.container}>
                <Grid item className={classes.closeButtonContainer}>
                    <IconButton size="small" onClick={onClose}>
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </Grid>
                <Grid item className={classes.item}>
                    {/*@ts-ignore*/}
                    <Icon />
                </Grid>
                <Grid item className={classes.item}>
                    <Typography variant="h3" className={classes.title}> {title} </Typography>
                </Grid>
                {description && (
                    <Grid item className={classes.item}>
                        <Typography dangerouslySetInnerHTML={{ __html: description || '' }} />
                    </Grid>
                )}
                {!_.isEmpty(buttonProps) && (
                    <Grid item className={classes.item}>
                        <Button
                            variant="contained"
                            color="primary"
                            {...buttonProps}
                            onClick={(event: any) => {
                                if (_.isFunction(buttonProps?.onClick)) {
                                    buttonProps?.onClick(event)
                                }
                                onClose()
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </Dialog>
    )
}