import { Fragment, ReactChild } from "react";
import { Grid, CircularProgress, Typography, GridProps } from "@mui/material";
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton";
import { useStyles } from "./Loading.styles";

export interface LoadingPageProps extends GridProps {
    isLoading: boolean;
    text?: string;
    children?: ReactChild | ReactChild[] | any;
    variant?: 'circular' | 'skeleton' | 'text';
    skeletonContainerProps?: GridProps;
    skeletonProps?: SkeletonProps;
    skeletonsNumber?: number;
    showSkeletonTitle?: boolean;
}

/** Show a variant loader if children component(s) are undefined or null */
export function Loading({
    text,
    isLoading,
    children,
    variant = 'circular',
    skeletonContainerProps,
    skeletonProps,
    skeletonsNumber,
    /** If is true show a header skeleton like as title header */
    showSkeletonTitle,
    ...rest
}: LoadingPageProps) {
    const { classes, cx } = useStyles();

    function getContent() {
        switch (variant) {

            case 'circular':
                return (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                        className={classes.container}
                        {...rest}
                    >
                        <CircularProgress color="primary" />
                        <Typography
                            className={classes.text}
                            dangerouslySetInnerHTML={{ __html: text || 'Estamos procesando tu información, esto puede tardar unos minutos...' }}
                        />
                    </Grid>
                )

            case 'skeleton':
                return (
                    <Grid container className={classes.skeletonsContainer} {...skeletonContainerProps} {...rest}>
                        {showSkeletonTitle && (
                            <Skeleton
                                variant="rectangular"
                                height={30}
                                width={250}
                                style={{ marginBottom: 60 }}
                                {...skeletonProps}
                            />
                        )}
                        {Array(skeletonsNumber || 4).fill(null).map((_, index) => (
                            <Skeleton
                                key={index}
                                variant="rectangular"
                                height={200}
                                width="100%"
                                style={{ marginBottom: 60 }}
                                {...skeletonProps}
                            />
                        ))}
                    </Grid>
                )

            case 'text':
                return (
                    <Grid container className={classes.skeletonsContainer} {...skeletonContainerProps} {...rest}>
                        {Array(skeletonsNumber || 1).fill(null).map((_, index) => (
                            <Skeleton
                                key={index}
                                variant="rectangular"
                                height={20}
                                width="100%"
                                style={{ marginBottom: skeletonsNumber ? 16 : 0 }}
                                {...skeletonProps}
                            />
                        ))}
                    </Grid>
                )

            default:
                return <Fragment />
        }
    }

    return isLoading ? getContent() : children
}