import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	emptyState: {
		paddingTop: 48
	},
	container: {
		width: '100%',
		maxWidth: 1440,
		padding: '60px 30px',
		['@media (max-width: 1023px)']: {
			padding: '32px 16px 65px'
		},
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center'
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& > h3': {
			color: theme.palette.primary.dark,
			fontSize: 34,
			fontWeight: 'bold'
		}
	},
	button: {
		padding: '8px 16px',
		fontSize: 14,
		height: 36,
		textTransform: 'none',
		letterSpacing: 0.5
	},
	item: {
		border: '1px solid #CFD5E2',
		boxShadow: ' 0px 5px 22px rgba(57, 74, 97, 0.12)',
		borderRadius: 8,
		marginBottom: 60,
	},
	title: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		padding: '18px 25px',
		background: 'rgba(117, 139, 183, 0.04)',
		borderBottom: '1px solid #CFD5E2',
		position: 'relative',
		'& > h3': {
			color: theme.palette.primary.dark,
			fontSize: 24,
			fontWeight: 'bold',
		},
		'& p': {
			color: '#8892A0',
			fontSize: 16,
			fontWeight: 400,
		},
		'& span': {
			color: '#242C37',
		},
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			padding: '18px 16px',
			'& > h3': {
				width: '100%',
				marginBottom: 6,
				fontSize: '18px'
			},
			'& p': {
				width: '100%',
				marginRigth: '10px'
			},
		}
	},
	headerCell: {
		color: '#242C37',
		fontSize: 16,
		fontWeight: 'bold',
	},
	applications: {
		paddingTop: 24
	},
	mobileContainer: {
		'& > div': {
			padding: 16,
			borderBottom: '1px solid #CFD5E2',
		},
		'& p': {
			fontSize: 14
		}
	},
	firstCell: {
		paddingLeft: 25,

	},
	progressLine: {
		[theme.breakpoints.down('md')]: {
			width: '40% !important',
			display: 'flex',
			alignItems: 'center',
			'& p': {
				width: 'auto',
				marginRight: '8px'
			}
		}
	},
	dateAndMenuContainer: {
		display: 'flex',
		gap: '20px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			justifyContent: 'flex-start'
		}
	},
	moreIconContainer: {
		"& svg": {
			color: "#394A61",

		},
		"& svg:hover": {
			cursor: 'pointer'
		},
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			top: 18,
			right: 16,
		},
	},
	menu: {
		'& .MuiList-padding': {
			padding: 0,
			'& a': {
				textDecoration: 'none'
			}
		}
	},
	menuItem: {
		fontSize: 18,
		color: '#242C37',
		fontWeight: 400,
		minHeight: 43,
		display: 'flex',
		justifyContent: 'center',
		'&:hover': {
			background: 'rgba(117, 139, 183, 0.08)'
		}
	},
	progressTitle: {
		display: 'inline'
	},
	statusContainer: {
		display: 'flex',
		gap: 16
	},
	pageTitle: {
		color: theme.palette.primary.dark,
		fontSize: 34,
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			fontSize: 24
		}
	},
	applicationsList: {
		marginTop: 32,
	}
}));