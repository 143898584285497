
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Grid, GridProps, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import { EmailOutlined as EmailIcon, PhoneIphoneOutlined as PhoneIcon } from "@mui/icons-material";
import { Link } from "src/components";
import { ApplicationResponseCard } from "../Card";
import { formatPhone, getWhatsAppLinkWithMessage, initialsFor } from "src/helpers";
import { applicationActions } from "src/store/application/actions";
import { useStyles } from "./styles";

interface ConsultantCardProps extends GridProps {
	text?: string;
	/** if is lastOne get latest application */
	applicationOrigin?: 'lastOne' | 'current',
	/** if is true get request lastest application */
	requestLastOneApplication?: boolean;
	/** Show a loader during 9s */
	showDelayedLoader?: boolean
}

export function ConsultantCard({ text, applicationOrigin = 'lastOne', requestLastOneApplication, showDelayedLoader, ...rest }: ConsultantCardProps) {
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const user = useSelector(state => state.auth.customer.user);
	const application = useSelector(state => state.applications.application);
	const lastOneApplication = useSelector(state => state.applications.lastApplication);
	const isLoading = useSelector(state => state.applications.isLoading);
	const isAuthLoading = useSelector(state => state.customer.isLoading)
	const [delayed, setDelayed] = useState(showDelayedLoader)

	useEffect(() => {
		if (applicationOrigin === 'lastOne' && requestLastOneApplication) {
			dispatch(applicationActions.getLastOneApplication());
		}
	}, []);

	useEffect(() => {
		if (showDelayedLoader) {
			setDelayed(true)
			setTimeout(() => {
				setDelayed(false)
			}, 9000)
		}
	}, [showDelayedLoader])

	return (
		<ApplicationResponseCard
			title={text || "¿Dudas? Agenda tu asesoría."}
			className={classes.card}
			{...rest}
		>

			{isLoading || isAuthLoading || delayed ? (
				<Grid container item xs={12} className={classes.imageAndText}>
					<Skeleton variant="circular" width={90} height={90} className={classes.skeleton} />
					<div className={classes.dataContainer}>
						<div className={classes.data}>
							<Skeleton variant="text" width={500} className={classes.skeleton} />
							<Skeleton variant="text" width={500} className={classes.skeleton} />
						</div>
						<div className={classes.contactData}>
							<Skeleton variant="text" width={300} className={classes.skeleton} />
						</div>
					</div>
				</Grid>
			) : (
				<Grid container item xs={12} className={classes.imageAndText}>
					<Avatar
						color="primary"
						className={classes.avatar}
						src={user?.profile_image}
					>
						{initialsFor(`${user?.first_name} ${user?.last_name}`)}
					</Avatar>
					<div className={classes.dataContainer}>
						<div className={classes.data}>
							<Typography variant="h3"> {user?.first_name || ''} {user?.last_name || ''} </Typography>
							<Typography> — ¡Hola! Estoy para resolver tus preguntas, por favor no dudes en contactarme. </Typography>
						</div>
						<div className={classes.contactData}>
							{user?.email && <Typography> <EmailIcon /> {user?.email} </Typography>}
							{user?.phone && <Typography> <PhoneIcon /> {formatPhone(user?.phone)} </Typography>}
						</div>
					</div>
				</Grid>
			)}

			{!isLoading && !isAuthLoading && !delayed && (
				<Grid item xs={12} className={classes.itemActions}>
					{user?.calendly_user && (
						<Link to='/home/consultant/schedule'>
							<Button
								color="primary"
								variant="text"
								size="small"
							>
								Agendar asesoría
							</Button>
						</Link>
					)}
					{user?.phone && (
						<a
							href={getWhatsAppLinkWithMessage({
								user,
								application: applicationOrigin === 'lastOne' ? lastOneApplication : application,
								origin: applicationOrigin
							})}
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: 'none' }}
						>
							<Button
								color="inherit"
								variant="text"
								size="small"
							>
								Enviar WhatsApp
							</Button>
						</a>
					)}
				</Grid>
			)}

		</ApplicationResponseCard>
	)
}