import { useEffect } from 'react';
import { StagePage, EmptyState, Link, Loading } from 'src/components';
import { Button, Grid, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from 'react-redux';
import { customerActions } from 'src/store/customer/actions';
import { currencyString, getEmploymentType } from 'src/helpers';
import { useIsMobile, segment } from 'src/helpers';
import { Add } from '@mui/icons-material';

export function Employments() {
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const { employments, isLoading } = useSelector(state => state.customer);
	const getEmployments = () => dispatch(customerActions.getEmployments());
	const isMobile = useIsMobile()

	useEffect(() => {
		getEmployments();
		segment.track('Customer', 'Viewed', 'Employments')
	}, [])

	return (
		<StagePage
			title="Empleos"
			className={classes.container}
			containerProps={{ md: 12 }}
			breadcrumbs={[
				{
					name: "Perfil",
					to: "/home/profile"
				},
				{
					name: "Empleos",
					to: ""
				},
			]}
		>
			<Loading isLoading={isLoading}>
				{employments?.length === 0 ? (
					<EmptyState text="No tienes empleos registrados" className={classes.emptyState} />
				) : (
					<>
						<Grid container className={classes.employments}>
							{employments?.map(employment => (

								<Grid key={employment.id} item xs={12} className={classes.item}>
									<Grid container>
										<Typography variant="h3" className={classes.employmentTitle}>{getEmploymentType(employment.salary_scheme)}</Typography>
									</Grid>
									<Grid container justifyContent="space-between" alignItems="center">
										<Typography>Ingreso neto {currencyString(employment.net_salary || 0)}</Typography>
										{!isMobile && <Link to={'/home/profile/employments/' + employment.id}>
											<Button
												color="primary"
											>
												Editar
											</Button>
										</Link>}
									</Grid>
									<Grid container justifyContent="flex-end">
										{isMobile && <Link to={'/home/profile/employments/' + employment.id}>
											<Button color="primary">
												Editar
											</Button>
										</Link>}
									</Grid>

								</Grid>


							))}
						</Grid>
						<Grid container>
							<Link to="/home/profile/employments/new">
								<Button
									variant="contained"
									color="primary"
									startIcon={<Add />}
									className={classes.button}
								>
									Agregar
								</Button>
							</Link>
						</Grid>
					</>
				)}
			</Loading>
		</StagePage>
	)
}