import { Button, Divider, Grid, Typography } from "@mui/material"
import { EmptyState } from "src/components"
import { MenuActionProps, SectionCard, SectionCardProps } from "./Card"
import { MainAction, MainActionProps } from "./MainAction"

import { useStyles } from "./styles"

export * from "./Card"

export interface DocumentsSectionProps {
    title?: string
    action?: JSX.Element
    addButtonProps?: MainActionProps
    items?: SectionCardProps[]
    hideAddButtonOnEmptyCards?: boolean
    sectionCardProps?: {
        actions?: MenuActionProps[]
    }
}

export function DocumentsSection({
    title,
    action,
    items = [],
    addButtonProps,
    sectionCardProps,
    hideAddButtonOnEmptyCards
}: DocumentsSectionProps) {
    const { classes, cx } = useStyles()

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Grid
                    container
                    className={cx(classes.titleContainer, { ['flex']: Boolean(action) })}
                >
                    <Grid item>
                        <Typography className={classes.title}> {title} </Typography>
                    </Grid>

                    {action && (
                        <Grid item> {action} </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={3}>

                    {items.length <= 0 && (
                        <Grid item xs={12} lg={3}>
                            <EmptyState
                                text="No hay documentos por subir"
                                size="small"
                                className={cx(classes.emptyState, { ['withoutChildren']: hideAddButtonOnEmptyCards })}
                                containerProps={{
                                    style: { textAlign: 'center' }
                                }}
                            >
                                {hideAddButtonOnEmptyCards ? undefined : (
                                    <Button
                                        variant="text"
                                        color="primary"
                                        disabled={addButtonProps?.disabled}
                                        onClick={() => {
                                            if (addButtonProps?.onClick) {
                                                addButtonProps.onClick()
                                            }
                                        }}
                                    >
                                        {addButtonProps?.text || 'Agregar nuevo'}
                                    </Button>
                                )}
                            </EmptyState>
                        </Grid>
                    )}

                    {items.map((item, index) => {
                        return (
                            <Grid key={index} item xs={12} lg={3}>
                                <SectionCard
                                    {...item}
                                    menuActions={sectionCardProps?.actions}
                                />
                            </Grid>
                        )
                    })}

                    {items.length > 0 && addButtonProps?.show && (
                        <Grid item xs={12} lg={3}>
                            <MainAction {...addButtonProps} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}