import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	root: {
		border: "1px solid #CFD5E2",
		borderRadius: 8,
		padding: 24,
		backgroundColor: 'white'
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 21,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse'

		}
	},
	image: {
		width: 127,
		height: 91,
		[theme.breakpoints.down('sm')]: {
			width: 91,

		}
	},
	title: {
		fontSize: 24,
		color: theme.palette.primary.dark,
		fontWeight: 'bold'
	},
	details: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			alignItems: 'flex-start',
			flexDirection: 'column',
			"& p": {
				marginBottom: 8,
			}
		}
	},
	dividerDetails: {
		paddingRight: 16,
		borderRight: "1px solid #CFD5E2",
		fontSize: 14,
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0,
			borderRight: "none",

		}
	},
	actions: {
		alignItems: 'center',
		display: 'flex',
		gap: 38,
		[theme.breakpoints.down('sm')]: {
			marginTop: 24,
			width: '100%',
			justifyContent: 'space-between'
		}
	},
	statusChip: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 15,
		padding: '4px 12px',
		maxWidth: 110,
		fontSize: 14

	},
	status: {
		display: 'flex',
		gap: 4,
		alignItems: 'center',
		paddingLeft: 16,
		fontSize: 14,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
		},
	},
	stepperTitle: {
		fontSize: 24,
		fontWeight: "bold",
		color: theme.palette.primary.dark
	},
	stepperDivider: {
		borderTop: "1px solid #CFD5E2",
		width: "100%",
		paddingTop: 24,
	},
	stepperMargin: {
		marginTop: 24,

	},
	collapse: {
		width: "100%",
	},
	stepperButton: {
		'& .MuiTouchRipple-root': {
			display: 'none'
		},
		'& svg': {
			transition: '0.3s',
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
		}
	},
	stepperOpen: {
		'& svg': {
			transform: 'rotate(-180deg)'
		}
	},
	chip: {
		height: 24,
		marginLeft: 16,
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0
		}
	},
	rejectedDocumentsChip: {
		cursor: 'pointer',
		color: '#F44336',
		background: 'rgba(244, 67, 54, 0.1)',
		'&:hover': {
			background: 'rgba(244, 67, 54, 0.2)',
		},
		'& svg': {
			color: '#FF782D',
			height: 16,
			'&:hover': {
				color: '#FF782D',
			}
		}
	}
}))