import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	container: {
		padding: '45px 76px 45px 30px',
		[theme.breakpoints.down('sm')]: {
			padding: '32px 16px 78px'
		}
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center'
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '8px 24px',
		background: 'rgba(117, 139, 183, 0.04)',
		borderBottom: '1px solid #CFD5E2',
		'& > h3': {
			color: theme.palette.primary.dark,
			fontSize: 24,
			fontWeight: 'bold',
			letterSpacing: '0.19px'
		},
		'& p': {
			color: '#8892A0',
			fontSize: 16,
			fontWeight: 400,
			letterSpacing: '0.5px'
		},
		[theme.breakpoints.down('md')]: {
			'& > h3': {
				fontSize: 20,
			},
		}
	},
	button: {
		padding: '8px 30px',
		fontSize: 14,
		height: 36,
	},
	item: {
		border: '1px solid #CFD5E2',
		boxShadow: ' 0px 5px 22px rgba(57, 74, 97, 0.12)',
		borderRadius: 8,
		marginBottom: 30,
		padding: '24px'

	},
	row: {
		'& > div': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start'
		},
		/* [theme.breakpoints.down('sm')]:{
			'& > div': {
				display: 'grid',
				gridTemplateColumns: '50% 50%',
				gridTemplateRows: '50% 50%',
			},
		} */
	},
	cell: {
		display: 'flex',
		flexDirection: 'column',
		'& > strong': {
			color: '#242C37'
		},
		'& > span': {
			color: '#8892A0'
		}
	},
	emptyState: {
		paddingTop: 75
	},
	employments: {
		paddingTop: 40
	},
	employmentTitle: {
		fontSize: 20,
		lineHeight: '24px',
		fontWeight: 'bold',
	}
}));