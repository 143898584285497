import { Address, Customer, CustomerEmploymentQueryParamsType, Employment, GetOptionsType } from "src/types";
import { CustomerStateType } from "./reducer";
import { CUSTOMER_TYPES } from "./types";

function set(key: keyof CustomerStateType, value: any) {
    return {
        type: CUSTOMER_TYPES.SET,
        payload: { key, value }
    }
}

function update(customer: Customer) {
    return {
        type: CUSTOMER_TYPES.UPDATE,
        payload: { customer }
    }
}

// EMPLOYMENTS

function getEmployments(options?: GetOptionsType<CustomerEmploymentQueryParamsType>) {
    return {
        type: CUSTOMER_TYPES.GET_EMPLOYMENTS,
        payload: { ...options }
    }
}

function setEmployments(employments: Employment[]) {
    return {
        type: CUSTOMER_TYPES.SET_EMPLOYMENTS,
        payload: { employments }
    }
}

function getEmployment(id: string) {
    return {
        type: CUSTOMER_TYPES.GET_EMPLOYMENT,
        payload: { id }
    }
}

function setEmployment(employment: Employment) {
    return {
        type: CUSTOMER_TYPES.SET_EMPLOYMENT,
        payload: { employment }
    }
}

function createEmployment(employment: Employment, notRedirect?: boolean) {
    return {
        type: CUSTOMER_TYPES.CREATE_EMPLOYMENT,
        payload: { employment, notRedirect }
    }
}

function updateEmployment(employment: Employment, notRedirect?: boolean) {
    return {
        type: CUSTOMER_TYPES.UPDATE_EMPLOYMENT,
        payload: { employment, notRedirect }
    }
}

function destroyEmployment(id: string, notRedirect?: boolean) {
    return {
        type: CUSTOMER_TYPES.DESTROY_EMPLOYMENT,
        payload: { id, notRedirect }
    }
}

function executeEmploymentEvaluators(employment: Employment, applicationId: string, isNew?: boolean, isDelete?: boolean) {
    return {
        type: CUSTOMER_TYPES.EXECUTE_EMPLOYMENT_EVALUATORS,
        payload: { employment, applicationId, isNew, isDelete }
    }
}

// ADDRESSES

function getAddresses() {
    return {
        type: CUSTOMER_TYPES.GET_ADDRESSES
    }
}

function setAddresses(addresses: Address[]) {
    return {
        type: CUSTOMER_TYPES.SET_ADDRESSES,
        payload: { addresses }
    }
}

function getAddress(id: string) {
    return {
        type: CUSTOMER_TYPES.GET_ADDRESS,
        payload: { id }
    }
}

function setAddress(address: Address) {
    return {
        type: CUSTOMER_TYPES.SET_ADDRESS,
        payload: { address }
    }
}

function createAddress(address: Address) {
    return {
        type: CUSTOMER_TYPES.CREATE_ADDRESS,
        payload: { address }
    }
}

function updateAddress(address: Address) {
    return {
        type: CUSTOMER_TYPES.UPDATE_ADDRESS,
        payload: { address }
    }
}

function destroyAddress(id: string) {
    return {
        type: CUSTOMER_TYPES.DESTROY_ADDRESS,
        payload: { id }
    }
}

function hasPendingApplication() {
    return {
        type: CUSTOMER_TYPES.HAS_PENDING_APPLICATION,
    }
}

function setHasPendingApplication(value: boolean) {
    return {
        type: CUSTOMER_TYPES.SET_HAS_PENDING_APPLICATION,
        payload: { value }
    }
}

export const customerActions = {
    set,
    update,

    getEmployments,
    setEmployments,
    getEmployment,
    setEmployment,
    createEmployment,
    updateEmployment,
    destroyEmployment,
    executeEmploymentEvaluators,

    getAddresses,
    setAddresses,
    getAddress,
    setAddress,
    createAddress,
    updateAddress,
    destroyAddress,

    hasPendingApplication,
    setHasPendingApplication
}