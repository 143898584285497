import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        padding: '24px 32px !important',
        [theme.breakpoints.down('sm')]: {
            padding: '24px 16px !important',
        }
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 14,
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            '& > :first-child': {
                marginRight: 0
            }
        },
    },
    iconButton: {
        '& path': {
            fill: '#242C37'
        }
    },
    button: {
        color: '#242C37',
        fontSize: 14,
        fontWeight: 700,
        '& path': {
            fill: '#242C37'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    },
    disabledButton: {
        '& path': {
            fill: 'rgba(0, 0, 0, 0.26)'
        },
    }
}))