import { useResolvedPath } from "react-router-dom"

/**
 * Replaces the useRouteMatch hook from react-router-dom v5
 * @returns 
 */
export function useRouteMatch() {
    const match = useResolvedPath("")

    return {
        url: match.pathname,
        path: match.pathname,
    }
}