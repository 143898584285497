import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    title: {
        color: '#F44336',
        display: 'flex',
        fontSize: 16,
        fontWeight: 700,
    },
    infoMarkdown: {
        color: '#F44336',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.25px',
        '& .mde-preview-content': {
            padding: 0,
        },
        '& .mde-preview-content strong': {
            fontSize: 14,
            color: theme.palette.primary.main,
            lineHeight: '20px',
            letterSpacing: '0.25px',
        },
        '& .mde-preview-content p': {
            marginBottom: 8,
        },
        '& .mde-preview-content ul': {
            paddingLeft: '1em'
        },
        '& .mde-preview-content ul li': {
            fontSize: 14,
            lineHeight: '20px',
            letterSpacing: '0.25px',
        },
        '& .mde-preview-content ul li::marker': {
            color: '#F44336',
        },
        '& p': {
        },
        [theme.breakpoints.down('sm')]: {
        }
    },
    rejectedComment: {
        backgroundColor: '#FFF1F1',
        borderRadius: 8,
        padding: '6px 13px',
        '& p': {
            fontSize: 14,
            fontWeight: 700,
            lineHeigh: '16px',
        },
        '& svg path': {
            fill: '#F44336'
        }
    }
}))