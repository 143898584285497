import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    viewer: {
        '& .viewer-layout-container': {
            border: '1px solid #CFD5E2',
        },
        '& .viewer-layout-toolbar': {
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: '1px solid #CFD5E2',
            backgroundColor: '#FAFAFC',
        },
        '& .viewer-toolbar-left': {
            display: 'none',
        },
        '& .viewer-open-file': {
            display: 'none',
        },
        '& .viewer-button': {
            display: 'none',
        }
    }
}))