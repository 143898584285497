import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import lottie from "lottie-web";
import { useParams } from "react-router-dom";
import { useStyles } from "./styles";
import { isValidId, navigate, segment, useApplicationPusher } from "src/helpers";
import { getCustomer, getDefaultCustomerEmployment } from "../helpers";

import loader from "src/assets/img/animacion-gris.json";
import Bugsnag from '@bugsnag/js';

import { useDispatch, useSelector } from "react-redux";
import { applicationActions } from "src/store/application/actions";
import { authActions } from "src/store/auth/actions";

export function ApplicationRunningScoring() {
	const { applicationId }: any = useParams();
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const scoringData = useSelector(state => state.auth.scoringSocketData)

	useApplicationPusher({ getDefaultCustomerEmployment, getCustomer });

	const application = useSelector(state => state.applications.application)
	let timer: any = null;

	function redirectToResponse() {
		setTimeout(() => {
			navigate.to(`/application/${applicationId}/status/response/detail?application_id=${applicationId}&application_status=${scoringData?.message || ''}`)
		}, 3000)
	}

	useEffect(() => {
		if (isValidId(applicationId) && application?.id != applicationId) {
			segment.page('Application');
			dispatch(applicationActions.getApplication(applicationId));
		}
	}, [applicationId])

	useEffect(() => {
		if (application?.id && (
			scoringData?.application_workflow_id != applicationId ||
			scoringData?.stage_id != application.current_stage?.id
		)) {
			dispatch(applicationActions.getLastScore(application.id, application.workflow_id!, application.current_stage?.id!, application?.status))
		} else {
			redirectToResponse()
		}
	}, [application?.id, scoringData?.stage_id])

	useEffect(() => {
		lottie.loadAnimation({
			container: document.querySelector("#loader-scoring")!,
			animationData: loader,
			renderer: "svg",
			autoplay: true,
		});
	}, []);

	useEffect(() => {
		if (!timer) {
			if (scoringData?.stage_id) {
				redirectToResponse()
			}

			timer = setTimeout(() => {
				if (import.meta.env.REACT_APP_ENV === 'production') {
					Bugsnag.notify(new Error('El cliente ha esperado más de 20 segundos'));
				}

				dispatch(authActions.setScoringData({
					application_workflow_id: applicationId,
					message: 'in_review',
					stage_id: application.current_stage?.id
				}));
				navigate.to(`/application/${applicationId}/status/response/detail?application_id=${applicationId}&application_status=in_review`)
			}, 20000)
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		}
	}, [])

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="center"
			direction="column"
			className={classes.loadingContainer}
		>
			<div id="loader-scoring" className={classes.loader} />
			<Typography
				className={classes.text}
				dangerouslySetInnerHTML={{ __html: 'Estamos procesando tu información, esto puede tardar unos minutos...' }}
			/>
		</Grid>
	)
}