import { parsePhoneNumber, getRegionCodeForCountryCode, ParsedPhoneNumber } from 'awesome-phonenumber'

export function formatPhone(phone: string, phone_country_code?: string) {
	let phonenumber: ParsedPhoneNumber;
	if (phone_country_code) {
		const regionCode = getRegionCodeForCountryCode(Number(phone_country_code))
		phonenumber = parsePhoneNumber(`${phone_country_code}${phone || ''}`, { regionCode })
	} else {
		phonenumber = parsePhoneNumber(`${phone}`)
	}
	return phonenumber.number?.international
}