import MuiLink from "@mui/material/Link";
import { Fragment, ReactChild } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

export interface LinkProps extends RouterLinkProps {
	disabled?: boolean;
	children?: ReactChild | ReactChild[];
	underline?: 'none' | 'hover' | 'always';
	native?: boolean;
	href?: string;
}

export function Link({ disabled, to, underline, children, href, native, ...rest }: LinkProps) {
	const Component = disabled ? Fragment : MuiLink;
	const props = disabled ? {} : {
		to: !native ? to || '' : null,
		href: native ? to || '' : null,
		component: native ? 'a' : RouterLink,
		underline: underline || 'none',
		color: "initial",
		...rest
	};

	return (
		<Component {...props}>
			{children}
		</Component>
	)
}