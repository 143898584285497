import { Box, Button, IconButton, Typography } from "@mui/material"
import AddIcon from "src/assets/img/home/documents/add_icon.svg"
import { useStyles } from "./styles"

export interface MainActionProps {
    show?: boolean
    text?: string
    disabled?: boolean
    onClick?: () => void
}

export function MainAction({ show, text, disabled, onClick }: MainActionProps) {
    const { classes, cx } = useStyles()

    function handleClick() {
        if (onClick) {
            onClick()
        }
    }

    return (
        <Box className={classes.root}>
            <IconButton
                className={classes.button}
                disabled={disabled}
                onClick={handleClick}
            >
                <AddIcon /*className={classes.icon}*/ />
            </IconButton>

            <Box onClick={handleClick}>
                <Typography className={classes.text}> {text || 'Agregar'} </Typography>
            </Box>
        </Box>
    )
}