import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        background: 'white',
        padding: 24,
        borderRadius: 30,
        border: '1px solid #CFD5E2',
    },
    header: {
        textAlign: 'center'
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
    },
    requestedAmount: {
        fontSize: 32,
        fontWeight: 700,
        color: theme.palette.primary.main
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 16
    },
    buttonLinkIcon: {
        verticalAlign: 'top',
        fill: theme.palette.primary.main + ' !important'
    }
}))