import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ApplicationWorkflowStage } from "src/types";
import { STAGE_STATUS } from "../workflow";
import { isCancelledOrRejected } from "../application";

interface UseApplicationStageProps {
    useLastOneApplication?: boolean
}

/**
 * Custom hook to get current application stage 
 * and know if should prioritize the application flow
 */
export function useApplicationStage({ useLastOneApplication }: UseApplicationStageProps): {
    currentStage?: ApplicationWorkflowStage
    isPriority: boolean,
    isFromCredit?: boolean,
    canContinue: boolean
    currentSectionLink?: string
} {
    const { creditId }: any = useParams()
    const application = useSelector(state => useLastOneApplication
        ? state.applications.lastApplication
        : state.applications.application
    )
    const currentStage = application?.applicationWorkflowStages?.find(stage => (
        stage.stage_id === application?.current_stage?.id
    ))
    const canContinue = !isCancelledOrRejected(application)
        && currentStage?.status !== STAGE_STATUS.IN_REVIEW
        && currentStage?.visibility == 'customer'
    const currentSectionLink = `/application/${application?.id}/${application?.current_stage?.slug}/${application?.current_section?.slug}`

    return {
        currentStage,
        canContinue,
        isPriority: (currentStage?.sort_order || 0) <= 1,
        isFromCredit: Boolean(creditId),
        currentSectionLink
    }
}