import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    imageAndText: {
        flexWrap: 'nowrap',
        marginBottom: 32,
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            marginBottom: 40,
        }
    },
    avatar: {
        height: 64,
        width: 64,
        backgroundColor: theme.palette.primary.light,
        marginRight: 16,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16
        }
    },
    dataContainer: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    data: {
        display: 'flex',
        flexDirection: 'column',
        '& > h3': {
            fontSize: 20,
            color: '#242C37',
            fontWeight: 700,
        },
        [theme.breakpoints.down('sm')]: {
            '& > h3': {
                color: '#242C37',
                fontWeight: 700,
                fontSize: 16,
            },
            '& > p': {
                paddingTop: 8,
                fontSize: 16,
            }
        }
    },
    contactData: {
        display: 'none',
        gap: 50,
        '& > p': {
            display: 'flex',
            alignItems: 'center'
        },
        '& svg': {
            height: 14,
            fill: theme.palette.primary.main
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            padding: 0,
            flexDirection: 'column',
            marginTop: 16,
            gap: 16,
            '& > p': {
                justifyContent: 'flex-start'
            }
        }
    },
    itemActions: {
        gap: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 24,
        }
    },
    skeleton: {
        maxWidth: '100%'
    }
}));
