import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        padding: '8px 16px',
        [theme.breakpoints.between('sm', 'md')]: {
            padding: theme.spacing(1, 0),
        },
        [theme.breakpoints.down('sm')]: {
            padding: 8
        }
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(117, 139, 183, 0.08)',
        },
        '&:nth-of-type(2n)': {
            backgroundColor: '#fff',
        },
        '& > :first-child': {
            borderLeft: '1px solid rgba(117, 139, 183, 0.3)',
        },
        '& > :last-child': {
            borderRight: '1px solid rgba(117, 139, 183, 0.3)'
        },
    },
    headCell: {
        fontSize: 14,
        fontWeight: 600,
        color: 'rgba(57, 74, 97, 0.95)',
    },
    cell: {
        fontSize: 14,
        fontWeight: 500,
        borderBottom: '.5px solid #CFD5E2',
        // color: 'rgba(57, 74, 97, 0.95)'
    },
    paidout: {
        '& td': {
            textDecoration: 'line-through',
            color: 'grey'
        }
    },
    totalCell: {
        fontSize: 14,
        fontWeight: 500,
        borderTop: '.5px solid #CFD5E2',
    },
    scrollbar: {
        '&::-webkit-scrollbar': {
            width: 5,
            height: 5,
            borderRadius: 10,
            opacity: .8,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(117, 139, 183, 0.3)',
            borderRadius: 10,
        }
    },
    tableCollapsed: {
        maxHeight: 320,
    },
    tableExpanded: {
        maxHeight: '100%',
    },
    expandContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    expandToggle: {
        cursor: 'pointer',
        display: 'flex',
        paddingTop: 8,
        '& p': {
            fontWeight: 600,
            fontSize: 16,
            color: '#365863',
        }
    },
    title: {
        fontWeight: 600,
        fontSize: 18,
        color: '#242C37', // '#394A61',
        padding: '8px 0px'
    },
    stickyFooter: {
        left: 0,
        bottom: 0,
        zIndex: 2,
        position: 'sticky',
        background: '#fff',
        '& > :first-child': {
            borderLeft: '1px solid rgba(117, 139, 183, 0.3)'
        },
        '& > :last-child': {
            borderRight: '1px solid rgba(117, 139, 183, 0.3)'
        }
    },
    table: {
        transition: theme.transitions.create(['all'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    paidoutIcon: {
        width: 18,
    }
}));