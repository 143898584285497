import { Button, Grid, GridProps, Typography } from "@mui/material";
import { Link } from "src/components";
import { useStyles } from "./styles";
import { MainCard } from "../Card";
import HomeCalculadoraImg from 'src/assets/img/home_calculadora.svg';

interface MainQuoteCardProps extends GridProps {
    text?: string;
    actionElement: JSX.Element;
}

export function MainQuoteCard({ text, actionElement, ...rest }: MainQuoteCardProps) {
    const { classes, cx } = useStyles();

    return (
        <MainCard title={text || "Cotizaciones"} {...rest}>
            <Grid container item xs={12} className={classes.imageAndText}>
                <HomeCalculadoraImg /*color="primary" className={classes.avatar}*/ />
                <div className={classes.dataContainer}>
                    <div className={classes.data}>
                        <Typography> Utiliza nuestro simulador hipotecario, calcula y compara las características de tu crédito. </Typography>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} className={classes.itemActions}>
                {actionElement}
            </Grid>
        </MainCard>
    )
}