import { AddressType } from "src/types";

export const formatAddress = (address?: AddressType): string => {
    if (!address)
        return ''
    const { street, street_number, neighborhood, zipcode } = address
    let strAddress = ''
    if (street && street_number)
        strAddress += `${street} ${street_number}, `
    if (street && !street_number)
        strAddress += `${street}, `
    if (!street && street_number)
        strAddress += `${street_number}, `
    return strAddress += `${neighborhood} ${zipcode}`
}