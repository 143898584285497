export function isSvgByUrl(url: string) {
    return url.includes('.svg')
}

export async function fetchSvgFromUrl(url: string, onError?: (error: string) => void) {
    try {
        const response = await fetch(url)
        if (!response.ok) {
            throw new Error(`FetchSvg: ${response.statusText}`)
        }
        return await response.text()
    } catch (error) {
        if (typeof error === 'string') {
            onError?.(error)
        } else {
            onError?.('FetchSvg: Error fetching the svg')
        }
        console.log(error)
        return ''
    }
}

/** Set some attributes from child svg to parent svg  */
export function mergeAttributes(parentSvg: SVGSVGElement, childSvg: SVGSVGElement) {
    const attributesNotToMerge = ['class']
    for (let i = 0; i < childSvg.attributes.length; i++) {
        const { name, value } = childSvg.attributes[i]
        if (!attributesNotToMerge.includes(name)) {
            parentSvg.setAttribute(name, value)
        }
    }
}

export function parseStrToSvg(svg: string) {
    const domParser = new DOMParser()
    const doc = domParser.parseFromString(svg, 'image/svg+xml')
    const svgElement = doc.querySelector('svg')

    return svgElement
}