import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        // width: '100%',
        // height: '100%',
        position: 'relative',
        padding: 0
    },
    conversationRoot: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100% - 88px)',
        position: 'relative',
        overflowY: 'auto'
    },
    conversation: {
        display: 'flex',
        overflowY: 'auto',
        maxWidth: 1440,
        maxHeight: '100%'
    },
    messageContainer: {
        display: 'flex',
        padding: 24,
        '& svg': {
            marginRight: 16
        },
    },
    messageContainerMine: {
        display: 'flex',
        padding: 24,
        flexDirection: 'row-reverse',
        '& svg': {
            marginLeft: 16
        }
    },
    message: {
        maxWidth: 525,
        background: '#E5F4EC',
        padding: 16,
        borderRadius: 8,
    },
    messageHeader: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        marginBottom: 16,
        '& > h3': {
            color: '#0D8955',
            fontSize: 16,
            fontWeight: 'bold'
        },
        '& > p': {
            color: theme.palette.primary.main,
            fontSize: 12,
            fontWeight: 400
        }
    },
    isMine: {
        background: ' rgba(117, 139, 183, 0.08) !important'
    },
    dateMine: {
        color: '#8892A0 !important'
    },
    sendContainer: {
        width: '100%',
        display: 'flex',
        padding: '16px 24px',
        maxWidth: 1440,
        gap: 16,
    },
    sendRoot: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        borderTop: '1px solid #CFD5E2',
        position: 'absolute',
        bottom: 1,
        background: '#F9FAF9',
        borderRadius: '0px 0px 8px 8px'
    },
    iconButton: {
        height: 56,
        width: 56,
        minWidth: 56,
    },
    sendIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 56,
        width: 56,
        minWidth: 56,
        borderRadius: '50%',
        background: theme.palette.primary.main,
        cursor: 'pointer',
        '& > svg': {
            fill: '#fff'
        },
        '&:hover': {
            boxShadow: '0px 16px 24px rgba(61, 154, 65, 0.14), 0px 8px 10px rgba(44, 191, 128, 0.26)'
        }
    },
}))