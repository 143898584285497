import { call, put, takeLatest } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { Action, Response } from "src/types";
import { relatedApplcationsActions } from "./actions";
import { RELATED_APPLICATIONS_TYPES } from "./types";
import { applicationActions } from "../application/actions";

const applicationWorkflowsService = new Service('application_workflows');

function* getAll({ payload }: Action) {
	const response: Response = yield call(
		applicationWorkflowsService.allPath,
		`${payload.applicationId}/application_workflow_applications`,
		payload.params
	)
	yield put(relatedApplcationsActions.setAll(response.data, response.meta))
}

function* create({ payload }: Action) {
	const response: Response = yield call(
		applicationWorkflowsService.createPath,
		`${payload.applicationId}/application_workflow_applications`,
		payload.params
	)
	yield put(applicationActions.continueApplication(
		response.data.related_application_workflow_id
	))
}

export function* relatedApplcationsSagas() {
	yield takeLatest(RELATED_APPLICATIONS_TYPES.GET_ALL, sagaWrapper(getAll, relatedApplcationsActions.setAll([], {})))
	yield takeLatest(RELATED_APPLICATIONS_TYPES.CREATE, sagaWrapper(create))
} 