import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    ctaButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 8,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    cancelButton: {
        color: `${theme.palette.primary.main} !important`,
        [theme.breakpoints.down('md')]: {
            height: 42,
            width: '50% !important',
            '& svg': {
                display: 'block !important'
            }
        }
    },
    submitButton: {
        [theme.breakpoints.down('md')]: {
            height: 42,
            width: '50%',
            color: `${theme.palette.primary.main} !important`,
        }
    },
    summaryButton: {
        [theme.breakpoints.down('md')]: {
            height: 42,
            width: '100%',
            marginBottom: 8
        }
    }
}))