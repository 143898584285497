import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    titleContainer: {
        padding: '24px 32px 12px',
        '& h3': {
            color: '#242C37',
            fontSize: 24,
            fontWeight: 'bold'
        },
        '& svg': {
            fill: '#8892A0',
            height: 28,
            width: 28
        }
    },
    divider: {
        borderBottom: '1px solid #CFD5E2',
    },
    titleContainerArrow: {
        padding: '24px 16px 0',
        '& svg': {
            fill: '#242C37',
        }
    },
    container: {
        padding: '37px 16px',
        maxHeight: 'calc(100% - 69px)',
        overflowY: 'auto'
    },
    paper: {
        width: '25%',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '55%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    arrowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }
}));