import { call, put, takeLatest } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { Action, Response } from "src/types";
import { workflowActions } from "./actions";
import { WORKFLOW_TYPES } from "./types";

const WorkflowService = new Service('workflows');

function* getOne({ payload }: Action) {
    const response: Response = yield call(WorkflowService.findOne, payload.id);
    yield put(workflowActions.setOne(response.data));
}

export function* workflowSagas() {
    yield takeLatest(WORKFLOW_TYPES.GET_ONE, sagaWrapper(getOne));
}

