import { Box, Grid, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { useStyles } from "./styles"

export function LoginAlternatives() {
    const { classes } = useStyles()
    const { login_alternatives } = useSelector(state => state.organization)

    return (
        <Grid container>
            {login_alternatives.map((alternative, index) => (
                <Grid item xs={12} key={index}>
                    <a
                        href={alternative.url}
                        rel="noreferrer nofollow noopener"
                        className={classes.link}
                    >
                        <Box className={classes.loginAlternativeContainer}>
                            <img
                                src={alternative.icon}
                                alt={alternative.name}
                                className={classes.icon}
                            />

                            <Typography className={classes.title}>{alternative.name}</Typography>
                        </Box>
                    </a>
                </Grid>
            ))}
        </Grid>
    )
}