import { Grid, Stepper, Step, StepLabel, StepperProps, StepConnectorProps, GridProps, alpha } from "@mui/material";
import { useStyles, StepConnector } from "./styles";
import { ApplicationWorkflowStage } from "src/types";
import { StepIcon, groupStages, getCurrentStep } from "./helpers";

export interface StageStepperProps {
    activeStep: number;
    stages: ApplicationWorkflowStage[];
    currentStage?: ApplicationWorkflowStage;
    stepperProps?: Partial<StepperProps>;
    stepConnectorProps?: StepConnectorProps
    containerProps?: GridProps
}

export function StageStepper({
    activeStep,
    stages,
    currentStage,
    stepperProps,
    stepConnectorProps,
    containerProps
}: StageStepperProps) {
    const { classes, cx } = useStyles();
    const groupedStages = groupStages(stages);

    return (
        <Grid container {...containerProps}>
            <Stepper
                alternativeLabel={false}
                activeStep={getCurrentStep(groupedStages, currentStage!) || activeStep}
                connector={<StepConnector {...stepConnectorProps} />}
                orientation='vertical'
                {...stepperProps}
                className={cx(classes.stepper, stepperProps?.className)}
                sx={theme => ({
                    '& .MuiStepLabel-completed': {
                        textDecoration: 'line-through',
                        color: theme.palette.primary.main + '!important'
                    },
                    '& .MuiStepLabel-labelContainer': {
                        paddingLeft: 8,
                        paddingRight: 8,
                        width: 'auto'
                    },
                    '& $currentStep .MuiStepLabel-labelContainer': {
                        color: '#242C37 !important',
                        backgroundColor: alpha(theme.palette.primary.main, .08),
                        borderRadius: 24,
                    }
                })}
            >
                {groupedStages.map((stage, index) => {
                    const isCurrentStage = currentStage?.id === stage.id;
                    const isPrivate = stage.visibility === 'user';

                    if (isPrivate && groupedStages[index - 1]?.visibility === 'user') {
                        return <></>
                    }

                    return (
                        <Step key={stage.id}>
                            <StepLabel
                                StepIconComponent={StepIcon}
                                StepIconProps={{
                                    //@ts-ignore
                                    isPrivate,
                                }}
                                icon={<></>}
                                className={cx(classes.label, {
                                    [cx(classes.currentStep, 'currentStep')]: isCurrentStage
                                })}
                            >
                                {isPrivate ? 'Revisión de documentos' : stage?.name}
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Grid>
    )
}