import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    menu: {
        '& .MuiList-padding': {
            padding: 0,
            '& a': {
                textDecoration: 'none'
            }
        }
    },
}));