import { takeLatest, put, call, debounce, select, takeEvery } from "redux-saga/effects";
import { sagaWrapper, segment } from "src/helpers";
import { PRODUCT_APPLICATION_TYPES } from "./types";
import { Service } from "src/services";
import { Action, Customer, Response } from "src/types";
import { productApplicationActions } from "./actions";
import { snackbarActions } from "../snackbar/actions";
import { RootState } from "..";

const ProductApplicationService = new Service('product_applications', { api: 'abacus' });
const amortizationTableService = new Service('amortization_tables', { api: 'abacus' });

function* getAll() {
    const response: Response = yield call(ProductApplicationService.all, {});
    yield put(productApplicationActions.setAll(response.data))
}

function* destroy({ payload }: Action) {
    yield call(ProductApplicationService.destroy, payload.id);
    segment.track('Quotation', 'Deleted', { QuotationId: payload.id })
    yield put(snackbarActions.add('success', 'Cotización eliminada'));
    yield put(productApplicationActions.getAll());
}

function* sendResult({ payload }: Action) {
    const customer: Customer = yield select((state: RootState) => state.auth.customer);
    yield call(ProductApplicationService.createPath, `${payload.id}/send_amortization_table`, { user: { email: customer.email } }, false);
    segment.track('Quotation', 'Sent', { to: customer.email, quotationId: payload.id })

    yield put(snackbarActions.add('success', '¡La cotización ha sida enviada a tu correo electrónico!'));
}

function* getOne({ payload }: Action) {
    const response: Response = yield call(ProductApplicationService.findOne, payload.id)
    yield put(productApplicationActions.setOne(response.data))
}

function* getData({ payload }: Action) {
    try {
        const { type, amortizationTableId } = payload
        let response: Response;

        if (payload.type === 'amortizationTable') {
            response = yield call(amortizationTableService.findOne, amortizationTableId)
        } else {
            response = yield call(amortizationTableService.findOnePath, amortizationTableId, `graph?type=${type}&period=yearly`)
        }

        yield put(productApplicationActions.setData(type, response.data))
    } catch {
        yield put(productApplicationActions.setData(payload.type))
    }
}

export function* productApplicationSagas() {
    yield takeLatest(PRODUCT_APPLICATION_TYPES.GET_ONE, sagaWrapper(getOne));
    yield debounce(1000, PRODUCT_APPLICATION_TYPES.GET_ALL, sagaWrapper(getAll, productApplicationActions.setAll([])));
    yield takeLatest(PRODUCT_APPLICATION_TYPES.DESTROY, sagaWrapper(destroy, productApplicationActions.set('isLoading', false)));
    yield debounce(500, PRODUCT_APPLICATION_TYPES.SEND_RESULT, sagaWrapper(sendResult));
    yield takeEvery(PRODUCT_APPLICATION_TYPES.GET_DATA, sagaWrapper(getData));
}