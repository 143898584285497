import { Address, Customer, Employment, Action } from "src/types";
import { AUTH_TYPES } from "../auth/types";
import { CUSTOMER_TYPES } from "./types";

export interface CustomerStateType {
    customer: Customer;
    employments: Employment[];
    employment: Employment;
    addresses: Address[];
    address: Address
    isLoading: boolean;
    hasPendingApplication: boolean;
    employmentsEvaluatorsExecuted: boolean;
    isLoadingEvaluators: boolean;
}

const INITIAL_STATE: CustomerStateType = {
    customer: {},
    employments: [],
    addresses: [],
    address: {},
    employment: {},
    isLoading: false,
    hasPendingApplication: false,
    employmentsEvaluatorsExecuted: false,
    isLoadingEvaluators: false,
}

export const customerReducer = (state: CustomerStateType = INITIAL_STATE, action: Action): CustomerStateType => {
    const { type, payload } = action;
    switch (type) {

        case CUSTOMER_TYPES.SET: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }

        case CUSTOMER_TYPES.GET_ADDRESSES:
        case CUSTOMER_TYPES.GET_ADDRESS:
        case CUSTOMER_TYPES.GET_EMPLOYMENTS:
        case CUSTOMER_TYPES.GET_EMPLOYMENT: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case CUSTOMER_TYPES.EXECUTE_EMPLOYMENT_EVALUATORS: {
            return {
                ...state,
                isLoading: true,
                isLoadingEvaluators: true,
                employmentsEvaluatorsExecuted: true,
            }
        }

        case CUSTOMER_TYPES.SET_EMPLOYMENTS: {
            return {
                ...state,
                isLoading: false,
                employments: payload.employments
            }
        }

        case CUSTOMER_TYPES.SET_EMPLOYMENT: {
            return {
                ...state,
                isLoading: false,
                employment: payload.employment
            }
        }


        case CUSTOMER_TYPES.SET_ADDRESSES: {
            return {
                ...state,
                isLoading: false,
                addresses: payload.addresses
            }
        }

        case CUSTOMER_TYPES.SET_ADDRESS: {
            return {
                ...state,
                isLoading: false,
                address: payload.address
            }
        }

        case CUSTOMER_TYPES.SET_HAS_PENDING_APPLICATION: {
            return {
                ...state,
                hasPendingApplication: payload.value
            }
        }

        case AUTH_TYPES.SUCCESS_LOGIN: {
            return {
                ...state,
                isLoading: false
            }
        }

        case AUTH_TYPES.ME:
        case AUTH_TYPES.SEND_MAGIC_LINK: {
            return {
                ...state,
                isLoading: true
            }
        }

        case AUTH_TYPES.SET_EMPLOYMENTS: {
            return {
                ...state,
                isLoading: false
            }
        }

        case AUTH_TYPES.SET_CUSTOMER: {
            return {
                ...state,
                isLoading: false
            }
        }

        default:
            return state;
    }
} 