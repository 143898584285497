import { OrganizationThemeType } from "src/types";
import { tss } from "tss-react/mui";

export const useStyles = (orgTheme?: OrganizationThemeType) => tss.create(({ theme }) => {
    const { navbar } = orgTheme || {}

    return {
        appbar: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: navbar?.background_color || '#fff',
            height: 70,
            boxShadow: '0px 1px 0px #E9EAED',
            zIndex: 1200,
            [theme.breakpoints.down('sm')]: {
                height: 50,
            }
        },
        title: {
            flexGrow: 1,
            textAlign: 'right',
            color: '#242C37',
        },
        toolbar: {
            background: navbar?.background_color || '#fff',
            display: 'flex',
            alignItems: "center",
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            padding: '0px 37px',
            '& label, & button, & svg, & a': {
                color: navbar?.color,
            },
            '& svg path': {
                fill: navbar?.color
            },
            '& hr': {
                backgroundColor: navbar?.color
            },
            '& .MuiButton-contained': {
                color: navbar?.background_color,
                backgroundColor: navbar?.color,
                '&:hover': {
                    color: `${navbar?.background_color} !important`,
                    backgroundColor: `${navbar?.color} !important`,
                }
            },
            [theme.breakpoints.down('sm')]: {
                padding: '0px 19px',
                boxShadow: '0px 1px 0px #E9EAED',
                justifyContent: 'center',
                position: 'relative'
            }
        },
        center: {
            justifyContent: 'center',
        },
        navItems: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                marginLeft: 24,
            }
        },
        menu: {
            '& .MuiList-padding': {
                padding: 0,
                '& a': {
                    textDecoration: 'none'
                }
            }
        },
        menuItem: {
            fontSize: 16,
            color: '#242C37 !important',
            padding: 0,
            // minHeight: 43,
            '&:hover': {
                background: 'rgba(117, 139, 183, 0.08)'
            }
        },
        autoFocus: {
            background: 'rgba(117, 139, 183, 0.08)'
        },
        link: {
            padding: '6px 16px',
            width: '100%',
            color: '#242C37 !important',
            '&:active': {
                color: '#242C37'
            },
            '&:selected': {
                color: '#242C37'
            }
        },
        logout: {
            fontSize: 16,
            color: '#8892A0 !important',
        },
        iconContainer: {
            borderRadius: '50%',
            background: theme.palette.primary.main,
            padding: 8
        },
        usernameContainer: {
            borderRadius: 20,
            background: theme.palette.primary.main,
            padding: '6px 16px',
            '& > p': {
                fontSize: 14,
                fontWeight: 'bold',
                color: '#fff',
                marginRight: 16
            }
        },
        expandIcon: {
            height: 16,
            width: 24
        },
        menuActive: {
            background: theme.palette.primary.dark,
            transition: '300ms ease-out'
        },
        navIcon: {
            marginRight: 8
        },
        menuIconResponsive: {
            position: 'absolute',
            top: 13,
            left: 19
        },
        selectedItemLink: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '4px solid ' + theme.palette.primary.main,
        },
        selectedItemContainer: {
            height: '100%'
        },
        tooltip: {
            backgroundColor: 'black',
            color: 'white'
        },
        avatar: {
            border: '2px solid #fafafa',
            width: 32,
            height: 32,
            marginRight: 8,
            '&:nth-child(2n+1)': {
                background: theme.palette.primary.main
            },
            '&:nth-child(2n)': {
                background: theme.palette.primary.light
            }
        },
        avatarText: {
            color: '#fff',
            fontSize: '12px !important',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px',
        },
        divider: {
            backgroundColor: theme.palette.text.primary,
            height: 20,
            margin: '0px 12px',
        },
        customerName: {
            fontSize: 16,
            fontWeight: 450,
            lineHeight: '16px',
            color: navbar?.color
        }
    }
});