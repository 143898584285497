import { Fragment, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import ConfettiExplosion from 'react-confetti-explosion';
import _ from "lodash"

interface ConfettiProps {
    duration?: number
}

/** Shows a confetti explosion by the given duration */
export function Confetti({ duration = 4000 }: ConfettiProps) {
    const [isExploding, setIsExploding] = useState(true)

    setTimeout(() => {
        setIsExploding(false)
    }, duration - 500)

    return useMemo(() => isExploding ? (
        <Grid container alignItems="flex-start" justifyContent="center">
            <ConfettiExplosion
                duration={duration}
                height={2000}
                width={2000}
            // floorHeight={2000}
            // floorWidth={2000}
            />
        </Grid>
    ) : <Fragment />, [isExploding])
}