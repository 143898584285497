import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        padding: '24px 24px 16px 24px'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.palette.primary.dark
    },
    description: {

    },
    closeButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    closeIcon: {
        color: '#758BB7'
    },
    item: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: 16
    }
}))