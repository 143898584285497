import { CloudDownloadOutlined as CloudDownloadIcon } from "@mui/icons-material";
import { Chip, IconButton } from "@mui/material";
import { TableColumnType } from "src/components";

export const proceduresColumns: TableColumnType[] = [
    {
        label: 'Tipo',
        key: 'type',
    },
    {
        label: 'Procedimiento',
        key: 'procedure',
    },
    {
        label: 'Estatus',
        key: 'status',
        render: function Component() {
            return (
                <Chip
                    label="En proceso"
                    style={{
                        background: '#E4F3FF',
                        color: '#4AAAFF',
                        maxHeight: 24
                    }}
                />
            )
        }
    },
    {
        label: 'Fecha',
        key: 'created_at',
    },
];