import { CreditProposalSchema, CreditProposalStatusType, SelectValueType } from "src/types";

export const creditProposalSchemas: SelectValueType[] = [
    { caption: 'Pagos fijos', value: 'traditional' },
    { caption: 'Pagos crecientes', value: 'growth' },
    { caption: 'Free', value: 'free' }
]

export const translateCreditProposalSchema: { [key in CreditProposalSchema | '']: string } = {
    'traditional': 'Pagos fijos',
    'free': 'Free',
    'growth': 'Pagos crecientes',
    '': '-'
}

export const translateCreditProposalStatus: { [key in CreditProposalStatusType | '']: string } = {
    'draft': 'Borrador',
    'sent': 'Pendiente',
    'accepted': 'Aceptado',
    'rejected': 'Rechazado',
    'expired': 'Expirado',
    '': '-'
}