import { useEffect, useState } from "react"
import { Grid, Typography } from "@mui/material"
import { AutoCompleteInput, BinaryInput, SelectInput } from "@kredimx/form-builder"
import { HelpOutline as HelpIcon } from "@mui/icons-material"

import { FormBuilder, Tooltip } from "src/components"
import { isValidId } from "src/helpers"
import { Employment, EmploymentResourceType } from "src/types"

import { FormFlow, FormFlowProps } from "../../../components"

import { useDispatch, useSelector } from "react-redux"
import { applicationEvaluatorFieldsActions } from "src/store/application/actions"
import { providersActions } from "src/store/providers/actions";

import { getEmploymentFields } from "./fields"
import { initialValues } from "./helpers"
import { useStyles } from "./styles"

import _ from "lodash"

interface EmploymentFormFlowProps {
    isNew?: boolean
    resourceType?: EmploymentResourceType
    formFlowProps?: FormFlowProps
    handleSubmit?: () => void
}

export function EmploymentFormFlow({ isNew, resourceType, formFlowProps, handleSubmit }: EmploymentFormFlowProps) {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    const application = useSelector(state => state.applications.application)
    const employment = useSelector(state => state.applications.evaluatorFields?.actionable?.data) as (Employment | undefined)
    const { data: countries, isLoading: isLoadingCountries } = useSelector(state => state.providers.countries);

    const [values, setValues] = useState<Employment>({})

    const fields = getEmploymentFields({ values, catalogs: {} })

    const _initialValues = initialValues({
        fields,
        employment: values,
        resourceType,
        isNew: Boolean(isNew),
    })

    useEffect(() => {
        if (employment?.id) {
            setValues(employment)
        }
    }, [employment?.id])

    function onSubmit(values: any) {
        const defaultPhone = employment?.phones?.find(phone => phone.default)

        const checking_method = _.isString(values?.checking_method)
            ? [values?.checking_method]
            : (_.isArray(values?.checking_method) ? values?.checking_method?.map((value: any) => value.value ? value.value : value) : [])

        const _values = {
            ...(!isNew && ({ ...employment })),
            ...values,

            // checking_method,
            ends_on: values.predetermined ? '' : values.ends_on,
            net_salary: Boolean(values?.net_salary) ? values?.net_salary : 0,

            before_taxes_salary: 100000,
            checking_method: "statement",
            customer_id: "d6c8a260-f8f7-4d05-b1f1-e7cb89eab502",
            filing_frequency: "",
            nationality: "México",
            predetermined: false,
            proof_of_seniority_type: "",
            salary_scheme: "freelance",
            yearly_before_taxes_salary: 1200000,

            ...(resourceType === 'creditParticipant' && ({
                phones_attributes: [{
                    id: defaultPhone?.id,
                    code_area: String(values?.default_phone?.phone_country_code || '').slice(1),
                    number: values?.default_phone?.phone,
                    default: Boolean(defaultPhone) || ((employment?.phones?.length || 0) === 0),
                }]
            }))
        }

        if (isValidId(application?.id)) {
            dispatch(applicationEvaluatorFieldsActions.executeEvaluators(
                isNew ? 'create' : 'update',
                application.id!,
                'Employment',
                { ...employment, ..._values }
            ))

            if (_.isFunction(handleSubmit)) {
                handleSubmit()
            }
        }
    }

    return (
        <Grid container spacing={3}>

            <Grid
                item
                xs={12}
                style={{ textAlign: 'center' }}
            >
                <Typography className={classes.title}>
                    Información de empleo
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <FormBuilder
                    fields={fields}
                    initialValues={_initialValues}
                    onSubmit={onSubmit}
                    hideCancel
                    hideHelpers
                    showValidationCheckIcon={false}
                    actionContainerProps={{
                        style: {
                            justifyContent: 'flex-end',
                            marginTop: 33
                        },
                    }}
                    submitCaption="Guardar cambios"
                    customInputs={{
                        salary_scheme: function SalarySchemeInput({ onChange, ...rest }: any) {
                            return (
                                <SelectInput
                                    {...rest}
                                    onChange={(e: any) => {
                                        onChange(e)
                                        setValues(values => ({
                                            ...values,
                                            salary_scheme: e.target.value
                                        }))
                                    }}
                                />
                            )
                        },
                        custom_binary: function CustomBinaryInput({ onChange, ...rest }: any) {
                            return (
                                <BinaryInput
                                    {...rest}
                                    onChange={(e: any) => {
                                        onChange(e)
                                        setValues({
                                            ...values,
                                            predetermined: e.target.checked
                                        })
                                    }}
                                />
                            )
                        },
                        predetermined_binary: function CustomBinaryInput({ label, ...rest }: any) {
                            return (
                                <BinaryInput
                                    {...rest}
                                    label={(
                                        <Grid container className={classes.predeterminedLabel}>
                                            <Typography> {label} </Typography>
                                            <Tooltip title="Empleo que se prellenará en las solicitudes que haga el cliente">
                                                <span className={classes.helIconContainer}><HelpIcon color="primary" className={classes.helpIcon} /> </span>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                />
                            )
                        },
                        autocomplete: function customAutocompleteInput(props: any) {
                            return (
                                <AutoCompleteInput
                                    {...props}
                                    multiple={props?.config?.multiple || false}
                                    labelAttribute="caption"
                                    valueAttribute="value"
                                />
                            )
                        },
                        autocomplete2: function AutocompleteInput2(props: any) {
                            const value = props?.options?.find((o: any) => o.value === props?.value)
                            return (
                                <AutoCompleteInput
                                    {...props}
                                    multiple={props?.config?.multiple || false}
                                    labelAttribute="caption"
                                    valueAttribute="value"
                                    value={{ ...value }}
                                />
                            )
                        },
                        nationality: function NationalityInput({ value, onChange, ...rest }: any) {
                            return (
                                <SelectInput
                                    {...rest}
                                    value={value}
                                    onChange={(e: any) => {
                                        onChange(e)
                                        setValues(values => ({
                                            ...values,
                                            nationality: e.target.value
                                        }))
                                    }}
                                />
                            )
                        },
                        checking_method: function CheckingMethodInput({ value, onChange, ...rest }: any) {
                            return (
                                <SelectInput
                                    {...rest}
                                    value={value}
                                    onChange={(e: any) => {
                                        onChange(e)
                                        setValues(values => ({
                                            ...values,
                                            proof_of_seniority_type: undefined,
                                            checking_method: e.target.value
                                        }))
                                    }}
                                />
                            )
                        },
                    }}
                    specialInputsProps={{
                        countryInputProps: {
                            isLoading: isLoadingCountries,
                            countries: countries || [],
                            getCountries: () => dispatch(providersActions.getCountries()),
                            setCountries: (countries?: any[]) => dispatch(providersActions.setCountries(countries)),
                            getStates: (countryCode?: string) => dispatch(providersActions.getStates(countryCode)),
                        }
                    }}
                />
            </Grid>

        </Grid>
    )
}