import { useEffect } from "react"
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { Link, StagePage, Loading } from "src/components";
import { MainSmallCard } from "../Main/components";
import { currencyString, formatDate, useIsMobile } from "src/helpers";
import { getsmallCardsItems } from "./smallCardsItems";
import { useStyles } from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { creditsActions } from "src/store/credits/actions";

import EventImg from 'src/assets/img/credits/event.svg'
import IncrementImg from 'src/assets/img/credits/timeline.svg'
import CoinImg from 'src/assets/img/credits/monetization_on.svg'
import CalendarImg from 'src/assets/img/credits/calendar_today.svg'

export function CreditsPage() {
    const { classes, cx } = useStyles();
    const isMobile = useIsMobile();
    const dispatch = useDispatch()
    const credit = useSelector(state => state.credits.credits.credit)
    const isLoading = useSelector(state => state.credits.credits.isLoading)

    useEffect(() => {
        dispatch(creditsActions.getOne())
    }, [])

    return (
        <StagePage title='Crédito'>
            <Grid container spacing={isMobile ? 2 : 3}>
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={isMobile ? 2 : 3}
                    >
                        <Grid item xs={12} md={6}>
                            <Card elevation={0} className={classes.card}>
                                <Grid container>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        className={classes.cardItemsContainer}
                                    >
                                        <Grid xs={12} className={classes.cardTitleContainer}>
                                            <Box display='flex' alignItems='center'>
                                                <Typography color="primary" className={classes.cardTitle}> Información de tu crédito </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6} className={classes.cardItemContainer}>
                                            <Box display='flex' alignItems='center'>
                                                <EventImg />
                                                <Typography className={classes.cardItemTitle}> Saldo insoluto </Typography>
                                            </Box>
                                            <Loading isLoading={isLoading} variant="text" style={{ padding: 16 }}>
                                                <Typography className={classes.cardItemValue}> {currencyString(credit?.outstanding_balance)} </Typography>
                                            </Loading>
                                        </Grid>
                                        <Grid xs={12} md={6} className={classes.cardItemContainer}>
                                            <Box display='flex' alignItems='center'>
                                                <IncrementImg />
                                                <Typography className={classes.cardItemTitle}> Tasa ordinaria anual </Typography>
                                            </Box>
                                            <Loading isLoading={isLoading} variant="text" style={{ padding: 16 }}>
                                                <Typography className={classes.cardItemValue}>
                                                    {credit?.interest_rate?.toString()?.includes('.') ? credit?.interest_rate : (parseFloat(credit?.interest_rate?.toString() || '0')).toFixed(2)} %
                                                </Typography>
                                            </Loading>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.cardActionsContainer}>
                                        <Link to={`/home/credit/${credit?.id}`} color="primary">
                                            <strong>Ver detalles</strong>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card elevation={0} className={classes.card}>
                                <Grid container>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        className={classes.cardItemsContainer}
                                    >
                                        <Grid xs={12} className={classes.cardTitleContainer}>
                                            <Box display='flex' alignItems='center'>
                                                <Typography color="primary" className={classes.cardTitle}> Pago </Typography>
                                                {/* <div className={classes.chip} style={{ color: '#F44336', background: '#FFF1F1', marginLeft: 'auto' }}>
                                                    Pendiente
                                                </div> */}
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6} className={classes.cardItemContainer}>
                                            <Box display='flex' alignItems='center'>
                                                <CoinImg />
                                                <Typography className={classes.cardItemTitle}> Mensualidad </Typography>
                                            </Box>
                                            <Loading isLoading={isLoading} variant="text" style={{ padding: 16 }}>
                                                <Typography className={classes.cardItemValue}>{currencyString(credit?.last_account_statement?.total_payment)}</Typography>
                                            </Loading>
                                        </Grid>
                                        <Grid xs={12} md={6} className={classes.cardItemContainer}>
                                            <Box display='flex' alignItems='center'>
                                                <CalendarImg />
                                                <Typography className={classes.cardItemTitle}> Fecha límite de pago </Typography>
                                            </Box>
                                            <Loading isLoading={isLoading} variant="text" style={{ padding: 16 }}>
                                                <Typography className={classes.cardItemValue}>
                                                    {formatDate(credit?.last_account_statement?.due_date, 'DD/MMM/YYYY') || 'Sin especificar'}
                                                </Typography>
                                            </Loading>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.cardActionsContainer}>
                                        <Link to={`/home/credit/${credit?.id}/payment`} color="primary">
                                            <strong>Ver detalles</strong>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={isMobile ? 2 : 3}
                    >
                        {getsmallCardsItems(credit?.id).map((item, index) => {
                            const { image, description, title } = item;
                            return (
                                <MainSmallCard
                                    key={index}
                                    Icon={image}
                                    description={description}
                                    title={title}
                                    actionElement={(
                                        <Link
                                            to={item.linkTo}
                                            native={item.linkTo.includes('http')}
                                            data-cy={`card-option-${item.title}`}
                                        >
                                            <Button
                                                variant="text"
                                                color="primary"
                                                className={classes.smallCardButton}
                                            >
                                                {item.linkTitle}
                                            </Button>
                                        </Link>
                                    )}
                                    rootGridProps={{
                                        item: true,
                                        container: true,
                                        xs: 6,
                                        lg: 3,
                                        md: 3
                                    }}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </StagePage>
    )
}