import React, { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts'
import { Grid, Divider, Typography, GridSize, GridProps } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useStyles } from "./styles";
import { currencyString, getTotal } from "src/helpers";
import { currency, getConceptColors, getConceptColor, fontFamilies } from "../../helpers";
import _ from "lodash";
import { AmortizacionTableType } from "src/types";

export interface ChartState {
    series?: any[];
    options: ApexOptions;
}

export interface DonutChartProps extends GridProps {
    chartColumns?: GridSize;
    tableColumns?: GridSize;
    data?: AmortizacionTableType
}

export function DonutChart({ chartColumns, tableColumns, className, data, ...rest }: DonutChartProps) {
    const { classes, cx } = useStyles();
    const periods = data?.amortization_table_periods || []
    const series = [
        {
            name: "Capital",
            data: getTotal(periods, 'capital_payment', true)
        },
        {
            name: "Intereses",
            data: getTotal(periods, 'interest', true)
        },
        {
            name: "Seguro de vida",
            data: getTotal(periods, 'life_insurance', true)
        },
        {
            name: "Seguro de daños",
            data: getTotal(periods, 'damage_insurance', true)
        },
        {
            name: "Comisión por administración",
            data: getTotal(periods, 'admin_fee', true)
        },
    ]

    const [options, setOptions] = useState<ApexOptions>({
        series: [],
        labels: [],
        chart: {
            type: 'donut',
        },
        legend: {
            show: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            fontWeight: 500,
                            formatter: (w: any) => {
                                return currencyString(0);
                            },
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            y: {
                formatter: (val: number, opts?: any) => currencyString(val)
            }
        },
        colors: getConceptColors(),
    });

    useEffect(() => {
        if (!_.isEmpty(data)) {
            setOptions({
                ...options,
                series: series.map((serie: any) => serie.data),
                labels: series.map((serie: any) => serie.name),
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,

                                total: {
                                    show: true,
                                    showAlways: true,
                                    fontWeight: 500,
                                    fontFamily: fontFamilies,
                                    formatter: (w: any) => {
                                        return currencyString(getTotal(periods, 'total_payment', true));
                                    }
                                }
                            }
                        }
                    }
                },
            });
        }
    }, [series])

    return (
        <Grid container className={cx(classes.container, className)} {...rest}>
            <Grid item xs={12}>
                <div className={classes.titleContainer}>
                    <Typography className={classes.title}> Composición de tu crédito </Typography>
                </div>
            </Grid>
            <Grid container item xs={12} className={classes.itemContainer}>
                <Grid item xs={12} sm={6} md={chartColumns || 6}>
                    <ReactApexChart
                        options={options}
                        series={options.series}
                        type="donut"
                        height="320"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={tableColumns || 6}>
                    {series.map((item: any, index: number) => (
                        <div key={index} className={classes.tableItemContainer}>
                            <Grid container className={classes.tableItem}>
                                <Grid item xs={8}>
                                    <div className={classes.dot} style={{ background: getConceptColor(item.name) }} />
                                    <Typography noWrap> {item.name} </Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.amount}>
                                    <AddIcon fontSize="small" />
                                    <Typography> {currency(item.data)} </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </div>
                    ))}
                    <Typography className={classes.tableTotal}> Total = {getTotal(periods, 'total_payment')} </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}