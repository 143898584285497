import { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts'
import { Grid, Typography, GridProps } from "@mui/material";
import { nFormatter, fontFamilies, getMax } from "../../helpers"
import { useStyles } from "./styles";
import _ from "lodash";
import { AmortizacionTableType } from "src/types";
import { defaultChartOptions } from "./helpers";

export interface AmortizationChartProps extends GridProps {
    data?: AmortizacionTableType
}

export function AmortizationChart({ data, className, ...rest }: AmortizationChartProps) {
    const { classes, cx } = useStyles();
    const [options, setOptions] = useState<ApexOptions>(defaultChartOptions);
    const periods = data?.amortization_table_periods || []
    // const categories = new Array(Number(periods[periods.length - 1]?.period || 12) / 12).fill(null).map((_, i) => i + 1)
    const categories = periods.map(p => p.period)
    const series = [
        {
            name: "Remanente",
            data: periods.map(p => p.final_balance)
        },
        {
            name: "Principal",
            data: periods.map(p => p.capital_payment)
        },
        {
            name: "Interés",
            data: periods.map(p => p.interest)
        },
    ]


    useEffect(() => {
        if (!_.isEmpty(data)) {
            const oppositeMax = getMax([
                ...series[1].data,
                ...series[2].data
            ], true)

            setOptions({
                ...options,
                series: series.map((serie: any) => ({
                    ...serie,
                    data: serie.data.map((value: number) => value.toFixed(2))
                })),
                xaxis: {
                    ...options.xaxis,
                    categories//: categories.map((category: string) => parseInt(category))
                },
                yaxis: [{
                    title: {
                        text: 'Monto',
                        offsetY: -150,
                        offsetX: 35,
                        rotate: 360,
                        style: {
                            fontFamily: fontFamilies,
                            fontWeight: 500,
                            color: '#8892A0',
                            fontSize: '12px'
                        }
                    },
                    show: true,
                    labels: {
                        formatter: (val: number, opts?: any) => nFormatter(val, 1),
                        offsetX: -45,
                        style: {
                            fontFamily: fontFamilies,
                            fontWeight: 600,
                            fontSize: '14px',
                            colors: '#242C37'
                        }
                    },
                    max: getMax(series[0].data),
                }, {
                    opposite: true,
                    title: {
                        text: '',
                        offsetY: 0,
                        offsetX: 0,
                        style: {
                            fontFamily: fontFamilies,
                            fontWeight: 500,
                            color: '#8892A0',
                            fontSize: '12px'
                        }
                    },

                    show: true,
                    labels: {
                        formatter: (val: number, opts?: any) => nFormatter(val, 1),
                        offsetX: -15,
                        style: {
                            fontFamily: fontFamilies,
                            fontWeight: 600,
                            fontSize: '14px',
                            colors: '#242C37'
                        }
                    },
                    max: oppositeMax,
                }, {
                    opposite: true,
                    show: false,
                    max: oppositeMax,
                }]
            })
        }
    }, [data])

    return (
        <Grid container className={cx(classes.container, className)} {...rest}>
            <Grid item xs={12}>
                <Typography className={classes.title}> Evolución de tu crédito </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 16 }}>
                <ReactApexChart
                    options={options}
                    series={options.series}
                    type="area"
                    height="350"
                />
            </Grid>
        </Grid>
    )
}