import { REFRESH_TOKEN_TYPES } from "./types";

export interface RefreshTokenStateType {
    isRefreshingToken?: boolean
    failedRequestsQueue: any[]
}

const initialState: RefreshTokenStateType = {
    isRefreshingToken: false,
    failedRequestsQueue: []
}

export function refreshTokenReducer(state: RefreshTokenStateType = initialState, action: any): RefreshTokenStateType {
    const { type, payload } = action;
    switch (type) {

        case REFRESH_TOKEN_TYPES.SET: {
            return {
                ...state,
                [payload.name]: payload.value
            }
        }

        case REFRESH_TOKEN_TYPES.ADD_FAILED_QUEUE: {
            return {
                ...state,
                failedRequestsQueue: [...state.failedRequestsQueue, payload.request]
            }
        }

        case REFRESH_TOKEN_TYPES.CLEAR_FAILED_QUEUE: {
            return {
                ...state,
                failedRequestsQueue: []
            }
        }

        default:
            return state;
    }
}