import { call, put, takeLatest } from "redux-saga/effects";
import { Action, Response } from "src/types";
import { Service } from "src/services";
import { sagaWrapper } from "src/helpers";

import { directsDebitsActions } from "./actions";
import { DIRECTS_DEBITS_TYPES } from "./types";
import { snackbarActions } from "src/store/snackbar/actions";

const creditService = new Service('credits', { api: 'kronos', namespace: 'customer' });
const directsDebitsService = new Service('domiciliation_bank_accounts', { api: 'kronos', namespace: 'customer' })

function* getAll({ payload }: Action) {
    const response: Response = yield call(creditService.allPath, `${payload.creditId}/domiciliation_bank_accounts`, {});
    yield put(directsDebitsActions.setAll(response.data));
}

function* getOne({ payload }: Action) {
    const response: Response = yield call(directsDebitsService.findOne, payload.id);
    yield put(directsDebitsActions.setOne(response.data));
}

function* create({ payload }: Action) {
    yield call(creditService.createPath, `${payload.creditId}/domiciliation_bank_accounts`, payload.directDebit)
    yield put(snackbarActions.add('success', 'Se ha creado la cuenta'))
    yield put(directsDebitsActions.getAll(payload.creditId, {}))
}

function* update({ payload }: Action) {
    const { id, creditId, directDebit } = payload
    // yield call(creditService.updatePath, creditId, directDebit, `domiciliation_bank_accounts/${id}`)
    yield call(directsDebitsService.update, id, directDebit)
    yield put(snackbarActions.add('success', 'Se ha actualizado la cuenta'))
    yield put(directsDebitsActions.getAll(payload.creditId, {}))
}

function* destroy({ payload }: Action) {
    const { id, creditId } = payload
    // yield call(creditService.destroyPath, `${creditId}/domiciliation_bank_accounts/${id}`)
    yield call(directsDebitsService.destroy, id)
    yield put(snackbarActions.add('success', 'Se ha eliminado la cuenta'))
    yield put(directsDebitsActions.getAll(payload.creditId, {}))
}

export function* directsDebitSagas() {
    yield takeLatest(DIRECTS_DEBITS_TYPES.GET_ALL, sagaWrapper(getAll, directsDebitsActions.setAll()))
    yield takeLatest(DIRECTS_DEBITS_TYPES.GET_ONE, sagaWrapper(getOne, directsDebitsActions.setOne()))
    yield takeLatest(DIRECTS_DEBITS_TYPES.CREATE, sagaWrapper(create))
    yield takeLatest(DIRECTS_DEBITS_TYPES.UPDATE, sagaWrapper(update))
    yield takeLatest(DIRECTS_DEBITS_TYPES.DESTROY, sagaWrapper(destroy))
}