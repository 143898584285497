import { tss } from "tss-react/mui";
//@ts-ignore
import tinycolor from "tinycolor2";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'rgba(249, 250, 249, 0.6)',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 50px)',
        }
    },
    backgroundTop: {
        position: 'absolute',
        top: 0,
        left: 0,
        [theme.breakpoints.down('sm')]: {
            zIndex: -1
        }
    },
    backgroundBottom: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        [theme.breakpoints.down('sm')]: {
            zIndex: -1
        }
    },
    card: {
        padding: '50px 40px',
        marginTop: '8%',
        width: 560,
        minHeight: 426,
        // boxShadow: ' 0px 0px 38px rgba(108, 195, 151, 0.15), 0px 48px 50px rgba(13, 84, 53, 0.05)',
        boxShadow: `0px 0px 38px ${tinycolor(theme.palette.primary.light).setAlpha(.15)},  0px 48px 50px ${tinycolor(theme.palette.primary.light).setAlpha(.05)}`,
        maxWidth: '100%',
        zIndex: 1,
        '& > h3': {
            fontSize: 34,
            fontWeight: 'bold',
            color: theme.palette.primary.dark,
            textAlign: 'center',
            margin: '12px 0px'
        },
        '& > p': {
            maxWidth: 440,
            textAlign: 'center',
            marginBottom: 30,
            '& > br': {
                display: 'none'
            }
        },
        [theme.breakpoints.down('sm')]: {
            '& > h3': {
                fontSize: 24,
            },
            '& > p': {
                fontSize: 16
            },
            padding: 24,
            marginTop: '40%'
        }
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 43
    },
    button: {
        padding: '10px 16px',
    },
    text: {
        fontSize: 12,
        lineHeight: '16px',
        marginTop: 32,
        '& > a': {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            fontWeight: 500,
            cursor: 'pointer',
            marginLeft: 4,
            textUnderlinePosition: 'under',
            '&:hover': {
                color: theme.palette.primary.dark
            }
        }
    }
}));