import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { StagePage } from 'src/components'
import { ApplicationStatusCard } from "./components"
import { ApplicationCardsMenu } from "./CardsMenu"
import { CancelApplicationDrawer } from "../Applications/CancelApplicationDrawer"
import { CoaccreditDrawer } from "../Applications/CoaccreditDrawer"

import { useDispatch, useSelector } from "react-redux"
import { applicationCategoriesActions } from "src/store/application/actions"

import { segment } from "src/helpers"
import { useStyles } from "./styles"
import _ from "lodash"

export function HomeMain() {
	const { classes, cx } = useStyles()
	const dispatch = useDispatch()

	const customer = useSelector(state => state.auth.customer)
	const lastOneApplication = useSelector(state => state.applications.lastApplication)
	const isSegmentLoaded = useSelector(state => state.auth.isSegmentLoaded)
	const canceledCategories = useSelector(state => state.applications.categories?.data || [])

	const [cancelApplicationOpen, setCancelApplicationOpen] = useState(false)
	const [coaccreditOpen, setCoaccreditOpenOpen] = useState(false)

	const showApplicationCards = !['cancelled', 'rejected'].includes(lastOneApplication?.status || '')

	useEffect(() => {
		if (isSegmentLoaded) {
			segment.page('Home')
		}

		if (canceledCategories.length <= 0) {
			dispatch(applicationCategoriesActions.getAll({
				search: {
					status_eq: 'cancelled'
				},
				perPage: 200
			}))
		}
	}, [window.analytics])

	return (
		<>
			<CancelApplicationDrawer
				application={lastOneApplication}
				open={cancelApplicationOpen}
				onClose={() => setCancelApplicationOpen(false)}
			/>

			<CoaccreditDrawer
				application={lastOneApplication}
				open={coaccreditOpen}
				onClose={() => setCoaccreditOpenOpen(false)}
			/>

			<StagePage
				title={customer?.first_name ? `¡Hola ${customer?.first_name || ''}!` : '¡Hola!'}
				titleProps={{
					className: classes.stagePageTitle
				}}
			>
				<Grid
					container
					spacing={1}
				>

					<Grid item xs={12}>
						<ApplicationStatusCard />
					</Grid>

					{showApplicationCards && (
						<Grid item xs={12}>
							<ApplicationCardsMenu
								setCancelApplicationOpen={setCancelApplicationOpen}
								setCoaccreditOpen={setCoaccreditOpenOpen}
							/>
						</Grid>
					)}

				</Grid>
			</StagePage>
		</>
	)
}