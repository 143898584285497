import { currencyString, formatDate } from "src/helpers";
import { Field, PhoneType, Employment, CatalogsType } from "src/types";
import { mapCatalogToOptions } from "src/helpers/credit";

/**
 * Kronos Bancoppel fields
 */
export let bancoppelCreditParticipantEmploymentFields = ({
    salaryScheme,
    employmentWorkSector,
    employmentEconomicActivity,
}: CatalogsType): Field<keyof Employment>[] => [
        {
            label: "Tipo de empleo",
            field_type: "select_field",
            key: 'salary_scheme',
            name: 'i_empleo_tipo_empleo',
            options: salaryScheme?.map(scheme => ({
                value: scheme?.attributes?.external_id,
                caption: scheme?.attributes?.description
            })),
            row: 1,
            width: 4,
            sort_order: 1,
            disabled: false,
            required: true,
            config: {
                disableRenderRadio: true,
                row: true,
            }
        },
        {
            label: "Sector en el trabaja el cliente",
            field_type: "select_field",
            key: 'work_sector',
            name: 'i_persona_sector',
            row: 1,
            width: 4,
            sort_order: 2,
            required: true,
            options: mapCatalogToOptions(employmentWorkSector)
        },
        {
            label: "Razon social de la empresa",
            field_type: "text_field",
            key: 'company_legal_name',
            name: 'n_empleo_razon_social',
            row: 1,
            width: 4,
            sort_order: 2,
            required: true,
        },
        {
            label: "Actividad económica de la empresa",
            field_type: "select_field",
            key: 'economic_activity',
            name: 'n_empleo_actividad_economica',
            row: 1,
            width: 4,
            sort_order: 2,
            required: true,
            options: mapCatalogToOptions(employmentEconomicActivity),
            config: {
                convertAutocomplete: true
            }
        },
        {
            label: "Teléfono del empleo",
            field_type: "phone",
            key: 'default_phone',
            row: 1,
            width: 4,
            required: true,
        },
        // {
        //     label: "Continuidad Laboral (en meses)",
        //     field_type: "number",
        //     key: 'employment_continuity',
        //     name: 'q_empleo_antiguedad_laboral',
        //     row: 1,
        //     width: 4,
        //     sort_order: 2,
        //     required: true,
        // },
        {
            label: "Sueldo mensual neto",
            field_type: "currency",
            key: 'net_salary',
            name: 'n_empleo_sueldo_mensual',
            row: 1,
            width: 4,
            sort_order: 1,
            required: true,
        },
        {
            label: "Fecha de inicio del empleo",
            field_type: "date",
            key: 'starts_on',
            row: 1,
            width: 4,
            sort_order: 2,
            required: true,
            config: {
                variant: "picker"
            },
        },
        {
            label: "Fecha de fin del empleo",
            field_type: "date",
            key: 'ends_on',
            row: 1,
            width: 4,
            sort_order: 3,
            config: {
                variant: "picker"
            },
        },
    ]