import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	buttonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center'
	},
	container: {
		padding: '45px 76px 45px 30px',
		[theme.breakpoints.down('sm')]: {
			padding: '32px 16px 78px'
		}
	},
	button: {
		padding: '8px 30px',
		fontSize: 14,
		height: 36,
	},
	emptyState: {
		paddingTop: 75
	},
	addresses: {
		paddingTop: 40
	},
	item: {
		border: '1px solid #CFD5E2',
		boxShadow: ' 0px 5px 22px rgba(57, 74, 97, 0.12)',
		borderRadius: 8,
		marginBottom: 30,
		padding: 24,
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '8px 24px',
		background: 'rgba(117, 139, 183, 0.04)',
		borderBottom: '1px solid #CFD5E2',
		'& > h3': {
			color: theme.palette.primary.dark,
			fontSize: 24,
			fontWeight: 'bold',
			letterSpacing: '0.19px'
		},
		'& > p': {
			color: theme.palette.primary.dark,
			fontSize: 24,
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		'& span': {
			color: theme.palette.primary.main,
		},
		[theme.breakpoints.down('md')]: {
			'& > p': {
				fontSize: 20,
			},
		}
	},
	address: {
		padding: '32px 24px',
		'& > p': {
			color: '#242C37',
			fontSize: 18
		}
	},

	addressTitle: {
		fontSize: 20,
		lineHeight: '24px',
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			lineHeight: '16px',
			marginBottom: 4

		}
	},
	addressDetial: {
		[theme.breakpoints.down(500)]: {
			flexDirection: 'column',
			alignItems: 'flex-end'
		}
	}
}));