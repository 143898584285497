import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    stagePage: {
        [theme.breakpoints.down('sm')]: {
            padding: '32px 16px 78px'
        }
    },
    card: {
        border: "1px solid #CFD5E2",
        borderRadius: 8,
        padding: "28px 40px",
        marginTop: 24,
        [theme.breakpoints.down('sm')]: {
            padding: "24px 8px 24px 22px",
            // border: "none",
            // backgroundColor: 'transparent',
            overflow: 'visible'
        }
    },
    submitButton: {
        [theme.breakpoints.down('sm')]: {
            marginRight: 16,
            width: 153
        }
    },
    deleteButton: {
        color: '#8892A0',
        fontWeight: 'bold',
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            width: 100
        }
    }
}));