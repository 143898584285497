import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    card: {
        width: '100%',
        padding: '24px 16px',
        border: '1px solid #CFD5E2',
        borderRadius: 8,
        marginBottom: 24,
    },
    stages: {
        minHeight: 160,
        '& h3': {
            fontSize: 20,
            fontWeight: 'bold',
            color: theme.palette.primary.dark,
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            '& a': {
                width: '100%'
            },
            '& button': {
                minHeight: 52,
                width: '100%'
            }
        }
    }
}));
