import { cookies, qs } from "."
import queryString from "query-string";
import _ from "lodash";

/** Use to get kredi data params from cookies or sessionStorage */
export function getKrediParams() {
    const queryParams = cookies.get('kredi_data') || sessionStorage.getItem('kredi_data') || window.location.search;
    if (_.isEmpty(queryParams)) {
        return {};
    }
    const params = queryString.parse(queryParams);

    return {
        referral_code: params.referral_code,
        // @ts-ignore
        utm_data: getParams(qs.parse(params), 'utm'),
        // @ts-ignore
        answers: getParams(qs.parse(params), 'answer')?.answer,

    };
}

export function getParams(queryParams: any[], paramKey: string): any {
    const params = {};
    _.forEach(queryParams, (value: any, key: any) => {
        if (key.includes(paramKey)) {
            //@ts-ignore
            params[key] = value;
        }
    });
    return params;
}

export function getDomainName(hostname: string): string {
    return hostname?.substring(hostname?.lastIndexOf(".", hostname?.lastIndexOf(".") - 1) + 1);
}