export const QUESTION_TYPES = {
	GET_QUESTIONS: "QUESTION/GET_QUESTIONS",
	SET_QUESTIONS: "QUESTION/SET_QUESTIONS",
	GET_CONDITIONED_QUESTIONS: "QUESTION/GET_CONDITIONED_QUESTIONS",
	SAVE_ANSWERS: "QUESTION/SAVE_ANSWERS",
	GET_ADDRESS_BY_ZIP_CODE: "QUESTION/GET_ADDRESS_BY_ZIP_CODE",
	HANDLE_ZIP_CODE_ERROR: "QUESTION/HANDLE_ZIP_CODE_ERROR",
	DOWNLOAD_FILE: "QUESTIONS/DOWNLOAD_FILE",
	ANSWERS_BACKUP: "APPLICATION/ANSWERS_BACKUP",
	ONLY_SAVE_ANSWERS: "APPLICATION/ONLY_SAVE_ANSWERS",
	CLEAN_COUNTRY_INPUTS: "ONBOARDING_QUESTION/CLEAN_COUNTRY_INPUTS",

}