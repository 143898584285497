import { call, takeLatest, put } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { Service } from "src/services";
import { Action, Response } from "src/types";
import { BANK_ACCOUNTS_TYPES } from "./types";
import { bankAccountsActions } from "./actions";

const applicationWorkflowService = new Service('application_workflows');

function* getAll({ payload }: Action) {
    const response: Response = yield call(applicationWorkflowService.allPath, `${payload.applicationId}/bank_accounts`, {})
    yield put(bankAccountsActions.setAll(response.data))
}

function* getOne({ payload }: Action) {
    const response: Response = yield call(applicationWorkflowService.allPath, `${payload.applicationId}/bank_accounts/${payload.id}`, {})
    yield put(bankAccountsActions.setOne(response.data))
}

function* create({ payload }: Action) {
    yield call(applicationWorkflowService.createPath, `${payload.applicationId}/bank_accounts`, payload.bankAccount)
    yield put(bankAccountsActions.getAll(payload.applicationId))
}

function* update({ payload }: Action) {
    yield call(applicationWorkflowService.updateCustomPath, `${payload.applicationId}/bank_accounts/${payload.id}`, payload.bankAccount)
    yield put(bankAccountsActions.getAll(payload.applicationId))
}

function* destroy({ payload }: Action) {
    yield call(applicationWorkflowService.destroyPath, `${payload.applicationId}/bank_accounts`, payload.id)
    yield put(bankAccountsActions.getAll(payload.applicationId))
}

function* updateAttachment({ payload }: Action) {
    yield call(applicationWorkflowService.updateCustomPath, `${payload.applicationId}/bank_accounts/${payload.id}/update_attachment`, payload.attachment)
}

export function* bankAccountsSagas() {
    yield takeLatest(BANK_ACCOUNTS_TYPES.GET_ALL, sagaWrapper(getAll, bankAccountsActions.setAll()))
    yield takeLatest(BANK_ACCOUNTS_TYPES.GET_ONE, sagaWrapper(getOne, bankAccountsActions.setOne()))
    yield takeLatest(BANK_ACCOUNTS_TYPES.CREATE, sagaWrapper(create))
    yield takeLatest(BANK_ACCOUNTS_TYPES.UPDATE, sagaWrapper(update))
    yield takeLatest(BANK_ACCOUNTS_TYPES.DESTROY, sagaWrapper(destroy))
    yield takeLatest(BANK_ACCOUNTS_TYPES.UPDATE_ATTACHMENT, sagaWrapper(updateAttachment))
}