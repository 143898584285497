import { useState } from "react"
import { useParams } from "react-router-dom"
import { CircularProgress, IconButton } from "@mui/material"
import { ConfirmModal, OptionsMenu, Tooltip } from "src/components"

import { MonitoringIcon } from "src/assets/icons"
import { DownloadIcon, EmailIcon } from "src/assets/icons/actions"

import { useDispatch, useSelector } from "react-redux"
import { creditProposalsActions } from "src/store/creditProposals/actions"

import { useStyles } from "./styles"

export function StagePageActions() {
    const { applicationId }: any = useParams()
    const { classes } = useStyles()
    const dispatch = useDispatch()

    const isDownloading = useSelector(state => state.creditProposals.isDownloading)
    const creditProposal = useSelector(state => state.creditProposals.creditProposal)

    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const [amortizationTableAnchorEl, setAmortizationTableAnchorEl] = useState<null | HTMLElement>(null)

    const amortizationDownloadOptions = [
        {
            label: 'Descargar tabla de amortización en PDF',
            onClick: () => {
                dispatch(creditProposalsActions.downloadAmortizationTable(
                    applicationId,
                    creditProposal,
                    { withTaxes: true }
                ))
                setAmortizationTableAnchorEl(null)
            }
        },
        {
            label: 'Descargar tabla de amortización en Excel',
            onClick: () => {
                dispatch(creditProposalsActions.downloadAmortizationTable(
                    applicationId,
                    creditProposal,
                    { withTaxes: true, format: 'excel' }
                ))
                setAmortizationTableAnchorEl(null)
            }
        }
    ]

    return (
        <>
            <ConfirmModal
                open={confirmationOpen}
                title="Enviar propuesta"
                description="¿Deseas enviar la propuesta por email?"
                confirmText="Sí, enviar"
                onConfirm={() => {
                    dispatch(creditProposalsActions.sendEmail(creditProposal?.id!))
                    setConfirmationOpen(false)
                }}
                onCancel={() => setConfirmationOpen(false)}
            />

            <OptionsMenu
                anchorEl={amortizationTableAnchorEl}
                onClose={() => setAmortizationTableAnchorEl(null)}
                options={amortizationDownloadOptions}
            />

            <div className={classes.root}>
                <Tooltip title={isDownloading ? '' : "Descargar tabla de amortización"}>
                    <IconButton
                        size="small"
                        color="primary"
                        className={classes.actionButton}
                        disabled={isDownloading || !creditProposal?.id}
                        onClick={e => setAmortizationTableAnchorEl(e.currentTarget)}
                    >
                        {isDownloading ? <CircularProgress size={20} /> : <MonitoringIcon />}
                    </IconButton>
                </Tooltip>

                <Tooltip title={isDownloading ? '' : "Enviar propuesta"}>
                    <IconButton
                        size="small"
                        color="primary"
                        className={classes.actionButton}
                        disabled={isDownloading || !creditProposal?.id}
                        onClick={() => setConfirmationOpen(true)}
                    >
                        {isDownloading ? <CircularProgress size={20} /> : <EmailIcon />}
                    </IconButton>
                </Tooltip>

                <Tooltip title={isDownloading ? '' : "Descargar propuesta"}>
                    <IconButton
                        size="small"
                        color="primary"
                        className={classes.actionButton}
                        disabled={isDownloading || !creditProposal?.id}
                        onClick={e => dispatch(creditProposalsActions.download(applicationId, creditProposal, false))}
                    >
                        {isDownloading ? <CircularProgress size={20} /> : <DownloadIcon />}
                    </IconButton>
                </Tooltip>
            </div>
        </>
    )
}