import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        height: '100%',
        display: 'flex',
    },
    content: {
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: '#FAFAFC',
        justifyContent: 'center',
        width: 'calc(100% - 238px)',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    sidebar: {
        background: '#fff',
        width: 238,
        '& a:last-child': {
            marginTop: 'auto',
            marginBottom: 16
        },
        '& a > div': {
            paddingLeft: '16px',
            paddingRight: '16px',
        }
    }
}));