import { useSelector } from "react-redux"
import { Button, Chip, ChipProps, Divider, Grid, Typography } from "@mui/material"
import { ArrowForward as ArrowIcon } from "@mui/icons-material"

import { Link } from "src/components"

import { Application, ApplicationWorkflowStage } from "src/types"
import { STAGE_STATUS, getStatusColor, isExpiredStatus, translateStatus, useIsMobile } from "src/helpers"
import { getStatusInfo } from "./helpers"

import { useStyles } from "./styles"
import _ from "lodash"

interface ApplicationStatusProps {
    currentApplicationWorkflowStage?: ApplicationWorkflowStage
    application?: Application
    chipProps?: ChipProps
}

export function ApplicationStatus({ application, currentApplicationWorkflowStage, chipProps }: ApplicationStatusProps) {
    const { classes, cx } = useStyles()
    const isMobile = useIsMobile()

    const hasRejectedDocuments = useSelector(state => state.applications.rejectedDocuments.hasAny)

    const hasCredit = Boolean(application?.credit_id)
    const _isExpiredStatus = isExpiredStatus(application);
    const applicationInfo = getStatusInfo(application, _isExpiredStatus, hasRejectedDocuments, hasCredit);
    const status = getStatusColor(application?.status || '');
    const continueAppLink = `/application/${application?.id}/${application?.current_stage?.slug}/${application?.current_section?.slug}?from=summary`;
    const detailLink = `/home/applications/summary/${application?.id || ''}/main`;

    const canContinueApplication = currentApplicationWorkflowStage?.status !== STAGE_STATUS.IN_REVIEW && currentApplicationWorkflowStage?.visibility == 'customer'
    const withoutApplication = _.isEmpty(application)

    const Image = () => {
        const StatusImage = applicationInfo?.Icon;
        const StatusImageMobile = applicationInfo?.IconMobile;
        return (isMobile && StatusImageMobile ? <StatusImageMobile /> : <StatusImage />)
    }

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.container}>
                <Typography variant="h1" className={classes.title}>
                    {applicationInfo.title}
                </Typography>
                <Typography variant="subtitle1" className={classes.description}>
                    {applicationInfo.description}
                </Typography>

                {(application && !_isExpiredStatus) && (
                    <div className={classes.dataContainer}>
                        <div className={classes.applicationDataContainer}>
                            <Typography className={cx(classes.fontWeightBold, classes.dataText)}>
                                Solicitud
                            </Typography>
                            <Typography className={classes.dataText}>
                                {application?.workflow_title}
                            </Typography>
                        </div>

                        {(!hasRejectedDocuments && !hasCredit) && (
                            <>
                                <Divider orientation="vertical" flexItem className={classes.divider} />
                                <div className={classes.statusContainer}>
                                    <Typography className={cx(classes.fontWeightBold, classes.dataText)}>
                                        Estatus
                                    </Typography>
                                    <Chip
                                        className={classes.chip}
                                        color="default"
                                        label={translateStatus(application?.status || '')}
                                        {...chipProps}
                                        style={{ color: status.color, background: status.background }}
                                    />
                                </div>
                            </>
                        )}

                        {(hasRejectedDocuments && !hasCredit) && (
                            <>
                                <Divider orientation="vertical" flexItem className={classes.divider} />
                                <div className={classes.statusContainer}>
                                    <Link to={`/home/applications/summary/${application?.id}/documents`}>
                                        <Chip
                                            className={cx(classes.chip, classes.rejectedDocumentsChip)}
                                            color="default"
                                            label="Por favor valida los siguientes documentos"
                                            deleteIcon={<ArrowIcon fontSize="small" />}
                                            onDelete={() => { }}
                                            {...chipProps}
                                        />
                                    </Link>
                                </div>
                            </>
                        )}

                    </div>
                )}

                <div className={classes.actionsContainer}>
                    {(application?.status === 'draft' && !hasCredit) ? (
                        <>
                            <Link to={continueAppLink} disabled={applicationInfo?.disabled}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.primaryButton}
                                    disabled={applicationInfo?.disabled}
                                >
                                    {applicationInfo.buttonTitle}
                                </Button>
                            </Link>
                            <Link to={detailLink}>
                                <Button
                                    variant="text"
                                    color="primary"
                                    className={cx(classes.secondaryButton)}
                                >
                                    Ver detalles
                                </Button>
                            </Link>
                        </>
                    ) : (
                        <>
                            {(canContinueApplication || hasCredit) && (
                                <Link
                                    to={!applicationInfo.linkTo ? detailLink : applicationInfo.linkTo}
                                    disabled={applicationInfo?.disabled}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.primaryButton}
                                        disabled={applicationInfo?.disabled}
                                    >
                                        {applicationInfo.buttonTitle}
                                    </Button>
                                </Link>
                            )}

                            {!hasCredit && (['rejected', 'cancelled'].includes(application?.status || '') || (withoutApplication)) && (
                                <Link to='/application'>
                                    <Button
                                        variant={withoutApplication ? "contained" : "text"}
                                        color="primary"
                                        className={cx(classes.secondaryButton, { [classes.secondaryButtonWithoutApplications]: withoutApplication })}
                                    >
                                        Nueva solicitud
                                    </Button>
                                </Link>
                            )}

                            {!hasCredit && (['in_process'].includes(application?.status || '') && !_isExpiredStatus) && (
                                <Link to={detailLink} className={cx({ [classes.link]: !canContinueApplication })}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        className={cx({
                                            [classes.secondaryButton]: canContinueApplication,
                                            [classes.secondSecondarybutton]: !canContinueApplication
                                        })}
                                    >
                                        Ver detalles
                                    </Button>
                                </Link>
                            )}
                        </>
                    )}
                </div>

            </Grid>
            <Grid item className={classes.imageContainer}>
                <Image />
            </Grid>
        </Grid>
    )
}