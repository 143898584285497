import { Fragment } from "react";
import { FormBuilder as KrediFormBuilder, IFormBuilderProps } from "@kredimx/form-builder";
import { ButtonProps } from "@mui/material";
import { useStyles } from "./FormBuilder.styles";
import { ContractInput } from '../Inputs'

export interface SubmitButtonProps extends ButtonProps {
	dataCy?: string;
}
export interface FormBuilderProps extends IFormBuilderProps {
}

/** Use to generate a form by custom fields */
export function FormBuilder({ cancelButtonProps, submitButtonProps, actionContainerProps, ...rest }: FormBuilderProps) {
	const { classes, cx } = useStyles();
	const { className: submitClassName, ...restSubmitButtonProps } = submitButtonProps || {}
	const { className: cancelClassName, ...restCancelButtonProps } = cancelButtonProps || {}

	return (
		<KrediFormBuilder
			showValidationCheckIcon
			fieldsContainerProps={{
				style: {
					maxHeight: '100%'

				},
				...rest.fieldsContainerProps
			}}
			cancelButtonProps={{
				size: "large",
				startIcon: <Fragment />,
				className: cx(classes.button, cancelClassName),
				...restCancelButtonProps
			}}
			submitButtonProps={{
				size: "large",
				startIcon: <Fragment />,
				color: "primary",
				className: cx(classes.button, submitClassName, {
					[classes.onlySubmitButton]: rest.hideCancel
				}),
				style: {
					color: '#fff',
				},
				...restSubmitButtonProps
			}}
			itemContainerProps={{
				style: {
					marginBottom: 16
				},
				...rest.itemContainerProps
			}}
			actionContainerProps={{
				alignItems: 'center',
				...actionContainerProps
			}}
			customInputs={{
				contract: ContractInput,
			}}
			{...rest}
		/>
	)
}