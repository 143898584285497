import { tss } from "tss-react/mui"
import { lighten } from "@mui/material"

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    concept: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 4,
        '& + &': {
            marginTop: 4
        }
    },
    text: {
        fontWeight: 700,
    },
    title: {
    },
    value: {
        color: lighten(theme.palette.text.primary, 0.3)
    }
}))