import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    card: {
        border: '1px solid #CFD5E2',
        borderRadius: 8,
        padding: 24,
        background: '#fff',
    },
    item: {
        margin: '24px 0'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        '& > h3': {
            fontSize: 24,
            fontWeight: 'bold',
            color: theme.palette.primary.dark,
            marginBottom: 8
        },
    },
    button: {
        maxWidth: '100%',
        width: 166,
        marginTop: 24,
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    label: {
        fontWeight: 900,
        lineHeight: '24px',
        maxWidth: '100%',
        wordBreak: 'break-word',
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
        }
    },
    value: {
        fontWeight: 300,
        lineHeight: '24px',
        maxWidth: '100%',
        wordBreak: 'break-word',
        fontSize: 16,
        color: '#8892A0',
        [theme.breakpoints.down('sm')]: {
        }
    },
}))