import { BANK_ACCOUNTS_TYPES } from "./types"

function getAll(applicationId: string) {
    return {
        type: BANK_ACCOUNTS_TYPES.GET_ALL,
        payload: { applicationId }
    }
}

function setAll(data?: any) {
    return {
        type: BANK_ACCOUNTS_TYPES.SET_ALL,
        payload: { data }
    }
}

function getOne(id: string) {
    return {
        type: BANK_ACCOUNTS_TYPES.GET_ONE,
        payload: { id }
    }
}

function setOne(bankAccount?: any) {
    return {
        type: BANK_ACCOUNTS_TYPES.SET_ONE,
        payload: { bankAccount }
    }
}

function create(applicationId: string, bankAccount: any) {
    return {
        type: BANK_ACCOUNTS_TYPES.CREATE,
        payload: { applicationId, bankAccount }
    }
}

function update(applicationId: string, id: string, bankAccount: any) {
    return {
        type: BANK_ACCOUNTS_TYPES.UPDATE,
        payload: { applicationId, id, bankAccount }
    }
}

function destroy(applicationId: string, id: string) {
    return {
        type: BANK_ACCOUNTS_TYPES.DESTROY,
        payload: { applicationId, id }
    }
}

function updateAttachment(applicationId: string, id: string, attachment: FormData) {
    return {
        type: BANK_ACCOUNTS_TYPES.UPDATE_ATTACHMENT,
        payload: { applicationId, id, attachment }
    }
}

export const bankAccountsActions = {
    getAll,
    setAll,
    getOne,
    setOne,
    create,
    update,
    destroy,
    updateAttachment
}