import { Action, ParticipantType } from "src/types";
import { PARTICIPANTS_TYPES } from "./types";

interface ParticipantsStateType {
    data?: ParticipantType[],
    participant?: ParticipantType,
    isLoading: boolean
}

const INITIAL_STATE: ParticipantsStateType = {
    isLoading: false
}

export function participantsReducer(state: ParticipantsStateType = INITIAL_STATE, action: Action): ParticipantsStateType {
    const { type, payload } = action
    switch (type) {

        case PARTICIPANTS_TYPES.GET_ALL:
        case PARTICIPANTS_TYPES.GET_ONE: {
            return {
                ...state,
                isLoading: true
            }
        }

        case PARTICIPANTS_TYPES.SET_ONE: {
            return {
                ...state,
                isLoading: false,
                participant: payload.participant
            }
        }

        case PARTICIPANTS_TYPES.SET_ALL: {
            return {
                ...state,
                isLoading: false,
                data: payload.participants
            }
        }

        default:
            return state
    }
}