import { DrawerRight, TextField } from "src/components";
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Typography, Zoom } from "@mui/material";
import CancelApplicationImg from 'src/assets/img/cancel_application_sad.svg';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applicationCategoriesActions } from "src/store/application/categories/actions";
import { Application } from "src/types";
import { isCancelledOrRejected, isValidId } from "src/helpers";
import { useStyles } from "./styles";
import _ from "lodash";

interface CancelApplicationDrawerProps {
    application?: Application;
    open: boolean;
    onClose: () => void;
}

export function CancelApplicationDrawer({ application, open, onClose }: CancelApplicationDrawerProps) {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const [subCategory, setSubCategory] = useState('')
    // const [reason, setReason] = useState('')
    const canceledCategories = useSelector(state => state.applications.categories?.data || [])
    const subCategories = canceledCategories.map(item => item.subCategories).reduce((prev, current) => [...prev, ...current], [])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubCategory(event.target.value);
    };

    const handleCancelApplication = () => {
        if (!_.isEmpty(application) && isValidId(application?.id)) {
            dispatch(applicationCategoriesActions.create(application?.id || '', subCategory, 'list'))
            close();
        }
    }

    const close = () => {
        setSubCategory('')
        // setReason('')
        onClose();
    }

    return (
        <DrawerRight
            open={open || false}
            onClose={close}
            title="Cancelar mi solicitud"
            containerProps={{
                className: classes.container
            }}
        >
            <Grid container className={classes.gridContainer}>
                <Grid item xs={12} className={classes.imgContainer}>
                    <CancelApplicationImg />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.drawerDescription}>
                        Lamentamos que canceles tu solicitud, por favor indícanos el motivo de tu cancelación,
                        esto nos ayudará a ofrecerte un mejor servicio
                    </Typography>
                </Grid>
                {/* if is add the rejected reason uncomment and replace the following line ... */}
                {/* <Grid item xs={12} style={{ marginBottom: subCategory === '' ? 0 : 24 }}> */}
                <Grid item xs={12}> {/* ... for this */}
                    <RadioGroup name="sub_category" value={subCategory} onChange={handleChange}>
                        {subCategories.map((item, index) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    value={item.id}
                                    control={<Radio />}
                                    label={item.name}
                                    className={classes.radio}
                                />
                            );
                        })}
                        {/* <FormControlLabel
                            value=''
                            control={<Radio />}
                            label='Otro'
                            className={classes.radio}
                            style={{ marginBottom: 0 }}
                        /> */}
                    </RadioGroup>
                </Grid>
                {/* {subCategory === '' && (
                    <Zoom in={true}>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={3}
                                placeholder='Ingresa tu respuesta'
                                variant="outlined"
                                value={reason}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReason(e.target.value)}
                            />
                        </Grid>
                    </Zoom>
                )} */}
                {/* {['cancelled', 'rejected'].includes(application?.status || '') && (
                    <Typography className={classes.statusMessage}> La solicitud ya se encuentra cancelada o rechazada </Typography>
                )} */}
                <Grid item xs={6}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        onClick={close}
                        style={{ marginRight: 11 }}
                    >
                        Volver
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        onClick={handleCancelApplication}
                        style={{ marginLeft: 11 }}
                        // disabled={subCategory === '' && reason === ''}
                        disabled={_.isEmpty(subCategory) || isCancelledOrRejected(application)}
                    >
                        Cancelar solicitud
                    </Button>
                </Grid>
            </Grid>
        </DrawerRight>
    );
}